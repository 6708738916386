import { displayDate } from "utils/formatter";

const DueDate = ({
  startDate,
  endDate,
}: {
  startDate: string;
  endDate: string;
}) => (
  <div className="date-box type-limit">
    <span className="subject">마감일</span>{" "}
    <span className="con">
      {displayDate(startDate)} - {displayDate(endDate)}
    </span>
  </div>
);
export default DueDate;
