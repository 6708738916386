import { Breadcrumb, Tab, Tabs } from "react-bootstrap";

import DentInCourseTable from "components/Admin/DentInCourseTable";

const AdminEducationDentInPage = () => (
  <>
    <Breadcrumb>
      <Breadcrumb.Item>치과인 강좌</Breadcrumb.Item>
    </Breadcrumb>

    <Tabs defaultActiveKey="home">
      <Tab eventKey="home" title="온라인 교육">
        <DentInCourseTable />

      </Tab>
      {/* <Tab eventKey="notice" title="공지사항">
        <CourseNoticeTable />
      </Tab> */}
    </Tabs>
  </>
);
export default AdminEducationDentInPage;
