import MobileHeadBox from "components/MobileHeadBox";
import useWorkArea, { useWorkAreaOptions } from "hooks/useWorkArea";

import RegionImg from "assets/img/ic_hope_region.svg";
import WorkAreaSelector from "components/WorkAreaSelector";

const WorkAreaPopup = ({ onClose }: { onClose: () => void }) => {
  const {
    workAreas,
    handler: { getArea },
  } = useWorkAreaOptions();
  const {
    handler: { setFirstSeq, setSecondSeq, register },
  } = useWorkArea();

  const onCloseWithAlert = () => {
    alert("작성하신 희망근무지역은 회원정보에서 수정이 가능합니다");
    onClose();
  };

  return (
    <article className="popup" style={{ display: "block" }}>
      <div className="popup-wrap">
        <div className="popup-head">
          <MobileHeadBox popup onClose={onCloseWithAlert} />

          <button className="close-btn" onClick={onCloseWithAlert}></button>
        </div>

        <div className="popup-body">
          <article className="popup-hope-region">
            <div className="ic-box">
              <img src={RegionImg} alt="이미지" />
            </div>

            <div className="txt-box">
              <div className="main-box">희망근무지역 선택하기</div>
              <div className="sub-box">
                {/* 희망하시는 근무지역을 선택해주세요!(최대 2개) */}
                작성하신 희망근무지역은 회원정보에서 수정이 가능해요!
              </div>
            </div>

            <div className="select-list">
              <div className="row">
                <div className="subject">1순위</div>
                <div className="con">
                  <WorkAreaSelector
                    workArea={workAreas}
                    onSelect={(id: string) => {
                      const area = getArea(id);
                      setFirstSeq(area);
                    }}
                  />
                </div>
              </div>

              <div className="row">
                <div className="subject">2순위</div>
                <div className="con">
                  <WorkAreaSelector
                    workArea={workAreas}
                    onSelect={(id: string) => {
                      const area = getArea(id);
                      setSecondSeq(area);
                    }}
                  />
                </div>
              </div>
            </div>
          </article>
        </div>
        <div className="popup-foot">
          <div className="foot-menu-box">
            <button
              className="foot-menu-btn type-1"
              onClick={async () => {
                await register();
                onCloseWithAlert();
              }}
            >
              완료
            </button>
          </div>
        </div>
      </div>
    </article>
  );
};
export default WorkAreaPopup;
