import { useEffect, useState } from "react";
import moment from "moment";

import { fetchJobPosting } from "api/jobPosting/rests";
import { IJobOpeningView } from "api/jobPosting/types";
import { displaySalaryInfo, displayWorkExperience } from "utils/formatter";
import { JOBPOSTING_PATH } from "routes";
import ApplyPopup from "components/Popups/ApplyPopup";
import { useUserContext } from "contexts/UserContext";
import { USER_TYPE } from "utils/enums";

const JobPostingDiffPopup = ({
  selectedJobId,
  onClose,
}: {
  selectedJobId: number[];
  onClose: () => void;
}) => {
  const { isSignIn, userType } = useUserContext();
  const [selectedJobPostingForApply, setSelectedJobPostingForApply] =
    useState<number>(0);
  const [jobPostings, setJobPostings] = useState<IJobOpeningView[]>([]);

  useEffect(() => {
    if (selectedJobId.length === 0) return;

    (async () => {
      const datas = await Promise.all(
        selectedJobId.map((id) => fetchJobPosting(id))
      );
      setJobPostings(datas.map((d) => d.data));
      // console.log(datas);
    })();
  }, [selectedJobId]);

  return (
    <article
      className="popup popup-compare-detail"
      style={{ display: "block" }}
    >
      <div className="popup-wrap">
        <div className="popup-head">
          <div className="mobile-head-box">
            <div className="mobile-head-txt"></div>
          </div>

          <button className="close-btn" onClick={onClose}></button>
        </div>
        <div className="popup-body">
          <article className="popup-compare-detail-form">
            <div className="list-box">
              {jobPostings.map((jp) => (
                <div key={jp.job_id} className="item-box">
                  <div className="item-head-box">
                    <div className="corp-box">{jp.clinic_name}</div>
                    {/* <div className="place-box">
                      <i className="ic ic-place"></i>
                      <span className="txt">지역 서울</span>
                    </div> */}

                    <div className="menu-box">
                      <a
                        href={`${JOBPOSTING_PATH}/${jp.job_id}`}
                        className="menu-btn type-1"
                      >
                        상세보기
                      </a>
                      {isSignIn && userType === USER_TYPE.개인회원 && (
                        <div
                          className="menu-btn type-2"
                          onClick={() =>
                            setSelectedJobPostingForApply(jp.job_id)
                          }
                        >
                          지원하기
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="item-body-box">
                    <div className="info-box">
                      <div className="info-tit-box">고용정보</div>
                      <div className="info-con-box">
                        <div className="row">
                          <div className="subject">모집직종</div>
                          <div className="con">
                            {jp.occupationList.join(" | ")}
                          </div>
                        </div>
                        <div className="row">
                          <div className="subject">담당업무</div>
                          <div className="con">{jp.work_list.join(" | ")}</div>
                        </div>
                        <div className="row">
                          <div className="subject">고용형태</div>
                          <div className="con">
                            {jp.work_type_list.join(" | ")}
                          </div>
                        </div>
                        <div className="row">
                          <div className="subject">경력조건</div>
                          <div className="con">
                            {displayWorkExperience(
                              jp.min_work_experience,
                              jp.max_work_experience
                            )}
                          </div>
                        </div>
                        <div className="row">
                          <div className="subject">학력조건</div>
                          <div className="con">{jp?.last_education_type}</div>
                        </div>
                        <div className="row">
                          <div className="subject">급여조건</div>
                          <div className="con">
                            {jp?.salary_info && jp.salary_info.salary
                              ? displaySalaryInfo(jp.salary_info)
                              : "면접시 협의"}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="info-box">
                      <div className="info-tit-box">근무정보</div>
                      <div className="info-con-box">
                        <div className="row">
                          <div className="subject">근무요일</div>
                          <div className="con">{jp?.work_day_type}</div>
                        </div>
                        <div className="row">
                          <div className="subject">근무시간</div>
                          <div className="con">{jp?.work_time}</div>
                        </div>
                        <div className="row">
                          <div className="subject">복리후생</div>
                          <div className="con">
                            {(jp?.benefit_list || [])
                              .join(", ")
                              .substring(0, 25)}
                            {(jp?.benefit_list || []).join(", ").length > 25
                              ? "..."
                              : ""}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </article>
        </div>
      </div>

      {selectedJobPostingForApply > 0 && (
        <ApplyPopup
          jobId={selectedJobPostingForApply}
          handleClose={() => setSelectedJobPostingForApply(0)}
        />
      )}
    </article>
  );
};

export default JobPostingDiffPopup;
