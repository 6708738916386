import { useState } from "react";
import { wishResume, cancelWishResume } from "api/clinic/rests";
import { IResumeOverview } from "api/resume/types";
import OfferPopup from "components/Popups/OfferPopup";
import { useUserContext } from "contexts/UserContext";
import { displayDate } from "utils/formatter";
import { RESUME_PATH } from "routes";
import {
  displayYearMonth,
  monthToYearMonth,
  resourcePath,
} from "utils/formatter";
import { USER_TYPE } from "utils/enums";
import DefaultProfile from "assets/img/no_profile.png";

const ResumeOverview = ({
  resume,
  refreshResumes,
}: {
  resume: IResumeOverview;
  refreshResumes: () => void;
}) => {
  const { userType } = useUserContext();
  const [showOfferPopup, setShowOfferPopup] = useState<boolean>(false);

  const { year, month } = monthToYearMonth(resume.work_experience_month);

  const handleWish = async () => {
    try {
      await wishResume(resume.resume_id);
      await refreshResumes();
    } catch (e) {
      alert("오류가 발생하였습니다.");
    }
  };

  const handleCancelWish = async () => {
    try {
      await cancelWishResume(resume.resume_id);
      await refreshResumes();
    } catch (e) {
      alert("오류가 발생하였습니다.");
    }
  };

  return (
    <>
      <li>
        <div className="item-wrap">
          <a href={`${RESUME_PATH}/${resume.resume_id}`} className="item-box">
            <div className="img-box">
              <img
                src={
                  resume.image_filename
                    ? resourcePath(resume.image_filename)
                    : DefaultProfile
                }
                alt="이미지"
              />
            </div>

            <div className="info-box">
              <div className="tit-box">
                <div className="subject-box">{resume.title}</div>
                <div className="name-box">{resume.name}</div>
              </div>

              <article className="my-summary-box type-padding-reset">
                <div className="summary-box">
                  <div className="summary-item-box">
                    <div className="row">
                      <div className="summary-txt-box type-1">
                        {displayYearMonth(year, month)}
                      </div>
                    </div>
                    {resume.work_type_list.length > 0 && (
                      <div className="row">
                        <div className="summary-txt-box type-8">
                          고용형태 {resume.work_type_list.join(", ")}
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="summary-item-box">
                    <div className="row">
                      <div className="summary-txt-box type-6">
                        학력 {resume.last_education_name}
                      </div>
                    </div>

                    <div className="row">
                      <div className="summary-txt-box type-2">
                        지역 {resume.city}
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            </div>

            {userType === USER_TYPE.병원회원 && (
              <div className="like-toggle-btn-box mobile">
                <article className="like-toggle-btn">
                  <button
                    type="button"
                    className={resume.wish_flag ? "on" : ""}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      resume.wish_flag ? handleCancelWish() : handleWish();
                    }}
                  ></button>
                </article>
              </div>
            )}

            <div className="date-box">
              {userType === USER_TYPE.병원회원 && (
                <article className="like-toggle-btn">
                  <button
                    type="button"
                    className={resume.wish_flag ? "on" : ""}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      resume.wish_flag ? handleCancelWish() : handleWish();
                    }}
                  ></button>
                </article>
              )}

              <div className="date-item-box">
                <div className="date-subject">최근 수정일</div>
                <div className="date-txt">{displayDate(resume.upd_date)}</div>
              </div>
            </div>

            {userType === USER_TYPE.병원회원 && (
              <article className="my-menu-box">
                <div className="menu-box">
                  <button
                    type="button"
                    className="menu-btn type-1"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setShowOfferPopup(true);
                    }}
                  >
                    <span className="txt">
                      <span className="web web-inline">면접</span>제안
                    </span>
                  </button>
                </div>
              </article>
            )}
          </a>
        </div>
      </li>

      {showOfferPopup && (
        <OfferPopup
          resumeId={resume.resume_id}
          handleClose={() => setShowOfferPopup(false)}
        />
      )}
    </>
  );
};
export default ResumeOverview;
