const RememberIdCheckbox = ({
  checked,
  onChange,
}: {
  checked: boolean;
  onChange: () => void;
}) => (
  <div className="remember-box">
    <div className="cbox">
      <label>
        <input type="checkbox" checked={checked} onChange={onChange} />
        <i></i>
        <span className="txt">아이디 저장</span>
      </label>
    </div>
  </div>
);

export default RememberIdCheckbox;
