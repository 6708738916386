import { useReducer } from "react";

enum ACTIONS {
  SET_VALUE = "SET_VALUE",
  RESET = "RESET",
}
interface State {
  /**
   * 급여기준 : 연봉 | 월급
   */
  isAnnual: boolean;
  /**
   * 퇴직금 : 별도 | 포함
   */
  includePension: boolean;
  /**
   * 연봉
   */
  paycheck: number;
  /**
   * 부양가족 수(본인포함)
   */
  familyCount: number;
  /**
   * 20세 이하 자녀 수
   */
  childUnder20: number;
  /**
   * 비과세액
   */
  taxFreeAmount: number;
}
type Action =
  | {
      type: ACTIONS.SET_VALUE;
      payload: {
        key: keyof State;
        value: boolean | number;
      };
    }
  | {
      type: ACTIONS.RESET;
    };

const initial: State = {
  isAnnual: true,
  includePension: false,
  paycheck: 0,
  familyCount: 1,
  childUnder20: 0,
  taxFreeAmount: 0,
};

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case ACTIONS.SET_VALUE:
      const { key, value } = action.payload;
      return { ...state, [key]: value };
    case ACTIONS.RESET:
      return { ...initial };
    default:
      throw new Error("Unknown action type");
  }
};

const usePreviewIncome = () => {
  const [state, dispatch] = useReducer(reducer, initial);

  const _isNumber = (str: string) => /^\d+$/.test(str);

  const _set = (key: keyof State, newValue: boolean | number) =>
    dispatch({
      type: ACTIONS.SET_VALUE,
      payload: { key: key, value: newValue },
    });

  return {
    state,
    handler: {
      setAnnual: () => _set("isAnnual", true),
      setMonthly: () => _set("isAnnual", false),
      includePension: () => _set("includePension", true),
      excludePension: () => _set("includePension", false),
      setPaycheck: (paycheck: number) => _set("paycheck", paycheck),
      setFamilyCount: (count: string) =>
        _isNumber(count) && _set("familyCount", parseInt(count)),
      decreaseFamilyCount: () =>
        state.familyCount > 0 && _set("familyCount", state.familyCount - 1),
      increaseFamilyCount: () => _set("familyCount", state.familyCount + 1),
      setChildUnder20: (count: string) =>
        _isNumber(count) && _set("childUnder20", parseInt(count)),
      decreaseChildUnder20: () =>
        state.childUnder20 > 0 && _set("childUnder20", state.childUnder20 - 1),
      increaseChildUnder20: () => _set("childUnder20", state.childUnder20 + 1),
      reset: () =>
        dispatch({
          type: ACTIONS.RESET,
        }),
    },
  };
};
export default usePreviewIncome;
