import { useEffect, useState } from "react";

import { SUCCESS_RESULT } from "api/types";
import { fetchPolicied } from "api/policy/rests";
import { IPolicyView } from "api/policy/types";
import { USER_TYPE } from "utils/enums";

export enum POLICY_TYPE {
  약관 = "약관",
  개인정보 = "개인정보",
}

export enum POLICY_CATEGORY {
  개인회원 = "MEMBER",
  병원회원 = "CLINIC",
}

const usePolicies = (userType: USER_TYPE, term: string) => {
  const [policies, setPolicies] = useState<Array<IPolicyView>>([]);

  useEffect(() => {
    (async () => {
      const { data, result } = await fetchPolicied(
        userType === USER_TYPE.개인회원
          ? POLICY_CATEGORY.개인회원
          : POLICY_CATEGORY.병원회원,
        term
      );

      if (result === SUCCESS_RESULT) {
        setPolicies(data as IPolicyView[]);
      }
    })();
  }, [userType]);

  return policies;
};

export default usePolicies;
