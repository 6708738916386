import { useEffect, useState } from "react";
import Layout from "layouts";
import { Banner } from "components/commons";
import { FIND_ID_PASSWORD, LOGIN_PATH } from "routes";
import { findMemberId } from "api/member/rests";

const MemberFindIdPage = () => {
  const [authCode, setAuthCode] = useState<string>("");
  const [findedID, setFindedID] = useState<{ member_id: string; name: string }>(
    {
      member_id: "",
      name: "",
    }
  );

  const getPassAuth = () => {
    const userCode = process.env.REACT_APP_IMP_CODE;
    // @ts-ignore
    const { IMP } = window;
    // IMP.init(userCode);
    IMP.init("imp88488634");
    IMP.certification({}, (response: any) => {
      if (response.success) {
        setAuthCode(response.imp_uid);
      }
    });
  };

  useEffect(() => {
    if (!authCode) return;

    (async () => {
      try {
        const { data } = await findMemberId(authCode);
        setFindedID(data);
      } catch (e) {
        alert("가입 정보가 없습니다.");
      }
    })();
  }, [authCode]);

  return (
    <Layout extraClass="exclude-menu bg-grey" excludeMenu={true}>
      <Banner type="SIGNUP" />

      <section className="join">
        <div className="inner wrap">
          <div className="join-form find-form">
            <div className="form-box">
              <div className="form-tit-box">개인회원 아이디 찾기</div>
              <div className="form-tit-box2">본인인증🔒</div>
            </div>

            {authCode ? (
              <div className="form-box type-find">
                <div className="submit-box">
                  <div className="submit-btn off">인증이 완료되었습니다.</div>
                </div>
              </div>
            ) : (
              <div className="form-box type-find">
                <div className="submit-box">
                  <div className="submit-btn" onClick={getPassAuth}>
                    휴대폰 인증
                  </div>
                </div>
              </div>
            )}

            {findedID.member_id && (
              <div className="form-box">
                <div className="form-con-box">
                  <div className="row">
                    <div className="input-msg-box type-normal">
                      {findedID.name}님의 아이디는 <b>{findedID.member_id}</b>
                      입니다
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="form-box type-find-sub">
              <div className="form-con-box">
                <div className="menu-box">
                  <a href={`${LOGIN_PATH}`} className="menu-btn">
                    로그인
                  </a>
                </div>

                <div className="menu-box">
                  <a
                    href={`${FIND_ID_PASSWORD}/member/password`}
                    className="menu-btn"
                  >
                    비밀번호 찾기
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};
export default MemberFindIdPage;
