import PasswordChangeImg from "assets/img/ic_password_change.svg";

const ChangeSuccess = () => (
  <article className="popup-check type-success">
    <div className="ic-box">
      <img src={PasswordChangeImg} alt="이미지" />
    </div>

    <div className="txt-box">
      <div className="main-box type-1">비밀번호가 변경되었습니다.</div>
    </div>
  </article>
);

export default ChangeSuccess;
