import { IMemberView } from "api/member/types";
import { bridgeLogout } from "./appBridge";
import { USER_TYPE } from "./enums";

const KEY_AUTH_INFO = "_authInfo";
const KEY_TOKEN_INFO = "_token";
const KEY_FCM_TOKEN = "fcmToken";
const KEY_MEMBER_ID = "_memberId";

type AuthInfo = Pick<IMemberView, "member_id"> & {
  member_id: string;
  name: string;
  user_type: USER_TYPE;
  clinic_type:string;
  occupation: string;
};
type TokenInfo = {
  token: string;
  refresh_token: string;
};

const _get = (key: string) =>
  JSON.parse(window.localStorage.getItem(key) || '""');
const _set = (key: string, value: object | string) =>
  window.localStorage.setItem(key, JSON.stringify(value));

export const logout = () => {
  if (typeof window !== "undefined") {
    window.localStorage.removeItem(KEY_AUTH_INFO);
    window.localStorage.removeItem(KEY_TOKEN_INFO);
    bridgeLogout();
    // context가 남아서 이슈 생기면 reload하기
  }
};

export const getAuth = () => _get(KEY_AUTH_INFO);
export const getMemberId = () => _get(KEY_MEMBER_ID) || "";
export const getAuthToken = () => (_get(KEY_TOKEN_INFO) || { token: "" }).token;
export const getRefreshToken = () =>
  (_get(KEY_TOKEN_INFO) || { refresh_token: "" }).refresh_token;
export const getFcmToken = () => localStorage.getItem(KEY_FCM_TOKEN) || "";

export const setAuth = (authInfo: AuthInfo, callback?: () => void) => {
  _set(KEY_AUTH_INFO, authInfo);
  callback !== undefined && callback();
};
export const setMemberId = (memberId: string) => _set(KEY_MEMBER_ID, memberId);
export const setTokens = (tokenInfo: TokenInfo) => {
  _set(KEY_TOKEN_INFO, tokenInfo);
};
