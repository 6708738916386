import {
  IMemberPolicyAgreeInfo,
  IMemberPolicyAgreeRequest,
} from "api/member/types";
import { IResponse } from "api/types";
import { AxiosResponse } from "axios";
import axiosClient from "../axiosClient";

import { TResultResponseListPolicyView, IPolicyView } from "./types";

/**
 * 정책 조회
 * GET /policy
 *
 * @param {string} category - 개인회원 / 병원회원
 * @param {string} type
 * }
 */
export const fetchPolicied = async (
  category: string | null,
  type: string | null
) => {
  let param = new URLSearchParams();
  type && param.append("type", type);
  category && param.append("category", category);
  const resp: AxiosResponse<TResultResponseListPolicyView, any> =
    await axiosClient.get(`/policy?${param.toString()}`);
  console.log(resp)
  return resp.data;
};

/**
 * 정책 정보 조회
 * GET /policy/{policy_id}
 * }
 */
export const fetchPolicy = async (policy_id: number) => {
  const resp = await axiosClient.get<IResponse<IPolicyView>>(
    `/policy/${policy_id}`
  );
  return resp.data;
};

export const fetchPolicyMyList = async (userType: string) => {
  const resp = await axiosClient.get<IResponse<IMemberPolicyAgreeInfo[]>>(
    `/${userType}/policy`
  );
  return resp.data;
};
