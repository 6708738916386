import { useEffect, useState } from "react";

import { fetchLecture as fetchMemberLecture } from "api/member/rests";
import { ILectureCourseTakeView } from "api/education/types";
import { fetchLecture as fetchClinicLecture } from "api/clinic/rests";
import { useUserContext } from "contexts/UserContext";
import { USER_TYPE } from "utils/enums";

const useLecture = (courseId: number) => {
  const { userType } = useUserContext();
  const [lecture, setLecture] = useState<ILectureCourseTakeView | null>(null);

  useEffect(() => {
    if (courseId < 1) return;

    (async () => {
      const { data } =
        userType === USER_TYPE.개인회원
          ? await fetchMemberLecture(courseId)
          : await fetchClinicLecture(courseId);
      setLecture(data);
    })();
  }, [courseId, userType]);

  return lecture;
};
export default useLecture;
