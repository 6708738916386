import { SearchType, InputSearchType } from "./Comps";

enum SEARCH_TYPE {
  없음 = "없음",
  지역 = "지역",
  직종 = "직종",
  키워드 = "키워드",
}

const SearchTypeSelector = ({
  hideOccupation = false,
  searchType,
  addQuery,
  onSelect,
}: {
  hideOccupation?: boolean;
  searchType: SEARCH_TYPE;
  addQuery: (query: string) => void;
  onSelect: (type: SEARCH_TYPE) => void;
}) => (
  <article className="search-type-list">
    <div className="type-list">
      <SearchType
        label="지역 선택"
        type="region"
        selected={searchType === SEARCH_TYPE.지역}
        onClick={() =>
          onSelect(
            searchType !== SEARCH_TYPE.지역
              ? SEARCH_TYPE.지역
              : SEARCH_TYPE.없음
          )
        }
      />

      {!hideOccupation && (
        <SearchType
          label="직종 선택"
          type="job"
          selected={searchType === SEARCH_TYPE.직종}
          onClick={() =>
            onSelect(
              searchType !== SEARCH_TYPE.직종
                ? SEARCH_TYPE.직종
                : SEARCH_TYPE.없음
            )
          }
        />
      )}

      <InputSearchType
        label="키워드 검색"
        type="keyword"
        selected={searchType === SEARCH_TYPE.키워드}
        onClick={() =>
          onSelect(
            // SEARCH_TYPE.키워드
            searchType !== SEARCH_TYPE.키워드
              ? SEARCH_TYPE.키워드
              : SEARCH_TYPE.없음
          )
        }
        onConfirm={(value) => addQuery(`k|${value}`)}
      />
    </div>
  </article>
);

export default SearchTypeSelector;
