import Layout from "layouts";
import Paginator from "components/Paginator";
import { useNoticesByType } from "hooks/useNotices";
import { NOTICE_PATH } from "routes";
import { displayDate } from "utils/formatter";

const NoticeInternPage = () => {
  const {
    page,
    totalPage,
    notices,
    handler: { setPage },
  } = useNoticesByType("INTERN");

  return (
    <Layout>
      <section className="notice-list">
        <div className="inner wrap">
          <article className="board-head-box">
            <div className="head-tit-box">실습치과</div>
            <div className="head-option-box"></div>
          </article>

          <article className="board-list-box">
            <div className="table-box">
              {notices.map((notice) => (
                <a
                  href={`${NOTICE_PATH}/${notice.notice_id}`}
                  className="row-box"
                >
                  <div className="subject-box">{notice.title}</div>
                  {notice.contents && (
                    <div
                      className="data-txt-box"
                      style={{ height: "30px", overflow: "hidden" }}
                      dangerouslySetInnerHTML={{
                        __html: notice.contents.replaceAll("\n", "<br/>"),
                      }}
                    ></div>
                  )}
                  {/* <div className="con-box">{notice.contents}</div> */}

                  <div className="count-box">
                    <div className="date-box">
                      {displayDate(
                        notice.upd_date ? notice.upd_date : notice.reg_date
                      )}
                    </div>
                  </div>
                </a>
              ))}
            </div>
          </article>

          {totalPage > 1 && (
            <Paginator
              currentPage={page}
              totalPage={totalPage}
              changePage={(page) => setPage(page)}
            />
          )}
        </div>
      </section>
    </Layout>
  );
};
export default NoticeInternPage;
