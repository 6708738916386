import { Fragment } from "react";
import { IResumeView } from "api/resume/types";
import { InfoRow, SectionTitle } from "./Comps";

import {
  displayYearMonth,
  displayDate,
  monthToYearMonth,
} from "utils/formatter";

type TProps = Pick<
  IResumeView,
  "work_experience_month" | "work_experience_list"
>;

const CareerBox = ({ work_experience_month, work_experience_list }: TProps) => {
  const { year, month } = monthToYearMonth(work_experience_month);

  return (
    <div className="resume-data-group">
      <SectionTitle title="경력사항" />

      <div className="data-list-box">
        <InfoRow label="경력" value={displayYearMonth(year, month)} />

        {work_experience_month > 0 &&
          work_experience_list.map((experience, i) => (
            <Fragment key={i}>
              <InfoRow label="근무처" value={experience?.company_name} />
              <InfoRow label="담당업무" value={experience?.work} />

              <div className="row">
                <div className="subject">근무기간</div>
                <div className="con">
                  <div className="range-box">
                    <div className="range-txt">
                      {displayDate(experience?.start_date)}
                    </div>
                    <div className="range-unit">-</div>
                    <div className="range-txt">
                      {displayDate(experience?.end_date)}
                    </div>
                    <div style={{ marginLeft: "15px" }}>
                      {experience.work_flag ? "재직" : "퇴사"}
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>
          ))}
      </div>
    </div>
  );
};
export default CareerBox;
