import { useState } from "react";

const Keyword = ({
  label,
  onClick = () => {},
}: {
  label: string;
  onClick?: () => void;
}) => (
  <li>
    <div className="item-wrap">
      <a className="item-btn" onClick={onClick} role="button">
        #{label}
      </a>
    </div>
  </li>
);

const KeywordSelector = ({
  recommends,
  onClose,
  onSelect,
}: {
  recommends: Array<string>;
  onClose: () => void;
  onSelect: (query: string) => void;
}) => {
  const [value, setValue] = useState<string>("");

  return (
    <article
      className="info-search-group info-search-keyword"
      data-tabs="keyword"
      style={{ display: "block" }}
    >
      <div className="search-wrap">
        <article className="mobile-keyword-head">
          <div className="keyword-head-wrap">
            <div className="input-box">
              <button className="close-btn" onClick={onClose}></button>

              <input
                type="text"
                placeholder=""
                value={value}
                onChange={(e) => setValue(e.target.value)}
                onKeyDown={(e) => {
                  if (
                    e.key === "Enter" &&
                    e.nativeEvent.isComposing === false
                  ) {
                    !!value && onSelect(value);
                    setValue("");
                  }
                }}
              />
            </div>
          </div>
        </article>

        <div className="keyword-list">
          <div className="keyword-tit">추천 키워드</div>

          <div className="list-wrap">
            <ul>
              {recommends.map((keyword, i) => (
                <Keyword
                  key={i}
                  label={keyword}
                  onClick={() => onSelect(keyword)}
                />
              ))}
            </ul>
          </div>
        </div>
      </div>
    </article>
  );
};
export default KeywordSelector;
