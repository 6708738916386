import { LECTURE_PATH, LECTURE_SUBPATH } from "routes";

import useEducationFaqs from "hooks/useEducationFaqs";

const OverviewFaq = () => {
  const faqs = useEducationFaqs();

  return (
    <div className="edu-board-item">
      <div className="tit-box">
        <div className="tit-txt">FAQ</div>
        <a href={`${LECTURE_PATH}/${LECTURE_SUBPATH.FAQ}`} className="more-btn">
          +
        </a>
      </div>

      <div className="list-box">
        {faqs.slice(0, 3).map((faq) => (
          <a
            key={faq.faq_id}
            href={`${LECTURE_PATH}/${LECTURE_SUBPATH.FAQ}`}
            className="board-item"
          >
            <div className="board-txt-box">{faq.question}</div>
          </a>
        ))}
      </div>
    </div>
  );
};
export default OverviewFaq;
