import { useRef } from "react";

import Html2Pdf from "html2pdf.js";
import { useReactToPrint } from "react-to-print";

const PrintPopup = ({
  title,
  children,
  handleClose,
}: {
  title: string | undefined;
  children: JSX.Element | Array<JSX.Element>;
  handleClose: () => void;
}) => {
  const printArea = useRef<HTMLDivElement | null>(null);

  const handlePrint = useReactToPrint({
    content: () => {
      const currentPrintArea = printArea.current as HTMLDivElement;
      currentPrintArea.style.margin = '50px 50px';
      return currentPrintArea;
    },
    documentTitle: title,
    removeAfterPrint: true,
  });

  const downloadPdf = useReactToPrint({
    content: () => printArea.current,
    print: async (printIframe: any) => {
      const document = printIframe.contentDocument;
      if (document) {
        const html = document.getElementsByTagName("html")[0];
        const opt = {
          filename: `${title}.pdf`,
          image: { type: "jpeg", quality: 1 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
        };
        const exporter = new Html2Pdf(html, opt);
        await exporter.getPdf(true);
      }
    },
  });

  return (
    <article className="print-layer-box" style={{ display: "block" }}>
      <div className="layer-wrap">
        <div className="layer-menu-box no-print">
          <div className="left-menu-box">
            <div
              className="layer-menu-btn type-1"
              onClick={handlePrint}
            >
              <span className="txt">인쇄하기</span>
              <i className="ic ic-print"></i>
            </div>
            <div
              className="layer-menu-btn type-1"
              onClick={downloadPdf}
            >
              <span className="txt">저장하기</span>
              <i className="ic ic-save"></i>
            </div>
          </div>

          <div className="right-menu-box">
            <div
              className="layer-menu-btn type-2"
              onClick={handleClose}
            >
              <span className="txt">닫기</span>
              <i className="ic ic-close"></i>
            </div>
          </div>
        </div>

        <div
          className="layer-con-box scroll-effect"
          id="printArea"
          ref={printArea}
          style={{ border: "none" }}
        >
          <style type="text/css" media="print">
            {"@page { size: portrait; }"}
          </style>
          {children}
        </div>
      </div>
    </article>
  );
};
export default PrintPopup;
