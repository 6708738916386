import axiosClient from "../axiosClient";

import { IResponse } from "api/types";

export type TRecommendType = "JOB" | "RESUME";
/**
 * 추천 키워드 조회
 * GET /keyword/recommend
 */
export const fetchKeywords = async (recommend_type: TRecommendType) => {
  const resp = await axiosClient.get<IResponse<Array<string>>>(
    `/keyword/recommend?recommend_type=${recommend_type}`
  );

  return resp.data;
};
