import { AxiosResponse } from "axios";
import axiosClient from "../axiosClient";

import { IResponse } from "api/types";
import {
  GROUP_CODE_ID,
  ICommonCodeView,
  ICommonCodeViewWithSecondary,
} from "./types";

/**
 * 코드 목록 조회
 * GET /code
 *
 * query
 *  type: string;
 *  category: string;
 */
export const fetchCodes = async (groupCodeId?: GROUP_CODE_ID) => {
  const resp: AxiosResponse<
    IResponse<ICommonCodeView[]>,
    any
  > = await axiosClient.get(`/code?group_code_id=${groupCodeId}`);
  return resp.data;
};

/**
 * 코드 조회
 * GET /code/{code_id}
 */
export const fetchCode = async (codeId?: GROUP_CODE_ID) => {
  const resp: AxiosResponse<
    IResponse<Array<ICommonCodeView>>,
    any
  > = await axiosClient.get(`/code/${codeId}`);
  return resp.data;
};

export const fetchCodeWithSecondary = async (codeId?: GROUP_CODE_ID) => {
  const resp: AxiosResponse<
    IResponse<Array<ICommonCodeViewWithSecondary>>,
    any
  > = await axiosClient.get(`/code/${codeId}&econdary=true`);
  return resp.data;
};
