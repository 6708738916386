import PasswordPopup from "components/commons/PasswordPopup";
import useEditMode from "hooks/useEditMode";

const PasswordFieldRow = () => {
  const {
    isEditOn,
    handler: { onEditMode, offEditMode },
  } = useEditMode();

  return (
    <>
      <div className="row">
        <div className="subject-box">비밀번호</div>

        <div className="con-box">
          <div className="view-box">
            <div className="view-txt">*********</div>

            <div className="menu-box">
              <button
                className="menu-btn type-2"
                style={{ backgroundColor: "#fff" }}
                onClick={onEditMode}
              >
                수정
              </button>
            </div>
          </div>
        </div>
      </div>

      {isEditOn && <PasswordPopup handleClose={offEditMode} />}
    </>
  );
};

export default PasswordFieldRow;
