import axiosClient from "../axiosClient";

import { IPageResponse, IResponse } from "api/types";
import { INoticeView, TNoticeType, INoticeQuery, IDriveView } from "./types";

/**
 * 자료실 처리
 * GET /reference
 */
export const fetchDrives = async (page: number) => {
  const resp = await axiosClient.get<IPageResponse<Array<IDriveView>>>(
    `/reference?page=${page}&size=15`
  );
  return resp.data;
};

/**
 * 공지 정보 조회
 * GET /notice
 */
export const fetchNotices = async (page: number, notice_type?: TNoticeType) => {
  const resp = await axiosClient.get<IPageResponse<Array<INoticeView>>>(
    `/notice?notice_type=${
      notice_type || "NORMAL"
    }&page=${page}&size=15&sort_property=REG_DATE&sort_direction=DESC`
  );
  return resp.data;
};

/**
 * 자료실 정보 조회
 */
export const fetchDrive = async (drive_id: number) => {
  const resp = await axiosClient.get<IResponse<IDriveView>>(
    `/reference/${drive_id}`
  );
  return resp.data;
};

/**
 * 공지 정보 조회
 * GET /notice/{notice_id}
 */
export const fetchNotice = async (notice_id: number) => {
  const resp = await axiosClient.get<IResponse<INoticeView>>(
    `/notice/${notice_id}`
  );
  return resp.data;
};

/**
 * 공지 검색
 * POST /notice/search
 */
export const searchNotices = async (
  page: number,
  size: number,
  params: INoticeQuery
) => {
  const resp = await axiosClient.post<IPageResponse<Array<INoticeView>>>(
    `/notice/search`,
    {
      page,
      size,
      query: params,
    }
  );
  return resp.data;
};
