import { IResumeLicenseEdit } from "api/resume/types";
import DatePicker from "components/DatePicker";

const LicenseFieldBox = ({
  license,
  showAddButton = true,
  showDeleteButton = true,
  onClickAdd,
  onClickDelete,
  onChange,
}: {
  license: IResumeLicenseEdit;
  showAddButton?: boolean;
  showDeleteButton?: boolean;
  onClickAdd: () => void;
  onClickDelete: () => void;
  onChange: (license: IResumeLicenseEdit) => void;
}) => (
  <div className="item-box">
    <div className="con">
      <div className="career-box">
        <div className="mobile-row-box">
          <div className="tbox medium">
            <input
              type="text"
              placeholder="자격증명"
              value={license.license || ""}
              onChange={(e) =>
                onChange({ ...license, license: e.target.value })
              }
            />
          </div>
        </div>

        <div className="mobile-row-box">
          <div className="unit-box type-2 type-m-margin">취득일</div>
          <DatePicker
            value={license.certificate_date}
            maxDate={new Date()}
            onChange={(date) =>
              onChange({ ...license, certificate_date: date })
            }
          />
          {showAddButton && (
            <button className="career-btn type-2" onClick={onClickAdd}>
              <span className="web">특기</span>추가+
            </button>
          )}
          {showDeleteButton && (
            <button className="career-btn type-2" onClick={onClickDelete}>
              <span className="web">특기</span>삭제
            </button>
          )}
        </div>
      </div>
    </div>
  </div>
);
export default LicenseFieldBox;
