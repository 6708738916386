import Layout from "layouts";

import React, { Fragment, useEffect, useState } from "react";

import { useNavigate, useSearchParams } from "react-router-dom";

import { IPolicyView } from "api/policy/types";
import { fetchPolicied, fetchPolicy } from "api/policy/rests";
import Paginator from "components/Paginator";

const PolicyPage = () => {
  const [searchParams] = useSearchParams();

  const policyId = searchParams.get("policy_id")
    ? parseInt(searchParams.get("policy_id")!)
    : undefined;
  const category = searchParams.get("category");
  const type = searchParams.get("type");
  const title = searchParams.get("title");
  const noHeader = searchParams.get("noheader") === "true";

  const [policy, setPolicy] = useState<IPolicyView>();
  const [policyList, setPolicyList] = useState<IPolicyView[]>();
  const [currentIdx, setIdx] = useState<number>(0);

  const navigate = useNavigate();

  useEffect(() => {
    if (category || type) {
      (async () => {
        const { data } = await fetchPolicied(category, type);
        if(title) {
          let list = data.filter(x=>x.title === title).sort((a, b) => a.policy_id - b.policy_id)
          setPolicyList(list)
          setIdx(list.length-1)
        } else {
          let list = data.sort((a, b) => a.policy_id - b.policy_id)
          setPolicyList(list)
          setIdx(list.length-1)
        }
      })();
    } else if (policyId) {
      (async () => {
        const { data } = await fetchPolicy(Number(policyId));
        setPolicy(data);
      })();
    } else {
      navigate("/", { replace: true });
    }
  }, [policyId, category, type]);

  useEffect(()=>{
    if(policyList) {
      setPolicy(policyList[currentIdx]);
    }
  },[currentIdx, policyList])
  const dateFormatter: () => string = () => {

    let convertDate = policy? new Date(policy?.reg_date): new Date()
    let convertString = convertDate.getFullYear()+'.'+(convertDate.getMonth()+1)+'.'+convertDate.getDate()

    return convertString;
  }
  return (
    <Layout extraClass="bg-grey" excludeHeader={noHeader}>
      {/* <Banner /> */}

      <section className="join policy">
        <div className="inner wrap">
          <div className="intro-txt-box">{policy?.title} <span className="" style={{float:'right'}}>시행일자 : {dateFormatter()}</span></div>

          <div
            className="txt-box"
            dangerouslySetInnerHTML={{ __html: policy?.contents || "" }}
          >
            {/* {policy?.contents.split("\n").map((row, i) => (
              <Fragment key={i}>
                {row}
                <br />
              </Fragment>
            ))} */}
          </div>
          <div className="mt-5">

          </div>
          <div>
            <button disabled={(currentIdx === 0)} onClick={()=>{
              let idx = currentIdx -1
              if(idx === -1) idx =0
              setIdx(idx)
            }}>
              이전 약관
            </button>
            <button disabled={!!(policyList && policyList?.length - 1 === currentIdx)} onClick={()=>{
              let idx = currentIdx +1
              if(policyList && (idx >= policyList?.length -1)) idx = policyList?.length -1
              setIdx(idx)
            }} style={{float:'right'}}>
              다음 약관
            </button>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default PolicyPage;
