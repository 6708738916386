import { USER_TYPE } from "utils/enums";
import UserTypeSelector from "components/commons/UserTypeSelector";
import Layout from "layouts";
import { Banner } from "components/commons";

import ClinicSignup from "./ClinicSignup";
import MemberSignup from "./MemberSignup";
import { useEffect, useState } from "react";
import { useUserContext } from "contexts/UserContext";
import { useNavigate } from "react-router-dom";

const SignupPage = () => {
  const [userType, setUserType] = useState<USER_TYPE>(USER_TYPE.개인회원);
  const { isSignIn } = useUserContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (isSignIn) {
      navigate("/main", { replace: true });
    }
  }, [isSignIn]);

  return (
    <Layout extraClass="bg-grey">
      <div style={{ width: "100%" }}>
        <Banner type="SIGNUP" />
      </div>

      <section className="join">
        <div className="inner wrap">
          <div className="intro-txt-box">치과인에 오신 것을 환영합니다</div>

          <UserTypeSelector
            label="회원가입"
            userType={userType}
            onChange={(value) => setUserType(value)}
          />

          {userType === USER_TYPE.개인회원 ? (
            <MemberSignup />
          ) : (
            <ClinicSignup />
          )}
        </div>
      </section>
    </Layout>
  );
};

export default SignupPage;
