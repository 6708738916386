import { useState } from "react";

import { applyJobPosting } from "api/member/rests";
import { ResumeGroup } from "components/ResumeGroup";
import useResumeGroups from "hooks/resume/useResumeGroups";
import useResumeGroupDetail from "hooks/resume/useResumeGroupDetail";
import { displayDateTime } from "utils/formatter";

import MobileHeader from "./MobileHeader";

const ApplyPopup = ({
  jobId,
  handleClose,
  afterSuccessCallback = () => {},
}: {
  jobId: number;
  handleClose: () => void;
  afterSuccessCallback?: () => void;
}) => {
  const [selectedResumeGroupId, setSelectedResumeGroupId] = useState<number>(0);
  const [selectedResumeId, setSelectedResumeId] = useState<number>(0);

  const { resumeGroups } = useResumeGroups();
  const { resumes } = useResumeGroupDetail(selectedResumeGroupId);

  const apply = async () => {
    try {
      await applyJobPosting(jobId, selectedResumeId);
      handleClose();
      alert("지원되었습니다.");
      afterSuccessCallback();
    } catch (e: any) {
      if (/already exist/.test(e.response.data.data)) {
        alert("해당 이력서로 이미 지원된 공고입니다.");
      } else {
        alert("오류가 발생하였습니다.");
      }
    }
  };

  return (
    <article
      className="popup popup-resume-group-select"
      style={{ display: "block" }}
    >
      <div className="popup-wrap">
        <div className="popup-head">
          <MobileHeader />
          <button className="close-btn" onClick={handleClose}></button>
        </div>
        <div className="popup-body">
          <article className="popup-resume-proposition">
            <div className="tit-box">
              <div className="icon-box">
                {selectedResumeGroupId ? "📋" : "📂"}
              </div>
              <div className="txt-box">지원할 이력서를 선택하세요.</div>
              <div className="sub-box">
                {resumes.length > 0 ? (
                  "지원할 이력서를 선택해주세요!"
                ) : selectedResumeGroupId ? (
                  <>
                    치과인 이력서 작성 후 지원이 가능합니다.
                    <br />
                    아래 버튼을 클릭 후 이력서를 먼저 작성해주세요!
                  </>
                ) : (
                  "지원할 이력서의 그룹을 먼저 선택해주세요!"
                )}
              </div>
            </div>

            {/* 이력서 그룹 리스트 */}
            {resumes.length === 0 && (
              <div className="resume-group-select-list">
                {resumeGroups.map((group) => (
                  <ResumeGroup
                    key={group.group_id}
                    resumeGroup={group}
                    isEditMode={false}
                    onClickCheckbox={() => console.log(group.group_id)}
                    onClickDetail={() =>
                      setSelectedResumeGroupId(group.group_id)
                    }
                  />
                ))}
              </div>
            )}

            {/* 이력서 리스트 */}
            {resumes.length > 0 &&
              resumes.map((resume) => (
                <div key={resume.resume_id} className="resume-select-list">
                  <div className="select-box">
                    <div
                      className={`select-btn-box ${
                        selectedResumeId === resume.resume_id ? "on" : ""
                      }`}
                      onClick={() => setSelectedResumeId(resume.resume_id)}
                    >
                      <div className="date-box">
                        {displayDateTime(resume.upd_date)} 수정
                      </div>
                      <div className="subject-box">{resume.title}</div>
                      <div className="con-box">{/* {resume} */}</div>
                    </div>
                  </div>
                </div>
              ))}
          </article>
        </div>

        <div className="popup-foot">
          <div className="foot-menu-box">
            {resumes.length > 0 && (
              <div className="foot-menu-btn type-1" onClick={apply}>
                완료
              </div>
            )}
          </div>
        </div>
      </div>
    </article>
  );
};
export default ApplyPopup;
