import PasswordChangeImg from "assets/img/ic_password_change.svg";
import { checkPassword } from "utils/formatter";

const ChangePassword = ({
  isValid,
  newPassword,
  rePassword,
  setNewPassword,
  setRePassword,
}: {
  isValid?: boolean;
  newPassword: string;
  rePassword: string;
  setNewPassword: (password: string) => void;
  setRePassword: (password: string) => void;
}) => (
  <article className="popup-check">
    <div className="ic-box">
      <img src={PasswordChangeImg} alt="이미지" />
    </div>

    <div className="txt-box">
      <div className="main-box">변경할 비밀번호를 입력해주세요</div>
    </div>

    <div className="input-list-box">
      <div className="row">
        <div className="subject">변경할 비밀번호</div>
        <div className="con">
          <div className="tbox full">
            <input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </div>
          {!checkPassword(newPassword) && (
            <div className="input-msg-box">
              8~16자 영문 대/소문자, 숫자, 특수문자를 사용하세요.
            </div>
          )}
        </div>
      </div>

      <div className="row">
        <div className="subject">다시 입력해주세요</div>
        <div className="con">
          <div className="tbox full">
            <input
              type="password"
              value={rePassword}
              onChange={(e) => setRePassword(e.target.value)}
            />
          </div>

          {(isValid === false || newPassword !== rePassword) && (
            <div className="input-msg-box">비밀번호가 일치하지 않습니다.</div>
          )}
        </div>
      </div>
    </div>
  </article>
);

export default ChangePassword;
