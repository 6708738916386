import { JOBPOSTING_PATH } from "routes";
import { IJobOpeningOverview } from "api/jobPosting/types";
import { useEffect, useState } from "react";
import { searchJobPostingsByLocation } from "api/jobPosting/rests";
import { fetchWorkarea } from "api/area/rests";
import Map from "../../components/JobPostingSearch/Map";
import MapItem from "../../components/JobPostingSearch/MapItem";

/**
 * 채용정보 > 지도검색 탭
 */

const MapSearchSection = () => {
  const [mapData, setMapData] = useState<IJobOpeningOverview[]>([]);
  const [position, setPosition] = useState<number[]>([37.50802, 127.062835]);

  useEffect(() => {
    window.scroll({ top: 1 }); // 앱에서 지도검색 UI 의 스크롤이 겹치는 경우 PTR 묶기
    (async () => {
      try {
        const { data } = await fetchWorkarea();
        const firstSeq = data.sort((a, b) => a.seq - b.seq)[0];
        setPosition([firstSeq.latitude, firstSeq.longitude]);
      } catch (e) {
        if ("geolocation" in navigator) {
          navigator.geolocation.getCurrentPosition((position) => {
            setPosition([position.coords.latitude, position.coords.longitude]);
          });
        }
      }
    })();
  }, []);

  return (
    <section className="info-location">
      <div className="inner wrap">
        <div className="location-layout">
          <Map
            latitude={position[0]}
            longitude={position[1]}
            setPosition={setPosition}
            setMapData={setMapData}
            searchByLocation={searchJobPostingsByLocation}
          />
          <div className="location-box">
            <div className="tit-box">
              <div className="tit-txt">지역목록 {mapData.length}개</div>
            </div>

            <div
              className="list-box scroll-effect"
              style={{ maxHeight: "810px" }}
            >
              {mapData.map((item: IJobOpeningOverview, idx: number) => (
                <a
                  href={`${JOBPOSTING_PATH}/${item.job_id}`}
                  className="item-box"
                  key={idx}
                >
                  <MapItem item={item} />
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default MapSearchSection;
