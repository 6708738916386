import { useCallback, useEffect, useState } from "react";
import moment, { Moment } from "moment";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { LECTURE_PATH, LECTURE_SUBPATH } from "routes";
import useLecture from "hooks/lecture/useLecture";
import { axiosFile } from "utils/axiosFile";
import { useUserContext } from "contexts/UserContext";
import { USER_TYPE } from "utils/enums";
import { startLecture as startMemberLecture } from "api/member/rests";
import {
  startLecture as startClinicLecture,
  restartLecture as restartClinicLecture, completeLecture as completeClinicLecture,
} from "api/clinic/rests";
import {useInterval} from "usehooks-ts";
import {start} from "repl";

interface FileModel {
  material_download_filename: string;
  material_download_link: string;
}
const DutyLecturePlayPage = () => {
  const { userType } = useUserContext();
  const navigate = useNavigate();
  const [startTime, setStartTime] = useState<Moment>(moment());
  const [file, setFile] = useState<FileModel[]>([]);
  const { courseId } = useParams();
  const [params] = useSearchParams();
  const collectFlag = params.get("collectFlag");
  const retry = params.get("retry");
  const resume = params.get("resume");
  const [time, setTime] = useState<number>(0);
  const [displayTime, setDisplayTime] = useState<string>("");
  const [isTimerStart, setTimerStart] = useState<boolean>(false)
  const { lecture_list, lecture_type } = useLecture(Number(courseId)) || {
    lecture_list: [
      {
        lecture_id: 0,
        name: "",
        video_id: "",
        description: "",
        material_download_link: "",
        material_download_filename: "",
        provisions:""
      },
    ],
  };

  const id = lecture_list[0].lecture_id;

  const startLecture = useCallback(async () => {

    const lecture = lecture_list[0];
    if (lecture && lecture_type) {
      userType === USER_TYPE.개인회원
        ? await startMemberLecture(
            lecture_type,
            lecture.lecture_id,
            !!collectFlag
          )
        : retry
        ? await restartClinicLecture(
            lecture_type,
            lecture.lecture_id,
            !!collectFlag
          )
        : await startClinicLecture(
            lecture_type,
            lecture.lecture_id,
            !!collectFlag
          );
    }
  }, [userType, courseId, lecture_type, retry, resume]);

  useEffect(() => {
    (async () => {

      if(resume && resume === 'true') {
        return;
      }

      try {
        await startLecture();
      } catch (e) {
        alert("수강에 실패했습니다. \n 잠시 후 다시 시도해 주세요.")
        window.history.back()
      }
    })();
  }, [lecture_type, courseId, userType, retry, resume]);

  useEffect(() => {
    if(!lecture_list) {
      return;
    }
    if (id) {
      let courseTime = window.localStorage.getItem(id.toString());
      if (!courseTime) {
        window.localStorage.setItem(id.toString(), '3600');
        setTime(Number('3600'));
        setTimerStart(true)
      } else {
        setTime(Number(courseTime));
        setTimerStart(true)
      }
    }
    if(lecture_list) {
      if(lecture_list[0].material_download_filename && lecture_list[0].material_download_link) {
        // @ts-ignore
        let nameArr = lecture_list[0].material_download_filename.split(',')

        // @ts-ignore
        let linkArr = lecture_list[0].material_download_link.split(',')

        let initialArr:Array<FileModel> = []

        nameArr.forEach((val:string,i:number) => {
          initialArr.push({
            material_download_filename: val,
            material_download_link: linkArr[i],
          })
        })
        setFile(initialArr)
      }
    }

  }, [lecture_list]);

  const displayTimer = () =>{
    let min: string | number = Math.floor(time/60);
    min = min <10?'0'+min:min;
    let sec: string | number = time%60;
    sec = sec <10?'0'+sec:sec;

    setDisplayTime(min+':'+sec);
    window.localStorage.setItem(id.toString(), String(time-1));
    setTime(time-1)
    if( time <= 0 ) {
      setTimerStart(false)
      setTime(0)
      alert("수강 시간이 완료됐습니다.");
    }
  }
  useInterval(
    () => {
      displayTimer()
    },
    isTimerStart ? 1000 : null,
  )
  const listAction = () => {
    if(window.confirm('강의 목록으로 이동하시겠습니까?')) {
      window.localStorage.setItem(id.toString(), String(time));
      window.location.href = '/lecture/duty'
    }
  }

  const completeAction = async () => {
    let timestamp = moment()
    const completeTime = moment(`${timestamp.format("YYYY-MM-DD")} ${timestamp.format("HH:mm:ss")}`);
    console.log(completeTime)
    if( time <= 0 ) {
      try{
        await completeClinicLecture(
          Number(lecture_list[0].lecture_id),
          completeTime.format("YYYY-MM-DD HH:mm:ss"),
          3600
        ).then(()=>{
          window.localStorage.removeItem(id.toString())
          window.location.href = `/lecture/complete/?lectureId=${lecture_list[0].lecture_id}`
        })
      } catch (e: any) {
        if (/already complete/.test(e.response.data.data)) {
          alert("이미 수강완료 처리된 교육입니다.");
        } else {
          alert("오류가 발생하였습니다.");
        }
      }
    } else {
      alert('최소 강의 시간이 지나지 않았습니다.')
    }
  }

  return (
    <section className="edu-detail">
      <div className="detail-group">
        <div className="tit-box">
          <div>
            <div className="back-btn" onClick={() => navigate(-1)}>
              <i className="ic ic-back"></i>
            </div>
          </div>
          <div className="tit-txt">{lecture_list[0].name}</div>
        </div>

        <div className="video-box" style={{ height: "538px" }}>
          <iframe
            width="100%"
            height="100%"
            src={`https://www.youtube.com/embed/${lecture_list[0].video_id}`}
            title="YouTube video player"
            frameBorder={0}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen={false}
          ></iframe>
        </div>
        <div className="con-box border-bottom pb-3">
          <pre>
            {lecture_list[0].description}
          </pre>
        </div>
        <div className="con-box border-bottom pb-3">
          <h6 className="pb-1 text-primary">
            아래 교육 자료 배포하여 영상과 별도로 자체 교육 해주십시오
          </h6>
          <p>
            {
              file.map((v,i)=>{
                return(
                  <button
                    className="mr-3"
                    onClick={() =>
                      axiosFile(
                        v.material_download_link as string,
                        v.material_download_filename as string,
                      )
                    }
                  >
                    {v.material_download_filename} <span style={{ fontSize: '1.5rem'}}>📎</span>
                  </button>
                )
              })
            }
          </p>

        </div>
        <div className="con-box border-bottom pb-3">
          <pre>
            {lecture_list[0]?.provisions}
          </pre>
        </div>
        <div className="con-box border-bottom pb-3">
          <p>
            치과인에서 <span style={{fontWeight: "bold", color: "red"}}>법정의무교육</span>을 <span
              style={{fontWeight: "bold", color: "red"}}>집합교육</span>으로 진행할 수 있도록 서비스 하고 있습니다.<br/>
            제공되는 <span style={{fontWeight: "bold", color: "red"}}>동영상 강의와 교육자료</span>를 통해 <span
              style={{fontWeight: "bold", color: "red"}}>1시간 이상 집합교육을 진행</span>해주셔야 하며 영상 재생시간과 상관없이 아래<br/>
            <span style={{fontWeight: "bold", color: "red"}}>녹색버튼의 시간 카운트</span>가 00:00 이 되어 <span
              style={{fontWeight: "bold", color: "red"}}>수강완료</span>로 버튼이 변경된 후 <span
              style={{fontWeight: "bold", color: "red"}}>버튼을 클릭해야 최종 수강</span>이 완료됩니다.
          </p>
        </div>
        <div className="view-menu-box">
          <article className="lecture-menu-box">
            <a
              onClick={()=>{
                if(window.confirm('수강 완료 하시겠습니까?')){
                  completeAction()
                }

              }}
              className="lecture-menu-btn on"
            >
              <span className="txt">{time==0?'수강완료하기':displayTime}</span>
              <i className="ic ic-lock"></i>
            </a>
          </article>
        </div>
      </div>

      <div className="view-menu-box">
        <article className="lecture-menu-box">
          <a
            className="lecture-menu-btn"
            onClick={listAction}
          >
            <span className="txt" >목록</span>
          </a>
        </article>
      </div>
    </section>
  );
};

export default DutyLecturePlayPage;
