import Layout from "layouts";
import { FIND_ID_PASSWORD } from "routes";
import { Banner } from "components/commons";
import { useState } from "react";

const FindIdPasswordPage = () => {
  const [memberOver, setMemberOver] = useState<boolean>(false);
  const [clinicOver, setClinicOver] = useState<boolean>(false);

  return (<Layout>
    <section className="find-home">
      <div className="inner wrap">
        <div className="fine-home-group">
          <div className="find-home-tit-box">ID/PW 찾기</div>

          <div className="find-home-list-box">
            <div className="find-home-box">
              <div className="find-home-type-box" style={memberOver ? { backgroundColor: "#ededed" } : {}}>
                <i className="ic ic-person"></i>
                <span className="txt">개인회원</span>
              </div>

              <div className="find-menu-box" >
                <a
                  onMouseOver={() => setMemberOver(true)} onMouseOut={() => setMemberOver(false)}
                  href={`${FIND_ID_PASSWORD}/member/id`}
                  className="find-menu-btn"
                >
                  아이디 찾기
                </a>
                <a
                  onMouseOver={() => setMemberOver(true)} onMouseOut={() => setMemberOver(false)}
                  href={`${FIND_ID_PASSWORD}/member/password`}
                  className="find-menu-btn"
                >
                  비밀번호 찾기
                </a>
              </div>
            </div>

            <div className="find-home-box">
              <div className="find-home-type-box" style={clinicOver ? { backgroundColor: "#ededed" } : {}}>
                <i className="ic ic-corp"></i>
                <span className="txt">병원회원</span>
              </div>

              <div className="find-menu-box">
                <a
                  onMouseOver={() => setClinicOver(true)} onMouseOut={() => setClinicOver(false)}
                  href={`${FIND_ID_PASSWORD}/clinic/id`}
                  className="find-menu-btn"
                >
                  아이디 찾기
                </a>
                <a
                  onMouseOver={() => setClinicOver(true)} onMouseOut={() => setClinicOver(false)}
                  href={`${FIND_ID_PASSWORD}/clinic/password`}
                  className="find-menu-btn"
                >
                  비밀번호 찾기
                </a>
              </div>
            </div>
          </div>

          <div className="find-home-img-box">
            <Banner type="LOGIN" />
          </div>
        </div>
      </div>
    </section>
  </Layout>)
};

export default FindIdPasswordPage;
