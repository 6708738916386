import { checkAdmin } from "api/admin/rests";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Container, Row, Col, Nav } from "react-bootstrap";
import { Outlet } from "react-router-dom";
// import Navbar from "react-bootstrap/Navbar";

import "bootstrap/dist/css/bootstrap.min.css";
import { ADMIN_PATH } from "routes";

import { logout } from "utils/storage";

const AdminLayout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.location.href = "https://admin.dent-in.co.kr/";
  }, []);

  return (
    <Container
      style={{ padding: "0", minWidth: "100%", backgroundColor: "#edf2f9" }}
    >
      <Row style={{ minHeight: "100vh" }}>
        <Col
          className="col-6"
          style={{ backgroundColor: "#333", color: "#fff", width: "180px" }}
        >
          <Nav
            className="flex-column"
            style={{ gap: "20px", padding: "20px 10px" }}
          >
            <a href={`${ADMIN_PATH}/dashboard`}>대시보드</a>
            <a href={`${ADMIN_PATH}/user`}>회원관리</a>
            <a href={`${ADMIN_PATH}/jobposting`}>공고관리</a>
            <a href={`${ADMIN_PATH}/resume`}>이력서관리</a>
            <a href={`${ADMIN_PATH}/education`}>법정의무교육</a>
            <a href={`${ADMIN_PATH}/education/dent-in`}>치과인 강좌</a>
            <a href={`${ADMIN_PATH}/education/oral-health`}>구강보건교육</a>
            <a href={`${ADMIN_PATH}/education/etc`}>기타교육</a>
            <a href={`${ADMIN_PATH}/notice`}>메인 공지사항</a>
            <a href={`${ADMIN_PATH}/banner`}>배너관리</a>
            <button
              onClick={() => {
                logout();
                window.location.reload();
              }}
              style={{ color: "moccasin" }}
            >
              로그아웃
            </button>
          </Nav>
        </Col>
        <Col style={{ padding: "40px 30px" }}>
          <Outlet />
        </Col>
      </Row>
    </Container>
    // <Container style={{ minHeight: "-webkit-fill-available" }}>

    // <div
    //   className="d-flex flex-column flex-shrink-0 p-3 text-bg-dark"
    //   style={{ width: "280px", height: "-webkit-fill-available" }}
    // >
    //   <a
    //     href="/"
    //     className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none"
    //   >
    //     <svg className="bi pe-none me-2" width="40" height="32"></svg>
    //     <span className="fs-4">Sidebar</span>
    //   </a>
    //   <hr />
    //   <ul className="nav nav-pills flex-column mb-auto">
    //     <li className="nav-item">
    //       <a href="#" className="nav-link active" aria-current="page">
    //         <svg className="bi pe-none me-2" width="16" height="16"></svg>
    //         Home
    //       </a>
    //     </li>
    //     <li>
    //       <a href="#" className="nav-link text-white">
    //         <svg className="bi pe-none me-2" width="16" height="16"></svg>
    //         Dashboard
    //       </a>
    //     </li>
    //     <li>
    //       <a href="#" className="nav-link text-white">
    //         <svg className="bi pe-none me-2" width="16" height="16"></svg>
    //         Orders
    //       </a>
    //     </li>
    //     <li>
    //       <a href="#" className="nav-link text-white">
    //         <svg className="bi pe-none me-2" width="16" height="16"></svg>
    //         Products
    //       </a>
    //     </li>
    //     <li>
    //       <a href="#" className="nav-link text-white">
    //         <svg className="bi pe-none me-2" width="16" height="16"></svg>
    //         Customers
    //       </a>
    //     </li>
    //   </ul>
    //   <hr />
    // </div>
    // </Container>
  );
};
export default AdminLayout;
