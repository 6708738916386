import moment from "moment";
import _DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/esm/locale";
import "./custom.css";

import { displayDate } from "utils/formatter";

const DatePicker = ({
  style,
  inputClassName = "",
  value = "",
  minDate,
  maxDate,
  placeholderText = "",
  dateFormat = "yyyy/MM/dd",
  onChange,
}: {
  style?: React.CSSProperties;
  inputClassName?: string;
  value?: string | null;
  minDate?: Date | null | undefined;
  maxDate?: Date | null | undefined;
  placeholderText?: string;
  dateFormat?: string;
  onChange: (date: string) => void;
}) => (
  <div className="tbox small with-calendar" style={style ? style : {}}>
    <_DatePicker
      className={`date-picker ${inputClassName}`}
      dateFormat={dateFormat}
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
      locale={ko}
      minDate={minDate}
      maxDate={maxDate}
      placeholderText={placeholderText}
      // maxDate={(new Date().setDate(Number(Date().getDate) + 30))}
      selected={!value ? null : moment(value).toDate()}
      onChange={(date: Date) => onChange(displayDate(date, "-"))}
    />
  </div>
);

export default DatePicker;
