import { useState } from "react";
import Layout from "layouts";
import { Banner } from "components/commons";
import { FIND_ID_PASSWORD, LOGIN_PATH } from "routes";
import { findClinicId, getAuthCodeEmail } from "api/clinic/rests";
import { useInterval } from 'usehooks-ts'
const ClinicFindIdPage = () => {
  const [email, setEmail] = useState("");
  const [isSend, setIsSend] = useState(false);
  const [authCode, setAuthCode] = useState<string>("");
  const [time, setTime] = useState<number>(0);
  const [displayTime, setDisplayTime] = useState<string>("");
  const [isTimerStart, setTimerStart] = useState<boolean>(false)

  const [findedID, setFindedID] = useState<{
    member_id: string;
    clinic_name: string;
  }>({
    member_id: "",
    clinic_name: "",
  });
  const getId = async () => {
    if(!isTimerStart) {
      alert("인증시간이 초과되었습니다. \n새로고침 후 다시 시도해 주세요");
      return;
    }
    if (authCode === "") {
      alert("인증코드를 입력해주세요.");
      return;
    }
    try {
      const data = await findClinicId(email, authCode);
      setFindedID(data.data);
    } catch (err) {
      alert("인증에 실패하였습니다.");
    }
  };
  const getPassAuth = () => {
    if (email.trim() === "") {
      alert("이메일을 입력해주세요.");
      return;
    }
    getAuthCodeEmail(email)
      .then((_) => {
        setIsSend(true);
        setTimerStart(true);
        setTime(300);
      })
      .catch((err) => {
        if (err) {
          alert(`계정을 찾을 수 없습니다. 이메일을 확인해주세요.`);
        }
      });
  };

  const displayTimer = () =>{
    let min: string | number = Math.floor(time/60);
    min = min <10?'0'+min:min;
    let sec: string | number = time%60;
    sec = sec <10?'0'+sec:sec;

    setDisplayTime(min+':'+sec);

    setTime(time-1)
    if( time <= 0 ) {
      setTimerStart(false)
      setTime(0)
      alert("인증시간이 초과되었습니다. \n새로고침 후 다시 시도해 주세요");
    }
  }
  useInterval(
    () => {
      displayTimer()
    },
    isTimerStart ? 1000 : null,
  )

  return (
    <Layout extraClass="bg-grey" excludeMenu={true}>
      <Banner type="SIGNUP" />

      <section className="join">
        <div className="inner wrap">
          <div className="join-form find-form">
            <div className="form-box">
              <div className="form-tit-box">병원회원 아이디 찾기</div>
              {/* <div className="form-tit-box2">본인인증🔒</div> */}
              <div className="form-con-box">
                <div className="row">
                  <div className="input-box">
                    <input
                      type="text"
                      placeholder="이메일"
                      value={email}
                      readOnly={isSend}
                      onChange={(e) =>
                        setEmail(
                          e.target.value.replace(
                            /[^ㄱ-ㅎㅏ-ㅣ가-힣\w-\d@.]/,
                            ""
                          )
                        )
                      }
                    />
                  </div>
                </div>
                {isSend && (
                  <div className="row">
                    <div className="input-box">
                      <input
                        type="text"
                        placeholder="인증코드"
                        value={authCode}
                        onChange={(e) => setAuthCode(e.target.value)}
                      />
                      <span id="timer">
                        {displayTime}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="form-box type-find">
              <div className="submit-box">
                <a
                  className="submit-btn"
                  onClick={() => {
                    if (!isSend) {
                      getPassAuth();
                    } else {
                      getId();
                    }
                  }}
                  role="button"
                >
                  {!isSend ? "인증코드 발송" : "인증번호 인증하기"}
                </a>
              </div>
            </div>
            {/* {false && (
                <div className="form-box type-find">
                  <div className="submit-box">
                    <a href="#none" className="submit-btn off">
                      인증이 완료되었습니다.
                    </a>
                  </div>
                </div>
              )
            } */}
            {findedID.member_id && (
              <div className="form-box">
                <div className="form-con-box">
                  <div className="row">
                    <div className="input-msg-box type-normal">
                      {findedID.clinic_name}님의 아이디는{" "}
                      <b>{findedID.member_id}</b>
                      입니다
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="form-box type-find-sub">
              <div className="form-con-box">
                <div className="menu-box">
                  <a href={`${LOGIN_PATH}?login_type=2`} className="menu-btn">
                    로그인
                  </a>
                </div>

                <div className="menu-box">
                  <a
                    href={`${FIND_ID_PASSWORD}/clinic/password`}
                    className="menu-btn"
                  >
                    비밀번호 찾기
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};
export default ClinicFindIdPage;
