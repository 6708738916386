import UserContext from "contexts/UserContext";
import Routes from "routes";
import SwiperCore, { Autoplay, Grid, Navigation } from "swiper";

import { useMemo } from "react";

import { getAuth } from "utils/storage";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

SwiperCore.use([Autoplay, Grid, Navigation]);
const app = initializeApp({
  apiKey: "AIzaSyC-Lq8tvZ9tvq5eHdftY5p5ZkMf3p_2rcY",
  authDomain: "dent-in-kda.firebaseapp.com",
  projectId: "dent-in-kda",
  storageBucket: "dent-in-kda.firebasestorage.app",
  messagingSenderId: "589549126723",
  appId: "1:589549126723:web:8d2ebe70e6062a35a2c0f6",
  measurementId: "G-VN5P909SX7",
});

const App = () => {
  const {
    member_id: memberId = "",
    name: name = "",
    user_type,
    occupation,
    clinic_type,
  } = getAuth();

  getAnalytics(app);
  /** TODO isMemberType 로직 채워넣기 */
  const value = useMemo(
    () => ({
      memberId,
      name,
      userType: user_type,
      isSignIn: !!memberId,
      occupation: occupation,
      clinicType:clinic_type,
    }),
    [memberId, user_type, memberId, occupation]
  );

  return (
    <UserContext.Provider value={value}>
      <Routes />
    </UserContext.Provider>
  );
};

export default App;
