import { useCallback, useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";

import { deleteAdminBanner, fetchBannerList } from "api/admin/rests";

import { IAdminMainBannerView } from "api/admin/types";

import { displayDateTime } from "utils/formatter";

import AdminBanner from "./AdminBanner";

const AdminBannerTable = () => {
  const [adminBannerList, setAdminBannerList] = useState<
    IAdminMainBannerView[]
  >([]);
  const [showBanner, setShowBanner] = useState<boolean>(false);
  const [selectedBanner, setSelectedBanner] = useState<IAdminMainBannerView | null>(null);

  const getAdminBannerList = useCallback(async () => {
    const { data } = await fetchBannerList();

    setAdminBannerList(data);

  }, [showBanner]);

  const removeAdminBanner = async (bannerId : number) => {
  
    const data = await deleteAdminBanner(bannerId);
    console.log(data);

  }

  useEffect(() => {
    (async () => {
      getAdminBannerList();
    })();
  }, [getAdminBannerList]);

  return (
    <>

      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button disabled={adminBannerList.length > 4} onClick={() => setShowBanner(true)}>등록</Button>
      </div>

      <div style={{ minHeight: "750px" }}>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>ID</th>
              <th>타입</th>
              <th>이미지명</th>
              <th>배너 URL</th>
              <th>최종수정일시</th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {adminBannerList.map((banner) => (
              <tr key={banner.banner_id}>
                <td>{banner.banner_id}</td>
                <td> {banner.banner_type} </td>
                <td> {banner.image_filename} </td>
                <td> {banner.banner_url} </td>
                <td> {displayDateTime(banner.upd_date)} </td>
                <td style={{ display: "flex", gap: "4px" }}>
                <Button size="sm" variant="outline-secondary" onClick={() => {
                  setSelectedBanner(banner);
                  setShowBanner(true);
                }}
                >
                  편집
                </Button>

                <Button size="sm" variant="outline-secondary" 
                  onClick={() => {
                  removeAdminBanner(banner.banner_id) 
                  getAdminBannerList()
                  }}
                  >
                  삭제
                </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

        <AdminBanner 
          banner={selectedBanner} 
          show={showBanner} 
          onClose={() => {
            setSelectedBanner(null);
            setShowBanner(false);
            getAdminBannerList();
          }}
        />
    </>
  );
};

export default AdminBannerTable;
