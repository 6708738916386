import BlankImg from "assets/img/ic_chat_blank.svg";

const NoMessage = ({ target_id }: { target_id?: string | null }) => (
  <div className="chat-blank-box">
    <img src={BlankImg} alt="이미지" />

    <div className="blank-txt">
      {target_id
        ? "메세지를 입력하면 새 채팅을 시작할 수 있습니다."
        : "진행중인 대화가 없습니다."}
    </div>
  </div>
);
export default NoMessage;
