import axiosClient, { doctorLicenseClient } from "../axiosClient";

import {
  IResponse,
  IPageResponse,
  IUploadFileView,
  IChatMessageView,
  IChatMemberView,
} from "api/types";
import {
  IClinicMemberCreateRequest,
  IClinicMemberLoginRequest,
  IClinicMemberView,
  IClinicMemberUpdateRequest,
  IJobOpeningClinicOverview,
  IJobActionResumeView,
  IClinicMemberWishView,
  IClinicEmployeeView,
  IJobOpeningClinicView,
} from "./types";
import {IJobActionView, IMasterLoginRequest} from "api/member/types";
import {
  ILectureEmployeeView,
  ILectureTakeView,
  ILegalLectureTakeView,
  ILectureCourseTakeView,
  ILectureCourseBookmarkView,
  ILectureCourseTakeOverview, ILectureDutyNotice, ILegalLecture,
} from "api/education/types";

import {
  getAuth,
  getRefreshToken,
  logout,
  setAuth,
  setTokens,
} from "utils/storage";
import { USER_TYPE } from "utils/enums";
import { PAGE_SIZE } from "utils/constants";
import {
  IJobOpeningView,
  IJobOpeningEditRequest,
  IResultResponseJobOpeningView,
} from "api/jobPosting/types";
import { IResumeOverview } from "api/resume/types";
import { bridgeLogin } from "./../../utils/appBridge";

/**
 * 회원 탈퇴
 * DELETE /clinic/withdraw
 */
export const deleteClinicMember = async () => {
  const resp = await axiosClient.delete<IResponse<any>>(`/clinic/withdraw`);

  return resp.data;
};

/**
 * 회원 정보 조회
 * GET /clinic/profile
 */
export const fetchClinicMember = async () => {
  const resp = await axiosClient.get<IResponse<IClinicMemberView>>(
    `/clinic/profile`
  );

  return resp.data;
};

/**
 * 회원 가입
 * POST /clinic/signup
 */
export const createClinic = async (
  params: IClinicMemberCreateRequest
): Promise<IResponse<IClinicMemberView>> => {
  const resp = await axiosClient.post<IResponse<IClinicMemberView>>(
    "/clinic/signup",
    params
  );

  return resp.data;
};

/**
 * 병원회원 확인
 * HEAD /clinic/{id}
 */
export const checkClinicMemberById = (clinic_id: string) => {
  return axiosClient.head(`/clinic/${clinic_id}`);
};

/**
 * 로그인
 * POST /clinic/login
 */
export const loginClinic = async (params: IClinicMemberLoginRequest) => {
  const resp = await axiosClient.post<IResponse<IClinicMemberView>>(
    "/clinic/login",
    params
  );

  const { data } = resp.data;
  const { authorization } = resp.headers;

  setAuth({
    member_id: data.member_id,
    name: data.clinic_name,
    user_type: USER_TYPE.병원회원,
    clinic_type: data.clinic_type,
    occupation: "치과의사",
  });
  setTokens({
    token: authorization,
    refresh_token: resp.headers["refresh-token"],
  });

  //return resp.data;
};

/**
 * 로그인
 * POST /clinic/login
 */
export const loginMasterClinic = async (params: IClinicMemberLoginRequest) => {
  const resp = await axiosClient.post<IResponse<IClinicMemberView>>(
    "/clinic/loginmaster",
    params
  );

  const { data } = resp.data;
  const { authorization } = resp.headers;

  setAuth({
    member_id: data.member_id,
    name: data.clinic_name,
    user_type: USER_TYPE.병원회원,
    clinic_type: data.clinic_type,
    occupation: "치과의사",
  });
  setTokens({
    token: authorization,
    refresh_token: resp.headers["refresh-token"],
  });

  //return resp.data;
};

/**
 * 사용자 token 재발급
 * POST /clinic/refresh-token
 */
export const refreshToken = async () => {
  const { member_id } = getAuth();
  const refreshToken = getRefreshToken();

  try {
    const resp = await axiosClient.post<IResponse<IClinicMemberView>>(
      "/clinic/refresh-token",
      {
        member_id: member_id,
        refresh_token: refreshToken,
      }
    );

    const { authorization } = resp.headers;
    setTokens({
      token: authorization,
      refresh_token: refreshToken,
    });
    bridgeLogin(authorization, refreshToken);
  } catch (e: any) {
    // e.response?.data?.message === "Expired jwt"
    //   ? alert("인증이 만료되었습니다. 다시 로그인해주세요")
    //   : alert("오류가 발생하였습니다.");
    localStorage.clear();
    sessionStorage.clear();
    logout();
    window.location.href = "/login";
  }
};

/**
 * 병원 회원 Id/Password 체크
 * POST /clinic/check
 */
export const checkClinicPassword = async (
  member_id: string,
  password: string
): Promise<IResponse<string>> => {
  const resp = await axiosClient.post<IResponse<string>>("/clinic/check", {
    member_id,
    password,
  });

  return resp.data;
};

/**
 * 병원 회원 정보 수정
 * PATCH /clinic
 */
export const updateClinic = async (
  params: Partial<IClinicMemberUpdateRequest>
): Promise<IResponse<IClinicMemberView>> => {
  const resp = await axiosClient.patch("/clinic", params);

  return resp.data;
};

/**
 * 병원 회원 채용 공고 조회
 * GET /clinic/job
 */
export const fetchJobPostings = async (action_type: "APPLY" | "OFFER") => {
  const resp = await axiosClient.get<IResponse<IJobOpeningClinicOverview[]>>(
    `/clinic/job?action_type=${action_type}`
  );

  return resp.data;
};

/**
 * 면접 제한
 * POST /clinic/job/action
 */
export const offerInterview = async (job_id: number, resume_id: number) => {
  const resp = await axiosClient.post<IResponse<IJobActionView>>(
    `/clinic/job/action`,
    {
      job_id,
      resume_id,
      action_type: "OFFER",
      action: "PASS",
    }
  );

  return resp.data;
};

/**
 * 병원 회원 채용 공고 점프
 * PATCH /clinic/job/{job_id}
 */
export const jumpJobPosting = async (job_id: number) => {
  const resp = await axiosClient.patch<IResponse<IJobOpeningView>>(
    `/clinic/job/${job_id}`
  );

  return resp.data;
};

/**
 * 채용 공고 액션 조회 API (채용 공고 지원 또는 면접 제안)
 * GET /clinic/job/action
 * 지원 : APPLY / 면접제안 : OFFER
 */
export const fetchJobPostingHistory = async (
  action: "APPLY" | "OFFER",
  page: number,
  page_size?: number
) => {
  const resp = await axiosClient.get<
    IPageResponse<Array<IJobActionResumeView>>
  >(
    `/clinic/job/action?action_type=${action}&page=${page}&size=${
      page_size || PAGE_SIZE
    }&sort_property=UPD_DATE&sort_direction=ASC`
  );

  return resp.data;
};

/**
 * 채용 공고 액션 조회 API (채용 공고 지원 또는 면접 제안)
 * GET /clinic/job/action/{job_id}
 */
export const fetchJobPostingVolunteers = async (
  job_id: number,
  action: "APPLY" | "OFFER"
  // page: number
) => {
  const resp = await axiosClient.get<
    IPageResponse<Array<IJobActionResumeView>>
  >(
    `/clinic/job/action/${job_id}?action_type=${action}&page=1&size=100&sort_property=UPD_DATE&sort_direction=DESC`
  );

  return resp.data;
};

/**
 * 이미지 파일 업로드
 * POST /clinic/file/upload
 */
export const uploadClinicImage = async (params: FormData) => {
  const resp = await axiosClient.post<IUploadFileView>(
    `/clinic/file/upload`,
    params
  );

  return resp.data;
};

/**
 * 지원 이력서 읽음
 * PATCH /clinic/job/action/read
 */
export const readApplyResume = async (job_id: number, resume_id: number) => {
  const resp = await axiosClient.patch<IResponse<IJobActionView>>(
    `/clinic/job/action/read?job_id=${job_id}&resume_id=${resume_id}`
  );

  return resp.data;
};

/**
 * GET
/clinic/wish
이력서 찜 목록 조회
 */
export const fetchWishResume = async (page: number) => {
  const resp = await axiosClient.get<IPageResponse<IClinicMemberWishView[]>>(
    `/clinic/wish?page=${page}&size=${PAGE_SIZE}&sort_property=REG_DATE&sort_direction=DESC`
  );

  return resp.data;
};

/**
 * 이력서 찜하기
 * POST /clinic/wish/{resume_id}
 */
export const wishResume = async (resume_id: number) => {
  const resp = await axiosClient.post<IResponse<IClinicMemberWishView>>(
    `/clinic/wish/${resume_id}`
  );

  return resp.data;
};

/**
 * 이력서 찜하기 해제
 * DELETE /member/wish/{job_id}
 */
export const cancelWishResume = async (resume_id: number) => {
  await axiosClient.delete<IResponse<IClinicMemberWishView>>(
    `/clinic/wish/${resume_id}`
  );
};

/**
 * 이력서 찜 메모 수정
 * PATCH /clinic/wish/{resume_id}
 */
export const updateWishMemo = async (resume_id: number, memo: string) => {
  await axiosClient.patch<IResponse<IClinicMemberWishView>>(
    `/clinic/wish/${resume_id}?memo=${memo}`
  );
};

/**
 * 채팅 메세지 리스트 조회
 * GET /member/chat/list
 */
export const fetchChatList = async (keyword: string) => {
  const resp = await axiosClient.get<IResponse<IChatMemberView[]>>(
    `/clinic/chat/list${!!keyword ? "?keyword=" + keyword : ""}`
  );

  return resp.data;
};

/**
 * 채팅 메세지 조회
 * GET /clinic/chat
 */
export const fetchMessages = async (member_type: string, member_id: string) => {
  const resp = await axiosClient.get<IResponse<IChatMessageView[]>>(
    `/clinic/chat?member_type=${member_type}&member_id=${member_id}`
  );

  return resp.data;
};

/**
 * 채팅 메세지 전송 (이력서 기반)
 * POST /clinic/chat/resume
 */
export const sendMessageFromResume = async (
  // member_type: "MEMBER" | "CLINIC" | "ADMIN",
  resume_id: number,
  message: string
) => {
  const resp = await axiosClient.post<IResponse<IChatMessageView>>(
    `/clinic/chat/resume`,
    {
      resume_id: resume_id,
      message: message,
    }
  );

  return resp.data;
};

/**
 * 채팅 메세지 전송
 * POST /clinic/chat
 */
export const sendMessage = async (
  member_type: string,
  member_id: string,
  name: string,
  message: string
) => {
  const resp = await axiosClient.post<IResponse<IChatMessageView>>(
    `/clinic/chat`,
    {
      member_type,
      member_id,
      name,
      message,
    }
  );

  return resp.data;
};

/**
 * 지원자 이려서 메모 수정
 * PATCH /clinic/job/action
 */
export const updateVolunteerMemo = async (
  job_id: number,
  resume_id: number,
  memo: string
): Promise<IResponse<IClinicMemberView>> => {
  const resp = await axiosClient.patch("/clinic/job/action", {
    job_id,
    resume_id,
    memo,
  });

  return resp.data;
};

/**
 * 법정의무교육 직원 조회
 * GET /clinic/employee
 */
export const fetchMyLectureEmployees = async () => {
  const resp = await axiosClient.get<IResponse<Array<IClinicEmployeeView>>>(
    `/clinic/employee`
  );

  return resp.data;
};

/**
 * 법정의무교육 직원 추가
 * GET /clinic/employee
 */
export const addLectureEmployees = async (employee_id: string) => {
  const resp = await axiosClient.post<IResponse<IClinicEmployeeView>>(
    `/clinic/employee`,
    { employee_id }
  );

  return resp.data;
};

/**
 * 법정의무교육 직원 초대
 * POST /clinic/employee/text-message
 */
export const sendInviteTextMessage = async (
  phone_number: string,
  contents: string
) => {
  const resp = await axiosClient.post<IResponse<any>>(
    `/clinic/employee/text-message`,
    { phone_number, contents }
  );

  return resp.data;
};

/**
 * 법정의무교육 직원 체크 (개인 회원 체크) API
 * POST /clinic/employee/check
 */
export const checkLectureEmployees = async (
  name: string,
  phone_number: string
) => {
  const resp = await axiosClient.post<IResponse<{ member_id: string }>>(
    `/clinic/employee/check`,
    {
      name,
      phone_number,
    }
  );

  return resp.data;
};

/**
 * 법정의무교육 직원 삭제
 * GET /clinic/employee
 */
export const removeLectureEmployee = async (employee_id: string) => {
  const resp = await axiosClient.delete<IResponse<Array<IClinicEmployeeView>>>(
    `/clinic/employee`,
    { data: { employee_id } }
  );

  return resp.data;
};

/**
 * 법정 의무 교육 강의 조회
 * GET /clinic/education/lecture/legal
 */
export const fetchMyLegalLecture = async () => {
  const resp = await axiosClient.get<IResponse<Array<ILegalLectureTakeView>>>(
    `/clinic/education/lecture/legal`
  );

  return resp.data;
};
/**
 * 법정 의무 교육 완료 강의 조회
 * GET /clinic/education/lecture/legal
 */
export const fetchMyCompleteLegalLecture = async () => {
  const resp = await axiosClient.get<IResponse<Array<any>>>(
    `/clinic/education/lecture/legal/complete`
  );
  return resp.data;
};
/**
 * 법정 의무 교육 상단 공지 조회
 * GET /clinic/education/lecture/legal
 */
export const fetchLegalLectureNotice = async () => {
  const resp = await axiosClient.get<IResponse<ILectureDutyNotice>>(
    `/notice/duty_notice`
  );

  return resp.data;
};

/**
 * 회원 수강 중인 강의 코스 조회
 * GET /clinic/education/lecture/course
 */
export const fetchMyLecture = async () => {
  const resp = await axiosClient.get<
    IResponse<Array<ILectureCourseTakeOverview>>
  >(`/clinic/education/lecture/course`);

  return resp.data;
};

/**
 * 온라인 교육 강의 코스 정보 상세 조회
 * GET /clinic/education/lecture/course/{course_id}
 */
export const fetchLecture = async (course_id: number) => {
  const resp = await axiosClient.get<IResponse<ILectureCourseTakeView>>(
    `/clinic/education/lecture/course/${course_id}`
  );

  return resp.data;
};

/**
 * 온라인 교육 강의 코스 즐겨찾기 목록 조회
 * GET /clinic/education/lecture/course/bookmark
 */
export const fetchBookmarkLecture = async () => {
  const resp = await axiosClient.get<
    IResponse<Array<ILectureCourseBookmarkView>>
  >(`/clinic/education/lecture/course/bookmark`);

  return resp.data;
};

/**
 * 온라인 교육 강의 코스 즐겨찾기 설정
 * POST /clinic/education/lecture/course/bookmark
 */
export const bookmarkLecture = async (course_id: number) => {
  const resp = await axiosClient.post<IResponse<ILectureCourseBookmarkView>>(
    `/clinic/education/lecture/course/bookmark?course_id=${course_id}`
  );

  return resp.data;
};

/**
 * 온라인 교육 강의 코스 즐겨찾기 제거
 * DELETE /clinic/education/lecture/course/bookmark/{course_id}
 */
export const removeBookmarkLecture = async (course_id: number) => {
  const resp = await axiosClient.delete<IResponse<any>>(
    `/clinic/education/lecture/course/bookmark/${course_id}`
  );

  return resp.data;
};

/**
 * 온라인 교육 강의 시작
 * POST /clinic/education/lecture/start
 */
export const startLecture = async (
  lecture_type: string,
  lecture_id: number,
  collect_flag: boolean
) => {
  const resp = await axiosClient.post<IResponse<ILectureTakeView>>(
    `/clinic/education/lecture/start`,
    {
      lecture_type,
      lecture_id,
      collect_flag,
    }
  );

  return resp.data;
};

/**
 * 온라인 교육 강의 재시작
 * POST /clinic/education/lecture/restart
 */
export const restartLecture = async (
  lecture_type: string,
  lecture_id: number,
  collect_flag: boolean
) => {
  const resp = await axiosClient.post<IResponse<ILectureTakeView>>(
    `/clinic/education/lecture/restart`,
    {
      lecture_type,
      lecture_id,
      collect_flag,
    }
  );

  return resp.data;
};

/**
 * 강의 코스 수강자 임직원 명단 조회
 * GET /clinic/education/lecture/course/employee
 */
export const fetchCourseEmployees = async (course_id: number) => {
  const resp = await axiosClient.get<IResponse<Array<ILectureEmployeeView>>>(
    `/clinic/education/lecture/course/employee?course_id=${course_id}`
  );

  return resp.data;
};

/**
 * 온라인 교육 강의 완료
 * PUT /clinic/education/lecture/complete
 */
export const completeLecture = async (
  lecture_id: number,
  complete_date: string, // 완료 일시
  view_time: number // 수강 시간
) => {
  const resp = await axiosClient.put<IResponse<ILectureTakeView>>(
    `/clinic/education/lecture/complete`,
    {
      lecture_id,
      complete_date,
      view_time,
    }
  );

  return resp.data;
};

/**
 * 온라인 교육 강의 코스 즐겨찾기 목록 조회
 * GET /clinic/education/lecture/course/bookmark
 */
export const fetchBookmarks = async () => {
  const resp = await axiosClient.get<
    IResponse<Array<ILectureCourseBookmarkView>>
  >(`/clinic/education/lecture/course/bookmark`);

  return resp.data;
};

/**
 * 인재 추천
 * GET /clinic/resume/recommend
 */
export const fetchRecommendResume = async () => {
  const resp = await axiosClient.get<IResponse<Array<IResumeOverview>>>(
    `/clinic/resume/recommend?size=8`
  );

  return resp.data;
};

/**
 * 면허인증 API
 */
export const fetchLicense = async (
  license_no: string,
  birthday: string,
  name: string,
  gender: "1" | "0" // (남: 1, 여: 0)
) => {
  const resp = await doctorLicenseClient.get(
    `/Api/License.kda?license_no=${license_no}&birthday=${birthday}&name=${name}&gender=${gender}`
  );
  return resp.data;
};

/**
 * 병원 회원 채용 공고 등록
 * POST /clinic/job
 */
export const createJobPosting = async (params: IJobOpeningEditRequest) => {
  const resp = await axiosClient.post<IResponse<IResultResponseJobOpeningView>>(
    "/clinic/job",
    params
  );

  return resp.data;
};

/**
 * 병원 회원 채용 상세 조회
 * GET /clinic/job/{job_id}
 */
export const fetchJobPosting = async (job_id: number) => {
  const resp = await axiosClient.get<IResponse<IJobOpeningClinicView>>(
    `/clinic/job/${job_id}`
  );

  return resp.data;
};

/**
 * 병원 회원 채용 공고 삭제
 * DELETE /clinic/job/{job_id}
 */
export const deleteJobPosting = async (job_id: number) => {
  const resp = await axiosClient.delete<IResponse<any>>(
    `/clinic/job/${job_id}`
  );

  return resp.data;
};

/**
 * 병원 회원 채용 공고 수정
 * PUT /clinic/job/{job_id}
 */
export const updateJobPosting = async (
  jobId: number,
  params: IJobOpeningEditRequest
) => {
  const resp = await axiosClient.put<IResponse<IResultResponseJobOpeningView>>(
    `/clinic/job/${jobId}`,
    params
  );

  return resp.data;
};

/**
 * 병원 회원 채용 공고 개수 조회
 * GET /clinic/job/count
 */
export const fetchJobPostingCount = async (intern_flag: boolean) => {
  const resp = await axiosClient.get<
    IResponse<{
      current_count: number;
      max_count: number;
    }>
  >(`/clinic/job/count?intern_flag=${intern_flag}`);
  return resp.data;
};

/**
 * 이메일 인증 코드 전송
 * POST /clinic/auth-code
 */
export const getAuthCodeEmail = async (email: string, member_id?: string) => {
  const resp = await axiosClient.post<IResponse<any>>(
    `/clinic/auth-code`,
    member_id
      ? {
          email,
          member_id,
        }
      : {
          email,
        }
  );

  return resp.data;
};

/**
 * 아이디 찾기
 * POST /member/find/id
 */
export const findClinicId = async (email: string, auth_code: string) => {
  const resp = await axiosClient.post<
    IResponse<{ member_id: string; clinic_name: string }>
  >(`/clinic/find/id`, {
    email,
    auth_code,
  });

  return resp.data;
};

/**
 * 비밀번호 재설정
 * PUT /member/password
 */
export const resetPassword = async (
  member_id: string,
  new_password: string,
  auth_code: string
) => {
  const resp = await axiosClient.put<IResponse<string>>(`/clinic/password`, {
    member_id,
    new_password,
    auth_code,
  });

  return resp.data;
};
