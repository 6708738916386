import { useCallback, useEffect, useState } from "react";

import { fetchJobPostings } from "api/clinic/rests";
import { IJobOpeningClinicOverview } from "api/clinic/types";

const useMyJobPostings = () => {
  const [jobPostings, setJobPostings] = useState<IJobOpeningClinicOverview[]>(
    []
  );

  const fetch = useCallback(async () => {
    try {
      const { data } = await fetchJobPostings("APPLY");
      setJobPostings(data);
    } catch (e) {
      setJobPostings([]);
    }
  }, []);

  useEffect(() => {
    (async () => fetch())();
  }, [fetch]);

  return { jobPostings, handler: { fetch } };
};

export default useMyJobPostings;
