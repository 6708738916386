import React, { SetStateAction, useContext, useEffect } from "react";
import { ClinicSignupContext } from "contexts/SignupContex";

import InputFieldRow from "./Comps/InputFieldRow";
import AddressFieldRow from "components/FormFields/AddressFieldRow";

const ClinicContactBox = () => {
  const context = useContext(ClinicSignupContext);
  const {
    email,
    address,
    extra_address,
    phone_number,
    homepage_url,
    errors,
    setEmail,
    setAdress,
    setExtraAdress,
    setPhoneNumber,
    setHompageUrl,
  } = context;

  return (
    <div className="form-box" data-type="corp" style={{ display: "block" }}>
      <div className="form-tit-box">이메일/주소/연락처/홈페이지 주소</div>
      <div className="form-con-box">
        <InputFieldRow
          errorMessage={errors?.email}
          placeholder="이메일*"
          value={email}
          onChange={(value) =>
            setEmail(value.replace(/[^ㄱ-ㅎㅏ-ㅣ가-힣\w-\d@.]/, ""))
          }
        />
        {/* <InputFieldRow
          placeholder="주소*"
          value={address}
          onChange={(value) => setAdress(value)}
        /> */}
        <div className="row">
          <div className="input-box">
            <AddressFieldRow
              value={address}
              onChange={(value) => setAdress(value)}
            />
          </div>
        </div>
        <InputFieldRow
          errorMessage={errors?.address}
          placeholder="상세주소"
          value={extra_address}
          onChange={setExtraAdress}
        />
        <InputFieldRow
          errorMessage={errors?.phone_number}
          placeholder="대표자 휴대전화* (-없이 숫자만 입력)"
          value={phone_number}
          numberOnly
          onChange={setPhoneNumber}
        />
        <InputFieldRow
          errorMessage={errors?.homepage_url}
          placeholder="홈페이지 주소"
          value={homepage_url}
          onChange={(value) => setHompageUrl(value)}
        />
      </div>
    </div>
  );
};
export default ClinicContactBox;
