import { Link, useNavigate } from "react-router-dom";
import { fetchJobPostingCount } from "api/clinic/rests";
import { JOBPOSTING_FORM_PATH, JOBPOSTING_PATH } from "routes";
import { useUserContext } from "contexts/UserContext";
import { USER_TYPE } from "utils/enums";
import { IJobOpeningOverview } from "api/jobPosting/types";
import { useEffect, useState } from "react";
import { searchInternPostingsByLocation } from "api/jobPosting/rests";
import { fetchWorkarea } from "api/area/rests";
import Map from "../../components/JobPostingSearch/Map";
import MapItem from "../../components/JobPostingSearch/MapItem";

type Occupation = {
  name: string;
  value: string;
};

/**
 * 채용정보 > 실습치과리스트 탭
 */
const TrainingDentist = () => {
  const navigate = useNavigate();
  const { userType } = useUserContext();
  const [mapData, setMapData] = useState<IJobOpeningOverview[]>([]);
  const [position, setPosition] = useState<number[]>([37.50802, 127.062835]);
  const [queryFilter, setQueryFilter] = useState<string[]>([]);
  const occupations: Occupation[] = [
    { name: "재취업", value: "재취업" },
    { name: "치위생과 학생", value: "치위생과학생" },
    { name: "간호학원생", value: "간호학원생" },
  ];

  useEffect(() => {
    (async () => {
      try {
        const { data } = await fetchWorkarea();
        const firstSeq = data.sort((a, b) => a.seq - b.seq)[0];
        setPosition([firstSeq.latitude, firstSeq.longitude]);
      } catch (e) {
        if ("geolocation" in navigator) {
          navigator.geolocation.getCurrentPosition((position) => {
            setPosition([position.coords.latitude, position.coords.longitude]);
          });
        }
      }
    })();
  }, []);

  const handleClick = async () => {
    try {
      const {
        data: { current_count, max_count },
      } = await fetchJobPostingCount(true);
      if (current_count >= max_count) {
        alert(`노출 가능한 공고는 최대 ${max_count}개입니다.`);
        return;
      } else {
        navigate(`${JOBPOSTING_FORM_PATH}?isIntern=on`);
      }
    } catch (e) {}
  };
  return (
    <section className="info-location">
      <div className="inner wrap">
        <div className="location-layout">
          <Map
            latitude={position[0]}
            longitude={position[1]}
            setPosition={setPosition}
            setMapData={setMapData}
            occupations={queryFilter}
            searchByLocation={searchInternPostingsByLocation}
          >
            <div className="con">
              <div className="check-list" style={{ position: "relative" }}>
                <div className="list-wrap">
                  {occupations.map(({ name, value }, idx) => (
                    <article
                      key={`occ-${name}-${idx}`}
                      className="label-item-box-den"
                    >
                      <label>
                        <input
                          type="checkbox"
                          checked={queryFilter.includes(value)}
                          onClick={() =>
                            queryFilter.includes(value)
                              ? setQueryFilter(
                                  queryFilter.filter((item) => item !== value)
                                )
                              : setQueryFilter(queryFilter.concat(value))
                          }
                        />
                        <span className="txt">{name}</span>
                      </label>
                    </article>
                  ))}
                </div>
              </div>
              <div className="training-dentist-wrap">
                <div></div>
                <Link to="/intern">
                  <div className="training-dentist-go-intern">
                    실습치과지원센터🤝
                  </div>
                </Link>
              </div>
            </div>
          </Map>
          <div className="location-box">
            <div className="tit-box">
              <div className="tit-txt">지역목록 {mapData.length}개</div>
              {userType === USER_TYPE.병원회원 && (
                <div className="menu-btn" onClick={handleClick}>
                  실습치과 등록
                </div>
              )}
            </div>

            <div
              className="list-box scroll-effect"
              style={{ maxHeight: "810px" }}
            >
              {mapData.map((item: IJobOpeningOverview, idx: number) => (
                <a
                  href={`${JOBPOSTING_PATH}/${item.job_id}`}
                  className="item-box"
                  key={idx}
                >
                  <MapItem item={item} />
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default TrainingDentist;
