import { useNavigate } from "react-router-dom";
import { readApplyResume } from "api/clinic/rests";
import { IJobActionResumeView } from "api/clinic/types";
import {
  displaySalaryInfo,
  resourcePath,
  displayExperienceMonth,
} from "utils/formatter";
import { MESSAGE_PATH } from "routes";

import { RESUME_PATH } from "routes";

const Volunteer = ({
  job_id,
  resume_id,
  title,
  name,
  last_education_name,
  occupation_list,
  salary_info,
  image_filename,
  work_experience_month,
  member_id
}: IJobActionResumeView) => {
  const navigate = useNavigate();

  const readResume = async () => {
    await readApplyResume(job_id, resume_id);
    navigate(`${RESUME_PATH}/${resume_id}`);
  };

  return (
    <div className="list-box">
      <div className="profile-box">
        <div className="img-box">
          <img src={resourcePath(image_filename)} alt="이미지" />
        </div>
      </div>

      <div className="detail-box">
        <div className="detail-top-box">
          <div className="detail-tit-box">{title}</div>
          <div className="detail-name-box">{name}</div>
        </div>

        <div className="detail-bottom-box">
          <article className="my-summary-box type-padding-reset">
            <div className="summary-box">
              <div className="summary-item-box">
                <div className="row">
                  <div className="summary-txt-box type-1">
                    {displayExperienceMonth(work_experience_month)}
                  </div>
                </div>
                <div className="row">
                  <div className="summary-txt-box type-3">
                    직종 {occupation_list.join(", ")}
                  </div>
                </div>
              </div>

              <div className="summary-item-box">
                <div className="row">
                  <div className="summary-txt-box type-6">
                    학력 {last_education_name} 졸
                  </div>
                </div>

                <div className="row">
                  <div className="summary-txt-box type-7">
                    희망연봉 {displaySalaryInfo(salary_info)}
                  </div>
                </div>
              </div>
            </div>
          </article>
        </div>
      </div>

      <div className="detail-menu-box">
        <a
          href={`${MESSAGE_PATH}?target_id=${resume_id}&memberId=${member_id}&clinicName=${name}`}
          className="detail-menu-btn type-msg"
        >
          <span className="txt">메세지</span>
          <i className="ic ic-message"></i>
        </a>
        <button className="detail-menu-btn" onClick={readResume}>
          상세
        </button>
      </div>
    </div>
  );
};
export default Volunteer;
