import {
  IMemberCreateRequest,
  IMemberPolicyAgreeRequest,
} from "api/member/types";
import { createContext } from "react";
import { OCCUPATION } from "utils/enums";

export type TSignupContext = Pick<
  IMemberCreateRequest,
  | "member_id"
  | "password"
  | "policy_agree_list"
  | "event_follow_id"
  | "event_follow_id_clinic"
> & {
  errors: any;
  setMemberId: (id: string) => void;
  setPassword: (password: string) => void;
  setPolicyAgree: (agreeFields: IMemberPolicyAgreeRequest[]) => void;
  setEventFollowId: (id: string) => void;
  setEventFollowIdClinic: (id: string) => void;
};
export const SignupContext = createContext<TSignupContext>({
  member_id: "",
  password: "",
  policy_agree_list: [],
  errors: {},
  event_follow_id: "",
  event_follow_id_clinic: "",
  setMemberId: (memberId: string) => {},
  setPassword: () => {},
  setPolicyAgree: () => {},
  setEventFollowId: (id: string) => {},
  setEventFollowIdClinic: (id: string) => {},
});

export const MemberSignupContext = createContext({
  email: "",
  gender: "",
  occupation: "",
  auth_code: "",
  auth_state: "",
  licence_number: "",
  referrer: "치즈톡",
  errors: {} as any,
  setEmail: (email: string) => {},
  setOccupation: (occupation: OCCUPATION) => {},
  setGender: (gender: string) => {},
  setLicenceNumber: (licenceNumber: string) => {},
  setReferrer: (referrer: string) => {},
});

export const ClinicSignupContext = createContext({
  biz_reg_number: "",
  clinic_name: "",
  clinic_type: "",
  rep_name: "",
  rep_gender: "",
  rep_birth_date: "",
  rep_phone_number: "",
  rep_licence_number: "",
  email: "",
  address: "",
  extra_address: "",
  phone_number: "",
  homepage_url: "",
  errors: {} as any,
  setBizRegNumber: (number: string) => {},
  setClinicName: (name: string) => {},
  setClinicType: (clinicType: string) => {},
  setRepName: (name: string) => {},
  setRepBirthDate: (date: string) => {},
  setRepGender: (gender: string) => {},
  setRepLicenceNumber: (number: string) => {},
  setEmail: (email: string) => {},
  setAdress: (address: string) => {},
  setExtraAdress: (address: string) => {},
  setPhoneNumber: (phone: string) => {},
  setHompageUrl: (url: string) => {},
});
