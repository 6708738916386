import { useEffect, useRef, useState, useCallback } from "react";
import moment from "moment";
import { useParams, useSearchParams } from "react-router-dom";
import Html2Pdf from "html2pdf.js";
import { useReactToPrint } from "react-to-print";

import {
  fetchCourseEmployees,
  completeLecture as completeClinicLecture,
  restartLecture,
} from "api/clinic/rests";
import { completeLecture as completeMemberLecture } from "api/member/rests";
import { ILectureEmployeeView } from "api/education/types";
import { useUserContext } from "contexts/UserContext";
import useLecture from "hooks/lecture/useLecture";
import { USER_TYPE } from "utils/enums";
import PrintPopup from "components/Popups/PrintPopup";
import { startLecture as startMemberLecture } from "api/member/rests";
import { startLecture as startClinicLecture } from "api/clinic/rests";
import { LECTURE_PATH, LECTURE_SUBPATH } from "routes";
import CertificationLegal from "components/Lecture/CertificationLegal";
import { Banner } from "components/commons";
import useProfile from "hooks/useProfile";

const LecturePersonnelPage = () => {
  const { userType } = useUserContext();
  const { courseId } = useParams();
  const profile: any = useProfile();

  const [params] = useSearchParams();
  const printArea = useRef<HTMLDivElement | null>(null);

  const [showPrintPopup, setShowPrintPopup] = useState<boolean>(false);
  // const courseId = params.get("courseId");
  const timestamp = moment.unix(Number(params.get("timestamp"))); // 강의 플레이 페이지에서 넘어온 시작 시간
  const [endDate, setEndDate] = useState<string>(
    timestamp.format("YYYY-MM-DD")
  );
  const [endTime, setEndTime] = useState<string>(timestamp.format("HH:mm:ss"));
  const collectFlag = params.get("collectFlag");
  const retry = params.get("retry");
  const { lecture_list, title, complete_date, lecture_type } = useLecture(
    Number(courseId)
  ) || {
    lecture_type: "",
    lecture_list: [
      {
        lecture_id: 0,
        play_time: 0,
        collect_flag: false,
      },
    ],
    title: "",
    complete_date: "",
  };

  const startLecture = useCallback(async () => {
    const lecture = lecture_list[0];
    if (lecture && lecture_type) {
      userType === USER_TYPE.개인회원
        ? await startMemberLecture(
            lecture_type,
            lecture.lecture_id,
            !!collectFlag
          )
        : retry
        ? await restartLecture(lecture_type, lecture.lecture_id, !!collectFlag)
        : await startClinicLecture(
            lecture_type,
            lecture.lecture_id,
            !!collectFlag
          );
    }
  }, [userType, lecture_type]);
  const [lectureEmployees, setLectureEmployees] =
    useState<ILectureEmployeeView | null>(null);

  const completeLecture = async () => {
    const completeTime = moment(`${endDate} ${endTime}`);

    if (lecture_list[0].lecture_id && completeTime.isValid()) {
      try {
        userType === USER_TYPE.개인회원
          ? await completeMemberLecture(
              Number(lecture_list[0].lecture_id),
              completeTime.format("YYYY-MM-DD HH:mm:ss"),
              completeTime.diff(timestamp, "s")
            )
          : await completeClinicLecture(
              Number(lecture_list[0].lecture_id),
              completeTime.format("YYYY-MM-DD HH:mm:ss"),
              completeTime.diff(timestamp, "s")
            );
        alert("수강완료 처리되었습니다.");
        window.location.href = `${LECTURE_PATH}/${LECTURE_SUBPATH.DUTY}`;
      } catch (e: any) {
        if (/already complete/.test(e.response.data.data)) {
          alert("이미 수강완료 처리된 교육입니다.");
        } else {
          alert("오류가 발생하였습니다.");
        }
      }
    } else {
      alert("수강종료 시간 입력 포멧을 확인하세요.");
    }
  };

  const downloadPdf = useReactToPrint({
    content: () => printArea.current,
    print: async (printIframe: any) => {
      const document = printIframe.contentDocument;
      if (document) {
        const html = document.getElementsByTagName("html")[0];
        const opt = {
          margin: 0.5,
          filename: `${title}.pdf`,
          image: { type: "jpeg", quality: 1 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
        };
        const exporter = new Html2Pdf(html, opt);
        await exporter.getPdf(true);
      }
    },
  });

  useEffect(() => {
    if (userType === USER_TYPE.개인회원 || lecture_type === "") return;
    (async () => {
      try {
        // await startLecture();
      } finally {
        const { data } = await fetchCourseEmployees(Number(courseId));
        if (data.length > 0) {
          setLectureEmployees(data[0]); // 유효한 테이더는 무조건 1개, 여러개 등록되는 개념이 잘못된 경우.
        }
      }
    })();
  }, [lecture_type, courseId, userType]);

  useEffect(() => {
    const expectedEndTime = timestamp.add(lecture_list[0].play_time, "s");
    setEndDate(expectedEndTime.format("YYYY-MM-DD"));
    setEndTime(expectedEndTime.format("HH:mm:ss"));
  }, [lecture_list, timestamp]);

  return (
    <section className="edu-law-list">
      <div className="edu-banner-box">
        <Banner type="LECTURE_EMPLOYEE" />
      </div>

      <div className="edu-law-tit-box">
        <div className="tit-txt">교육 수강 직원리스트</div>
      </div>

      <div className="edu-finish-box">
        <div className="finish-head-box">{lectureEmployees?.name}</div>
        <div className="finish-body-box">
          <div className="item-list">
            {lectureEmployees?.employee_list.map((employee, i) => (
              <div key={`employee-list-${i}`} className="item-box">
                <div className="subject-box">{employee.name}</div>
                <div className="con-box">{employee.phoneNumber}</div>
              </div>
            ))}
            {profile && (
              <div key={`employee-list-my`} className="item-box">
                <div className="subject-box">{profile.rep_name}</div>
                <div className="con-box">{profile.rep_phone_number}</div>
              </div>
            )}
          </div>

          {params.get("timestamp") ? (
            <div className="setting-box">
              <div className="setting-item">
                <div className="subject">수강시작</div>
                <div className="con">
                  <div className="txt-box type-1">
                    {timestamp.format("HH:mm:ss")}
                  </div>
                </div>
              </div>

              <div className="setting-item type-mobile-layout">
                <div className="subject">수강종료</div>
                <div className="con">
                  <div className="input-box">
                    <div className="tbox medium">
                      <input
                        type="text"
                        placeholder="수강종료시간을 입력하세요"
                        value={endTime}
                        onChange={(e) => setEndTime(e.target.value)}
                      />
                    </div>
                    <div className="reg-btn web" onClick={completeLecture}>
                      등록
                    </div>
                  </div>
                </div>

                <div className="mobile-only-box">
                  <div className="reg-btn" onClick={completeLecture}>
                    등록
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="menu-box">
              <div
                className="menu-btn type-1 on web"
                onClick={() => setShowPrintPopup(true)}
              >
                <span className="txt">인쇄하기</span>
                <i className="ic ic-print"></i>
              </div>

              {/* <a href="#none" className="menu-btn type-1 web">
            <span className="txt">인쇄하기</span>
            <i className="ic ic-print"></i>
          </a> */}

              <div className="menu-btn type-2" onClick={downloadPdf}>
                <span className="txt">pdf 저장</span>
                <i className="ic ic-pdf"></i>
              </div>
            </div>
          )}
        </div>
      </div>

      {showPrintPopup && (
        <PrintPopup title={title} handleClose={() => setShowPrintPopup(false)}>
          {/* <PersonnelCertification
            courseId={Number(courseId)}
            courseTitle={title}
            completeDate={complete_date}
            employees={lectureEmployees?.employee_list || []}
          /> */}
          <CertificationLegal
            courseId={Number(courseId)}
            employees={lectureEmployees?.employee_list || []}
          />
        </PrintPopup>
      )}
      <div style={{ display: "none" }}>
        <div ref={printArea}>
          {/* <PersonnelCertification
            courseId={Number(courseId)}
            courseTitle={title}
            completeDate={complete_date}
            employees={lectureEmployees?.employee_list || []}
          /> */}
          <CertificationLegal
            courseId={Number(courseId)}
            employees={lectureEmployees?.employee_list || []}
          />
        </div>
      </div>
    </section>
  );
};

export default LecturePersonnelPage;
