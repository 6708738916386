import { IResumeView } from "api/resume/types";
import { displayDate, resourcePath } from "utils/formatter";

import { InfoRow } from "./Comps";

import DefaultProfile from "assets/img/no_profile.png";

type TProps = Pick<
  IResumeView,
  | "name"
  | "birth_date"
  | "image_filename"
  | "email"
  | "phone_number"
  | "address"
>;

// const phoneFormat = (strInput: String) => {
//   if (!strInput) return `input must be a number was sent ${strInput}`;
//   if (typeof strInput !== "string") strInput = strInput.toString();
//   return strInput;
// if (strInput.length === 10) {
//   return strInput.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
// } else if (strInput.length < 10) {
//   return "was not supplied enough numbers please pass a 10 digit number";
// } else if (strInput.length > 10) {
//   return "was supplied too many numbers please pass a 10 digit number";
// } else {
//   return "something went wrong";
// }
// };

const ProfileBox = ({
  name,
  birth_date,
  image_filename,
  email,
  phone_number,
  address,
}: TProps) => (
  <div className="resume-profile-box">
    <div className="profile-img-box">
      <div className="img-box">
        <img
          src={image_filename ? resourcePath(image_filename) : DefaultProfile}
          alt="이미지"
        />
      </div>
    </div>

    <div className="profile-info-box">
      <InfoRow label="이름" value={name} />
      <InfoRow label="생년월일" value={displayDate(birth_date)} />
      <InfoRow label="이메일" value={email} />
      <InfoRow label="휴대폰" value={phone_number} /> {/* TODO: 비공개 처리 */}
      <InfoRow label="주소" value={address} />
    </div>
  </div>
);

export default ProfileBox;
