import {useCallback, useEffect, useState} from "react";

import {fetchRecommendJobPostings, fetchRecommendJobPostingsWithLocation} from "api/jobPosting/rests";
import {IGeolocation, IJobOpeningOverview} from "api/jobPosting/types";
import { JOBPOSTING_PATH } from "routes";
import { displayDate } from "utils/formatter";
import {USER_TYPE} from "../../utils/enums";
import axios from "axios";
import useGeolocation from "react-hook-geolocation";

const RecommendJobPosting = ({userType}: { userType: USER_TYPE; }) => {
  const geolocation = useGeolocation();
  const [jobPostings, setJobPostings] = useState<IJobOpeningOverview[]>([]);
  const [currentGeolocation, setGeolocation] = useState<IGeolocation|null>(null);

  const getPostings = useCallback(async() => {
    if(!currentGeolocation){
      return;
    }
    if(userType) {
      const { data } = await fetchRecommendJobPostings();
      setJobPostings(data);
      return;
    }
    const { data } = await fetchRecommendJobPostingsWithLocation(currentGeolocation);
    setJobPostings(data);

  }, [currentGeolocation]);

  useEffect(() => {
    (async()=>{
      await getPostings()
    })()

  }, [getPostings]);

  useEffect(() => {
    if(geolocation.error) {
      axios.get('https://geolocation-db.com/json/')
        .then((res) => {
          if(res.status == 200) {
            let data:IGeolocation = {latitude:res.data.latitude,longitude:res.data.longitude}
            setGeolocation(data);
          }
        })

    } else {
      setGeolocation(geolocation);
    }

  }, [geolocation]);
  return (
    <>
      <div className="sub-tit-box">
        <div className="tit-box">
          <div className="tit-txt">
            나에게 딱 맞는 <b>추천공고</b>
          </div>
          <i className="ic ic-thumbsup"></i>
        </div>
      </div>

      <div className="sub-list-box">
        {jobPostings.map((jobPosting) => (
          <div key={jobPosting.job_id} className="item-box">
            <div className="item-tit-box">{jobPosting.clinic_name}</div>
            <a
              href={`${JOBPOSTING_PATH}/${jobPosting.job_id}`}
              className="item-con-box"
            >
              <div className="item-subject-box">{jobPosting.title}</div>
              <div className="item-option-box">
                <div className="item-row">
                  <div className="option-box">
                    <i className="ic ic-place"></i>
                    <span className="txt">지역 {jobPosting.city}</span>
                  </div>
                </div>
                <div className="item-row">
                  <div className="option-box">
                    <i className="ic ic-calendar"></i>
                    <span className="txt">
                      경력{" "}
                      {jobPosting.min_work_experience === null && "경력무관"}
                      {jobPosting.min_work_experience === 0 &&
                        jobPosting.max_work_experience === 0 &&
                        "신입"}
                      {jobPosting.min_work_experience >= 0 &&
                        jobPosting.max_work_experience > 0 &&
                        `${jobPosting.min_work_experience}년 ~ ${jobPosting.max_work_experience}년`}
                    </span>
                  </div>
                  {jobPosting.occupation_list.length > 0 && (
                    <div className="option-box">
                      <i className="ic ic-document"></i>
                      <span className="txt">
                        직종 {(jobPosting.occupation_list || []).join(", ")}
                      </span>
                    </div>
                  )}
                </div>
              </div>

              <div className="item-date-box">
                <div className="date-item">
                  <div className="date-label">마감일</div>
                  <div className="date-txt">
                    {displayDate(jobPosting.closing_date)}
                  </div>
                </div>
              </div>
            </a>
          </div>
        ))}
      </div>
    </>
  );
};
export default RecommendJobPosting;
