import { useEffect, useState } from "react";

import { fetchRecommendResume } from "api/clinic/rests";
import { IResumeOverview } from "api/resume/types";
import { RESUME_PATH } from "routes";
import { displayExperienceMonth } from "utils/formatter";

const RecommendResume = () => {
  const [resumes, setResumes] = useState<IResumeOverview[]>([]);

  useEffect(() => {
    (async () => {
      const { data } = await fetchRecommendResume();
      setResumes(data.slice(0, 8));
    })();
  }, []);

  return (
    <>
      <div className="sub-tit-box">
        <div className="tit-box">
          <div className="tit-txt">나에게 딱 맞는 인재추천</div>
          <i className="ic ic-thumbsup"></i>
        </div>
      </div>

      <div className="sub-list-box">
        {resumes.map((resume) => (
          <div className="item-box">
            <div className="item-tit-box">{resume.name}</div>
            <a
              href={`${RESUME_PATH}/${resume.resume_id}`}
              className="item-con-box"
            >
              <div className="item-subject-box">{resume.title}</div>
              <div className="item-option-box">
                <div className="item-row">
                  <div className="option-box">
                    <i className="ic ic-place"></i>
                    <span className="txt">지역 {resume.city}</span>
                  </div>
                </div>
                <div className="item-row">
                  <div className="option-box">
                    <i className="ic ic-calendar"></i>
                    <span className="txt">
                      {" "}
                      {displayExperienceMonth(resume.work_experience_month)}
                    </span>
                  </div>
                  <div className="option-box">
                    <i className="ic ic-document"></i>
                    <span className="txt">
                      직종 {resume.occupation_list.join(", ")}
                    </span>
                  </div>
                </div>
              </div>
            </a>
          </div>
        ))}
      </div>
    </>
  );
};
export default RecommendResume;
