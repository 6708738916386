import { useState } from "react";
import StaffImg from "assets/img/ic_staff.svg";

import {
  checkLectureEmployees,
  addLectureEmployees,
  sendInviteTextMessage,
} from "api/clinic/rests";
import { IClinicEmployeeView } from "api/clinic/types";
import { screeningId } from "utils/formatter";

const StaffAddPopup = ({
  handleAdd,
  handleClose,
}: {
  handleAdd: (employee: IClinicEmployeeView) => void;
  handleClose: () => void;
}) => {
  const [name, setName] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");

  const [checkedId, setCheckedId] = useState<string>("");
  const [isValid, setIsValid] = useState<boolean | undefined>(undefined);

  const checkEmployee = async () => {
    try {
      const {
        data: { member_id },
      } = await checkLectureEmployees(name.trim(), phoneNumber.trim());
      setCheckedId(member_id);
      setIsValid(true);
    } catch (e: any) {
      if (
        window.confirm(
          "가입되지 않은 회원입니다. 회원가입 요청 문자를 발송하겠습니까?"
        )
      ) {
        await sendInviteTextMessage(
          phoneNumber,
          `${name}님, 법정의무교육을 위한 치과인 가입링크 입니다. https://dent-in.co.kr/signup`
        );
        alert("초대 문자 메세지를 발송하였습니다");
      }
      setCheckedId("");
      setIsValid(false);
    }
  };

  const registEmployee = async () => {
    if (!checkedId) return;

    try {
      const { data } = await addLectureEmployees(checkedId);

      handleAdd(data);
      setName("");
      setPhoneNumber("");
      setCheckedId("");
      setIsValid(undefined);
    } catch (e) {
      alert("오류가 발생하였습니다.");
    }
  };

  return (
    <article className="popup popup-staff-add" style={{ display: "block" }}>
      <div className="popup-wrap">
        <div className="popup-head">
          <div className="mobile-head-box">
            <div className="mobile-head-txt">직원 추가하기</div>
          </div>

          <button className="close-btn" onClick={handleClose}></button>
        </div>
        <div className="popup-body">
          <article className="popup-staff-form">
            <div className="ic-box">
              <img src={StaffImg} alt="이미지" />
            </div>

            <div className="txt-box">
              <div className="main-box">직원 추가하기</div>
              <div className="sub-box">
                법정의무교육을 수강할 우리 치과의 직원을 추가해주세요
                <br />※ 개인회원 가입된 회원만 추가 가능합니다.
              </div>
            </div>

            <div className="staff-search-box">
              <div className="search-box">
                <div className="input-box">
                  <div className="tbox full">
                    <input
                      type="text"
                      placeholder="이름"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                </div>

                <div className="input-box">
                  <div className="tbox full">
                    <input
                      type="text"
                      placeholder="연락처"
                      value={phoneNumber}
                      onChange={(e) =>
                        setPhoneNumber(e.target.value.replace(/\D/g, ""))
                      }
                    />
                  </div>
                </div>

                <div className="search-btn-box">
                  <div className="search-btn" onClick={checkEmployee}>
                    조회
                  </div>
                </div>
              </div>

              <div className="result-box">
                {isValid !== undefined &&
                  (isValid ? (
                    <>
                      <div className="result-txt type-success">
                        유효한 회원입니다.
                        <br />
                        ID: {screeningId(checkedId)}
                      </div>
                      <div className="result-msg-txt">
                        아래 버튼을 클릭하시면 해당 회원에게 법정의무교육 권한이
                        부여됩니다.
                      </div>
                    </>
                  ) : (
                    <div className="result-txt type-fail">
                      유효하지 않은 회원입니다.
                    </div>
                  ))}
              </div>
            </div>
          </article>
        </div>

        <div className="popup-foot">
          <div className="foot-menu-box">
            <div className="foot-menu-btn type-1" onClick={registEmployee}>
              등록
            </div>
          </div>
        </div>
      </div>
    </article>
  );
};
export default StaffAddPopup;
