import { AxiosError } from "axios";

export const SUCCESS_RESULT = "SUCCESS";
export interface IError {
  errorMessage: string;
  field: string;
  objectName: string;
  rejectedValue: string;
}
export interface IResponse<T> {
  result: string; // SUCCESS | FAIL | ALREADY_EXIST
  /**
   * 결과 메시지 (실패인 경우, 오류 메시지)
   */
  message: string;
  data: T extends null | undefined ? never : T;
}

export type IPageResponse<T> = IResponse<{
  total_page: number;
  total_elements: number;
  element_list: T;
}>;

export type IResponseError = AxiosError<{
  result: string;
  message: string;
}>;

export type IUploadFileView = IResponse<{
  file_name: string;
  file_size: number;
}>;
export interface IChatMemberView {
  chat_id: number;
  member_type: string;
  member_id: string;
  name: string;
  read_flag: boolean;
  last_message: string;
  last_reg_date: string;
  sender: string;
}

export interface IQnaChatView {
  id: number;
  from_admin: boolean;
  member_type: "MEMBER" | "CLINIC";
  member_id: string;
  clinic_member_id: string;
  message: string;
  read_flag: boolean;
  is_qna: boolean;
  reg_date: string;
}
export interface IChatMessageView {
  chat_id: number;
  from_member_type: string;
  from_member_id: string;
  from_name: string;
  to_member_type: string;
  to_member_id: string;
  to_name: string;
  read_flag: boolean;
  message: string;
  reg_date: string;
}
