import { useState, useEffect, useCallback } from "react";
import moment from "moment";
import RecruitItem from "./RecruitItem";

import { JOBPOSTING_PATH } from "routes";

import { fetchJobPostings } from "api/jobPosting/rests";
import { IJobOpeningOverview } from "api/jobPosting/types";
import { wishJobPosting, cancelWishJobPosting } from "api/member/rests";
import Paginator from "components/Paginator";
const RecruitList = () => {
  const [page, setPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [jobPostings, setJobPostings] = useState<IJobOpeningOverview[]>([]);

  const handleWish = async (wishFlag: boolean, jobId: number) => {
    try {
      wishFlag
        ? await cancelWishJobPosting(jobId)
        : await wishJobPosting(jobId);
      await fetch();
    } catch (e: any) {
      alert("오류가 발생하였습니다.");
    }
  };

  const fetch = useCallback(async () => {
    const {
      data: { total_page, element_list },
    } = await fetchJobPostings(page, 24, "UPDATE_DATE");
    setJobPostings(element_list);
    setTotalPage(total_page);
  }, [page]);

  useEffect(() => {
    (async () => fetch())();
  }, [page]);

  return (
    <section className="main-recruit">
      <div className="inner wrap">
        <article className="list-layout1">
          <div className="list-head-box">
            <div className="head-txt-box">전체 채용 공고</div>
            <div className="list-option-box"></div>
          </div>

          <div className="list-body-box">
            <div className="list-wrap">
              <ul>
                {jobPostings.map((jobPosting, i) => (
                  <RecruitItem
                    key={i}
                    hospital={jobPosting.clinic_name}
                    subject={`${jobPosting.intern_flag ? "[실습치과] " : ""}${
                      jobPosting.title
                    }`}
                    logo={jobPosting.logo_image_filename}
                    remainedDays={moment(jobPosting.closing_date).diff(
                      moment(),
                      "days"
                    )}
                    favorite={jobPosting.wish_flag}
                    url={`${JOBPOSTING_PATH}/${jobPosting.job_id}`}
                    onClickFavorite={() =>
                      handleWish(jobPosting.wish_flag, jobPosting.job_id)
                    }
                    item={jobPosting}
                  />
                ))}
              </ul>
            </div>

            {totalPage > 1 && (
              <Paginator
                currentPage={page}
                totalPage={totalPage}
                changePage={(page) => setPage(page)}
              />
            )}
          </div>

          <div>
            <br></br>
            <br></br>
          </div>

          <div className="img-box">
            {banner_bottom.map((banner) => (
              <a
                href={banner.url}
                target="_blank"
                rel="noreferrer"
                className="banner kda-banner"
              >
                <img
                  src={banner.img}
                  width="70px"
                  alt="이미지"
                  className="banner-img"
                ></img>
                <div className="txt-box">
                  <div className="title">{banner.title}</div>
                  <div className="des">{banner.des}</div>
                </div>
              </a>
            ))}
          </div>
        </article>
      </div>
    </section>
  );
};

export default RecruitList;

const banner_bottom = [
  {
    url: "http://klpna.or.kr/",
    img: "/banner_main/banner01.png",
    title: "대한간호조무사협회",
    des: "Korea Licensed Practical Nurses Association",
  },
  {
    url: "https://klpnea.or.kr/",
    img: "/banner_main/banner02.png",
    title: "대한간호학원협회",
    des: "Korea Nursing Academy Association",
  },
  {
    url: "https://www.kdtech.or.kr/",
    img: "/banner_main/banner03.png",
    title: "대한치과기공사협회",
    des: "Korean Dental Technologist Association",
  },
  {
    url: "https://www.kdha.or.kr/",
    img: "/banner_main/banner04.png",
    title: "대한치과위생사협회",
    des: "Korean Dental Hygienists Association",
  },
  {
    url: "https://www.kda.or.kr/",
    img: "/banner_main/banner05.png",
    title: "대한치과의사협회",
    des: "korean Dental Association",
  },
  {
    url: "https://www.kwda.co.kr/",
    img: "/banner_main/banner06.png",
    title: "대한여성치과의사회",
    des: "Korean Women Dental Association",
  },
  {
    url: "https://hcakorea.org/",
    img: "/banner_main/banner07.png",
    title: "(사)병원코디네이터협회",
    des: "Hospital Coordinator Association",
  },
  {
    url: "https://www.dailydental.co.kr/",
    img: "/banner_main/banner08.png",
    title: "치의신보",
    des: "Daily Dental",
  },
];
