/**
 * 인재정보 검색 페이지
 */

import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import Layout from "layouts";

import { IResumeOverview } from "api/resume/types";
import { searchResumes } from "api/resume/rests";
import {
  AreaSelector,
  KeywordSelector,
  OccupationSelector,
  QueryBox,
  ResumeOverviewList,
  SearchTypeSelector,
} from "components/ResumeList";
import { TabSelector } from "components/ResumeList/Comps";
import Paginator from "components/Paginator";
import { PAGE_SIZE } from "utils/constants";
import useKeywords from "hooks/useKeywords";
import { useUserContext } from "contexts/UserContext";
import { USER_TYPE } from "utils/enums";
import { useNavigate } from "react-router-dom";
import { MAIN_PATH } from "routes";
import { Banner } from "components/commons";

enum SEARCH_TYPE {
  없음 = "없음",
  지역 = "지역",
  직종 = "직종",
  키워드 = "키워드",
}
const useResumeSearchPage = (searchType: string) => {
  const [queryType, setQueryType] = useState<SEARCH_TYPE>(SEARCH_TYPE.없음);
  const [queries, setQueries] = useState<string[]>([]);
  const [page, setPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [resumes, setResumes] = useState<Array<IResumeOverview>>([]);

  const search = useCallback(async () => {
    let region_list: string[] = [];
    let occupation_list: string[] = [];
    let keyword_list: string[] = [];

    queries.forEach((q) => {
      const [type, query] = q.split("|");
      if (type === "a") {
        region_list.push(query);
      } else if (type === "o") {
        occupation_list.push(query);
      } else {
        keyword_list.push(query);
      }
    });

    try {
      const {
        data: { total_page, total_elements, element_list },
      } = await searchResumes(page, PAGE_SIZE, {
        search_type: searchType.toUpperCase(),
        region_list,
        occupation_list,
        keyword_list,
        sort_property: "UPDATE_DATE",
        sort_direction: "DESC",
      });

      setTotalPage(total_page);
      setTotalElements(total_elements);
      setResumes(element_list);
    } catch (e) {
      setTotalPage(0);
      setTotalElements(0);
      setResumes([]);
    }
  }, [page, queries, searchType]);

  useEffect(() => {
    (async () => search())();
  }, [search]);

  return {
    queryType,
    queries,
    resumes,
    page,
    totalPage,
    totalElements,
    handler: {
      setPage,
      setQueryType,
      addQuery: (query: string) => {
        if (query) setQueries([...queries, query]);
      },
      clearQuery: () => setQueries([]),
      removeQuery: (query: string) =>
        setQueries((queries) => queries.filter((q) => q !== query)),
      search,
    },
  };
};

const ResumeSearchPage = () => {
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const navigate = useNavigate();
  const { isSignIn, userType } = useUserContext();
  const {
    queryType,
    queries,
    resumes,
    page,
    totalPage,
    totalElements,
    handler: {
      setQueryType,
      addQuery,
      clearQuery,
      removeQuery,
      setPage,
      search,
    },
  } = useResumeSearchPage(tab || "staff");
  const keywords = useKeywords("RESUME");

  useEffect(() => {
    if (!isSignIn) {
      alert("로그인 후 이용 가능합니다.");
      navigate(`${MAIN_PATH}`);
    }
    if (userType === USER_TYPE.개인회원) {
      alert("개인회원은 접근할 수 없습니다.");
      navigate(`${MAIN_PATH}`);
    }
  }, []);
  return (
    <Layout>
      <TabSelector selectedTab={tab} />

      <section className="info-search-form">
        <div className="inner wrap">
          <SearchTypeSelector
            hideOccupation={tab === "dentist"}
            searchType={queryType}
            addQuery={addQuery}
            onSelect={setQueryType}
          />

          {queryType === SEARCH_TYPE.지역 && (
            <AreaSelector
              selected={queries}
              onClose={() => setQueryType(SEARCH_TYPE.없음)}
              onSelect={(areaQ: string) => addQuery(`a|${areaQ}`)}
            />
          )}
          {queryType === SEARCH_TYPE.직종 && (
            <OccupationSelector
              selected={queries}
              onClose={() => setQueryType(SEARCH_TYPE.없음)}
              onSelect={(occupationQ: string) => addQuery(`o|${occupationQ}`)}
            />
          )}
          {queryType === SEARCH_TYPE.키워드 && (
            <KeywordSelector
              recommends={keywords}
              onClose={() => setQueryType(SEARCH_TYPE.없음)}
              onSelect={(keywordQ: string) => addQuery(`k|${keywordQ}`)}
            />
          )}

          <QueryBox
            isQueryTypeSelectActive={queryType !== SEARCH_TYPE.없음}
            queries={queries}
            onClear={clearQuery}
            onSearch={search}
            onRemove={removeQuery}
          />
          <div style={{ marginTop: 30 }}>
            <Banner type="RESUME" />
          </div>
          {userType === USER_TYPE.병원회원 && (
            <ResumeOverviewList
              resumes={resumes}
              totalCount={totalElements}
              refreshResumes={search}
            />
          )}

          {totalPage > 1 && (
            <Paginator
              currentPage={page}
              totalPage={totalPage}
              changePage={(page) => setPage(page)}
            />
          )}
        </div>
      </section>
    </Layout>
  );
};
export default ResumeSearchPage;
