import axiosClient from "api/axiosClient";
import {
  ChangeEvent,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import InputFieldRow from "./Comps/InputFieldRow";
// import { SignupContext } from "pages/SignupPage/MemberSignup";
import { SignupContext } from "contexts/SignupContex";

const IdPasswordBox = ({
  passwordVerify,
  setDisabled,
}: {
  passwordVerify?: boolean;
  setDisabled?: React.Dispatch<SetStateAction<boolean>>;
}) => {
  const context = useContext(SignupContext);
  const { member_id, password, errors, setMemberId, setPassword } = context;
  const [verify, setVerify] = useState<string>("");

  const p = new RegExp(
    "^(?=.*[A-Za-z])(?=.*\\d)(?=.*[~!@#$%^&*()+|=])[A-Za-z\\d~!@#$%^&*()+|=]{8,16}",
    "i"
  );

  const tempDeleteUser = async () => {
    await axiosClient.delete<any>(`/admin/member/${member_id}`);
  };

  return (
    <div className="form-box">
      <div className="form-tit-box" onClick={tempDeleteUser}>
        ID/PW
      </div>
      <div className="form-con-box">
        <InputFieldRow
          errorMessage={errors?.member_id}
          placeholder="ID*"
          value={member_id}
          onChange={setMemberId}
        />

        <InputFieldRow
          errorMessage={
            errors?.password ||
            (password.match(p) === null
              ? "비밀번호는 영문, 숫자, 특수문자 조합(따옴표, 부등호, 물음표 제외)으로 무조건 한개 이상 포함하여, 8 ~ 16자리까지 가능합니다."
              : "")
          }
          placeholder="PW*"
          value={password}
          type="password"
          onChange={(value) => setPassword(value)}
        />

        {passwordVerify && (
          <InputFieldRow
            errorMessage={
              verify !== password ? "비밀번호가 일치하지 않습니다" : ""
            }
            placeholder="비밀번호 재확인*"
            value={verify}
            type="password"
            onChange={(value) => setVerify(value)}
          />
        )}
      </div>
    </div>
  );
};

export default IdPasswordBox;
