import axiosClient from "../axiosClient";

import {
  IPageResponse,
  IResponse,
  IUploadFileView,
  IChatMessageView,
  IChatMemberView,
  IQnaChatView,
} from "api/types";
import {
  IMemberCreateRequest,
  IMemberLoginRequest,
  IMemberUpdateRequest,
  IMemberView,
  IResumeEditRequest,
  IResumeGroupView,
  IResumeGroupEditRequest,
  IResumeMemberView,
  IResumeMemberOverview,
  IMemberWishView,
  IJobActionView,
  IJobActionOpeningView, IMasterLoginRequest, IMemberFindWithNameRequest,
} from "./types";
import {
  ILectureTakeView,
  ILegalLectureTakeView,
  ILectureCourseTakeView,
  ILectureCourseBookmarkView,
  ILectureCourseTakeOverview,
} from "api/education/types";

import {
  getAuth,
  getRefreshToken,
  logout,
  setAuth,
  setTokens,
} from "utils/storage";
import { USER_TYPE } from "utils/enums";
import { PAGE_SIZE } from "utils/constants";
import { bridgeLogin } from "./../../utils/appBridge";
import axios from "axios";

/**
 * 회원 탈퇴
 * DELETE /member/withdraw
 */
export const deleteMember = async () => {
  const resp = await axiosClient.delete<IResponse<any>>(`/member/withdraw`);

  return resp.data;
};

/**
 * 회원 정보 조회
 * GET /member/profile
 */
export const fetchMember = async () => {
  const resp = await axiosClient.get<IResponse<IMemberView>>(`/member/profile`);

  return resp.data;
};

/**
 * 회원 정보 변경
 * PATCH /member
 */
export const updateMember = async (params: Partial<IMemberUpdateRequest>) => {
  const resp = await axiosClient.patch<IResponse<IMemberView>>(
    `/member`,
    params
  );

  return resp.data;
};

/**
 * 회원 존재 여부 체크
 * HEAD /member/{member_id}
 *
 * 존재 시 성공 반환, 존재하지 않은 경우 NOT_FOUND (404) 반환
 */
export const checkMember = async (member_id: string) => {
  const resp = await axiosClient.head<any>(`/member/${member_id}`);

  return resp.data;
};

/**
 * 회원 Id/Password 체크
 * POST /member/check
 */
export const checkMemberPassword = async (
  member_id: string,
  password: string
): Promise<IResponse<string>> => {
  const resp = await axiosClient.post<IResponse<string>>("/member/check", {
    member_id,
    password,
  });

  return resp.data;
};

/**
 * 회원 가입
 * POST /member/signup
 */
export const createMember = async (params: IMemberCreateRequest) => {
  const resp = await axiosClient.post<IResponse<IMemberView>>(
    "/member/signup",
    params
  );

  return resp.data;
};

/**
 * 회원 확인
 * HEAD /member/{id}
 */

export const checkMemberExistById = (member_id: string) => {
  return axiosClient.head(`/member/${member_id}`);
};
/**
 * 회원 확인
 * HEAD /member/{id}
 */

export const checkMemberExistByIdWithName = async (params:IMemberFindWithNameRequest) => {
  const resp = await axiosClient.post<any>("/member/find/id_name", params);

  return resp.data;
};
/**
 * 이력서 등록
 * POST /member/resume
 */
export const createResume = async (params: IResumeEditRequest) => {
  const resp = await axiosClient.post<any>("/member/resume", params);

  return resp.data;
};

/**
 * 이력서 수정
 * POST /member/resume
 */
export const updateResume = async (
  resumeId: number,
  params: IResumeEditRequest
) => {
  const resp = await axiosClient.put<any>(`/member/resume/${resumeId}`, params);

  return resp.data;
};

/**
 * 회원 로그인
 * POST /member/login
 */
export const loginMember = async (params: IMemberLoginRequest) => {
  const resp = await axiosClient.post<IResponse<IMemberView>>(
    "/member/login",
    params
  );
  const { data } = resp.data;
  if (!data) {
    return resp.data.message;
  }
  const { authorization } = resp.headers;

  axiosClient.defaults.headers.post[
    "Authorization"
  ] = `Bearer ${authorization}`;

  setAuth({
    member_id: data.member_id,
    name: data.name,
    user_type: USER_TYPE.개인회원,
    clinic_type:'개인회원',
    occupation: data.occupation,
  });
  setTokens({
    token: authorization,
    refresh_token: resp.headers["refresh-token"],
  });
  bridgeLogin(authorization, resp.headers["refresh-token"]);

  return resp.data;
};

/**
 * 마스터 로그인
 * POST /member/loginmaster
 */
export const loginMasterMember = async (params: IMemberLoginRequest) => {
  const resp = await axiosClient.post<IResponse<IMemberView>>(
    "/member/loginmaster",
    params
  );
  const { data } = resp.data;
  if (!data) {
    return resp.data.message;
  }
  const { authorization } = resp.headers;

  axiosClient.defaults.headers.post[
    "Authorization"
    ] = `Bearer ${authorization}`;

  setAuth({
    member_id: data.member_id,
    name: data.name,
    user_type: USER_TYPE.개인회원,
    clinic_type:'개인회원',
    occupation: data.occupation,
  });
  setTokens({
    token: authorization,
    refresh_token: resp.headers["refresh-token"],
  });
  bridgeLogin(authorization, resp.headers["refresh-token"]);

  return resp.data;
};

/**
 * 사용자 token 재발급
 * POST /member/refresh-token
 */
export const refreshToken = async () => {
  const { member_id } = getAuth();
  const refreshToken = getRefreshToken();

  try {
    const resp = await axiosClient.post<IResponse<IMemberView>>(
      "/member/refresh-token",
      {
        member_id: member_id,
        refresh_token: refreshToken,
      }
    );

    const { authorization } = resp.headers;
    setTokens({
      token: authorization,
      refresh_token: refreshToken,
    });
    bridgeLogin(authorization, refreshToken);
  } catch (e: any) {
    // e.response?.data?.message === "Expired jwt"
    //   ? alert("인증이 만료되었습니다. 다시 로그인해주세요")
    //   : alert("오류가 발생하였습니다.");
    localStorage.clear();
    sessionStorage.clear();
    logout();
    window.location.href = "/login";
  }
};

/**
 * 모든 이력서 그룹 조회
 * GET /member/resume/group
 */
export const fetchResumeGroups = async (): Promise<
  IResponse<IResumeGroupView[] | string>
> => {
  const resp = await axiosClient.get("/member/resume/group");

  return resp.data;
};

/**
 * 이력서 그룹 조회
 * GET /member/resume/group/{group_id}
 */
export const fetchResumeGroup = async (
  group_id: number
): Promise<IResponse<IResumeGroupView | string>> => {
  const resp = await axiosClient.get(`/member/resume/group/${group_id}`);

  return resp.data;
};

/**
 * 이력서 그룹 등록
 * POST /member/resume/group
 */
export const addResumeGroup = async (
  params: IResumeGroupEditRequest
): Promise<IResponse<IResumeGroupView | string>> => {
  const resp = await axiosClient.post("/member/resume/group", params);

  return resp.data;
};

/**
 * 이력서 그룹 수정
 * POST /member/resume/group
 */
export const updateResumeGroup = async (
  group_id: number,
  params: IResumeGroupEditRequest
): Promise<IResponse<IResumeGroupView | string>> => {
  const resp = await axiosClient.put(
    `/member/resume/group/${group_id}`,
    params
  );

  return resp.data;
};

/**
 * 이력서 그룹 삭제
 * POST /member/resume/group
 */
export const deleteResumeGroup = async (
  group_id: number
): Promise<IResponse<{ description: string }>> => {
  const resp = await axiosClient.delete(`/member/resume/group/${group_id}`);

  return resp.data;
};

/**
 * 이력서 List 조회
 * GET /member/resume
 */
export const fetchMemberResumes = async (groupId: number) => {
  const resp = await axiosClient.get<IResponse<Array<IResumeMemberOverview>>>(
    `/member/resume?group_id=${groupId}`
  );

  return resp.data;
};

/**
 * 회원 이력서 조회
 * GET /member/resume/{resume_id}
 */
export const fetchMemberResume = async (resumeId: number) => {
  const resp = await axiosClient.get<IResponse<IResumeMemberView>>(
    `/member/resume/${resumeId}`
  );

  return resp.data;
};

/**
 * 채용 공고 찜하기
 * POST /member/wish/{job_id}
 */
export const wishJobPosting = async (jobId: number) => {
  await axiosClient.post<IResponse<IMemberWishView>>(`/member/wish/${jobId}`);
};

/**
 * 채용 공고 찜하기 해제
 * POST /member/wish/{job_id}
 */
export const cancelWishJobPosting = async (jobId: number) => {
  await axiosClient.delete<IResponse<IMemberWishView>>(`/member/wish/${jobId}`);
};

/**
 * 채용 공고 찜 목록 조회
 * GET /member/wish
 */
export const fetchWishJobPosting = async (page: number) => {
  const resp = await axiosClient.get<IPageResponse<Array<IMemberWishView>>>(
    `/member/wish?page=${page}&size=${PAGE_SIZE}&sort_property=REG_DATE&sort_direction=ASC`
  );

  return resp.data;
};

/**
 * 채용 공고 지원
 * POST /member/job/action
 */
export const applyJobPosting = async (job_id: number, resume_id: number) => {
  const resp = await axiosClient.post<IResponse<IJobActionView>>(
    `/member/job/action`,
    {
      job_id,
      resume_id,
      action_type: "APPLY",
      action: "PASS",
    }
  );

  return resp.data;
};

/**
 * 채용 공고 액션 조회 API (채용 공고 지원 또는 면접 제안)
 * GET /member/job/action
 * 지원 : APPLY / 면접제안 : OFFER
 */
export const fetchJobPostingHistory = async (
  action: "APPLY" | "OFFER",
  page: number,
  page_size?: number
) => {
  const resp = await axiosClient.get<
    IPageResponse<Array<IJobActionOpeningView>>
  >(
    `/member/job/action?action_type=${action}&page=${page}&size=${
      page_size || PAGE_SIZE
    }&sort_property=UPD_DATE&sort_direction=ASC`
  );

  return resp.data;
};

/**
 * 채용 공고 지원 취소
 * DELETE /member/job/action
 */
export const cancelApply = async (job_id: number, resume_id: number) => {
  const resp = await axiosClient.delete<IResponse<null>>(
    `/member/job/action?resume_id=${resume_id}&job_id=${job_id}`
  );

  return resp.data;
};

/**
 * 이미지 파일 업로드
 * POST /member/file/upload
 */
export const uploadMemberImage = async (params: FormData) => {
  const resp = await axiosClient.post<IUploadFileView>(
    `/member/file/upload`,
    params
  );

  return resp.data;
};

/**
 * 이력서 그룹 수정 API (그룹 및 공개 여부 수정)
 * PATCH /member/resume/{resume_id}
 */
export const togglePublicResume = async (
  resume_id: number,
  public_flag: boolean
) => {
  const resp = await axiosClient.patch<IResponse<IResumeMemberView>>(
    `/member/resume/${resume_id}`,
    {
      public_flag: public_flag,
    }
  );

  return resp.data;
};

/**
 * 면접 제안 읽음
 * PATCH /member/job/action/read
 */
export const readRequestedJobPosting = async (
  job_id: number,
  resume_id: number
) => {
  const resp = await axiosClient.patch<IResponse<IJobActionView>>(
    `/member/job/action/read?job_id=${job_id}&resume_id=${resume_id}`
  );

  return resp.data;
};

/**
 * 면접 제안 결과 설정
 * PATCH /member/job/action (ACCEPT, REJECT)
 */
export const responseRequestedJobPosting = async (
  job_id: number,
  resume_id: number,
  action: "REJECT" | "ACCEPT"
) => {
  const resp = await axiosClient.patch<IResponse<IJobActionView>>(
    `/member/job/action`,
    {
      job_id,
      resume_id,
      action,
    }
  );

  return resp.data;
};

/**
 * 관리자 채팅
 * GET /member/qna
 */
export const fetchMemberQna = async () => {
  const resp = await axiosClient.get<IResponse<IQnaChatView[]>>(`/member/qna`);
  return resp.data;
};

export const fetchClinicQna = async () => {
  const resp = await axiosClient.get<IResponse<IQnaChatView[]>>(`/clinic/qna`);
  return resp.data;
};

/**
 * 관리자 채팅 보내기
 * POST /member/qna
 */
export const sendQnaMemberMessage = async (message: string) => {
  const resp = await axiosClient.post<IResponse<IQnaChatView>>(`/member/qna`, {
    message,
  });
  return resp.data;
};

export const sendQnaClinicMessage = async (message: string) => {
  const resp = await axiosClient.post<IResponse<IQnaChatView>>(`/clinic/qna`, {
    message,
  });
  return resp.data;
};

/**
 * 관리자 마지막 채팅 가져오기
 * GET /member/qna/top
 */
export const fetchQnaMemberMessageTop = async () => {
  const resp = await axiosClient.get<IResponse<IQnaChatView>>(
    `/member/qna/top`
  );
  return resp.data;
};
export const fetchQnaClinicMessageTop = async () => {
  const resp = await axiosClient.get<IResponse<IQnaChatView>>(
    `/clinic/qna/top`
  );
  return resp.data;
};

/**
 * 채팅 메세지 리스트 조회
 * GET /member/chat/list
 */
export const fetchChatList = async (keyword: string) => {
  const resp = await axiosClient.get<IResponse<IChatMemberView[]>>(
    `/member/chat/list${!!keyword ? "?keyword=" + keyword : ""}`
  );

  return resp.data;
};

/**
 * 채팅 메세지 조회
 * GET /member/chat
 */
export const fetchMessages = async (member_type: string, member_id: string) => {
  const resp = await axiosClient.get<IResponse<IChatMessageView[]>>(
    `/member/chat?member_type=${member_type}&member_id=${member_id}`
  );
  console.log(resp);
  return resp.data;
};

/**
 * 채팅 메세지 전송 (채용 공고 기반)
 * POST /member/chat/job
 */
export const sendMessageFromJobPosting = async (
  job_id: number,
  message: string
) => {
  const resp = await axiosClient.post<IResponse<IChatMessageView>>(
    `/member/chat/job`,
    {
      job_id: job_id,
      message: message,
    }
  );

  return resp.data;
};

/**
 * 채팅 메세지 전송
 * POST /member/chat
 */
export const sendMessage = async (
  member_type: string,
  member_id: string,
  name: string,
  message: string
) => {
  const resp = await axiosClient.post<IResponse<IChatMessageView>>(
    `/member/chat`,
    {
      member_type,
      member_id,
      name,
      message,
    }
  );

  return resp.data;
};

/**
 * 법정 의무 교육 강의 조회
 * GET /member/education/lecture/legal
 */
export const fetchMyLegalLecture = async () => {
  const resp = await axiosClient.get<IResponse<Array<ILegalLectureTakeView>>>(
    `/member/education/lecture/legal`
  );

  return resp.data;
};

/**
 * 회원 수강 중인 강의 코스 조회
 * GET /member/education/lecture/course
 */
export const fetchMyLecture = async () => {
  const resp = await axiosClient.get<
    IResponse<Array<ILectureCourseTakeOverview>>
  >(`/member/education/lecture/course`);

  return resp.data;
};

/**
 * 온라인 교육 강의 코스 정보 상세 조회
 * GET /member/education/lecture/course/{course_id}
 */
export const fetchLecture = async (course_id: number) => {
  const resp = await axiosClient.get<IResponse<ILectureCourseTakeView>>(
    `/member/education/lecture/course/${course_id}`
  );

  return resp.data;
};

/**
 * 온라인 교육 강의 코스 즐겨찾기 목록 조회
 * GET /member/education/lecture/course/bookmark
 */
export const fetchBookmarkLecture = async () => {
  const resp = await axiosClient.get<
    IResponse<Array<ILectureCourseBookmarkView>>
  >(`/member/education/lecture/course/bookmark`);

  return resp.data;
};

/**
 * 온라인 교육 강의 코스 즐겨찾기 설정
 * POST /member/education/lecture/course/bookmark
 */
export const bookmarkLecture = async (course_id: number) => {
  const resp = await axiosClient.post<IResponse<ILectureCourseBookmarkView>>(
    `/member/education/lecture/course/bookmark?course_id=${course_id}`
  );

  return resp.data;
};

/**
 * 온라인 교육 강의 코스 즐겨찾기 제거
 * DELETE /member/education/lecture/course/bookmark/{course_id}
 */
export const removeBookmarkLecture = async (course_id: number) => {
  const resp = await axiosClient.delete<IResponse<any>>(
    `/member/education/lecture/course/bookmark/${course_id}`
  );

  return resp.data;
};

/**
 * 온라인 교육 강의 시작
 * POST /member/education/lecture/start
 */
export const startLecture = async (
  lecture_type: string,
  lecture_id: number,
  collect_flag: boolean
) => {
  const resp = await axiosClient.post<IResponse<ILectureTakeView>>(
    `/member/education/lecture/start`,
    {
      lecture_type,
      lecture_id,
      collect_flag,
    }
  );

  return resp.data;
};

/**
 * 온라인 교육 강의 완료
 * PUT /member/education/lecture/complete
 */
export const completeLecture = async (
  lecture_id: number,
  complete_date: string, // 완료 일시
  view_time: number // 수강 시간
) => {
  const resp = await axiosClient.put<IResponse<ILectureTakeView>>(
    `/member/education/lecture/complete`,
    {
      lecture_id,
      complete_date,
      view_time,
    }
  );

  return resp.data;
};

/**
 * 온라인 교육 강의 코스 즐겨찾기 목록 조회
 * GET /member/education/lecture/course/bookmark
 */
export const fetchBookmarks = async () => {
  const resp = await axiosClient.get<
    IResponse<Array<ILectureCourseBookmarkView>>
  >(`/member/education/lecture/course/bookmark`);

  return resp.data;
};

/**
 * 아이디 찾기
 * POST /member/find/id
 */
export const findMemberId = async (auth_code: string) => {
  const resp = await axiosClient.post<
    IResponse<{ member_id: string; name: string }>
  >(`/member/find/id`, {
    auth_code,
  });

  return resp.data;
};

/**
 * 비밀번호 재설정
 * PUT /member/password
 */
export const resetPassword = async (
  member_id: string,
  new_password: string,
  auth_code: string
) => {
  const resp = await axiosClient.put<IResponse<string>>(`/member/password`, {
    member_id,
    new_password,
    auth_code,
  });

  return resp.data;
};
