const InfoRow = ({
  label,
  value = "",
}: {
  label: string | JSX.Element;
  value?: string;
}) => (
  <div className="row">
    <div className="subject">{label}</div>
    <div className="con">{value}</div>
  </div>
);
export default InfoRow;
