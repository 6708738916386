import { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import DatePicker from "components/DatePicker";
import {
  CheckboxGroupFieldRow,
  ScreeningFieldRow,
} from "components/FormFields";
import { SalaryFieldRow, WorkExperienceRow } from "components/ResumeForm/Comps";
import { GROUP_CODE_ID } from "api/code/types";

import { DescriptionContext } from "pages/JobPostingFormPage";

import { ContentState, EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { resourcePath } from "utils/formatter";
import { uploadClinicImage } from "api/clinic/rests";

import moment from "moment";

const DescriptionForm = ({ isIntern }: { isIntern?: boolean }) => {
  const [searchParams] = useSearchParams();
  const context = useContext(DescriptionContext);

  const isEditMode = searchParams.get("mode") === "edit";

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const {
    closing_date,
    contact_email,
    contact_phone_number,
    benefit_list,
    employee_cnt,
    introduction,
    last_education_type,
    notification_list,
    min_work_experience,
    max_work_experience,
    occupation_list,
    title,
    salary_info,
    submission_list,
    submission_method_list,
    work_day_type,
    work_list,
    work_time,
    work_type_list,
    screening_list,
    details,
    hiring_count,
    post_agreement_flag,
    handler: {
      setContactPhoneNumber,
      setContactEmail,
      setClosingDate,
      setBenefits,
      setIntroduction,
      setLastEducationType,
      setTitle,
      setEmployeeCnt,
      setNotifications,
      setMinWorkExperience,
      setMaxWorkExperience,
      setOccupations,
      setWorkDayType,
      setWorks,
      setWorkTime,
      setWorkType,
      setSubmissions,
      setSalaryInfo,
      setSubmissionMethodList,
      setDetails,
      setScreenings,
      setHiringCount,
      register,
      setPostAgreementFlag,
    },
  } = context;

  const onEditorStateChange = (editorState: EditorState) => {
    // editorState에 값 설정
    setEditorState(editorState);
  };

  useEffect(() => {
    const blocksFromHtml = htmlToDraft(details || "");

    if (blocksFromHtml) {
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );
      const editorState = EditorState.createWithContent(contentState);
      setEditorState(editorState);
    }
  }, [details]);

  return (
    <article className="job-register-form">
      <div className="form-group">
        <div className="row">
          <div className="item-box">
            <div className="subject accent">공고 제목*</div>
            <div className="con">
              <div className="tbox long">
                <input
                    type="text"
                    placeholder="공고 제목을 입력해주세요."
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row no-border">
          <div className="item-box">
            <div className="subject type-m-long">구성원 현황*</div>
            <div className="con">
              <div className="tbox smaller">
                <input
                    type="text"
                    value={employee_cnt}
                    onChange={(e) =>
                        setEmployeeCnt(
                            Number(e.target.value.replace(/[^0-9]/g, ""))
                        )
                    }
                />
              </div>
              <div className="unit-box">명</div>
            </div>
          </div>
        </div>

        <div className="row no-border">
          <div className="item-box">
            <div className="subject type-m-long">모집 인원*</div>
            <div className="con">
              <div className="tbox smaller">
                <input
                    type="text"
                    value={hiring_count}
                    onChange={(e) =>
                        setHiringCount(
                            Number(e.target.value.replace(/[^0-9]/g, ""))
                        )
                    }
                />
              </div>
              <div className="unit-box">명</div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="full-box">
            <div className="subject accent">병/의원 요약소개</div>
            <div className="con">
              <div className="textbox">
                <textarea
                    placeholder="병/의원 요약소개를 입력해주세요."
                    value={introduction}
                    onChange={(e) => setIntroduction(e.target.value)}
                ></textarea>
              </div>
            </div>
          </div>
        </div>

        <CheckboxGroupFieldRow
            label="모집직종*"
            extraClass="no-border"
            selected={occupation_list}
            groupCodeId={
              !isIntern ? GROUP_CODE_ID.회원직종 : GROUP_CODE_ID.실습직종
            }
            onChange={setOccupations}
            dentistDiff={true}
        />

        <CheckboxGroupFieldRow
            label="담당업무*"
            selected={work_list}
            groupCodeId={
              !isIntern ? GROUP_CODE_ID.이력서업무 : GROUP_CODE_ID.실습업무
            }
            onChange={setWorks}
        />

        <CheckboxGroupFieldRow
            label="고용형태*"
            selected={work_type_list}
            groupCodeId={
              !isIntern ? GROUP_CODE_ID.근무형태 : GROUP_CODE_ID.실습형태
            }
            onChange={setWorkType}
        />

        <CheckboxGroupFieldRow
            label="최종학력*"
            selected={[last_education_type]}
            groupCodeId={GROUP_CODE_ID.최종학력}
            pickLastOne={true}
            onChange={(occupations) => setLastEducationType(occupations[0])}
        />

        <WorkExperienceRow
            min_work_experience={min_work_experience}
            max_work_experience={max_work_experience}
            changeMinWorkExperience={setMinWorkExperience}
            changeMaxWorkExperience={setMaxWorkExperience}
        />

        <SalaryFieldRow salaryInfo={salary_info} onChange={setSalaryInfo}/>

        <CheckboxGroupFieldRow
            label="근무요일*"
            selected={work_day_type}
            groupCodeId={GROUP_CODE_ID.근무요일}
            pickLastOne={true}
            onChange={(day) => setWorkDayType(day[0])}
        />

        <div className="row">
          <div className="item-box">
            <div className="subject">근무시간*</div>
            <div className="con">
              <div className="textbox small">
                <textarea
                    placeholder="근무시간을 입력해주세요"
                    value={work_time}
                    onChange={(e) => {
                      if (e.target.value.length >= 1000) {
                        alert("근무시간의 내용은 최대 2000자를 지원합니다.");
                        return;
                      }
                      setWorkTime(e.target.value.substring(0, 100));
                    }}
                ></textarea>
              </div>
            </div>
            {/* <div className="con">
              <div className="tbox">
                <input
                  type="text"
                  placeholder="근무시간을 입력해주세요"
                  value={work_time}
                  onChange={(e) => setWorkTime(e.target.value)}
                />
              </div>
            </div> */}
          </div>
        </div>

        <CheckboxGroupFieldRow
            label="복리후생"
            selected={benefit_list}
            groupCodeId={GROUP_CODE_ID.복리후생}
            onChange={setBenefits}
        />

        <CheckboxGroupFieldRow
            label="제출서류*"
            selected={submission_list}
            groupCodeId={GROUP_CODE_ID.제출서류}
            onChange={setSubmissions}
        />

        <CheckboxGroupFieldRow
            label="제출방법*"
            selected={submission_method_list}
            groupCodeId={GROUP_CODE_ID.제출방법}
            //   pickLastOne={true}
            onChange={setSubmissionMethodList}
        />

        <ScreeningFieldRow
            screenings={screening_list}
            onChange={setScreenings}
        />

        <div className="row">
          <div className="item-box">
            <div className="subject">마감일</div>
            <div className="con">
              <DatePicker
                  value={closing_date}
                  minDate={new Date()}
                  maxDate={moment()
                      .add(isIntern ? 100 : 30, "days")
                      .toDate()}
                  onChange={setClosingDate}
              />
              <div className="subject_minor">
                마감일은 선택하지 않을 경우, 기존 {isIntern ? "90일" : "2주"}로
                자동 설정됩니다.
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="item-box">
            <div className="subject">
              담당자 <span className="web">정보</span>*
            </div>
            <div className="con">
              <div className="tbox-list">
                <div className="tbox m-full">
                  <input
                      type="text"
                      placeholder="담당자 이메일"
                      value={contact_email}
                      onChange={(e) => setContactEmail(e.target.value)}
                  />
                </div>
                <div className="tbox m-full">
                  <input
                      type="text"
                      placeholder="담당자 연락처 (-없이 숫자만 입력)"
                      value={contact_phone_number}
                      onChange={(e) => {
                        const text = e.target.value.replace(/\D/g, "");
                        if (text && !text.match(/^\d+$/)) return;
                        return setContactPhoneNumber(text);
                      }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <CheckboxGroupFieldRow
            label={
              <>
                알림 수신 <span className="web">방법</span>
              </>
            }
            selected={notification_list}
            groupCodeId={GROUP_CODE_ID.알림수신방법}
            onChange={setNotifications}
        />

        <div className="row">
          <div className="full-box">
            <div className="subject accent">상세 채용정보 입력</div>
            <div className="con">
              <div className="textbox">
                <Editor
                    editorStyle={{resize: "vertical"}}
                    localization={{
                      locale: "ko",
                    }}
                    toolbar={{
                      options: [
                        "inline",
                        "blockType",
                        "colorPicker",
                        "fontSize",
                        "fontFamily",
                        "list",
                        "textAlign",
                        "emoji",
                        "image",
                      ],
                      image: {
                        uploadCallback: (file: File) => {
                          return new Promise((resolve, reject) => {
                            const data = new FormData();
                            data.append("file", file, file.name);
                            uploadClinicImage(data).then((res) => {
                              resolve({
                                data: {link: resourcePath(res.data.file_name)},
                              });
                            });
                          });
                        },
                        alt: {present: true, mandatory: false},
                      },
                    }}
                    editorState={editorState}
                    placeholder="경단녀 환영 넣는건 어떠세요?"
                    onEditorStateChange={onEditorStateChange}
                    onBlur={() => {
                      const editorToHtml = draftToHtml(
                          convertToRaw(editorState.getCurrentContent())
                      );
                      setDetails(editorToHtml);
                    }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="item-box">
            <div className="subject">게시 동의</div>
            <div className="con">
              <label>
                <input
                    type="checkbox"
                    checked={post_agreement_flag} // 불리언 값 사용 시
                    onChange={(e) => setPostAgreementFlag(e.target.checked)} // 불리언 값 사용 시
                />
                <i></i>
              </label>
              <div className="subject_minor">
                인재채움뱅크(커리어넷, 보건복지부) 치과위생사 채용관 동시 게시 동의
              </div>
            </div>
          </div>
        </div>

      </div>

      <div className="form-menu-box">
        <button className="menu-btn" onClick={register}>
          채용공고 {isEditMode ? "수정" : "등록"}
        </button>
      </div>
    </article>
  );
};
export default DescriptionForm;
