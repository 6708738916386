import { useNavigate } from "react-router-dom";
import {
  FIELDS,
  IMemberCreateRequest,
  IMemberPolicyAgreeRequest,
} from "api/member/types";
import { createMember, loginMember } from "api/member/rests";
import { useReducer } from "react";

import { GENDER } from "utils/enums";
import { MYPAGE_PATH, MYPATH_SUB_PATH } from "routes";
import { checkPassword } from "utils/formatter";

enum SIGNUP_ACTIONS {
  SET_VALUE = "SET_VALUE",
  SET_ERROR = "SET_ERROR",
  RESET_ERRORS = "RESET_ERRORS",
}

type SignupState = {
  formFields: IMemberCreateRequest;
  errors: any;
};
type SignupAction =
  | {
      type: SIGNUP_ACTIONS.SET_VALUE;
      payload: {
        key: string;
        value: string | Array<IMemberPolicyAgreeRequest>;
      };
    }
  | {
      type: SIGNUP_ACTIONS.SET_ERROR;
      payload: {
        key: string;
        value: string;
      };
    }
  | {
      type: SIGNUP_ACTIONS.RESET_ERRORS;
    };

const reducer = (state: SignupState, action: SignupAction) => {
  switch (action.type) {
    case SIGNUP_ACTIONS.SET_VALUE:
      const { key, value } = action.payload;
      const newFormFields = { ...state.formFields, [key]: value };
      return { ...state, formFields: newFormFields };
    case SIGNUP_ACTIONS.SET_ERROR:
      const newErrors = {
        ...state.errors,
        [action.payload.key]: action.payload.value,
      };
      return { ...state, errors: newErrors };
    case SIGNUP_ACTIONS.RESET_ERRORS:
      return { ...state, errors: {} };
    default:
      return state;
  }
};

const initialState: SignupState = {
  formFields: {
    member_id: "",
    password: "",
    auth_code: "",
    email: "",
    occupation: "",
    referrer: "치즈톡",
    policy_agree_list: [],
    event_follow_id: "",
    event_follow_id_clinic: "",
  },
  errors: {},
};
const useMemberSignup = () => {
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, initialState);

  const _change = (
    key: string,
    newValue: string | IMemberPolicyAgreeRequest[]
  ) =>
    dispatch({
      type: SIGNUP_ACTIONS.SET_VALUE,
      payload: { key: key, value: newValue },
    });

  const setError = (key: string, value: string) =>
    dispatch({
      type: SIGNUP_ACTIONS.SET_ERROR,
      payload: { key, value },
    });

  const validateBeforeRequest = () => {
    /**
     * 서버로 전송 전,
     * - 에러 필드를 리셋하고,
     * - 비밀번호 / 치과의사일 경우, 면허번호 / 약관동의 먼저 FE에서 체크.
     */
    let isDirty = false;
    const {
      formFields: {
        member_id,
        email,
        auth_code,
        password,
        occupation,
        licence_number,
        policy_agree_list,
        referrer,
      },
    } = state;

    dispatch({ type: SIGNUP_ACTIONS.RESET_ERRORS });

    if (member_id.trim() === "") {
      setError("member_id", "ID를 입력해주세요.");
      alert("ID를 입력해주세요.");
      return false;
    }
    if (password.trim() === "") {
      setError("password", "비밀번호를 입력해주세요.");
      alert("비밀번호를 입력해주세요.");
      return false;
    }
    if (!checkPassword(password)) {
      setError(
        "password",
        "8~16자 영문 대 소문자, 숫자, 특수문자를 사용하세요."
      );
      alert("8~16자 영문 대 소문자, 숫자, 특수문자를 사용하세요.");
      return false;
    }
    if (!auth_code) {
      // setError("password", "휴대폰 인증을 먼저 진행해주세요.");
      alert("휴대폰 인증을 진행해주세요.");
      return false;
    }
    if (email.trim() === "") {
      // setError("email", "이메일을 입력해주세요.");
      alert("이메일을 입력해주세요.");
      return false;
    }
    if (occupation === "") {
      // setError(
      //   "occupation",
      //   "직종을 선택해주세요."
      // );
      alert("직종을 선택해주세요.");
      return false;
    }
    if (occupation === "치과의사" && !licence_number) {
      // setError("licence_number", "올바른 면허번호가 아닙니다.");
      alert("올바른 면허번호가 아닙니다.");
      return false;
    }
    if (!referrer) {
      alert("가입 경로를 확인해주세요.");
      return false;
    }
    if (policy_agree_list.length === 0) {
      // setError("password", "약관동의를 확인해주세요.");
      alert("약관동의를 확인해주세요.");
      return false;
    }

    // if (!checkBizNumber(biz_reg_number)) {
    //   setError("biz_reg_number", "올바른 사업자번호가 아닙니다.");
    //   isDirty = true;
    // }

    return true;
  };

  const signup = async () => {
    const { formFields } = state;
    // const { isValid, message } = validate();

    if (!validateBeforeRequest()) return;

    try {
      const { data } = await createMember(formFields as IMemberCreateRequest);
      const loginParams = {
        member_id: data.member_id,
        password: formFields.password,
      };
      await loginMember(loginParams);
      navigate(`${MYPAGE_PATH}/${MYPATH_SUB_PATH.RESUME_GROUP}?area-popup=on`);
      window.location.reload();
      alert("회원가입이 완료되었습니다.");
    } catch (e: any) {
      const resp = e.response.data;
      if (resp.hasOwnProperty("data")) {
        if (typeof resp.data === "string") {
          if (/Email duplicated/.test(resp.data)) {
            setError("email", "이미 사용중인 메일 주소입니다");
            alert("이미 사용중인 이메일 주소입니다");
          } else if (/duplicated./.test(resp.data)) {
            setError("password", "이미 가입된 휴대폰 번호입니다.");
            alert("이미 가입된 휴대폰 번호입니다.");
          } else if (
            /already exist/.test(resp.data) ||
            /member exist/.test(resp.data)
          ) {
            setError("password", "이미 가입된 아이디입니다.");
            alert("이미 가입된 아이디입니다. 다른 아이디를 사용해주세요");
          } else if (/License/.test(resp.data)) {
            setError("password", "면허 번호를 확인해주세요.");
            alert("면허 번호를 확인해주세요.");
          } else if (/Required policy/.test(resp.data)) {
            alert("약관 동의를 확인해주세요.");
          }
        } else {
          resp.data.forEach((d: { field: string; error_message: string }) =>
            setError(d.field, d.error_message)
          );
          if (resp.data.length) alert(resp.data[0].error_message || "");
          // alert(resp.message || "");
        }
      } else {
        alert(resp.message || "알 수 없는 에러가 발생하였습니다.");
      }
    }
  };

  return {
    state: state.formFields,
    errors: state.errors,
    handler: {
      setMemberId: (id: string) => _change(FIELDS.아이디, id),
      setPassword: (password: string) => _change(FIELDS.비밀번호, password),
      setEmail: (email: string) => _change(FIELDS.이메일, email),
      setReferrer: (referrer: string) => _change("referrer", referrer),
      setAuthCode: (code: string) => _change(FIELDS.인증코드, code),
      setOccupation: (occupation: string) => _change(FIELDS.직종, occupation),
      setLicenceNumber: (licenceNumber: string) =>
        _change(FIELDS.면허번호, licenceNumber),
      setGender: (gender: GENDER) => _change(FIELDS.성별, gender),
      setPolicyAgree: (agreeFields: IMemberPolicyAgreeRequest[]) =>
        _change(FIELDS.개인정보수집정책동의목록, agreeFields),
      setEventFollowId: (followId: string) => _change(FIELDS.추천인, followId),
      setEventFollowIdClinic: (clinicId: string) =>
        _change(FIELDS.병원추천인, clinicId),
      signup,
    },
  };
};
export default useMemberSignup;
