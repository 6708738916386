import { useEffect, useReducer } from "react";
import { IResumeEditRequest } from "api/member/types";
import {
  fetchMemberResume,
  createResume,
  updateResume,
} from "api/member/rests";
import useMember from "hooks/member/useMember";
import {logout} from "../../utils/storage";

enum ACTIONS {
  UPDATE_INITIAL = "UPDATE_INITIAL",
  SET_VALUE = "SET_VALUE",
}

type State = IResumeEditRequest & {
  extra_address: string; // FE에서만 사용. 상세주소 입력을 위해.
  readyToRegister: boolean;
};
type Action =
  | {
      type: ACTIONS.UPDATE_INITIAL;
      payload: any;
    }
  | {
      type: ACTIONS.SET_VALUE;
      payload: {
        key: string;
        value: number | string | Array<string>;
      };
    };

const initialState: State = {
  group_id: 0,
  image_filename: "",
  name: "",
  birth_date: "",
  email: "",
  public_email: true,
  phone_number: "",
  public_phone_number: true,
  address: "",
  extra_address: "",
  title: "",
  occupation_list: [],
  work_list: [],
  work_type_list: [],
  last_education_type: "",
  last_education_name: "",
  last_education_start_date: "",
  last_education_end_date: "",
  // work_experience_list: [],
  work_experience_list: [
    {
      company_name: "",
      work: "",
      start_date: "",
      end_date: "",
      work_flag: false,
    },
  ],
  work_experience_month: 0,
  license_list: [
    {
      license: "",
      certificate_date: "",
    },
  ],
  cover_letter: "",
  public_flag: true,
  public_class_complete: false,
  salary_info: {
    salary_type: "NEGOTIATION",
    tax_type: "BEFORE",
    salary: 0,
  },
  graduation_type: "졸업",
  readyToRegister: false,

  // group_id: 4,
  // image_filename: "profile-image.jpg",
  // name: "테스트9",
  // birth_date: "800901",
  // email: "test9@test.com",
  // public_email: true,
  // phone_number: "010-1111-9999",
  // public_phone_number: true,
  // address: "서울 강남구 테스트로 111",
  // title: "나의 첫번째 이력서",
  // occupation_list: ["치과의사", "기타"],
  // work_list: ["진료", "임플란트"],
  // work_type_list: ["정규직", "계약직"],
  // annual_salary: 100000000,
  // last_education_type: "대학교졸업",
  // last_education_name: "테스트학교",
  // last_education_start_date: "2010-06-21",
  // last_education_end_date: "2020-06-21",
  // work_experience_list: [
  //   {
  //     company_name: "테스트치과",
  //     work: "진료",
  //     start_date: "2020-07-01",
  //     end_date: "2022-06-21",
  //   },
  // ],
  // work_experience_month: 24,
  // license_list: [
  //   {
  //     license: "운전면허",
  //     certificate_date: "2022-06-21",
  //   },
  // ],
  // cover_letter: "자기소개서 입니다.",
  // public_flag: true,
  // public_class_complete: false,
  // salary_info: {
  //   salary_type: "NEGOTIATION",
  //   tax_type: "BEFORE",
  //   salary: 100000,
  // },
};

const fieldToLabel = (field: string) => {
  switch (field) {
    case "last_education_name":
      return "학력사항";
    case "last_education_start_date":
      return "학력사항";
    case "graduation_type":
      return "학력사항";
    case "last_education_type":
      return "학력사항";
    case "title":
      return "이력서 제목";
    case "address":
      return "주소";
    case "occupation_list":
      return "직종선택";
    case "work_list":
      return "희망 업무";
    case "work_type_list":
      return "희망 근무 형태";
    default:
      return field;
  }
};

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case ACTIONS.UPDATE_INITIAL:
      return { ...state, ...action.payload };
    case ACTIONS.SET_VALUE:
      const { key, value } = action.payload;
      return { ...state, [key]: value };
    default:
      throw new Error("Unknown action type");
  }
};

const useRegisterResume = (resume_id: number | null, mode: string | null, master:boolean | false) => {
  const member = useMember();
  const isEditMode = mode === "edit";
  const isMaster = master === true;
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    name,
    birth_date,
    email,
    phone_number,
    address,
    title,
    occupation_list,
    work_list,
    work_type_list,
  } = state;

  const changeValue = (key: keyof State, newValue: any) =>
    dispatch({
      type: ACTIONS.SET_VALUE,
      payload: { key: key, value: newValue },
    });
  const validate = () => {
    if (name.trim() === "") {
      alert("이름을 입력해주세요.");
      return false;
    }
    if (birth_date.trim() === "") {
      alert("생년월일을 입력해주세요.");
      return false;
    }
    if (email.trim() === "") {
      alert("이메일 입력해주세요.");
      return false;
    }
    if (phone_number.trim() === "") {
      alert("연락처를 입력해주세요.");
      return false;
    }
    if (address === "") {
      alert("주소를 입력해주세요.");
      return false;
    }
    if (title.length > 100) {
      alert("이력서 제목은 100자 이내로 입력해주세요");
      return false;
    }
    if (title.trim() === "") {
      alert("이력서 제목을 입력해주세요.");
      return false;
    }
    if (occupation_list.length === 0) {
      alert("직종을 선택해주세요.");
      return false;
    }
    if (work_list.length === 0) {
      alert("희망 업무을 선택해주세요.");
      return false;
    }
    if (work_type_list.length === 0) {
      alert("희망 근무형태를 선택해주세요.");
      return false;
    }
    return true;
  };
  const register = async () => {
    const { address, extra_address, ...rest } = state;
    if (!validate()) return false;
    const validatedState = {
      ...rest,
      address: address + " " + extra_address,
      license_list: state.license_list.filter(
        (license: any) => !!license.license
      ),
      // work_experience_list: state.work_experience_list.filter(
      //   (experience: any) => experience.company_name !== ""
      // ),
      work_experience_list:
        !state.work_experience_list || state.work_experience_list.length === 0
          ? undefined
          : state.work_experience_list.filter(
              (experience: any) => experience.company_name !== ""
            ),
    };

    // if (isEditMode) {
    //   await updateResume(Number(resume_id), validatedState);
    // } else {
    try {
      if (isEditMode) {
        await updateResume(Number(resume_id), validatedState);
      } else {
        await createResume(validatedState);
      }
      if(isMaster) {
        logout();
        window.location.reload();
      }
      return true;
    } catch (e: any) {
      const resp = e.response.data;
      if (Array.isArray(resp.data)) {
        const fields = resp.data.map((d: { field: string }) =>
          fieldToLabel(d.field)
        );
        const errors = resp.data.map((d: any) => d.error_message);
        if (errors.length) {
          alert((fields[0] ? `${fields[0]}은(는)` : "") + errors[0]);
        } else {
          alert(
            `다음 필드는 필수 입력입니다. \n - ${[...new Set(fields)].join(
              "\n - "
            )}`
          );
        }
      } else {
        if (/Address/.test(e.response.data.data)) {
          alert("주소 정보가 유효하지 않습니다.");
        } else if (/SalaryTypeRequest/.test(e.response.data.data)) {
          alert("희망 연봉을 입력해주세요.");
        } else {
          alert("오류가 발생하였습니다.");
        }
      }

      throw e;
    }
    // }
  };

  useEffect(() => {
    if (member && !resume_id) {
      dispatch({
        type: ACTIONS.UPDATE_INITIAL,
        payload: member,
      });
    }
  }, [member]);

  useEffect(() => {
    if (resume_id) {
      (async () => {
        const { data } = await fetchMemberResume(resume_id);
        // MEMO: license_list field name didn't match when GET : license_name / POST : license
        dispatch({
          type: ACTIONS.UPDATE_INITIAL,
          payload: {
            ...data,
            license_list: data.license_list
              ? data.license_list.map((licence) => {
                  return {
                    license: licence.license_name,
                    certificate_date: licence.certificate_date,
                  };
                })
              : [
                  {
                    license: "",
                    certificate_date: "",
                  },
                ],
          },
        });
      })();
    }
  }, [resume_id]);

  return {
    isEditMode,
    state,
    handler: {
      changeValue,
      register,
    },
  };
};
export default useRegisterResume;
