import { ChangeEvent, useState } from "react";
import { Button } from "react-bootstrap";
import DaumPostcodeEmbed from "react-daum-postcode";

const AddressFieldRow = ({
  value,
  onChange,
  btnText="주소 찾기",
}: {
  value: string;
  onChange: (newValule: string) => void;
  btnText?: string;
}) => {
  const [showAddressSearch, setShowAddressSearch] = useState<boolean>(false);

  const handleComplete = (data: any) => {
    let fullAddress = data.address;
    let extraAddress = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }

    onChange(fullAddress);
    setShowAddressSearch(false);
  };

  return showAddressSearch ? (
    <div className="tbox">
      <DaumPostcodeEmbed
        autoClose={false}
        onComplete={handleComplete}
        onClose={() => setShowAddressSearch(false)}
        onSearch={(e) => console.log(e)}
      />
    </div>
  ) : (
    <>
      <div className="tbox">
        <input
          className="address-input"
          placeholder="주소를 입력해주세요"
          value={value}
          disabled={!!value}
          onClick={() => !value && setShowAddressSearch(true)}
        />
      </div>
      <button
        className="ms-0 menu-btn"
        onClick={() => setShowAddressSearch(true)}
      >
        {btnText}
      </button>
    </>
  );
};

export default AddressFieldRow;
