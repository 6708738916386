import { useCallback, useEffect, useState } from "react";
import { Button, Table, Pagination } from "react-bootstrap";

import { deleteResume } from "api/admin/rests";
import { displayDateTime } from "utils/formatter";
import { IResumeOverview } from "api/resume/types";
import { fetchResumes } from "api/resume/rests";


const AdminResumeTable = () => {
  const [page, setPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [resumes, setResumes] = useState<IResumeOverview[]>([]);

const fetchAdminResumes = useCallback(async () => {
    const {
      data: { total_page, element_list },
    } = await fetchResumes(undefined, page);

    setResumes(element_list);
    setTotalPage(total_page);

  }, [page]);

const removeAdminResume = async (resume_id : number) =>{

  const data = await deleteResume(resume_id);
  console.log(data)

}  

const showDetailAdminResume = (resume_id : number) =>{
  window.open(`/resume/${resume_id}`, "_blank");
}

  useEffect(() => {
    (async () => fetchAdminResumes())();
  }, [fetchAdminResumes]);


  return (
    <>

      <div style={{ minHeight: "750px" }}>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>ID</th>
              <th>위치</th>
              <th>이름</th>
              <th>제목</th>
              <th>최종수정일시</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {resumes.map((resume) => (
              <tr key={resume.resume_id} onClick={() => showDetailAdminResume(resume.resume_id)} style={{cursor : "pointer"}}>
                <td>{resume.resume_id}</td>
                <td>{resume.city + " " +resume.district}</td>
                <td>{resume.name}</td>
                <td>{resume.title}</td>
                <td>{displayDateTime(resume.upd_date)}</td>
                <td style={{display: "flex", gap: "6px"}}>
                  <Button
                    size="sm"
                    variant="outline-secondary"
                    onClick={() => {
                      removeAdminResume(resume.resume_id)
                      fetchAdminResumes()
                    }}
                  >
                    삭제
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <Pagination>
          {[...Array(totalPage).keys()].map((pageIdx) => (
            <Pagination.Item
              key={pageIdx + 1}
              active={pageIdx + 1 === page}
              onClick={() => setPage(pageIdx + 1)}
            >
              {pageIdx + 1}
            </Pagination.Item>
          ))}
        </Pagination>
      </div>

    </>
  );
};

export default AdminResumeTable;
