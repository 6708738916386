import { createContext } from "react";
import { CalculateBox, FormBox } from "components/PreviewIncome";

import usePreviewIncome from "hooks/usePreviewIncome";

export const PreviewIncomeContext = createContext<any>(null);

const PreviewIncomePage = () => {
  const { state, handler } = usePreviewIncome();

  return (
    <section className="my-income-calculate">
      <PreviewIncomeContext.Provider value={{ state, handler }}>
        <FormBox />
        <CalculateBox />
      </PreviewIncomeContext.Provider>
    </section>
  );
};

export default PreviewIncomePage;
