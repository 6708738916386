import moment from "moment";

import useLecture from "hooks/lecture/useLecture";
import useProfile from "hooks/useProfile";

import KDAImg from "assets/img/kda-logo.png";
import { lectureTypeToLabel } from "utils/formatter";

// <!-- 강좌이수증 -->
const Certification = ({ courseId }: { courseId: number }) => {
  const profile: any = useProfile();
  const lecture = useLecture(courseId);

  const isClinic = profile && profile.hasOwnProperty("clinic_name");
  const getPlayTime = () => {
    if (!lecture) return;
    let result = '';
    const duration = moment.duration(moment(lecture.complete_date).diff(lecture.start_date));
    if (duration.days()) result += `${duration.days()}일 `;
    if (duration.hours()) result += `${duration.hours()}시간 `;
    if (duration.minutes()) result += `${duration.minutes()}분 `;
    if (duration.seconds()) result += `${duration.seconds()}초 `;
    return result;
  }

  if (!lecture) return <div />;

  return (
    <section className="resume-view">
      <div className="inner wrap" style={{ display: "block" }}>
        <div className="resume-view-layout">
          <div className="main-layout-box">
            <div className="certificate-box">
              <div className="tit-txt">
                {lectureTypeToLabel(lecture.lecture_type)} 이수증
              </div>
            </div>
            <div className="certificate-box">
              <div className="tit-txt2">{lecture.title}</div>
            </div>

            <div className="resume-data-box">
              <div className="resume-data-group">
                <div className="data-list-box">
                  <div className="row">
                    <div className="subject">
                      {isClinic ? "병원명" : "성명"}
                    </div>
                    <div className="con">
                      {isClinic ? profile?.clinic_name : profile?.name || ""}
                    </div>
                  </div>
                  {/* <div className="row">
                    <div className="subject">생년월일</div>
                    <div className="con">2022년 7월 13일</div>
                  </div> */}
                  <div className="row">
                    <div className="subject">강좌명</div>
                    <div className="con">{lecture.title}</div>
                  </div>
                  <div className="row">
                    <div className="subject">총 이수 시간</div>
                    <div className="con">{getPlayTime()}</div>
                  </div>
                  {/* <div className="row">
                    <div className="subject">교육강사</div>
                    <div className="con">강사명이 들어가는 공간</div>
                  </div> */}
                  <div className="row">
                    <div className="subject">강좌내용</div>
                    {lecture.lecture_list.map((lecture, i) => (
                      <div key={i} className="con">{`${i + 1}. ${
                        lecture.name
                      }`}</div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="certificate-box">
              <div className="tit-txt3">
                위 사람은 상기의 교육을 이수하였기에 이 증서를 수여합니다.
              </div>
            </div>
            <div className="certificate-box">
              <div className="date">{moment().format(`YYYY년 M월 DD일`)}</div>
            </div>
            {isClinic &&
            <div className="certificate-box">
              <div className="kda">{profile?.clinic_name}</div>
              {/* <img className="kda-logo" src={KDAImg} alt="img"></img> */}
            </div>
            }
          </div>
        </div>
      </div>
    </section>
  );
};
export default Certification;
