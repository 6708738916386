import { useEffect, useState } from "react";

import { fetchMyLectureEmployees } from "api/clinic/rests";
import { IClinicEmployeeView } from "api/clinic/types";

const useLectureEmployees = () => {
  const [employees, setEmployees] = useState<IClinicEmployeeView[]>([]);

  useEffect(() => {
    (async () => {
      const { data } = await fetchMyLectureEmployees();
      setEmployees(data);
    })();
  }, []);

  return { employees, handler: { setEmployees } };
};
export default useLectureEmployees;
