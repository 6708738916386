import { useEffect, useState } from "react";

import { fetchCodes } from "api/code/rests";
import { ICommonCodeView } from "api/code/types";
import { GROUP_CODE_ID } from "api/code/types";

const useOccupation = () => {
  const [occupations, setOccupations] = useState<ICommonCodeView[]>([]);

  useEffect(() => {
    (async () => {
      const { data } = await fetchCodes(GROUP_CODE_ID.이력서직종);
      setOccupations(data);
    })();
  }, []);

  return {
    occupations,
    handler: {},
  };
};
export default useOccupation;
