import moment from "moment";
import {displayDate, displayDateTime} from "utils/formatter";
import useLecture from "hooks/lecture/useLecture";
import useProfile from "hooks/useProfile";

// import KDAImg from "assets/img/kda-logo.png";
// import { lectureTypeToLabel } from "utils/formatter";
import { ILectureEmployee } from './../../api/education/types';

// <!-- 법정의무교육 강좌이수증 -->
const CertificationLegal = ({ courseId, lectureName,startDate, endDate, employees }: { courseId: number, lectureName?:string,startDate?:string, endDate?:string, employees?: ILectureEmployee[] }) => {
  const profile: any = useProfile();
  const lecture = useLecture(courseId);

  const isClinic = profile && profile.hasOwnProperty("clinic_name");
  const getPlayTime = () => {
    if (!lecture) return;
    let result = '';
    const duration = moment.duration(moment(lecture.complete_date).diff(lecture.start_date));
    if (duration.days()) result += `${duration.days()}일 `;
    if (duration.hours()) result += `${duration.hours()}시간 `;
    if (duration.minutes()) result += `${duration.minutes()}분 `;
    if (duration.seconds()) result += `${duration.seconds()}초 `;
    return result;
  }
  if (!profile || !lecture) return <div />;

  return (
    <div>
      <section className="resume-view page">
        <div className="page-rot">문서번호 : dentin-{new Date().getFullYear()}-{lecture.course_id}-{lecture.lecture_list[0].seq}</div>
        <div className="inner wrap" style={{ display: "block", padding:"30px" }}>
          <div className="resume-view-layout">
            <div className="main-layout-box">
              <div className="certificate-box">

                <div className="tit-txt">
                  {lectureName?lectureName:lecture.lecture_list[0].name}
                  <br/>
                  <br/>
                  교육 결과 보고서
                </div>
              </div>
              <div className="resume-data-box"  style={{paddingTop:'60px', marginBottom:'80px'}}>
                <div className="resume-data-group">
                  <div className={`data-list-box ${!employees ? 'more-padding' : ''} no-border`}>
                    <div className="row">
                      <div className="subject">
                        {isClinic ? "병원명" : "성명"}
                      </div>
                      <div className="con">
                        {isClinic ? profile?.clinic_name : profile?.name || ""}
                      </div>
                    </div>
                    {isClinic &&
                    <div className="row">
                        <div className="subject">교육장소</div>
                        <div className="con">{profile.clinic_name}</div>
                    </div>
                    }
                    {isClinic &&
                    <>
                        <div className="row">
                            <div className="subject">소재지</div>
                            <div className="con">{profile.address}</div>
                        </div>
                        <div className="row">
                            <div className="subject">기관장</div>
                            <div className="con">{profile.rep_name}</div>
                        </div>
                    </>
                    }
                    <div className="row">
                      <div className="subject">교육일시</div>
                      <div className="con">{!startDate?displayDateTime(lecture.start_date):displayDateTime(startDate)}</div>
                    </div>

                    {Array.isArray(employees) &&
                    <div className="row">
                        <div className="subject">이수인원</div>
                        <div className="con">{employees.length + 1} 명</div>
                    </div>
                    }
                    <div className="row">
                      <div className="subject">교육방법</div>
                      <div className="con">교육 자료를 활용한 집합교육</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="certificate-box">
                <div className="tit-txt3">
                  {lectureName?lectureName:lecture.lecture_list[0].name}을 이상없이 완료하였습니다.
                </div>
              </div>
              <div className="certificate-box">
                <div className="date">{moment().format(`YYYY년 M월 DD일`)}</div>
              </div>
              {isClinic &&
              <div className="certificate-box">
                  <div className="kda">{profile?.clinic_name}</div>
                {/* <img className="kda-logo" src={KDAImg} alt="img"></img> */}
              </div>
              }
            </div>
          </div>
        </div>
        <div className="page-number">1</div>
      </section>
      <section className="resume-view page">
        <div className="page-rot">문서번호 : dentin-{new Date().getFullYear()}-{lecture.course_id}-{lecture.lecture_list[0].seq}</div>
        <div className="inner wrap" style={{ display: "block", padding:"30px" }}>
          <div className="resume-view-layout">
            <div className="main-layout-box">
              {Array.isArray(employees) &&
                <div className="course-list">

                  <div className="tit">수강자 명단</div>
                  <div className="table-box">
                      <table>
                          <tr>
                              <th>No.</th>
                            {isClinic &&
                            <th>
                                소속
                            </th>
                            }
                              <th>성함</th>
                              <th>서명</th>
                              <th>비고</th>
                          </tr>

                        {isClinic && <tr>
                          <td>1</td>
                          <td>{profile.clinic_name}</td>
                          <td>{profile.rep_name}</td>
                          <td></td>
                          <td></td>
                        </tr>}
                        {employees.map((employee,i) => (
                          <tr>
                            <td>{i+1+(isClinic ? 1 : 0)}</td>
                            {isClinic &&
                            <td>
                              {profile.clinic_name}
                            </td>
                            }
                            <td>{employee.name}</td>
                            <td></td>
                            <td></td>
                          </tr>
                        )).slice(0, 18)}
                      </table>
                  </div>
              </div>
              }
            </div>
          </div>
        </div>
        <div className="page-number">2</div>
      </section>
      {Array.isArray(employees) && employees.length > 18 && (
        <section className="resume-view page">
          <div className="page-rot">문서번호 : dentin-{new Date().getFullYear()}-{lecture.course_id}-{lecture.lecture_list[0].seq}</div>
          <div className="inner wrap" style={{ display: "block", padding:"30px" }}>
            <div className="resume-view-layout">
              <div className="main-layout-box">
                {Array.isArray(employees) &&

                <div className="course-list">

                    <div className="table-box">
                        <table>
                          {employees.map((employee,i) => (
                            <tr>
                              <td>{i+(isClinic ? 1 : 0)+1}</td>
                              {isClinic &&
                              <td>
                                {profile.clinic_name}
                              </td>
                              }
                              <td>{employee.name}</td>
                              <td></td>
                              <td></td>
                            </tr>
                          )).slice(18, 38)}
                        </table>
                    </div>
                </div>
                }
              </div>
            </div>
          </div>
          <div className="page-number">3</div>
        </section>
      )}
      {Array.isArray(employees) && employees.length > 37 && (
        <section className="resume-view page">
          <div className="page-rot">문서번호 : dentin-{new Date().getFullYear()}-{lecture.course_id}-{lecture.lecture_list[0].seq}</div>
          <div className="inner wrap" style={{ display: "block", padding:"30px" }}>
            <div className="resume-view-layout">
              <div className="main-layout-box">
                {Array.isArray(employees) &&

                <div className="course-list">

                    <div className="table-box">
                        <table>
                          {employees.map((employee,i) => (
                            <tr>
                              <td>{i+(isClinic ? 1 : 0)+1}</td>
                              {isClinic &&
                              <td>
                                {profile.clinic_name}
                              </td>
                              }
                              <td>{employee.name}</td>
                              <td></td>
                              <td></td>
                            </tr>
                          )).slice(38, 50)}
                        </table>
                    </div>
                </div>
                }
              </div>
            </div>
          </div>
          <div className="page-number">4</div>
        </section>
      )}
    </div>

  );
};
export default CertificationLegal;
