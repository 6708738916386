import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import moment from "moment";

import Layout from "layouts";

import { searchJobPostings } from "api/jobPosting/rests";
import { IJobOpeningOverview } from "api/jobPosting/types";
import { searchCourses } from "api/education/rests";
import { ILectureCourseOverview } from "api/education/types";
import { searchNotices } from "api/notice/rests";
import { INoticeView } from "api/notice/types";
import { JOBPOSTING_PATH, NOTICE_PATH } from "routes";
import { displayDate, lectureBaseurl, resourcePath } from "utils/formatter";

const NoResult = () => (
  <article className="no-data">
    <div className="no-data-box">
      <div className="ic-box">
        <i className="ic ic-nodata"></i>
      </div>
      <div className="txt-box">
        <div className="txt">검색결과가 없습니다.</div>
      </div>
    </div>
  </article>
);

const SearchPage = () => {
  const [params, setParams] = useSearchParams();
  const _query = params.get("query");
  const [queryText, setQueryText] = useState<string>(_query || "");
  const [query, setQuery] = useState<string>(_query || "");

  const [jobPostings, setJobPostings] = useState<IJobOpeningOverview[]>([]);
  const [courses, setCourses] = useState<ILectureCourseOverview[]>([]);
  const [notices, setNotices] = useState<INoticeView[]>([]);

  const isAllShow =
    !!(params.get("posting") === "on") === !!(params.get("course") === "on") &&
    !!(params.get("course") === "on") === !!(params.get("notice") === "on");

  const showJobPostings = isAllShow || params.get("posting") === "on";
  const showCourses = isAllShow || params.get("course") === "on";
  const showNotices = isAllShow || params.get("notice") === "on";
  // const [showJobPostings, setShowJobPostings] = useState<boolean>(
  //   params.get("posting") === "on"
  // );
  // const [showCourses, setShowCourses] = useState<boolean>(
  //   params.get("course") === "on"
  // );
  // const [showNotices, setShowNotices] = useState<boolean>(
  //   params.get("notice") === "on"
  // );

  const handleSearch = async () => {
    const resp = await Promise.all([
      searchJobPostings(1, 100, {
        search_type: undefined,
        region_list: [],
        occupation_list: [],
        keyword_list: [query],
        sort_property: "UPDATE_DATE",
        sort_direction: "DESC",
      }),
      searchCourses(1, 100, {
        lecture_type: undefined,
        keyword_list: [query],
        sort_property: "REG_DATE",
        sort_direction: "DESC",
      }),
      searchNotices(1, 100, {
        notice_type: undefined,
        keyword_list: [query],
        sort_property: "REG_DATE",
        sort_direction: "DESC",
      }),
    ]);

    setJobPostings(resp[0].data.element_list);
    setCourses(resp[1].data.element_list);
    setNotices(resp[2].data.element_list);
    setParams({ query: query });
  };

  useEffect(() => {
    // setShowJobPostings(isAllShow || params.get("posting") === "on");
    // setShowCourses(isAllShow || params.get("course") === "on");
    // setShowNotices(isAllShow || params.get("notice") === "on");
  }, [params]);

  useEffect(() => {
    if (!query) return;

    // setShowJobPostings(true);
    // setShowCourses(true);
    // setShowNotices(true);
    handleSearch();
  }, [query]);

  useEffect(() => {
    if (!_query) return;
    setQuery(_query);
    setQueryText(_query);
  }, [_query]);

  return (
    <Layout>
      <section className="search-result">
        <div className="inner wrap">
          <div className="search-result-head">
            <div className="head-box">
              <b>{query}</b> 검색 결과
            </div>

            <div className="search-box">
              <div className="input-box">
                <input
                  type="search"
                  value={queryText}
                  onChange={(e) => setQueryText(e.target.value)}
                  onKeyDown={(e) => {
                    if (
                      e.key === "Enter" &&
                      e.nativeEvent.isComposing === false
                    ) {
                      setQuery(queryText);
                    }
                  }}
                />
                <div
                  className="search-btn"
                  onClick={() => setQuery(queryText)}
                ></div>
              </div>
            </div>
          </div>

          {showJobPostings && (
            <div className="search-result-body">
              <article className="list-layout1">
                <div className="list-head-box">
                  <div className="head-txt-box">채용정보</div>
                  {isAllShow && (
                    <div className="list-option-box">
                      <button
                        className="txt-btn"
                        onClick={() => {
                          setParams({
                            query: _query || "",
                            posting: "on",
                            course: "off",
                            notice: "off",
                          });

                          // setShowJobPostings(true);
                          // setShowCourses(false);
                          // setShowNotices(false);
                        }}
                      >
                        더보기
                      </button>
                    </div>
                  )}
                </div>

                {jobPostings.length > 0 ? (
                  <div className="list-body-box">
                    <div className="list-wrap">
                      <ul>
                        {jobPostings
                          .slice(
                            0,
                            showCourses && showNotices ? 6 : jobPostings.length
                          )
                          .map((jobPosting) => (
                            <li>
                              <div className="item-wrap">
                                <a
                                  href={`${JOBPOSTING_PATH}/${jobPosting.job_id}`}
                                  className="item-box"
                                >
                                  {jobPosting.logo_image_filename === "" ? (
                                    <div className="no-picture">
                                      <div className="name">
                                        {jobPosting.clinic_name}
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="img-box">
                                      <img
                                        src={resourcePath(
                                          jobPosting.logo_image_filename
                                        )}
                                        alt="이미지"
                                      />
                                    </div>
                                  )}

                                  <div className="info-box">
                                    <div className="top-box">
                                      <div className="subject-box">
                                        {jobPosting.clinic_name}
                                      </div>
                                      <div className="con-box">
                                        {`${
                                          jobPosting.intern_flag
                                            ? "[실습치과] "
                                            : ""
                                        }${jobPosting.title}`}
                                      </div>
                                    </div>
                                    <div className="bottom-box">
                                      <div className="day-box">
                                        <i className="ic ic-day"></i>
                                        <span className="txt">
                                          D-
                                          {moment(jobPosting.closing_date).diff(
                                            moment(),
                                            "days"
                                          )}
                                        </span>
                                      </div>
                                      {/* <div className="option-box">
                                    <div className="option-btn-box">
                                      <button
                                        type="button"
                                        className="option-btn"
                                      >
                                        <i className="ic ic-option1"></i>
                                      </button>
                                    </div>

                                    <div className="like-box">
                                      <article className="like-toggle-btn">
                                        <button
                                          type="button"
                                          className="on"
                                        ></button>
                                      </article>
                                    </div>
                                  </div> */}
                                    </div>
                                  </div>
                                </a>
                              </div>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                ) : (
                  <NoResult />
                )}
              </article>
            </div>
          )}

          {showCourses && (
            <div className="search-etc-box">
              <article className="list-layout2">
                <div className="list-head-box">
                  <div className="head-txt-box">온라인교육</div>
                  {isAllShow && (
                    <div className="list-option-box">
                      <button
                        className="txt-btn"
                        onClick={() => {
                          setParams({
                            query: _query || "",
                            posting: "off",
                            course: "on",
                            notice: "off",
                          });

                          // setShowJobPostings(false);
                          // setShowCourses(true);
                          // setShowNotices(false);
                        }}
                      >
                        더보기
                      </button>
                    </div>
                  )}
                </div>

                {courses.length > 0 ? (
                  <div className="list-body-box">
                    <div className="list-wrap">
                      <ul>
                        {courses
                          .slice(
                            0,
                            showJobPostings && showNotices ? 5 : courses.length
                          )
                          .map((course) => (
                            <li>
                              <div className="item-wrap">
                                <a
                                  href={`${lectureBaseurl(
                                    course.lecture_type
                                  )}/${course.course_id}`}
                                  className="item-box"
                                >
                                  {course.image_filename ? (
                                    <div className="img-box">
                                      <img
                                        src={resourcePath(
                                          course.image_filename
                                        )}
                                        alt="이미지"
                                      />
                                    </div>
                                  ) : (
                                    <div className="no-picture">
                                      <div className="name">
                                        {course.title.substring(0, 30)}
                                        {course.title.length >= 30 && "..."}
                                      </div>
                                    </div>
                                  )}
                                  <div className="info-box">
                                    <div className="top-box">
                                      <div className="subject-box">
                                        {course.title}
                                      </div>
                                      <div className="con-box">
                                        {course.description}
                                      </div>
                                    </div>
                                    {/* <div className="bottom-box">
                                  <div className="left-box">등록비: 무료</div>
                                </div> */}
                                  </div>
                                </a>
                              </div>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                ) : (
                  <NoResult />
                )}
              </article>
            </div>
          )}

          {showNotices && (
            <div className="search-etc-box">
              <article className="list-head-box">
                <div className="head-txt-box">공지사항</div>
                {isAllShow && (
                  <div className="list-option-box">
                    <button
                      className="txt-btn"
                      onClick={() => {
                        setParams({
                          query: _query || "",
                          posting: "off",
                          course: "off",
                          notice: "on",
                        });
                        // setShowJobPostings(false);
                        // setShowCourses(false);
                        // setShowNotices(true);
                      }}
                    >
                      더보기
                    </button>
                  </div>
                )}
              </article>

              {notices.length > 0 ? (
                <div className="board-list">
                  {notices
                    .slice(
                      0,
                      showJobPostings && showCourses ? 5 : notices.length
                    )
                    .map((notice) => (
                      <a
                        href={`${NOTICE_PATH}/${notice.notice_id}`}
                        className="board-item-box"
                      >
                        <div className="subject-box">{notice.title}</div>
                        <div
                          className="con-box"
                          style={{ maxHeight: "400px", overflow: "hidden" }}
                          dangerouslySetInnerHTML={{ __html: notice.contents }}
                        ></div>
                        <div className="date-box">
                          {displayDate(notice.upd_date)}
                        </div>
                      </a>
                    ))}
                </div>
              ) : (
                <NoResult />
              )}
            </div>
          )}
        </div>
      </section>
    </Layout>
  );
};

export default SearchPage;
