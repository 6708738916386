import { Breadcrumb, Tab, Tabs, Table, Row, Col } from "react-bootstrap";

import ClinicMemberStat from "components/dashboard/ClinicMemberStat";
import UserMemberStat from "components/dashboard/UserMemberStat";
import JobOpeningStat from "components/dashboard/JobOpeningStat";
import ResumeStat from "components/dashboard/ResumeStat";
import PopLectureList from "components/dashboard/PopLectureList";
import LectureTakeChart from "components/dashboard/LectureTakeChart";
import WithdrawMemberStat from "components/dashboard/WithdrawMemberStat";
import MemberTypeStat from "components/dashboard/MemberTypeStat";
import "components/dashboard/initFA";

const AdminUserStatPage = () => (
  <>
    <Breadcrumb>
      <Breadcrumb.Item>대시보드</Breadcrumb.Item>
    </Breadcrumb>

    <Row className="g-3 mb-3">
      <Col md={6} xxl={3}>
        <ClinicMemberStat />
      </Col>
      <Col md={6} xxl={3}>
        <UserMemberStat />
      </Col>
      <Col md={6} xxl={3}>
        <JobOpeningStat />
      </Col>
      <Col md={6} xxl={3}>
        <ResumeStat />
      </Col>
    </Row>
    <Row className="g-3 mb-3">
      <Col lg={6}>
        <PopLectureList />
      </Col>
      <Col lg={6}>
        <LectureTakeChart />
      </Col>
    </Row>
    <Row>
      <Col md={6} xxl={3}>
        <WithdrawMemberStat />
      </Col>
      <Col md={6} xxl={3}>
        <MemberTypeStat />
      </Col>
    </Row>
  </>
);
export default AdminUserStatPage;
