export interface MenuProps {
  label: string;
  url: string;
}

const MenuButton = ({
  depth,
  label,
  url,
}: {
  depth: number;
  label: string;
  url: string;
}) => (
  <a href={url} className={`depth${depth.toString()}-btn`}>
    {label}
  </a>
);

const MenuItem = ({
  subs,
  ...rest
}: {
  label: string;
  url: string;
  subs: Array<MenuProps>;
}) => (
  <li className="depth1-box">
    <MenuButton {...rest} depth={1} />
    <ul className="depth2">
      {/* TODO: key 중복 잡기 */}
      {subs.map((sub, i) => (
        <MenuButton key={i} {...sub} depth={2} />
      ))}
    </ul>
  </li>
);

export default MenuItem;
