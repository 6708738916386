import { useReducer } from "react";
import { useNavigate } from "react-router-dom";

import { IClinicMemberCreateRequest, TGender } from "api/clinic/types";
import { IMemberPolicyAgreeRequest } from "api/member/types";
import { createClinic } from "api/clinic/rests";
import { LOGIN_PATH } from "routes";
import { checkPassword, checkBizNumber } from "utils/formatter";

enum FIELDS {
  아이디 = "member_id",
  비밀번호 = "password",
  사업자등록번호 = "biz_reg_number",
  병원명 = "clinic_name",
  대표자이름 = "rep_name",
  대표자생년월일 = "rep_birth_date",
  대표자성별 = "rep_gender",
  대표자휴대폰번호 = "rep_phone_number",
  대표자면허번호 = "rep_licence_number",
  병원분류 = "clinic_type",
  이메일 = "email",
  병원주소 = "address",
  병원연락처 = "phone_number",
  홈페이지주소 = "homepage_url",
  위도 = "latitude",
  경도 = "longitude",
  개인정보수집정책동의목록 = "policy_agree_list",
  추천인 = "event_follow_id",
  병원추천인 = "event_follow_id_clinic",
}

enum SIGNUP_ACTIONS {
  SET_VALUE = "SET_VALUE",
  SET_ERROR = "SET_ERROR",
  RESET_ERRORS = "RESET_ERRORS",
}

type SignupState = {
  formFields: IClinicMemberCreateRequest;
  errors: any;
};
type SignupAction =
  | {
      type: SIGNUP_ACTIONS.SET_VALUE;
      payload: {
        key: string;
        value: string | Array<IMemberPolicyAgreeRequest>;
      };
    }
  | {
      type: SIGNUP_ACTIONS.SET_ERROR;
      payload: {
        key: string;
        value: string;
      };
    }
  | {
      type: SIGNUP_ACTIONS.RESET_ERRORS;
    };

const reducer = (state: SignupState, action: SignupAction) => {
  switch (action.type) {
    case SIGNUP_ACTIONS.SET_VALUE:
      const newFormFields = {
        ...state.formFields,
        [action.payload.key]: action.payload.value,
      };
      return { ...state, formFields: newFormFields };
    case SIGNUP_ACTIONS.SET_ERROR:
      const newErrors = {
        ...state.errors,
        [action.payload.key]: action.payload.value,
      };
      return { ...state, errors: newErrors };
    case SIGNUP_ACTIONS.RESET_ERRORS:
      return { ...state, errors: {} };
    default:
      return state;
  }
};

const initialState: SignupState = {
  formFields: {
    member_id: "",
    password: "",
    biz_reg_number: "",
    clinic_name: "",
    rep_name: "",
    rep_gender: "male",
    rep_birth_date: "",
    rep_phone_number: "",
    rep_licence_number: "",
    clinic_type: "",
    email: "",
    address: "",
    extra_address: "", //서버에는 필드 없음. FE에서 UI상 분리하여 입력받기 위함.
    phone_number: "",
    homepage_url: "",
    policy_agree_list: [],
    latitude: 0,
    longitude: 0,
    event_follow_id: "",
    event_follow_id_clinic: "",
    last_login_device:"",
  },
  errors: {},
};
const useClinicSignup = () => {
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, initialState);

  const changeValue = (
    key: string,
    newValue: string | IMemberPolicyAgreeRequest[]
  ) =>
    dispatch({
      type: SIGNUP_ACTIONS.SET_VALUE,
      payload: { key: key, value: newValue },
    });

  const setError = (key: string, value: string) =>
    dispatch({
      type: SIGNUP_ACTIONS.SET_ERROR,
      payload: { key, value },
    });

  const validateBeforeRequest = () => {
    /**
     * 서버로 전송 전,
     * - 에러 필드를 리셋하고,
     * - 비밀번호 / 사업자 등록번호 / 대표자 면허번호 / 약관동의 먼저 FE에서 체크.
     */
    const {
      formFields: {
        member_id,
        password,
        biz_reg_number,
        clinic_name,
        rep_name,
        rep_birth_date,
        rep_licence_number,
        clinic_type,
        email,
        address,
        phone_number,
        homepage_url,
        extra_address,
        policy_agree_list,
        event_follow_id,
        event_follow_id_clinic,
      },
    } = state;

    dispatch({ type: SIGNUP_ACTIONS.RESET_ERRORS });

    if (member_id.trim() === "") {
      setError("member_id", "ID를 입력해주세요.");
      alert("ID를 입력해주세요.");
      return false;
    }
    if (password.trim() === "") {
      setError("password", "비밀번호를 입력해주세요.");
      alert("비밀번호를 입력해주세요.");
      return false;
    }
    if (!checkPassword(password)) {
      setError(
        "password",
        "8~16자 영문 대 소문자, 숫자, 특수문자를 사용하세요."
      );
      alert("8~16자 영문 대 소문자, 숫자, 특수문자를 사용하세요.");
      return false;
    }
    if (biz_reg_number.trim() === "") {
      alert("사업자번호를 입력해주세요.");
      // setError('biz_reg_number', '사업자번호를 입력해주세요.')
      return false;
    }
    if (!checkBizNumber(biz_reg_number)) {
      // setError("biz_reg_number", "올바른 사업자번호가 아닙니다.");
      alert("올바른 사업자번호가 아닙니다");
      return false;
    }
    if (clinic_name.trim() === "") {
      // setError('clinic_name', '치과명을 입력해주세요.');
      alert("치과명을 입력해주세요.");
      return false;
    }
    if (rep_name.trim() === "") {
      // setError('rep_name', '대표자명을 입력해주세요.');
      alert("대표자명을 입력해주세요.");
      return false;
    }
    if (rep_birth_date === "") {
      alert("대표자 생년월일을 입력해주세요.");
      return false;
    }
    if (rep_licence_number.trim() === "" && clinic_type !== '보건소') {
      alert("대표자 면허번호를 입력해주세요.");
      return false;
    }
    if (email.trim() === "") {
      alert("이메일을 입력해주세요.");
      return false;
    }
    if (address.trim() === "") {
      alert("주소를 입력해주세요.");
      return false;
    }
    if (phone_number.trim() === "") {
      alert("연락처를 입력해주세요.");
      return false;
    }
    if (policy_agree_list.length === 0) {
      // setError("password", "약관동의를 확인해주세요.");
      alert("약관동의를 확인해주세요.");
      return false;
    }
    if (!clinic_type) {
      alert("병원분류를 선택해주세요");
      return false;
    }

    return true;
  };

  const signup = async () => {
    const { formFields } = state;

    if (!validateBeforeRequest()) return;

    const validatedFields = {
      ...formFields,
    } as IClinicMemberCreateRequest;

    try {
      await createClinic(validatedFields);
      alert("회원가입이 완료되었습니다.");
      navigate(`${LOGIN_PATH}`);
    } catch (e: any) {
      const resp = e.response.data;
      if (/License number/.test(resp.data)) {
        alert("유효하지 않은 면허번호 입니다.");
      } else if (/member exist/.test(resp.data)) {
        alert("이미 사용중인 ID 입니다. 다른 ID를 사용해주세요");
      } else if (/Biz number exist/.test(resp.data)) {
        alert("이미 가입한 사업자 번호입니다.");
      } else if (/Email already exist/.test(resp.data)) {
        alert("이미 사용중인 이메일 주소입니다");
      } else if (/Required policy/.test(resp.data)) {
        alert("약관동의를 확인해주세요.");
      } else if (resp.hasOwnProperty("data") && Array.isArray(resp.data)) {
        resp.data.forEach((d: { field: string; error_message: string }) =>
          setError(d.field, d.error_message)
        );
        alert(
          (resp.data[0] && resp.data[0].error_message) ||
            "회원가입 오류가 발생하였습니다."
        );
      } else {
        if (/ALREADY_EXIST|FAIL/.test(resp.result)) {
          setError("member_id", resp.message);
          alert("이미 가입된 ID 정보입니다. 다른 ID를 사용해주세요");
        } else {
          alert(resp.message || "알 수 없는 에러가 발생하였습니다.");
        }
      }
    }
  };

  return {
    state: state.formFields,
    errors: state.errors,
    handler: {
      setMemberId: (id: string) => changeValue(FIELDS.아이디, id),
      setPassword: (password: string) => changeValue(FIELDS.비밀번호, password),
      setBizRegNumber: (number: string) =>
        changeValue(FIELDS.사업자등록번호, number),
      setClinicName: (name: string) => changeValue(FIELDS.병원명, name),
      setClinicType: (clinicType: string) =>
        changeValue(FIELDS.병원분류, clinicType),
      setRepName: (name: string) => changeValue(FIELDS.대표자이름, name),
      setRepBirthDate: (date: string) =>
        changeValue(FIELDS.대표자생년월일, date),
      setRepGender: (gender: TGender) => changeValue(FIELDS.대표자성별, gender),
      setRepLicenceNumber: (number: string) =>
        changeValue(FIELDS.대표자면허번호, number),
      setEmail: (email: string) => changeValue(FIELDS.이메일, email),
      setAdress: (address: string) => changeValue(FIELDS.병원주소, address),
      setExtraAdress: (address: string) =>
        changeValue("extra_address", address),
      setPhoneNumber: (phone: string) => changeValue(FIELDS.병원연락처, phone),
      setHompageUrl: (url: string) => changeValue(FIELDS.홈페이지주소, url),
      setPolicyAgree: (agreeFields: IMemberPolicyAgreeRequest[]) =>
        changeValue(FIELDS.개인정보수집정책동의목록, agreeFields),
      setEventFollowId: (followId: string) =>
        changeValue(FIELDS.추천인, followId),
      setEventFollowIdClinic: (clinicId: string) =>
        changeValue(FIELDS.병원추천인, clinicId),
      signup,
    },
  };
};

export default useClinicSignup;
