import { useCallback, useEffect, useState } from "react";
import { Button, Table, Pagination } from "react-bootstrap";

import { deleteMainNotice, fetchMainNoticeList } from "api/admin/rests";
import { IAdminMainNoticeView } from "api/admin/types";

import { displayDateTime } from "utils/formatter";

import AdminNotice from "./AdminNotice";

const AdminNoticeTable = () => {
  const [page, setPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(15);
  const [mainNotice, setMainNotice] = useState<IAdminMainNoticeView[]>([]);
  const [showNotice, setShowNotice] = useState<boolean>(false);
  const [selectedNotice, setSelectedNotice] =
    useState<IAdminMainNoticeView | null>(null);

  const getMainNoticeList = useCallback(async () => {
    const {
      data: { element_list, total_page },
    } = await fetchMainNoticeList(page);

    setMainNotice(element_list);
    setTotalPage(total_page);
  }, [page]);

  const removeMainNotice = async (noticeId : number) => {
  
    const data = await deleteMainNotice(noticeId);
    console.log(data);

  }

  useEffect(() => {
    (async () => {
      getMainNoticeList();
    })();
  }, [getMainNoticeList]);


  return (
    <>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button onClick={() => setShowNotice(true)}>등록</Button>
      </div>

      <div style={{ minHeight: "750px" }}>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>ID</th>
              <th>제목</th>
              <th>컨텐츠</th>
              <th>최종수정일시</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {mainNotice.map((notice) => (
              <tr key={notice.notice_id}>
                <td>{notice.notice_id}</td>
                <td>{notice.title}</td>
                <td>{notice.contents}</td>
                <td>{displayDateTime(notice.reg_date)}</td>
                <td style={{display: "flex", gap: "6px"}}>
                  <Button
                    size="sm"
                    variant="outline-secondary"
                    onClick={() => {
                      setSelectedNotice(notice);
                      setShowNotice(true);
                    }}
                  >
                    편집
                  </Button>
                  <Button
                    size="sm"
                    variant="outline-secondary"
                    onClick={() => {
                      removeMainNotice(notice.notice_id)
                      getMainNoticeList();
                    }}
                  >
                    삭제
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <Pagination>
          {[...Array(totalPage).keys()].map((pageIdx) => (
            <Pagination.Item
              key={pageIdx + 1}
              active={pageIdx + 1 === page}
              onClick={() => setPage(pageIdx + 1)}
            >
              {pageIdx + 1}
            </Pagination.Item>
          ))}
        </Pagination>
      </div>
      <AdminNotice
        show={showNotice}
        notice={selectedNotice}
        onClose={() => {
          setSelectedNotice(null);
          setShowNotice(false);
          getMainNoticeList();
        }}
      />
    </>
  );
};

export default AdminNoticeTable;
