import { useCallback, useEffect, useState } from "react";
import { Button, Table, Pagination } from "react-bootstrap";

import { deleteCourse, fetchEducations } from "api/admin/rests";
import { ILectureCourseView } from "api/education/types";
import { lectureTypeToLabel } from "utils/formatter";

import { useNavigate  } from 'react-router-dom'; 
import EtcCourse from "./EtcCourse";

const LECTURE_TYPE = "ETC";

const EtcCourseTable = () => {
  const [page, setPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [courses, setCourses] = useState<ILectureCourseView[]>([]);

  const [showCourse, setShowCourse] = useState<boolean>(false);
  const [selectedCourse, setSelectedCourse] =
    useState<ILectureCourseView | null>(null);

  const getEducations = useCallback(async () => {
    const {
      data: { element_list, total_page },
    } = await fetchEducations(LECTURE_TYPE, page);

    setCourses(element_list);
    setTotalPage(total_page);
  }, [page]);

  const removeEducationCourse = async (course_id : number) => {
  
    const data = await deleteCourse(course_id);
    console.log(data);

  }

  const navigate = useNavigate ()

  const moveLectureList = (course_id : number) => {
    navigate(`${course_id}`)
  }

  useEffect(() => {
    (async () => {
      getEducations();
    })();
  }, [getEducations]);

  return (
    <>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button onClick={() => setShowCourse(true)}>코스 등록</Button>
      </div>

      <div style={{ minHeight: "750px" }}>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>코스 ID</th>
              <th>제작자</th>
              <th>제목</th>
              <th>이수 점수</th>
              <th>강의 리스트 수</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {courses.map((course) => (
              <tr key={course.course_id}>
                <td>{course.course_id}</td>
                <td>{lectureTypeToLabel(course.lecture_type)}</td>
                <td onClick={() => moveLectureList(course.course_id)} style={{cursor:"pointer"}}>{course.title}</td>
                <td>{course.score}</td>
                <td>{course.lecture_list.length}</td>
                <td style={{display: "flex", gap: "6px"}}>
                  <Button
                    size="sm"
                    variant="outline-secondary"
                    onClick={() => {
                      setSelectedCourse(course);
                      setShowCourse(true);
                    }}
                  >
                    편집
                  </Button>
                  <Button
                    size="sm"
                    variant="outline-secondary"
                    onClick={() => {
                      removeEducationCourse(course.course_id);
                      getEducations();
                    }}
                  >
                    삭제
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <Pagination>
          {[...Array(totalPage).keys()].map((pageIdx) => (
            <Pagination.Item
              key={pageIdx + 1}
              active={pageIdx + 1 === page}
              onClick={() => setPage(pageIdx + 1)}
            >
              {pageIdx + 1}
            </Pagination.Item>
          ))}
        </Pagination>
      </div>

      <EtcCourse
        show={showCourse}
        course={selectedCourse}
        onClose={() => {
          setSelectedCourse(null);
          setShowCourse(false);
          getEducations();
        }}
      />
    </>
  );
};
export default EtcCourseTable;
