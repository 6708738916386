import { Outlet } from "react-router-dom";
import Layout from "layouts";
import SideMenu from "components/Lecture/SideMenu";
import MobileSideMenu from "./../components/Lecture/MobileSideMenu";
import { useUserContext } from "contexts/UserContext";
import LoginPage from "pages/LoginPage";

const LectureLayout = () => {
  const { isSignIn } = useUserContext();

  if (!isSignIn) return <LoginPage />;
  return (
    <>
      <Layout>
        <MobileSideMenu />
        <section className="lnb-layout">
          <div className="inner wrap">
            <SideMenu />
            <div className="lnb-contents">
              <Outlet />
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};
export default LectureLayout;
