import { useState } from "react";

import { offerInterview } from "api/clinic/rests";
import useMyJobPostings from "hooks/jobPosting/useMyJobPostings";
import { displayDateTime } from "utils/formatter";
import MobileHeader from "./MobileHeader";
import { JOBPOSTING_FORM_PATH } from "routes";

const OfferPopup = ({
  resumeId,
  handleClose,
}: {
  resumeId: number;
  handleClose: () => void;
}) => {
  const [selectedJobPostingId, setSelectedJobPostingId] = useState<number>(0);

  const { jobPostings } = useMyJobPostings();

  const offer = async () => {
    if (resumeId < 0) return;
    try {
      await offerInterview(selectedJobPostingId, resumeId);
      handleClose();
      alert("면접 제안이 요청되었습니다.");
    } catch (e: any) {
      if (/already exist/.test(e.response.data.data)) {
        alert("이미 면접 제안이 요청된 인재입니다.");
      } else {
        alert("오류가 발생하였습니다.");
      }
    }
  };

  return (
    <article
      className={`popup ${
        jobPostings.length === 0
          ? "popup-resume-proposition-blank mobile-layer"
          : "popup-resume-proposition"
      }`}
      style={{ display: "block" }}
    >
      <div className="popup-wrap">
        <div className="popup-head">
          <MobileHeader />
          <button className="close-btn" onClick={handleClose}></button>
        </div>

        <div className="popup-body">
          <article className="popup-resume-proposition">
            <div className="tit-box">
              <div className="icon-box">📋</div>
              <div className="txt-box">면접을 제안할 공고를 선택하세요.</div>
              <div className="sub-box">
                {jobPostings.length === 0 ? (
                  <>
                    공고 작성 후 면접제안이 가능합니다.
                    <br />
                    아래 버튼을 클릭 후 채용공고를 먼저 작성해주세요!
                  </>
                ) : (
                  "제안할 공고를 선택해주세요!"
                )}
              </div>
            </div>

            <div className="resume-select-list">
              {jobPostings.map((jp) => (
                <div className="select-box">
                  <div
                    className={`select-btn-box ${
                      selectedJobPostingId === jp.job_id ? "on" : ""
                    }`}
                    onClick={() => setSelectedJobPostingId(jp.job_id)}
                  >
                    <div className="date-box">
                      {displayDateTime(jp.update_date)} 수정
                    </div>
                    <div className="subject-box">{jp.title}</div>
                    <div className="con-box"></div>
                  </div>
                </div>
              ))}
            </div>
          </article>
        </div>

        <div className="popup-foot">
          <div className="foot-menu-box">
            {jobPostings.length === 0 ? (
              <a
                href={`${JOBPOSTING_FORM_PATH}`}
                className="foot-menu-btn type-1"
              >
                공고 작성
              </a>
            ) : (
              <div className="foot-menu-btn type-1" onClick={offer}>
                완료
              </div>
            )}
          </div>
        </div>
      </div>
    </article>
  );
};
export default OfferPopup;
