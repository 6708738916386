import AdminJobPostingTable from "components/Admin/AdminJobPostingTable";
import Breadcrumb from "react-bootstrap/Breadcrumb";

const AdminJobPostingPage = () => (
  <>
    <Breadcrumb>
      <Breadcrumb.Item>공고관리</Breadcrumb.Item>
      <Breadcrumb.Item>리스트</Breadcrumb.Item>
    </Breadcrumb>

    <AdminJobPostingTable />

  </>
);
export default AdminJobPostingPage;
