import { useState } from "react";
import {
  InputFieldRow,
  BoxTitle,
  PasswordFieldRow,
} from "components/ProfilePage/Comps";
import useClinicProfile from "hooks/clinic/useClinicProfile";
import { AddressFieldRow } from "components/FormFields";
import PolicyAgreeFieldRow from "components/ProfilePage/Comps/PolicyAgreeFieldRow";

const ClinicProfile = () => {
  const [showWithDrawPopup, setShowWithDrawPopup] = useState(false);
  const {
    state: {
      clinic_name,
      homepage_url,
      phone_number,
      email,
      address,
      extra_address,
    },
    handler: {
      setClinicName,
      updateClinicName,
      setHomepageUrl,
      updateHomepageUrl,
      setEmail,
      updateEmail,
      setAddress,
      setExtraAddress,
      updateAddress,
      updateExtraAddress,
      setPhoneNumber,
      updatePhoneNumber,
      deleteClinicMember,
    },
  } = useClinicProfile();

  return (
    <>
      <div className="my-form-list">
        <div className="my-form-box">
          <BoxTitle title="기본정보" />
          <div className="my-form-con-box">
            <InputFieldRow
              label="병원명"
              value={clinic_name}
              onChange={setClinicName}
              onConfirm={updateClinicName}
            />
            <InputFieldRow
              label="홈페이지"
              value={homepage_url || ""}
              onChange={setHomepageUrl}
              onConfirm={updateHomepageUrl}
            />
          </div>
        </div>

        <div className="my-form-box">
          <BoxTitle title="연락처 정보" />
          <div className="my-form-con-box">
            <InputFieldRow
              label="휴대폰"
              value={phone_number}
              onChange={setPhoneNumber}
              onConfirm={updatePhoneNumber}
            />
            <InputFieldRow
              label="이메일"
              value={email}
              onChange={setEmail}
              onConfirm={updateEmail}
            />
            <div className="row address">
              <div className="subject">주소</div>
              <div className="con">
                <AddressFieldRow
                  value={address}
                  onChange={async (value) => {
                    await updateAddress(value);
                    setAddress(value);
                  }}
                  btnText="주소 수정"
                />
              </div>
            </div>

            <InputFieldRow
              label="상세주소"
              value={extra_address}
              onChange={setExtraAddress}
              onConfirm={updateExtraAddress}
            />
          </div>
        </div>

        <div className="my-form-box">
          <BoxTitle title="계정 정보" />
          <div className="my-form-con-box">
            <PasswordFieldRow />
          </div>
        </div>
        <div className="my-form-box">
          <BoxTitle title="약관 동의" />
          <div className="my-form-con-box">
            <PolicyAgreeFieldRow />
          </div>
        </div>

        <div className="my-form-box">
          <BoxTitle title="회원 탈퇴" />
          <div className="my-form-con-box">
            <div className="withdraw-wrap">
              <button
                className="withdraw-btn"
                onClick={() => setShowWithDrawPopup(true)}
              >
                탈퇴
              </button>
            </div>
          </div>
        </div>
      </div>
      {showWithDrawPopup && (
        <article className="popup" style={{ display: "block" }}>
          <div className="popup-wrap">
            <div className="popup-head">
              <button
                className="close-btn"
                onClick={() => setShowWithDrawPopup(false)}
              ></button>
            </div>

            <div className="popup-body">
              <div className="withdraw-popup-body">
                <p>회원 탈퇴를 진행하시겠습니까?</p>
                <div>
                  <button onClick={deleteClinicMember}>네</button>
                </div>
              </div>
            </div>
          </div>
        </article>
      )}
    </>
  );
};

export default ClinicProfile;
