import { Swiper, SwiperSlide } from "swiper/react";

import useNotices from "hooks/useNotices";
import { NOTICE_PATH } from "routes";

const Notice = () => {
  const { notices } = useNotices();

  return (
    <div className="login-notice-box">
      {/* <div className="tit-box">공지사항</div> */}
      <div className="tit-box">
        공지사항{" "}
        <a href={`${NOTICE_PATH}`} className="more-btn">
          더보기
        </a>
      </div>

      <div className="notice-list">
        <div className="swiper-button-next notice-swiper-button-next"></div>
        <div className="swiper-button-prev notice-swiper-button-prev"></div>

        <div className="swiper-container">
          <Swiper
            wrapperTag="ul"
            autoplay={true}
            loop={true}
            slidesPerView={1}
            navigation={{
              nextEl: ".notice-swiper-button-next",
              prevEl: ".notice-swiper-button-prev",
            }}
          >
            <ul className="swiper-wrapper">
              {notices.map((notice) => (
                <SwiperSlide
                  tag="li"
                  className="swiper-slide"
                  key={notice.notice_id}
                >
                  <a
                    href={`${NOTICE_PATH}/${notice.notice_id}`}
                    className="notice-box"
                  >
                    {notice.title}
                  </a>
                </SwiperSlide>
              ))}
            </ul>
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Notice;
