import { Breadcrumb, Tab, Tabs } from "react-bootstrap";

import EtcCourseTable from "components/Admin/EtcCourseTable";

const AdminEducationEtcPage = () => (
  <>
    <Breadcrumb>
      <Breadcrumb.Item>기타교육</Breadcrumb.Item>
    </Breadcrumb>

    <Tabs defaultActiveKey="home">
      <Tab eventKey="home" title="온라인 교육">
        <EtcCourseTable />

      </Tab>
      {/* <Tab eventKey="notice" title="공지사항">
        <CourseNoticeTable />
      </Tab> */}
    </Tabs>
  </>
);
export default AdminEducationEtcPage;
