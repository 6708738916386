import { useState } from "react";
import { useLocation } from "react-router-dom";
import { LECTURE_PATH, LECTURE_SUBPATH } from "routes";

// import { fetchBookmarks as fetchClinicBookmarks } from "api/clinic/rests";
// import { fetchBookmarks as fetchMemberBookmarks } from "api/member/rests";
// import { useUserContext } from "contexts/UserContext";
import { useBookmarkContext } from "contexts/BookmarkContext";
import { useUserContext } from "contexts/UserContext";
// import { ILectureCourseBookmarkView } from "api/education/types";
// import { USER_TYPE } from "utils/enums";
import { lectureBaseurl } from "utils/formatter";
import { USER_TYPE } from "utils/enums";

const Submenu = ({ label, subpath }: { label: string; subpath: string }) => {
  const { pathname } = useLocation();
  return (
    <li>
      <a href={`${LECTURE_PATH}/${subpath}`}>{label}</a>
    </li>
  );
};

type MENU_ITEM = "홈" | "MY" | "공지사항" | "즐겨찾기" | "FAQ";
const MobileSideMenu = () => {
  const [isOpenSide, setIsOpenSide] = useState(false);
  const { isSignIn, userType } = useUserContext();
  const { bookmarks } = useBookmarkContext();
  const { pathname } = useLocation();
  const [toggleState, setToggleState] = useState<{
    [key in MENU_ITEM]?: boolean;
  }>({
    홈: /duty|etc|dental-in|oral-health/.test(pathname),
    MY: /my-ing|my-done/.test(pathname),
  });

  const setToggle = (target: MENU_ITEM, bool: boolean) => {
    const newState: typeof toggleState = {};
    newState[target] = bool;
    setToggleState({
      ...toggleState,
      ...newState,
    });
  };
  return (
    <>
      <nav className={`mobile-menu type-edu ${isOpenSide ? "open" : ""}`}>
        <a
          className="close-btn"
          role="button"
          onClick={() => setIsOpenSide(false)}
        ></a>
        <div className="mobile-menu-wrap">
          <div className="mobile-menu-group">
            <ul className="depth1">
              <li>
                <a
                  className={`depth1-txt toggle ${toggleState.홈 ? "on" : ""}`}
                  onClick={() => setToggle("홈", !toggleState.홈)}
                  role="button"
                >
                  홈
                </a>
                <ul className="depth2">
                  {userType === USER_TYPE.병원회원 && (
                    <Submenu
                      label="법정의무교육"
                      subpath={LECTURE_SUBPATH.DUTY}
                    />
                  )}
                  <Submenu
                    label="치과인 강좌"
                    subpath={LECTURE_SUBPATH.DENTAL_IN}
                  />
                  <Submenu
                    label="구강보건교육"
                    subpath={LECTURE_SUBPATH.ORAL_HEALTH}
                  />
                  <Submenu label="기타교육" subpath={LECTURE_SUBPATH.ETC} />
                </ul>
              </li>
              {isSignIn && (
                <>
                  <li>
                    <a
                      className={`depth1-txt toggle ${
                        toggleState.MY ? "on" : ""
                      }`}
                      role="button"
                      onClick={() => setToggle("MY", !toggleState.MY)}
                    >
                      MY
                    </a>
                    <ul className="depth2">
                      <Submenu
                        label="수강중인강좌"
                        subpath={LECTURE_SUBPATH.MY_ING}
                      />
                      <Submenu
                        label="이수내역"
                        subpath={LECTURE_SUBPATH.MY_DONE}
                      />
                    </ul>
                  </li>
                  <li>
                    <a
                      className={`depth1-txt toggle ${
                        toggleState.즐겨찾기 && "on"
                      }`}
                      role="button"
                      onClick={() =>
                        setToggle("즐겨찾기", !toggleState.즐겨찾기)
                      }
                    >
                      즐겨찾기
                    </a>
                    <ul className="depth2">
                      {bookmarks.map((bookmark) => (
                        <li key={bookmark.course_id}>
                          <a
                            href={`${lectureBaseurl(bookmark.lecture_type)}/${
                              bookmark.course_id
                            }`}
                          >
                            {bookmark.title}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </li>
                </>
              )}
              <li>
                <a
                  href={`${LECTURE_PATH}/${LECTURE_SUBPATH.NOTICE}`}
                  className="depth1-txt"
                >
                  공지사항
                </a>
              </li>
              <li>
                <a
                  href={`${LECTURE_PATH}/${LECTURE_SUBPATH.FAQ}`}
                  className="depth1-txt"
                >
                  FAQ
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <aside className="mobile-floating-menu">
        <a
          className="floating-menu-btn"
          role="button"
          onClick={() => setIsOpenSide(!isOpenSide)}
        ></a>
      </aside>
    </>
    // <article className="lnb-edu-nav">
    //   <div className="lnb-wrap">
    //     <div className="edu-menu-list">
    //       <ul className="depth1">
    //         <Menu
    //           active={/duty|etc|dental-in|oral-health/.test(pathname)}
    //           label="홈"
    //         >
    //           <Submenu label="법정의무교육" subpath={LECTURE_SUBPATH.DUTY} />
    //           <Submenu
    //             label="치과인 강좌"
    //             subpath={LECTURE_SUBPATH.DENTAL_IN}
    //           />
    //           <Submenu
    //             label="구강보건교육"
    //             subpath={LECTURE_SUBPATH.ORAL_HEALTH}
    //           />
    //           <Submenu label="기타교육" subpath={LECTURE_SUBPATH.ETC} />
    //         </Menu>

    //         {isSignIn && (
    //           <>
    //             <Menu active={/my-ing|my-done/.test(pathname)} label="MY">
    //               <Submenu
    //                 label="수강중인강좌"
    //                 subpath={LECTURE_SUBPATH.MY_ING}
    //               />
    //               <Submenu label="이수내역" subpath={LECTURE_SUBPATH.MY_DONE} />
    //             </Menu>

    //             <Menu label="즐겨찾기">
    //               {bookmarks.map((bookmark) => (
    //                 <li key={bookmark.course_id}>
    //                   <a
    //                     href={`${lectureBaseurl(bookmark.lecture_type)}/${
    //                       bookmark.course_id
    //                     }`}
    //                     className="depth2-btn"
    //                   >
    //                     {bookmark.title}
    //                   </a>
    //                 </li>
    //               ))}
    //             </Menu>
    //           </>
    //         )}

    //         <Menu active={/notice|faq/.test(pathname)} label="더보기">
    //           <Submenu label="공지사항" subpath={LECTURE_SUBPATH.NOTICE} />
    //           <Submenu label="FAQ" subpath={LECTURE_SUBPATH.FAQ} />
    //         </Menu>
    //       </ul>
    //     </div>
    //   </div>
    // </article>
  );
};

export default MobileSideMenu;
