import MemberImg from "assets/img/img_login_type1.svg";
import ClinicImg from "assets/img/img_login_type2.svg";
import { USER_TYPE } from "utils/enums";

const UserTypeButton = ({
  userType,
  label,
  baseLabel = "로그인",
  isActive,
  onClick,
}: {
  userType: USER_TYPE;
  label: string;
  baseLabel?: string;
  isActive: boolean;
  onClick: () => void;
}) => (
  // <a>태그를 <button>태그로 대체
  <button
    className={`login-type-btn ${isActive ? "on" : ""}`}
    data-name={userType === USER_TYPE.개인회원 ? "guest" : "corp"}
    onClick={onClick}
  >
    <div className="img-box">
      <img
        src={userType === USER_TYPE.개인회원 ? MemberImg : ClinicImg}
        alt="이미지"
        className={`login-type-${userType}`}
      />
    </div>
    <div className="txt-box">
      <b>{label}</b> <span className="web">{baseLabel}</span>
    </div>
  </button>
);

export default UserTypeButton;
