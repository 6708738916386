import { Breadcrumb, Tab, Tabs, Table } from "react-bootstrap";

import AdminNoticeTable from "components/Admin/AdminNoticeTable";

const AdminNoticePage = () => (
  <>
    <Breadcrumb>
      <Breadcrumb.Item>공지사항 관리</Breadcrumb.Item>
      <Breadcrumb.Item>리스트</Breadcrumb.Item>
    </Breadcrumb>

    <Tabs
      defaultActiveKey="mainNotice"
      id="uncontrolled-tab-example"
      className="mb-4"
    >
      <Tab eventKey="mainNotice" title="공지사항">
        {/* <AdminNoticeTable userType="member" /> */}
        <AdminNoticeTable />
      </Tab>
    </Tabs>
  </>
);
export default AdminNoticePage;