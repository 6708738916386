import { RESUME_FORM_PATH } from "routes";
import useResumeGroupDetail from "hooks/resume/useResumeGroupDetail";
import {
  displayDate,
  displayYearMonth,
  monthToYearMonth,
} from "utils/formatter";

import { togglePublicResume } from "api/member/rests";

const ResumeGroupDetail = ({ groupId }: { groupId: number }) => {
  const {
    resumeGroup,
    resumes,
    handler: { refreshResumes },
  } = useResumeGroupDetail(groupId);

  const togglePublic = async (resumeId: number, publicFlag: boolean) => {
    const { data } = await togglePublicResume(resumeId, publicFlag);
    await refreshResumes();
  };

  return (
    <>
      <div className="group-data-head-box">
        <div className="left-box">
          <div className="group-name-box">{resumeGroup?.group_name}</div>
        </div>
      </div>

      <div className="group-data-body-box">
        <div className="list-wrap">
          <ul>
            {resumes.map((resume) => {
              const { year, month } = monthToYearMonth(
                resume.work_experience_month
              );
              return (
                <li key={resume.resume_id}>
                  <div className="item-box">
                    <div className="date-box">
                      <span className="subject">수정일</span>
                      <span className="con">
                        {displayDate(resume.upd_date)}
                      </span>
                    </div>
                    <div className="name-box">{resume.title}</div>

                    <article className="my-summary-box">
                      <div className="summary-box">
                        <div className="summary-item-box">
                          <div className="row">
                            <div className="summary-txt-box type-1">
                              {displayYearMonth(year, month)}
                            </div>
                          </div>

                          <div className="row">
                            <div className="summary-txt-box type-2">
                              지역 {resume.city}
                            </div>
                          </div>
                        </div>

                        <div className="summary-item-box">
                          <div className="row">
                            <div className="summary-txt-box type-3">
                              직종 {resume.occupation_list.join(", ")}
                            </div>
                          </div>

                          <div className="row">
                            <div className="summary-txt-box type-4">
                              희망업무 {resume.work_list.join(", ")}
                            </div>
                          </div>
                        </div>
                      </div>
                    </article>

                    <div className="public-box">
                      <div className="toggle-btn-box">
                        <label className="toggle">
                          <input
                            type="checkbox"
                            checked={resume.public_flag}
                            onClick={() =>
                              togglePublic(
                                resume.resume_id,
                                !resume.public_flag
                              )
                            }
                          />
                          <span className="toggle-txt toggle-on-txt">
                            이력서 공개
                          </span>
                          <span className="toggle-txt toggle-off-txt">
                            이력서 비공개
                          </span>
                          <span className="toggle-bg"></span>
                        </label>
                      </div>
                    </div>

                    <article className="my-menu-box">
                      <div className="menu-box">
                        <a
                          href={`${RESUME_FORM_PATH}?resume_id=${resume.resume_id}&mode=edit`}
                          className="menu-btn type-1"
                        >
                          <span className="txt">수정</span>
                        </a>
                        <a
                          href={`${RESUME_FORM_PATH}?resume_id=${resume.resume_id}&mode=copy`}
                          className="menu-btn type-2"
                        >
                          <span className="txt">복사</span>
                        </a>
                      </div>
                    </article>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
};
export default ResumeGroupDetail;
