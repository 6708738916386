import { useEffect, useState } from "react";
import { Footer, Header } from "components/commons";
import BookmarkContext from "contexts/BookmarkContext";
import { useUserContext } from "contexts/UserContext";
import { ILectureCourseBookmarkView } from "api/education/types";
import { USER_TYPE } from "utils/enums";

import { fetchBookmarks as fetchClinicBookmarks } from "api/clinic/rests";
import { fetchBookmarks as fetchMemberBookmarks } from "api/member/rests";

const Layout = ({
  children,
  extraClass,
  excludeHeader,
  excludeMenu,
  excludeFooter,
}: {
  children: JSX.Element | Array<JSX.Element>;
  extraClass?: string;
  excludeHeader?: boolean;
  excludeMenu?: boolean;
  excludeFooter?: boolean;
}) => {
  const { isSignIn, userType } = useUserContext();
  const [bookmarks, setBookmarks] = useState<ILectureCourseBookmarkView[]>([]);

  const addBookmark = (bookmark: ILectureCourseBookmarkView) =>
    setBookmarks([bookmark, ...bookmarks]);
  const removeBookmark = (courseId: number) =>
    setBookmarks(
      bookmarks.filter((bookmark) => bookmark.course_id !== courseId)
    );

  useEffect(() => {
    if (!isSignIn) return;

    (async () => {
      const { data } =
        userType === USER_TYPE.개인회원
          ? await fetchMemberBookmarks()
          : await fetchClinicBookmarks();
      setBookmarks(data);
    })();
  }, [isSignIn, userType]);

  return (
    <div className={`container ${extraClass || ""}`}>
      <BookmarkContext.Provider
        value={{
          bookmarks: bookmarks,
          handler: { addBookmark, removeBookmark },
        }}
      >
        {!excludeHeader && <Header excludeMenu={excludeMenu} />}
        {children}
      </BookmarkContext.Provider>
      {!(excludeFooter || false) && <Footer />}
    </div>
  );
};

export default Layout;
