import { ChangeEvent } from "react";

const Title = ({
  value,
  onChange,
}: {
  value: string;
  onChange: (newValule: string) => void;
}) => {
  return (
    <div className="row no-border">
      <div className="item-box type-m-column">
        <div className="subject accent">이력서 제목*</div>
        <div className="con">
          <div className="tbox long">
            <input
              type="text"
              placeholder="이력서 제목을 입력해주세요"
              value={value}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                onChange(e.target.value)
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Title;
