import { Iscreening } from "api/jobPosting/types";

const ScreeningFieldRow = ({
  screenings,
  onChange,
}: {
  screenings: Iscreening[];
  onChange: (screenings: Iscreening[]) => void;
}) => {
  const steps = screenings.map((s) => s.step);

  const handleChange = (step: number, screening: string) => {
    screenings.find((s) => s.step === step)
      ? onChange(screenings.filter((s) => s.step !== step))
      : onChange([...screenings, { step, screening }]);
  };

  return (
    <div className="row">
      <div className="item-box">
        <div className="subject">진행방법*</div>
        <div className="con">
          <div className="check-list">
            <div className="list-wrap">
              <article className="label-item-box">
                <label>
                  <input
                    type="checkbox"
                    checked={steps.includes(1)}
                    onChange={() => handleChange(1, "서류전형")}
                  />
                  <span className="txt">서류전형</span>
                </label>
              </article>

              <article className="label-item-box">
                <label>
                  <input
                    type="checkbox"
                    checked={steps.includes(2)}
                    onChange={() => handleChange(2, "실무면접")}
                  />
                  <span className="txt">실무면접</span>
                </label>
              </article>

              <article className="label-item-box">
                <label>
                  <input
                    type="checkbox"
                    checked={steps.includes(3)}
                    onChange={() => handleChange(3, "임원면접")}
                  />
                  <span className="txt">임원면접</span>
                </label>
              </article>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ScreeningFieldRow;
