import CompareSVG from "assets/img/ic_popup_compare.svg";
import { useEffect, useState } from "react";
import moment from "moment";

import { fetchJobPosting } from "api/jobPosting/rests";
import { IJobOpeningView } from "api/jobPosting/types";
import { resourcePath } from "utils/formatter";
import { JOBPOSTING_PATH } from "routes";

const JobPostingDiffConfirmPopup = ({
  selectedJobId,
  onConfirm,
  onClose,
}: {
  selectedJobId: number[];
  onConfirm: () => void;
  onClose: () => void;
}) => {
  const [jobPostings, setJobPostings] = useState<IJobOpeningView[]>([]);

  useEffect(() => {
    if (selectedJobId.length === 0) return;

    (async () => {
      const datas = await Promise.all(
        selectedJobId.map((id) => fetchJobPosting(id))
      );
      setJobPostings(datas.map((d) => d.data));
      // console.log(datas);
    })();
  }, [selectedJobId]);

  return (
    <article className="popup popup-compare" style={{ display: "block" }}>
      <div className="popup-wrap">
        <div className="popup-head">
          <div className="mobile-head-box">
            <div className="mobile-head-txt"></div>
          </div>

          <button className="close-btn" onClick={onClose}></button>
        </div>
        <div className="popup-body type-overflow-none">
          <article className="popup-compare-form">
            <div className="tit-box">
              <div className="ic-box">
                <img src={CompareSVG} alt="이미지" />
              </div>
              <div className="main-box">채용공고 비교하기</div>
              <div className="sub-box">아래의 채용공고를 비교해보시겠어요?</div>
            </div>

            <div className="compare-item-list scroll-effect">
              <article className="list-layout1">
                <div className="list-body-box">
                  <div className="list-wrap">
                    <ul>
                      {jobPostings.map((jp) => (
                        <li key={jp.job_id}>
                          <div className="item-wrap">
                            <div className="hover-box">
                              <div className="hover-wrap">
                                <div className="hover-close-btn"></div>

                                <div className="top-info-box">
                                  {!jp.logo_image_filename ? (
                                    <div className="no-picture">
                                      <div className="name">
                                        {jp.clinic_name}
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="img-box">
                                      <img
                                        src={resourcePath(
                                          jp.logo_image_filename
                                        )}
                                        alt="이미지"
                                      />
                                    </div>
                                  )}

                                  <div className="info-box">
                                    <div className="row">
                                      <div className="subject">병원명</div>
                                      <div className="con">
                                        {jp.clinic_name}
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="subject">주소</div>
                                      <div className="con">{jp.address}</div>
                                    </div>

                                    <div className="row">
                                      <div className="subject">상세주소</div>
                                      <div className="con">
                                        조아건물 빌딩 3층 302호
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="subject">홈페이지</div>
                                      <div className="con">
                                        www.seouliwith.co.kr
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="subject">대표번호</div>
                                      <div className="con">02-345-3213</div>
                                    </div>
                                  </div>
                                </div>

                                <div className="bottom-info-box">
                                  <div className="data-list">
                                    <div className="data-box">
                                      <div className="tit-box">고용정보</div>
                                      <div className="list-box">
                                        <div className="row">
                                          <div className="subject">
                                            모집직종
                                          </div>
                                          <div className="con">
                                            치과위생사 | 간호조무사
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="subject">
                                            담당업무
                                          </div>
                                          <div className="con">
                                            진료실 | 데스크
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="subject">
                                            고용형태
                                          </div>
                                          <div className="con">
                                            정규직 | 계약직 | 아르바이트
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="subject">
                                            경력조건
                                          </div>
                                          <div className="con">경력무관</div>
                                        </div>
                                        <div className="row">
                                          <div className="subject">
                                            학력조건
                                          </div>
                                          <div className="con">
                                            전문대졸 이상
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="subject">
                                            급여조건
                                          </div>
                                          <div className="con">
                                            협의 후 결정
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="data-box">
                                      <div className="tit-box">근무정보</div>
                                      <div className="list-box">
                                        <div className="row">
                                          <div className="subject">
                                            근무요일
                                          </div>
                                          <div className="con">
                                            주 5일(월~토)
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="subject">
                                            근무시간
                                          </div>
                                          <div className="con">
                                            09:00 ~ 17:30
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="subject">
                                            복리후생
                                          </div>
                                          <div className="con">
                                            복리후생, 복리후생, 복리후생
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <a
                              href={`${JOBPOSTING_PATH}/${jp.job_id}`}
                              className="item-box"
                            >
                              {!jp.logo_image_filename ? (
                                <div className="no-picture">
                                  <div className="name">{jp.clinic_name}</div>
                                </div>
                              ) : (
                                <div className="img-box">
                                  <img
                                    src={resourcePath(jp.logo_image_filename)}
                                    alt="이미지"
                                  />
                                </div>
                              )}

                              <div className="info-box">
                                <article className="compare-remove-box">
                                  <button
                                    type="button"
                                    className="compare-remove-btn"
                                  >
                                    <i className="ic ic-remove"></i>
                                  </button>
                                </article>

                                <div className="top-box type-compare">
                                  <div className="subject-box">
                                    {jp.clinic_name}
                                  </div>
                                  <div className="con-box">{`${
                                    jp.intern_flag ? "[실습치과] " : ""
                                  }${jp.title}`}</div>
                                </div>
                                <div className="bottom-box">
                                  <div className="day-box">
                                    <i className="ic ic-day"></i>
                                    <span className="txt">
                                      D-
                                      {moment(jp.closing_date).diff(
                                        moment(),
                                        "days"
                                      )}
                                    </span>
                                  </div>
                                  {/* <div className="option-box">
                                    <div className="option-btn-box hover-switch-box">
                                      <button
                                        type="button"
                                        className="option-btn"
                                      >
                                        <i className="ic ic-option1"></i>
                                      </button>
                                    </div>

                                    <div className="like-box">
                                      <article className="like-toggle-btn">
                                        <button
                                          type="button"
                                          className="on"
                                        ></button>
                                      </article>
                                    </div>
                                  </div> */}
                                </div>
                              </div>
                            </a>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </article>
            </div>
          </article>
        </div>
        <div className="popup-foot">
          <div className="foot-menu-box">
            {selectedJobId.length < 3 && (
              <div className="foot-menu-btn type-4" onClick={onClose}>
                추가하기
              </div>
            )}

            <div className="foot-menu-btn type-1" onClick={onConfirm}>
              비교하기
            </div>
          </div>
        </div>
      </div>
    </article>
  );
};

export default JobPostingDiffConfirmPopup;
