import { useNavigate, useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import {
  MYPAGE_PATH,
  MYPATH_SUB_PATH,
  MESSAGE_PATH,
  MAIN_PATH,
  SEARCH_PATH,
  LOGIN_PATH,
  SIGNUP_PATH,
} from "routes";
import { useUserContext } from "contexts/UserContext";
import { USER_TYPE } from "utils/enums";
import { useState } from "react";

import AlarmBox from "./AlarmBox";
import MessageBox from "./MessageBox";

const TopHeader = ({
  onClickMobileMenu,
}: {
  onClickMobileMenu: () => void;
}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { isSignIn, userType } = useUserContext();
  const [query, setQuery] = useState<string>("");

  const isSearchPage = /search/.test(pathname);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  return (
    <>
      <div
        className="header-top-box"
        style={{ display: isTabletOrMobile && isSearchPage ? "none" : "block" }}
      >
        <div className="inner wrap">
          <div className="mobile-menu-box mobile">
            <button
              onClick={onClickMobileMenu}
              className="mobile-menu-btn"
            ></button>
          </div>

          <h1 className="logo-box">
            <a href={`${MAIN_PATH}`} className="logo">
              &nbsp;
            </a>
          </h1>

          <div className="search-box">
            <form onSubmit={(e) => {
              e.preventDefault();
              navigate(`${SEARCH_PATH}?query=${encodeURI(query)}`);
            }}>
              <input
                type="search"
                placeholder="검색어를 입력해주세요."
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
              <button
                type="submit"
                className="search-btn"
              ></button>
            </form>
          </div>

          <div className="header-menu">
            {isSignIn ? (
              <>
                <div className="menu-box mobile">
                  <a href={`${SEARCH_PATH}`} className="search-btn">
                    &nbsp;
                  </a>
                </div>
                <MessageBox />

                <AlarmBox />

                <div className="menu-box">
                  <a
                    href={`${MYPAGE_PATH}/${
                      userType === USER_TYPE.개인회원
                        ? MYPATH_SUB_PATH.RESUME_GROUP
                        : MYPATH_SUB_PATH.JOBPOSTING
                    }`}
                    className="my-btn"
                  >
                    &nbsp;
                  </a>
                </div>
              </>
            ) : (
              <>
                <div className="txt-menu-box">
                  <a href={LOGIN_PATH} className="txt-menu-btn">
                    로그인
                  </a>
                </div>

                <div className="txt-menu-box">
                  <a href={SIGNUP_PATH} className="txt-menu-btn type-2">
                    회원가입
                  </a>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      {isSearchPage && (
        <article className="mobile-keyword-head">
          <div className="keyword-head-wrap">
              <div className="input-box">
                <button
                  className="close-btn"
                  onClick={() => navigate(-1)}
                ></button>
                <form style={{height: "100%"}} onSubmit={(e) => {
                  e.preventDefault();
                  console.log('will be navigated to', `${SEARCH_PATH}?query=${encodeURI(query)}`);
                  navigate(`${SEARCH_PATH}?query=${encodeURI(query)}`);
                }}>
                  <input
                    className="search-input"
                    type="search"
                    placeholder="검색어를 입력해주세요"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                  />

                  <button
                    type="submit"
                    className="search-btn"
                  ></button>
                </form>
              </div>
          </div>
        </article>
      )}
    </>
  );
};

export default TopHeader;
