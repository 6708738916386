import { useState } from "react";
import {
  BizNumberBox,
  ClinicBox,
  ClinicContactBox,
  IdPasswordBox,
  TermsBox,
} from "components/Signup";
import { USER_TYPE } from "utils/enums";
import { ClinicSignupContext, SignupContext } from "contexts/SignupContex";
import useClinicSignup from "hooks/clinic/useClinicSignup";
import EventFollowIdBox from "components/Signup/EventFollowIdBox";

const ClinicSignup = () => {
  const { state, errors, handler } = useClinicSignup();
  const { signup, setPolicyAgree } = handler;

  return (
    <SignupContext.Provider value={{ ...state, errors, ...handler } as any}>
      <div className="join-form">
        <IdPasswordBox passwordVerify />

        <ClinicSignupContext.Provider
          value={{ ...state, errors, ...handler } as any}
        >
          <BizNumberBox />
          <ClinicBox />
          <ClinicContactBox />
        </ClinicSignupContext.Provider>

        <TermsBox
          userType={USER_TYPE.병원회원}
          onChange={(value) => setPolicyAgree(value)}
        />

        <EventFollowIdBox />

        <div className="form-box">
          <div className="submit-box">
            <button className="submit-btn" onClick={signup}>
              회원가입 완료
            </button>
          </div>
        </div>
      </div>
    </SignupContext.Provider>
  );
};

export default ClinicSignup;
