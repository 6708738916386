import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { fetchNotice } from "api/notice/rests";
import { INoticeView } from "api/notice/types";

import Layout from "layouts";

import { Banner } from "components/commons";
import { axiosFile } from "utils/axiosFile";

interface FileModel {
  fileName: string;
  uploadFileName: string;
}

const NoticeDetailPage = () => {
  const { noticeId } = useParams();
  const [notice, setNotice] = useState<INoticeView | null>(null);
  const [file, setFile] = useState<FileModel[]>([]);
  useEffect(() => {
    if (noticeId === undefined) return;

    (async () => {
      const { data } = await fetchNotice(Number(noticeId));

      setNotice(data);

      if(!data.file_download_filename) {
        return;
      }

      let nameArr = data.file_download_filename.split(',')
      let linkArr = data.file_download_link.split(',')

      let initialArr:Array<FileModel> = []

      nameArr.forEach((val:string,i:number) => {
        initialArr.push({
          fileName: val,
          uploadFileName: linkArr[i],
        })
      })

      setFile(initialArr)

    })();
  }, [noticeId]);

  useEffect(() => {


    console.log(notice)
  }, [notice]);

  return (
    <Layout>
      <section className="notice-list">
        <div className="inner wrap">
          <article className="board-view-head">
            <div className="view-tit-box">
              <div className="tit-box">{notice?.title}</div>
            </div>

            <div className="view-sub-box">
              <div className="sub-box">운영자</div>
              <div className="sub-box">
                {dayjs(
                  notice?.upd_date ? notice?.upd_date : notice?.reg_date
                ).format(`YYYY.MM.DD HH:mm:ss`)}
              </div>
            </div>
          </article>

          <article className="board-view-body">
            <div className="contents-box">
              {notice?.contents && (
                <div>
                  {notice?.reg_date === "2022-11-01 12:33:23" ? (
                    <Link to="/signup">
                      <div
                        className="data-txt-box"
                        dangerouslySetInnerHTML={{
                          __html: notice?.contents.replaceAll("\n", "<br/>"),
                        }}
                      ></div>
                    </Link>
                  ) : (
                    <div
                      className="data-txt-box"
                      dangerouslySetInnerHTML={{
                        __html: notice?.contents.replaceAll("\n", "<br/>"),
                      }}
                    ></div>
                  )}
                </div>
              )}

              {/* {notice?.contents.split("\n").map((row) => (
                <>
                  {row}
                  <br />
                </>
              ))} */}
            </div>
            {
              file.map((v,i)=>{
                return(
                  <>
              <button
                className="material-link"
                onClick={() =>
                  axiosFile(
                    v.uploadFileName as string,
                    v.fileName as string,
                  )
                }
              >
                파일 자료{i+1} 🗂
              </button>
                  </>
                )
              })
            }
            <div className="img-box">
              <Banner type="NOTICE_DETAIL" />
            </div>
          </article>
        </div>
      </section>
    </Layout>
  );
};
export default NoticeDetailPage;
