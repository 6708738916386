import React, { SetStateAction, useContext, useEffect } from "react";
import { ClinicSignupContext } from "contexts/SignupContex";

import InputFieldRow from "./Comps/InputFieldRow";
const BizNumberBox = () => {
  const context = useContext(ClinicSignupContext);
  const { biz_reg_number, errors, setBizRegNumber } = context;

  return (
    <div className="form-box" data-type="corp" style={{ display: "block" }}>
      <div className="form-tit-box">사업자 등록번호</div>
      <div className="form-con-box">
        <InputFieldRow
          errorMessage={errors?.biz_reg_number}
          placeholder="사업자 등록번호* (-없이 숫자만 입력)"
          value={biz_reg_number}
          numberOnly
          onChange={(value) => setBizRegNumber(value)}
        />
      </div>
    </div>
  );
};

export default BizNumberBox;
