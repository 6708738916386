import Layout from "layouts";
import Paginator from "components/Paginator";
import { useDriveNotices } from "hooks/useNotices";
import { NOTICE_PATH, NOTICE_SUBPATH } from "routes";
import { displayDate } from "utils/formatter";

const NoticeDrivePage = () => {
  const {
    page,
    totalPage,
    drives,
    handler: { setPage },
  } = useDriveNotices();

  return (
    <Layout>
      <section className="notice-list">
        <div className="inner wrap">
          <article className="board-head-box">
            <div className="head-tit-box">자료실</div>
            <div className="head-option-box"></div>
          </article>

          <article className="board-list-box">
            <div className="table-box">
              {drives.map((drive) => (
                <a
                  href={`${NOTICE_PATH}/${NOTICE_SUBPATH.DRIVE}/${drive.reference_id}`}
                  className="row-box"
                >
                  <div className="subject-box">{drive.title}</div>
                  {drive.contents && (
                    <div
                      className="data-txt-box"
                      style={{ height: "30px", overflow: "hidden" }}
                      dangerouslySetInnerHTML={{
                        __html: drive.contents.replaceAll("\n", "<br/>"),
                      }}
                    ></div>
                  )}
                  {/* <div className="con-box">{notice.contents}</div> */}

                  <div className="count-box">
                    <div className="date-box">
                      {displayDate(
                        drive.upd_date ? drive.upd_date : drive.reg_date
                      )}
                    </div>
                  </div>
                </a>
              ))}
            </div>
          </article>

          {totalPage > 1 && (
            <Paginator
              currentPage={page}
              totalPage={totalPage}
              changePage={(page) => setPage(page)}
            />
          )}
        </div>
      </section>
    </Layout>
  );
};
export default NoticeDrivePage;
