import { useState } from "react";
import { ILectureTakeView } from "api/education/types";
import { displayPlayTime } from "utils/formatter";
import { Certification } from "components/Lecture";
import PrintPopup from "components/Popups/PrintPopup";
import { useNavigate } from "react-router-dom";
import CertificationLegal from './CertificationLegal';

export const takeTypeToLabel = (
  take_type: string
): { takeCssClass: string; takeLabel: string } => {
  switch (take_type) {
    case "NONE":
      return { takeCssClass: "type-3", takeLabel: "수강 미완료" };
    case "TAKE":
      return { takeCssClass: "type-2", takeLabel: "수강 중" };
    case "COMPLETE":
      return { takeCssClass: "type-1", takeLabel: "수강 완료" };
    default:
      return { takeCssClass: "", takeLabel: "" };
  }
};

const LectureTable = ({
  courseId = 0,
  courseTitle,
  lectureType,
  hideIssueButton = false,
  lectureBaseUrl,
  lectures,
}: {
  courseId?: number;
  courseTitle?: string;
  lectureType: string;
  hideIssueButton?: boolean;
  lectureBaseUrl: string;
  lectures: ILectureTakeView[];
}) => {
  const navigate = useNavigate();
  const [showCertificationPopup, setShowCertificationPopup] =
    useState<boolean>(false);

  const completeSeq: number = lectures
    .filter((lecture) => lecture.take_type === "COMPLETE")
    .reduce((prev, curr) => (curr.seq > prev ? curr.seq : prev), 0);

  return (
    <article className="edu-view-lecture-list">
      <div className="lecture-tit-box">
        <div className="main-box">
          <div className="subject-txt">강의목록</div>
          <div className="sub-txt">총 {lectures.length}강</div>
        </div>
      </div>

      <div className="lecture-table-box">
        <div className="web-table-box">
          <table>
            <colgroup>
              <col style={{ width: "80px" }} />
              <col style={{ width: "120px" }} />
              <col style={{ width: "auto" }} />
              <col style={{ width: "150px" }} />
              <col style={{ width: "150px" }} />
              <col style={{ width: "150px" }} />
            </colgroup>
            <thead>
              <tr>
                <th>순서</th>
                <th className="center">썸네일</th>
                <th>강의명</th>
                <th className="center">강의시간</th>
                <th className="center">수강여부</th>
                <th className="center">바로가기</th>
              </tr>
            </thead>
            <tbody>
              {lectures.map((lecture, i) => {
                const { takeCssClass, takeLabel } = takeTypeToLabel(
                  lecture.take_type
                );
                return (
                  <tr key={lecture.lecture_id}>
                    <td>{lecture.seq}강</td>
                    <td className="center">
                      <div className="img-box">
                        <div className="effect-box">
                          <i className="ic ic-play"></i>

                          <div className="bar-box">
                            <span
                              className="bar"
                              style={{ width: "50%" }}
                            ></span>
                          </div>
                        </div>

                        <img
                          src={`https://img.youtube.com/vi/${lecture.video_id}/0.jpg`}
                          alt="이미지"
                        />
                      </div>
                    </td>
                    <td className="left">
                      <div className="lecture-subject-box type-2">
                        {lecture.name}
                      </div>
                    </td>
                    <td className="center">
                      {displayPlayTime(lecture.play_time)}
                    </td>
                    <td className="center">
                      <div className={`lecture-state-box ${takeCssClass}`}>
                        {takeLabel}
                      </div>
                    </td>
                    <td className="center">
                      <div className="lecture-option-box">
                        {completeSeq + 1 >= lecture.seq ? (
                          <a
                            href={`${lectureBaseUrl}/${lecture.lecture_id}${
                              lectures.length - 1 > i
                                ? `?next=${lectures[i + 1].lecture_id}`
                                : ""
                            }`}
                            className="lecture-option-btn"
                          >
                            <i className="ic ic-link"></i>
                          </a>
                        ) : (
                          <div className="lecture-option-btn">
                            <i className={"ic ic-lock"}></i>
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <div className="mobile-table-box">
          <div className="item-list">
            {lectures.map((lecture, i) => {
              const { takeCssClass, takeLabel } = takeTypeToLabel(
                lecture.take_type
              );

              return (
                <div
                  key={lecture.lecture_id}
                  className="item-box on"
                  onClick={() =>
                    navigate(
                      completeSeq + 1 >= lecture.seq
                        ? `${lectureBaseUrl}/${lecture.lecture_id}${
                            lectures.length - 1 > i
                              ? `?next=${lectures[i + 1].lecture_id}`
                              : ""
                          }`
                        : ""
                    )
                  }
                >
                  <div className="timer-box">
                    <i className="ic ic-timer"></i>
                    <div className="timer-txt">
                      {displayPlayTime(lecture.play_time)}
                    </div>
                  </div>
                  <div className="info-box">
                    <div className="lecture-step-box">{lecture.seq}강</div>
                    <div className="lecture-subject-box type-1">
                      {lecture.name}
                    </div>
                    <div className={`lecture-state-box ${takeCssClass}`}>
                      {takeLabel}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {!hideIssueButton && (
        <article className="lecture-menu-box">
          <div
            className={`lecture-menu-btn ${
              completeSeq === lectures.length ? "on" : ""
            }`}
            onClick={() => {
              if (completeSeq === lectures.length) {
                setShowCertificationPopup(true);
              }
            }}
          >
            <span className="txt">이수증 발급</span>
            <i className="ic ic-lock"></i>
          </div>

          {/* <div className="lecture-menu-btn on">
        <span className="txt">이수증 발급</span>
        <i className="ic ic-lock"></i>
      </div> */}
        </article>
      )}

      {showCertificationPopup && (
        <PrintPopup
          title={courseTitle || "이수증 출력"}
          handleClose={() => setShowCertificationPopup(false)}
        >
          {lectureType === 'LEGAL'
          ? <CertificationLegal courseId={courseId} />
          : <Certification courseId={courseId} /> 
          }
        </PrintPopup>
      )}
    </article>
  );
};

export default LectureTable;
