import { ChangeEvent } from "react";

const isDigit = (text: string) => (!text || text.match(/^\d+$/) ? true : false);

const InputFieldRow = ({
  errorMessage,
  value,
  placeholder = "",
  type = "text",
  numberOnly = false,
  onChange,
}: {
  errorMessage?: string;
  placeholder: string;
  type?: string;
  value: string;
  numberOnly?: boolean;
  onChange: (newValule: string) => void;
}) => (
  <div className="row">
    <div className="input-box">
      <input
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          const text = e.target.value;
          if (numberOnly) {
            if (!isDigit(text.replace(/\D/g, ""))) return;
            onChange(text.replace(/\D/g, ""));
            return;
          }
          return onChange(text);
        }}
      />
    </div>
    {errorMessage && <div className="input-msg-box">{errorMessage}</div>}
  </div>
);

export default InputFieldRow;
