import { useEffect, useState } from "react";

import { fetchResumeGroup, fetchMemberResumes } from "api/member/rests";
import { IResumeGroupView, IResumeMemberOverview } from "api/member/types";

const useResumeGroupDetail = (groupId: number) => {
  const [resumeGroup, setResumeGroup] = useState<IResumeGroupView | null>(null);
  const [resumes, setResumes] = useState<IResumeMemberOverview[]>([]);

  const refreshResumes = async () => {
    try {
      const { data } = await fetchMemberResumes(groupId);
      setResumes(data);
    } catch (e) {
      setResumes([]);
    }
  };

  useEffect(() => {
    (async () => {
      if (!groupId) return;

      try {
        const { data } = await fetchResumeGroup(groupId);
        setResumeGroup(data as IResumeGroupView);
      } catch (e) {
        setResumeGroup(null);
      }

      try {
        const { data } = await fetchMemberResumes(groupId);
        setResumes(data);
      } catch (e) {
        setResumes([]);
      }
    })();
  }, [groupId]);

  return {
    resumeGroup,
    resumes,
    handler: { refreshResumes },
  };
};

export default useResumeGroupDetail;
