import { useEffect, useState } from "react";

import { LicenseFieldBox } from "components/ResumeForm/Comps";
import { IResumeEditRequest } from "api/member/types";
import { IResumeLicenseEdit } from "api/resume/types";

type TProps = Pick<IResumeEditRequest, "license_list"> & {
  onChangeValue: (
    key: "license_list",
    newValue: Array<IResumeLicenseEdit>
  ) => void;
};

const initialLicense: IResumeLicenseEdit = {
  license: "",
  certificate_date: "",
};

const useLicense = ({
  license_list: initials = [initialLicense],
  onChangeValue,
}: TProps) => {
  const [licenses, setLicenses] = useState<IResumeLicenseEdit[]>(
    initials.length > 0 ? initials : [initialLicense]
  );

  const updateValue = (value: IResumeLicenseEdit[]) => {
    onChangeValue("license_list", value);
    setLicenses(value);
  };

  useEffect(() => {
    setLicenses(initials);
  }, [initials]);

  return {
    licenses,
    handler: {
      addLicense: () => {
        setLicenses([...licenses, { ...initialLicense }]);
      },
      updateLicense: (idx: number, license: IResumeLicenseEdit) => {
        const newLicenses = [...licenses];
        newLicenses[idx] = license;
        updateValue(newLicenses);
      },
      removeLicense: (idx: number) => {
        if (licenses.length > 1) {
          updateValue([...licenses.slice(0, idx), ...licenses.slice(idx + 1)]);
        }
      },
    },
  };
};

const LicenseSection = (props: TProps) => {
  const {
    licenses,
    handler: { addLicense, updateLicense, removeLicense },
  } = useLicense(props);

  return (
    <div className="form-group">
      <div className="form-tit-box">특기사항</div>

      <div className="row">
        {licenses.map((license, i) => (
          <LicenseFieldBox
            key={i}
            license={license}
            showAddButton={licenses.length === i + 1}
            showDeleteButton={licenses.length > 0}
            onClickAdd={addLicense}
            onClickDelete={() => removeLicense(i)}
            onChange={(license) => {
              updateLicense(i, license);
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default LicenseSection;
