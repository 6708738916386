import MobileHeadBox from "components/MobileHeadBox";
import { useWorkAreaOptions } from "hooks/useWorkArea";

const ItemBox = ({
  label,
  isSelected = false,
  onClick,
}: {
  label: string;
  isSelected?: boolean;
  onClick: () => void;
}) => (
  <li>
    <div className="item-wrap">
      <a
        className={`item-box ${isSelected ? "on" : ""}`}
        onClick={onClick}
        role="button"
      >
        {label}
      </a>
    </div>
  </li>
);

const AreaSelector = ({
  selected,
  onClose,
  onSelect,
}: {
  selected: string[];
  onClose: () => void;
  onSelect: (query: string) => void;
}) => {
  const {
    cities,
    filteredAreas,
    selectedCity,
    handler: { selectCity },
  } = useWorkAreaOptions();

  return (
    <article
      className="info-search-group info-search-region"
      data-tabs="region"
      style={{ display: "block" }}
    >
      <div className="search-wrap">
        <MobileHeadBox popup onClose={onClose} headerRightSideText="선택완료" />

        <div className="region-depth-wrap">
          <div className="region-depth1 scroll-effect">
            <div className="depth1-list ">
              <div className="list-wrap">
                <ul>
                  {cities.map((city, i) => (
                    <ItemBox
                      key={i}
                      label={city}
                      isSelected={selectedCity === city}
                      onClick={() => selectCity(city)}
                    />
                  ))}
                </ul>
              </div>
            </div>
          </div>

          <div className="region-depth2 scroll-effect">
            <div className="depth2-list">
              <div className="list-wrap">
                <ul>
                  {filteredAreas.map((area) => {
                    const isSelected = selected.includes(
                      `a|${area.city} ${area.district}`
                    );
                    return (
                      <ItemBox
                        key={area.id}
                        label={area.district}
                        isSelected={isSelected}
                        onClick={() =>
                          !isSelected &&
                          onSelect(`${selectedCity} ${area.district}`)
                        }
                      />
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
};

export default AreaSelector;
