import { ChangeEvent } from "react";

const isDigit = (text:string) => (!text || text.match(/^\d+$/) ? true : false);

const InputFieldRow = ({
  label,
  value,
  placeholder = "",
  type = "text",
  numberOnly = false,
  withItemBox = false,
  onChange,
}: {
  label: string;
  value: string;
  placeholder?: string;
  type?: string;
  numberOnly?: boolean;
  withItemBox?: boolean;
  onChange: (newValule: string) => void;
}) => {
  const content = (
    <div className="con">
      <div className="tbox">
        <input
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            {
              const text = e.target.value;
              if(numberOnly){
                if(!isDigit(text.replace(/\D/g,''))) return;
                onChange(text.replace(/\D/g,''));
                return;
              }
            return onChange(text)
          }
          }
        />
      </div>
    </div>
  );
  return (
    <div className="row">
      {withItemBox ? (
        <div className="item-box">
          <div className="subject">{label}</div>
          {content}
        </div>
      ) : (
        <>
          <div className="subject">{label}</div>
          {content}
        </>
      )}
    </div>
  );
};

export default InputFieldRow;
