import {
  MAIN_PATH,
  FIND_ID_PASSWORD,
  MYPAGE_PATH,
  MYPATH_SUB_PATH,
  SIGNUP_PATH,
} from "routes";

import { logout } from "utils/storage";

const Action = ({
  highlighting,
  bold,
  label,
  url,
}: {
  label: string;
  highlighting: boolean;
  bold: boolean;
  url: string;
}) => (
  <div className="menu-box">
    <a href={url} className={`menu-btn ${highlighting ? "on" : ""}`}>
      {bold ? <b>{label}</b> : <>{label}</>}
    </a>
  </div>
);

const LogoutAction = ({ onClick }: { onClick: () => void }) => (
  <div className="menu-box">
    <button className={"menu-btn"} onClick={onClick}>
      로그아웃
    </button>
  </div>
);

const Actions = ({ isSignIn }: { isSignIn: Boolean }) => (
  <div className="login-option-box">
    {isSignIn ? (
      <>
        <LogoutAction
          onClick={() => {
            logout();
            window.location.href = `${MAIN_PATH}`;
          }}
        />
        <Action
          label="회원정보 수정"
          bold={false}
          highlighting={false}
          url={`${MYPAGE_PATH}/${MYPATH_SUB_PATH.PROFILE}`}
        />
      </>
    ) : (
      <>
        <Action
          label="회원가입"
          bold={false}
          highlighting={true}
          url={SIGNUP_PATH}
        />
        <Action
          label="ID/PW찾기"
          bold={false}
          highlighting={false}
          url={`${FIND_ID_PASSWORD}`}
        />
      </>
    )}
  </div>
);

export default Actions;
