import axiosClient from "api/axiosClient";
import {
  IMemberPolicyAgreeInfo,
  IMemberPolicyAgreeRequest,
} from "api/member/types";
import { fetchPolicyMyList } from "api/policy/rests";
import { useUserContext } from "contexts/UserContext";
import usePolicies from "hooks/policy/usePolicies";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { POLICY_PATH } from "routes";
import { newWindowURL } from "utils/appBridge";
import { USER_TYPE } from "utils/enums";

function popupwindow(url: string, title: string, w: number, h: number) {
  var left = window.screen.width / 2 - w / 2;
  var top = window.screen.height / 2 - h / 2;
  return window.open(
    url,
    title,
    "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" +
      w +
      ", height=" +
      h +
      ", top=" +
      top +
      ", left=" +
      left
  );
}

const PolicyAgreeFieldRow = () => {
  const { userType } = useUserContext();

  const privacyPolicies = usePolicies(userType, "PRIVACY")
    .filter(
      (item, idx, arr) =>
        !arr.find(
          (p) => p.policy_id > item.policy_id && p.title === item.title
        ) && !item.require_flag
    )
    .sort((a, b) => b.policy_id - a.policy_id);
  const ternmPolicies = usePolicies(userType, "TERM")
    .filter(
      (item, idx, arr) =>
        !arr.find(
          (p) => p.policy_id > item.policy_id && p.title === item.title
        ) && !item.require_flag
    )
    .sort((a, b) => b.policy_id - a.policy_id);

  const [myPolicy, setMyPolicy] = useState<IMemberPolicyAgreeInfo[]>([]);
  const userTypeURL = userType === USER_TYPE.개인회원 ? "member" : "clinic";

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  useEffect(() => {
    (async () => {
      const myListResp = await fetchPolicyMyList(userTypeURL);
      setMyPolicy(myListResp.data);
    })();
  }, []);

  const updateAgree = (id: number, agree: boolean) => {
    axiosClient
      .patch(`/${userTypeURL}/policy/${id}`, {
        agree: agree,
      })
      .then(() => {
        alert("약관 동의가 변경되었습니다");
        window.location.reload();
      });
  };

  return (
    <>
      {myPolicy.map((policy) => (
        <div className="row">
          <div className="con-box">
            <div className="view-box">
              <div
                className="view-txt"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (isTabletOrMobile)
                    newWindowURL(
                      `${POLICY_PATH}?policy_id=${policy.policy_id}&noheader=true`
                    );
                  else
                    popupwindow(
                      `${POLICY_PATH}?policy_id=${policy.policy_id}`,
                      policy.title,
                      600,
                      800
                    );
                }}
              >
                {policy.require_flag ? "(필수)" : "(선택)"} {policy.title}
              </div>
              <div className="menu-box">
                {!policy.require_flag && (
                  <div className="toggle-btn-box">
                    <label className="toggle">
                      <input
                        type="checkbox"
                        checked={
                          !!myPolicy.find(
                            (item) =>
                              item.policy_id === policy.policy_id && item.agree
                          )
                        }
                        onChange={() =>
                          updateAgree(
                            policy.policy_id,
                            !myPolicy.find(
                              (item) =>
                                item.policy_id === policy.policy_id &&
                                item.agree
                            )
                          )
                        }
                        disabled={policy.require_flag}
                      />
                      <span className="toggle-txt toggle-on-txt">동의</span>
                      <span className="toggle-txt toggle-off-txt">비동의</span>
                      <span className="toggle-bg"></span>
                    </label>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default PolicyAgreeFieldRow;
