import { useState } from "react";

const useEditMode = () => {
  const [isEditOn, setIsEditOn] = useState<boolean>(false);

  return {isEditOn, handler: {
    onEditMode: () => setIsEditOn(true),
    offEditMode: () => setIsEditOn(false),
  }}
}

export default useEditMode