import { useState } from "react";

import { deleteJobPosting } from "api/clinic/rests";
import { PageTitle } from "components/MyPage";
import HeadBox from "components/MyPage/HeadBox";
import { LinkCreateJobPostingButton } from "components/JobPosting/Comps";
import JobPosting from "components/JobPosting/JobPosting";
import useMyJobPostings from "hooks/jobPosting/useMyJobPostings";
import { isOverdue } from "utils/formatter";
import Alert from "components/Popups/Alert";

enum STATUS {
  전체 = "전체",
  게재중 = "게재중",
  마감 = "마감",
}
/**
 * (병원회원) 마이페이지 > 공고
 */
const MyJobPostingPage = () => {
  const {
    jobPostings,
    handler: { fetch },
  } = useMyJobPostings();
  const [statusFilter, setStatusFilter] = useState<STATUS>(STATUS.전체);
  const [deleteAlert, setDeleteAlert] = useState<number>(0);

  const filterComp = (
    <div className="sbox">
      <select
        value={statusFilter}
        onChange={(e) => setStatusFilter(e.target.value as STATUS)}
      >
        <option value={STATUS.전체}>전체 공고</option>
        <option value={STATUS.게재중}>게재중 공고</option>
        <option value={STATUS.마감}>마감 공고</option>
      </select>
    </div>
  );

  return (
    <section className="my-corp-job">
      <article className="lnb-contents-tit">
        <PageTitle iconClass="ic-job" title="공고 현황" />
        <LinkCreateJobPostingButton />
      </article>

      <HeadBox count={jobPostings.length} filterComp={filterComp} />

      <div className="group-data-body-box">
        <div className="list-wrap">
          <ul>
            {jobPostings
              .filter((jp) =>
                statusFilter === STATUS.전체
                  ? true
                  : statusFilter === STATUS.마감
                  ? isOverdue(jp.closing_date)
                  : !isOverdue(jp.closing_date)
              )
              .map((jobPosting) => (
                <JobPosting
                  key={jobPosting.job_id}
                  {...jobPosting}
                  handleDelete={() => setDeleteAlert(jobPosting.job_id)}
                />
              ))}
          </ul>
        </div>
      </div>
      {!!deleteAlert && (
        <Alert
          text="삭제하시겠습니까?"
          yesEvent={async () => {
            try {
              await deleteJobPosting(deleteAlert);
              await fetch();
            } finally {
              setDeleteAlert(0);
            }
          }}
          noEvent={() => {
            setDeleteAlert(0);
          }}
        />
      )}
    </section>
  );
};

export default MyJobPostingPage;
