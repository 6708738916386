import { useEffect, useState } from "react";
import { LECTURE_PATH, LECTURE_SUBPATH } from "routes";

import {ILectureDutyNotice, ILegalLectureTakeView} from "api/education/types";
import {fetchLegalLectureNotice, fetchMyLegalLecture as fetchClinicLegalLecture} from "api/clinic/rests";
import { fetchMyLegalLecture as fetchMemberLegalLecture } from "api/member/rests";
import { useUserContext } from "contexts/UserContext";
import LectureAddEmployeePopup from "components/Popups/LectureAddEmployeePopup";
import { USER_TYPE } from "utils/enums";
import { useNavigate } from "react-router-dom";
import { LOGIN_PATH, MAIN_PATH } from "routes";
import { Banner } from "components/commons";
import {displayDate} from "../utils/formatter";

/**
 * 온라인교육 상태값
 * @param take_type
 * @returns 상태 레이블
 */
export const takeTypeToLabel = (
  take_type: string
): { takeCssClass: string; takeLabel: string } => {
  switch (take_type) {
    case "NONE":
      return { takeCssClass: "type-2", takeLabel: "수강 미완료" };
    case "TAKE":
      return { takeCssClass: "type-3", takeLabel: "수강 중" };
    case "COMPLETE":
      return { takeCssClass: "type-1", takeLabel: "수강 완료" };
    default:
      return { takeCssClass: "", takeLabel: "" };
  }
};

const DutyLecturePage = () => {
  const navigate = useNavigate();
  const { isSignIn, userType } = useUserContext();
  const [selectedLecture, setSelectedLecture] =
    useState<ILegalLectureTakeView | null>(null);
  // const { lectures } = useLecturesByCourse("LEGAL");

  const [lectures, setLectures] = useState<ILegalLectureTakeView[]>([]);
  const [dutyNotice, setDutyNotice] = useState<ILectureDutyNotice | null>(null);

  useEffect(() => {
    (async () => {
      const { data } =
        userType === USER_TYPE.개인회원
          ? await fetchMemberLegalLecture()
          : await fetchClinicLegalLecture();
      setLectures(data);
    })();
  }, [userType]);
  useEffect(() => {
    if (!isSignIn) navigate(LOGIN_PATH);
    if (userType === USER_TYPE.개인회원) {
      alert("개인회원은 접근 할 수 없습니다.");
      navigate(MAIN_PATH);
    }
  }, []);

  useEffect(() => {
    (async () => {
      const { data } = await fetchLegalLectureNotice();
      setDutyNotice(data);
    })();
  }, []);

  return (
    <section className="edu-law-list">
      <div className="edu-banner-box type-top">
        <div className="banner-btn">
          <Banner type="LECTURE_LIST" />
        </div>
      </div>
      <div className="edu-law-label-box">
        <div dangerouslySetInnerHTML={{ __html: dutyNotice?.contents || "" }}></div>
      </div>
      <div className="edu-law-tit-box">
        <div className="tit-txt">교육목록</div>
      </div>

      <div className="web-table-box">
        <table>
          <colgroup>
            <col style={{ width: "auto" }} />
            <col style={{ width: "200px" }} />
            <col style={{ width: "200px" }} />
          </colgroup>
          <thead>
            <tr>
              <th>강의명</th>
              <th className="center">수강여부</th>
              <th className="center">수강목록</th>
              <th className="center">수강 완료일</th>
              <th className="center">수강하기</th>
            </tr>
          </thead>
          <tbody>
            {lectures.map((lecture) => {
              const { takeCssClass, takeLabel } = takeTypeToLabel(
                lecture.take_type
              );
              if(lecture.visible_flag !== 'y') {
                return null
              }
              return (
                <tr key={lecture.lecture_id}>
                  <td>
                    <div className="message-box">
                      <div className="message-txt type-1">{lecture.name}</div>
                    </div>
                  </td>
                  <td className="center">
                    <div className="course-box">
                      <div className="top-box">
                        {/*{lecture.take_type === "COMPLETE" && (*/}
                        {/*  <div className="type-txt">*/}
                        {/*    {lecture.collect_flag ? "집합교육" : "개별교육"}*/}
                        {/*  </div>*/}
                        {/*)}*/}
                        <div className={`state-box ${takeCssClass}`}>
                          {takeLabel}
                        </div>
                      </div>

                      {/*{userType === USER_TYPE.병원회원 &&*/}
                      {/*  lecture.take_type === "COMPLETE" && (*/}
                      {/*    <div className="bottom-box">*/}
                      {/*      {lecture.collect_flag ? (*/}
                      {/*        <a*/}
                      {/*          href={`${LECTURE_PATH}/${LECTURE_SUBPATH.DUTY}/${lecture.course_id}/personnel`}*/}
                      {/*          className="course-btn"*/}
                      {/*        >*/}
                      {/*          강의명단 바로가기*/}
                      {/*        </a>*/}
                      {/*      ) : (*/}
                      {/*        <a*/}
                      {/*          href={`${LECTURE_PATH}/${LECTURE_SUBPATH.MY_DONE}`}*/}
                      {/*          className="course-btn"*/}
                      {/*        >*/}
                      {/*          이수목록 바로가기*/}
                      {/*        </a>*/}
                      {/*      )}*/}
                      {/*    </div>*/}
                      {/*  )}*/}
                    </div>
                  </td>

                  <td className="center">
                    <button
                      className="table-option-btn"
                      onClick={() => {

                        navigate(
                          `${LECTURE_PATH}/${LECTURE_SUBPATH.DUTY_COMPLETE}/?lectureId=${lecture.lecture_id}`
                        );
                      }}
                    >
                      목록 보기
                    </button>

                  </td>
                  <td className="center">
                    {lecture.complete_date && (
                      <div className="message-txt type-1">{displayDate(lecture.complete_date)}</div>
                    )}
                  </td>
                  <td className="center">
                    <div className="table-option-box">

                      {lecture.take_type === "TAKE" && (
                        <button
                          className="table-option-btn"
                          onClick={() => {
                            navigate(
                              `${LECTURE_PATH}/${LECTURE_SUBPATH.DUTY}/${lecture.course_id}?resume=true`
                            );
                          }}
                        >
                          계속 수강 {lecture.complete_date}
                        </button>
                      )}
                      {lecture.take_type !== "NONE" && (
                        <button
                          className="table-option-btn"
                          onClick={() => {
                            setSelectedLecture(lecture);
                          }}
                        >
                          다시 수강
                        </button>
                      )}
                      {lecture.take_type === "NONE" && (
                        <button
                          className="table-option-btn"
                          onClick={() => {
                            setSelectedLecture(lecture);
                          }}
                        >
                          수강 하기
                        </button>
                      )}

                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div className="mobile-table-box">
        <div className="item-list">
          {lectures.map((lecture) => {
            const { takeCssClass, takeLabel } = takeTypeToLabel(
              lecture.take_type
            );
            if(lecture.visible_flag !== 'y') {
              return null
            }
            return (
              <div key={lecture.lecture_id} className="item-box">
                <div className="item-top-box">
                  <div className="message-box">
                    <div className="message-txt type-1">{lecture.name}</div>
                  </div>
                </div>

                <div className="item-mid-box">
                  <div className="course-box">
                    <div className="top-box">

                      <div className={`state-box ${takeCssClass}`}>
                        {takeLabel}
                      </div>
                      {lecture.complete_date && (
                        <div className={`mr-3 state-box`}>
                          {displayDate(lecture.complete_date)}
                        </div>
                      )}

                    </div>
                  </div>

                  <div className="table-option-box">
                    {lecture.take_type === "TAKE" && (
                      <div
                        className="table-option-btn"
                        onClick={() => {
                          navigate(
                            `${LECTURE_PATH}/${LECTURE_SUBPATH.DUTY}/${lecture.course_id}?resume=true`
                          );
                        }}
                      >
                        계속수강
                      </div>
                    )}
                    {lecture.take_type !== "NONE" && (
                      <div
                        className="table-option-btn"
                        onClick={() => {
                          setSelectedLecture(lecture)
                        }}
                      >
                        다시수강
                      </div>
                    )}
                    {lecture.take_type === "NONE" && (
                      <div
                        className="table-option-btn"
                        onClick={() => {
                          setSelectedLecture(lecture);
                        }}
                      >
                        수강하기
                      </div>
                    )}

                  </div>
                </div>

                {userType === USER_TYPE.병원회원 && (
                    <div className="quick-box">
                      <a
                        href={`${LECTURE_PATH}/${LECTURE_SUBPATH.DUTY_COMPLETE}/?lectureId=${lecture.lecture_id}`}
                        className="quick-btn"
                      >
                        목록 보기
                      </a>
                    </div>
                  )}
              </div>
            );
          })}
        </div>
      </div>

      {!!selectedLecture && (
        <LectureAddEmployeePopup
          selected={selectedLecture}
          onClose={() => {
            setSelectedLecture(null);
          }}
        />
      )}
    </section>
  );
};
export default DutyLecturePage;
