import { useEffect } from "react";
import { IJobOpeningLocationView } from "api/jobPosting/types";
import { IResponse } from "api/types";
import { debounce, throttle } from "lodash";

interface MapProps {
  latitude: number;
  longitude: number;
  setPosition: React.Dispatch<React.SetStateAction<number[]>>;
  occupations?: string[];
  setMapData: (param: IJobOpeningLocationView[]) => void;
  searchByLocation: (
    Ma: number,
    La: number,
    distance: number,
    occupations?: string[]
  ) => Promise<IResponse<IJobOpeningLocationView[]>>;
  children?: JSX.Element;
}

declare global {
  interface Window {
    kakao: any;
  }
}

const Map = ({
  latitude,
  longitude,
  setMapData,
  setPosition,
  occupations,
  searchByLocation,
  children,
}: MapProps) => {
  useEffect(() => {
    const mapScript = document.createElement("script");
    mapScript.async = true;
    mapScript.src =
      "//dapi.kakao.com/v2/maps/sdk.js?appkey=b4a4cb35fe6531167415b2eaf5aae024&autoload=false&libraries=clusterer,services";
    document.head.appendChild(mapScript);

    const onLoadKakaoMap = () => {
      window.kakao.maps.load(() => {
        let container = document.getElementById("map");
        let options = {
          center: new window.kakao.maps.LatLng(latitude, longitude),
          level: 9,
        };
        if (container?.childElementCount as number > 1) {
           const searchBox = container?.firstChild as ChildNode;
           container?.replaceChildren(searchBox);
        }
        let map = new window.kakao.maps.Map(container, options);
        let clusterer = new window.kakao.maps.MarkerClusterer({
          map: map,
          averageCenter: true,
          minLevel: 4,
        });
        let searchForm = document.getElementById(
          "search-form"
        ) as HTMLFormElement;

        const searchPlaces = (e: Event) => {
          let input = document.getElementById("keyword") as HTMLInputElement;
          let keyword = input.value;
          console.log(keyword);

          if (!keyword.replace(/^\s+|\s+$/g, "")) {
            alert("키워드를 입력해주세요!");
            return false;
          }

          let ps = new window.kakao.maps.services.Places();
          ps.keywordSearch(keyword, placesSearchCB);
          e.preventDefault();
        };

        const placesSearchCB = (data: any, status: any) => {
          if (status === window.kakao.maps.services.Status.OK) {
            let bounds = new window.kakao.maps.LatLngBounds();
            let placePosition = new window.kakao.maps.LatLng(
              data[0].y,
              data[0].x
            );
            bounds.extend(placePosition);
            map.setBounds(bounds);
            search();
          } else if (status === window.kakao.maps.services.Status.ZERO_RESULT) {
            alert("검색 결과가 존재하지 않습니다.");
            return;
          } else if (status === window.kakao.maps.services.Status.ERROR) {
            alert("검색 결과 중 오류가 발생했습니다.");
            return;
          }
        };

        const handlePositionChange = () => {
          let center = map.getCenter();
          setPosition([center.Ma as number, center.La as number]);
          search();
        };

        const search = () => {
          clusterer.clear();
          let level = map.getLevel();
          let center = map.getCenter();
          let scale = Math.pow(2, level - 3);
          let containerHeight = document.getElementById("map")?.clientHeight;
          let distance = containerHeight
            ? (containerHeight * scale) / 2
            : (600 * scale) / 2;
          searchByLocation(center.Ma, center.La, distance, occupations).then(
            (res: IResponse<IJobOpeningLocationView[]>) => {
              setMapData(res.data);
              let markers = res.data.map((item) => {
                return new window.kakao.maps.Marker({
                  position: new window.kakao.maps.LatLng(
                    item.latitude,
                    item.longitude
                  ),
                });
              });
              clusterer.addMarkers(markers);
            }
          );
        };

        window.kakao.maps.event.addListener(
          map,
          "dragend",
          handlePositionChange
        );
        window.kakao.maps.event.addListener(map, "zoom_changed", search);
        searchForm.addEventListener("submit", searchPlaces);

        handlePositionChange();
      });
    };

    mapScript.addEventListener("load", onLoadKakaoMap);

    return () => mapScript.removeEventListener("load", onLoadKakaoMap);
  }, [occupations, searchByLocation, setMapData, setPosition]);

  return (
    <div className="map-box">
      {children}
      <div className="map-area" id="map" style={{ aspectRatio: "1" }}>
        <div className="map-search-box" style={{ zIndex: 10 }}>
          <form id="search-form">
            <input type="text" placeholder="지역, 지하철역 검색" id="keyword" />
            <button type="submit" className="search-btn" />
          </form>
        </div>
      </div>
    </div>
  );
};

export default Map;
