import { IResumeView } from "api/resume/types";
import { InfoRow } from "./Comps";
type TProps = Pick<IResumeView, "license_list">;

const LicenseBox = ({ license_list }: TProps) => (
  <div className="resume-data-group">
    <div className="data-tit-box">특기사항</div>

    <div className="data-list-box">
      {license_list.map((license, i) => (
        <div className="row" key={i}>
          <div className="subject">자격증명</div>
          <div className="con">{license.license_name}</div>
        </div>
      ))}
    </div>
  </div>
);
export default LicenseBox;
