import { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

import {
  addEducationNotice as _addEducationNotice,
  updateEducationNotice as _updateEducationNotice,
} from "api/admin/rests";
import {
  ILectureCourseNotice,
  ILectureNoticeUpdateRequest,
} from "api/education/types";
import DatePicker from "components/DatePicker";

const INITIAL_NOTICE = {
  notice_type: "NORMAL",
  title: "",
  contents: "",
  start_date: "",
  end_date: ""
};

const CourseNotice = ({
  show,
  courseNotice: _courseNotice,
  onClose,
}: {
  show: boolean;
  courseNotice: ILectureCourseNotice | null;
  onClose: () => void;
}) => {
  const [courseNotice, setCourseNotice] = useState<ILectureNoticeUpdateRequest>(
    _courseNotice || INITIAL_NOTICE
  );
  const [selectedType, setSelectedType] = useState<string>("NORMAL")

  const addEducationNotice = async () => {
    try {
      await _addEducationNotice({
        ...courseNotice,
        notice_type : selectedType
      });

      onClose();
      setCourseNotice(INITIAL_NOTICE);
    } catch (e) {
      alert("에러가 발생하였습니다.");
    }
  };

  const updateEducationNotice = async () => {
    if (!_courseNotice || !courseNotice) return;
  
    try {

      await _updateEducationNotice(_courseNotice.notice_id, courseNotice);

      alert("수정되었습니다.");
      onClose();
      setCourseNotice(INITIAL_NOTICE);
    } catch (e) {
      alert("에러가 발생하였습니다.");
    }
  };

  useEffect(() => {
    if (!_courseNotice){
      setCourseNotice(INITIAL_NOTICE);

    }else{
      const { notice_id, ...params } =
      _courseNotice;

      setCourseNotice(params || INITIAL_NOTICE); 
    }
    
  }, [_courseNotice]);

  return (
    <Modal size="lg" show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{_courseNotice?.title || "법정의무교육 공지사항 등록"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>

        <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={3}>
              공지 유형
            </Form.Label>
            <Col>
            <Form.Select value={courseNotice.notice_type} onChange={(e) => {
                setCourseNotice({...courseNotice, notice_type: e.target.value})
                setSelectedType(e.target.value);
                }}>
                  <option>NORMAL</option>
                  <option>EVENT</option>
                  <option>EMERGENCY</option>
              </Form.Select>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={3}>
              제목
            </Form.Label>
            <Col>
              <Form.Control
                value={courseNotice.title}
                onChange={(e) =>
                  setCourseNotice({ ...courseNotice, title: e.target.value })
                }
              />
            </Col>
          </Form.Group>
          
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={3}>
              시작 일시
            </Form.Label>
            <Col>
              <DatePicker
                value={courseNotice.start_date}
                minDate={new Date()}
                onChange={(date) =>
                  setCourseNotice({ ...courseNotice, start_date: `${date} 00:00:00` })
                }
              />
            </Col>
            <Form.Label column sm={3}>
              종료 일시
            </Form.Label>
            <Col>
              <DatePicker
                value={courseNotice.end_date}
                minDate={new Date()}
                onChange={(date) =>
                  setCourseNotice({ ...courseNotice, end_date: `${date} 23:59:59` })
                }
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={3}>
              내용
            </Form.Label>
            <Col>
              <Form.Control
                as="textarea"
                rows={3}
                value={courseNotice.contents}
                onChange={(e) =>
                  setCourseNotice({
                    ...courseNotice,
                    contents: e.target.value,
                  })
                }
              />
            </Col>
          </Form.Group> 

          <div
            style={{
              display: "flex",
              gap: "8px",
              justifyContent: "flex-end",
              marginTop: "50px",
            }}
          >
            <Button
              id="clinic-member-form"
              type="submit"
              variant="primary"
              onClick={() => (_courseNotice ? updateEducationNotice() : addEducationNotice())}
            >
              {_courseNotice ? "수정하기" : "등록하기"}
            </Button>
            <Button variant="secondary" 
              onClick={onClose}>
              닫기
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default CourseNotice;
