import { ChangeEvent, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

import {
  addCourse as _addCourse,
  updateCourse as _updateCourse,
  uploadAdminImage,
} from "api/admin/rests";
import {
  ILectureCourseView,
  ILectureCourseUpdateRequest,
  ILectureRequest,
} from "api/education/types";
import DatePicker from "components/DatePicker";

const INITIAL_COURSE = {
  lecture_type: "",
  creator: "",
  image_filename: "",
  score: 0,
  title: "",
  start_date: "",
  end_date: "",
  description: "",
  recommend_flag: false,
};

const DentInCourse = ({
  show,
  course: _course,
  onClose,
}: {
  show: boolean;
  course: ILectureCourseView | null;
  onClose: () => void;
}) => {
  const [course, setCourse] = useState<ILectureCourseUpdateRequest>(
    _course || INITIAL_COURSE
  );

  const [imgFile, setImgFile] = useState<File>();

  const addCourse = async () => {

    if(!imgFile) return;

    const formData = new FormData();
    formData.append("file", imgFile);

    try {

      const result = await uploadAdminImage(formData)

      await _addCourse({
        ...course,
        lecture_type: "DENTIN",
        recommend_flag: false,
        lecture_list: [],
        image_filename : result.data.file_name
      });
      onClose();
      setCourse(INITIAL_COURSE);
    } catch (e) {
      alert("에러가 발생하였습니다.");
    }
  };

  const updateCourse = async () => {
    if (!_course || !course) return;

    try {

      if(imgFile) {
        const formData = new FormData();
        formData.append("file", imgFile);
  
        const result = await uploadAdminImage(formData);
        await _updateCourse(_course.course_id, {...course,image_filename : result.data.file_name });

      }else{

        await _updateCourse(_course.course_id, course);

      }

      alert("수정되었습니다.");
      onClose();
      setCourse(INITIAL_COURSE);
    } catch (e) {
      alert("에러가 발생하였습니다.");
    }
  };

  useEffect(() => {
    if (!_course){
      setCourse(INITIAL_COURSE);

    }else{
      const { course_id, lecture_list, recommend_flag, reg_date, ...params } =
      _course;
      setCourse(params || INITIAL_COURSE);

    }
    
  }, [_course]);

  const handleFile = (e:ChangeEvent<HTMLInputElement>) =>{

    if(!e.target.files) return;
  
    setImgFile(e.target.files[0]);
    
  }

  return (
    <Modal size="lg" show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{_course?.title || "코스 등록"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={3}>
              제작자
            </Form.Label>
            <Col>
              <Form.Control
                value={course.creator}
                onChange={(e) =>
                  setCourse({ ...course, creator: e.target.value })
                }
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={3}>
              제목
            </Form.Label>
            <Col>
              <Form.Control
                value={course.title}
                onChange={(e) =>
                  setCourse({ ...course, title: e.target.value })
                }
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={3}>
              이미지명
            </Form.Label>
            <Col>
              <input type="file" className="form-control" onChange={(e) => handleFile(e)}/>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={3}>
              이수 점수
            </Form.Label>
            <Col>
              <Form.Control
                value={course.score}
                type="number"
                onChange={(e) =>
                  setCourse({ ...course, score: Number(e.target.value) })
                }
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={3}>
              시작 일시
            </Form.Label>
            <Col>
              <DatePicker
                value={course.start_date}
                minDate={new Date()}
                onChange={(date) =>
                  setCourse({ ...course, start_date: `${date} 00:00:00` })
                }
              />
            </Col>
            <Form.Label column sm={3}>
              종료 일시
            </Form.Label>
            <Col>
              <DatePicker
                value={course.end_date}
                minDate={new Date()}
                onChange={(date) =>
                  setCourse({ ...course, end_date: `${date} 23:59:59` })
                }
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={3}>
              설명
            </Form.Label>
            <Col>
              <Form.Control
                as="textarea"
                rows={3}
                value={course.description}
                onChange={(e) =>
                  setCourse({
                    ...course,
                    description: e.target.value,
                  })
                }
              />
            </Col>
          </Form.Group>

          {/* <div style={{ padding: "20px 16px 016px" }}>
            <h2>강의정보</h2>
            <Form.Group as={Row} className="mb-3">
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={3}>
                  강의명
                </Form.Label>
                <Col>
                  <Form.Control
                    value={lecture.name}
                    onChange={(e) =>
                      setLecture({ ...lecture, name: e.target.value })
                    }
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={3}>
                  강의 제작자
                </Form.Label>
                <Col>
                  <Form.Control
                    value={lecture.creator}
                    onChange={(e) =>
                      setLecture({ ...lecture, creator: e.target.value })
                    }
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={3}>
                  영상 ID
                </Form.Label>
                <Col>
                  <Form.Control
                    value={lecture.video_id}
                    onChange={(e) =>
                      setLecture({ ...lecture, video_id: e.target.value })
                    }
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={3}>
                  영상 재생 시간 (second)
                </Form.Label>
                <Col>
                  <Form.Control
                    value={lecture.play_time}
                    type="number"
                    onChange={(e) =>
                      setLecture({
                        ...lecture,
                        play_time: Number(e.target.value),
                      })
                    }
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={3}>
                  강의 설명
                </Form.Label>
                <Col>
                  <Form.Control
                    value={lecture.description}
                    as="textarea"
                    rows={3}
                    onChange={(e) =>
                      setLecture({
                        ...lecture,
                        description: e.target.value,
                      })
                    }
                  />
                </Col>
              </Form.Group>
            </Form.Group>
          </div> */}

          <div
            style={{
              display: "flex",
              gap: "8px",
              justifyContent: "flex-end",
              marginTop: "50px",
            }}
          >
            <Button
              id="clinic-member-form"
              type="submit"
              variant="primary"
              onClick={() => (_course ? updateCourse() : addCourse())}
            >
              {_course ? "수정하기" : "등록하기"}
            </Button>
            <Button variant="secondary" onClick={onClose}>
              닫기
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default DentInCourse;
