import { useState, useEffect } from "react";
import useResumeGroups from "hooks/resume/useResumeGroups";
import MobileHeadBox from "components/MobileHeadBox";
import { addResumeGroup } from "api/member/rests";

const ResumeGroupPopup = ({
  onClose,
  onSelectGroup,
  onRegister,
}: {
  onClose: () => void;
  onSelectGroup: (id: number) => void;
  onRegister: () => void;
}) => {
  const [selectedGroupId, setSelectedGroupId] = useState<number|null>(null);
  const { resumeGroups, handler: fetch } = useResumeGroups();
  const [showAddField, setShowAddField] = useState<boolean>(false);
  const [newGroup, setNewGroup] = useState<string>("");
  
  const addNewGroup = async () => {
    try {
      await addResumeGroup({
        group_name: newGroup,
      });
      setNewGroup("");
      setShowAddField(false);
      await fetch();
    } catch (e) {
      alert("에러가 발생하였습니다.");
    }

    // setNewResumeGroup(data as IResumeGroupView);
  };

  return (
    <article
      className="popup popup-resume-group-select"
      style={{ display: "block" }}
    >
      <div className="popup-wrap">
        <div className="popup-head">
          <MobileHeadBox popup title="" onClose={onClose} />
          <button className="close-btn" onClick={onClose}></button>
        </div>

        <div className="popup-body">
          <article className="popup-resume-proposition">
            <div className="tit-box">
              <div className="icon-box">📂</div>
              <div className="txt-box">이력서를 그룹에 저장해주세요.</div>
              <div className="sub-box">
                지원할 이력서의 그룹을 먼저 선택해주세요!
              </div>
            </div>

            <div className="resume-select-list">
              {resumeGroups.map((rg) => {
                return (
                  <div
                    key={rg.group_id}
                    className="select-box"
                    onClick={() => {
                      setSelectedGroupId(rg.group_id);
                      onSelectGroup(rg.group_id);
                    }}
                  >
                    <a
                      className={`select-btn-box ${
                        selectedGroupId === rg.group_id ? "on" : ""
                      }`}
                    >
                      <div className="subject-box">{rg.group_name}</div>
                    </a>
                  </div>
                );
              })}

              {showAddField && (
                <div className="select-box">
                  <div className="select-add-box">
                    <div className="input-box">
                      <input
                        placeholder="새로운 이력서 그룹명을 입력해주세요."
                        value={newGroup}
                        onChange={(e) => setNewGroup(e.target.value)}
                      />
                    </div>

                    <div className="select-add-menu-box">
                      <div
                        className="menu-txt-btn"
                        onClick={addNewGroup}
                      >
                        완료
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="resume-select-add">
              <div
                className="resume-select-add-btn"
                onClick={() => setShowAddField(true)}
              >
                <i className="ic ic-add"></i>
                <span className="txt">그룹 추가하기</span>
              </div>
            </div>
          </article>
        </div>

        <div className="popup-foot">
          <div className="foot-menu-box">
            <button className="foot-menu-btn type-1" onClick={onRegister} disabled={selectedGroupId === null}>
              등록하기
            </button>
          </div>
        </div>
      </div>
    </article>
  );
};
export default ResumeGroupPopup;
