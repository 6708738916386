import { useState } from "react";
import MenuBar from "./MenuBar";
import MobileMenu from "./MobileMenu";
import TopHeader from "./TopHeader";

const Header = ({ excludeMenu }: { excludeMenu?: boolean }) => {
  const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false);
  return (
    <>
      <header>
        <TopHeader onClickMobileMenu={() => setShowMobileMenu(true)} />
        {!excludeMenu && <MenuBar />}
      </header>
      <MobileMenu
        isShow={showMobileMenu}
        onClose={() => setShowMobileMenu(false)}
      />
    </>
  );
};

export default Header;
