import { useEffect, useReducer } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

// import { fetchJobPosting } from "api/jobPosting/rests";
import { IJobOpeningEditRequest } from "api/jobPosting/types";
import {
  createJobPosting,
  fetchJobPostingCount,
  updateJobPosting,
  fetchJobPosting,
} from "api/clinic/rests";
import { IClinicMemberView } from "api/clinic/types";
import useProfile from "hooks/useProfile";

import { MYPAGE_PATH, MYPATH_SUB_PATH } from "routes";
import moment from "moment";
import {logout} from "../../utils/storage";

enum ACTIONS {
  UPDATE_INITIAL = "UPDATE_INITIAL",
  SET_VALUE = "SET_VALUE",
}

type State = IJobOpeningEditRequest;
type Action =
  | {
      type: ACTIONS.UPDATE_INITIAL;
      payload: any;
    }
  | {
      type: ACTIONS.SET_VALUE;
      payload: {
        key: keyof IJobOpeningEditRequest;
        value: number | string | string[] | object[];
      };
    };

const initialState: State = {
  logo_image_filename: "",
  clinic_name: "",
  address: "",
  extra_address: "",
  homepage_url: "",
  phone_number: "",
  image_filename_list: [],
  title: "",
  employee_cnt: 1,
  introduction: "",
  occupation_list: [],
  work_list: [],
  work_type_list: [],
  last_education_type: "",
  min_work_experience: 0,
  max_work_experience: 0,
  salary_info: {
    salary_type: "",
    tax_type: "",
    salary: 0,
  },
  work_day_type: "",
  work_time: "",
  benefit_list: [],
  submission_list: [],
  screening_list: [],
  closing_date: moment().add(14, "days").format("YYYY-MM-DD"),
  contact_email: "",
  contact_phone_number: "",
  public_flag: true,
  notification_list: [],
  submission_method: "",
  submission_method_list: [],
  details: "",
  hiring_count: 0,
  post_agreement_flag: false,

  // logo_image_filename: "",
  // clinic_name: "테스트치과100",
  // address: "서울 강남구 테스트로 테스트빌딩 10층",
  // homepage_url: "https://test-clinic100.com",
  // phone_number: "02-1234-5678",
  // image_filename_list: [],
  // title: "테스트 채용 공고",
  // employee_cnt: 100,
  // introduction: "테스트 채용 공고 입니다. 많이 지원 부탁드립니다.",
  // occupation_list: ["치과의사", "치과기공사", "기타"],
  // work_list: ["진료", "임플란트", "경영지원", "기타"],
  // work_type_list: ["정규직", "계약직"],
  // last_education_type: "대학교졸업",
  // min_work_experience: 0,
  // max_work_experience: 0,
  // salary_info: {
  //   salary_type: "NEGOTIATION",
  //   tax_type: "BEFORE",
  //   salary: 100000,
  // },
  // work_day_type: "주5일근무",
  // work_time: "09:00 ~ 18:00",
  // benefit_list: ["중식제공", "포상", "성과금"],
  // submission_list: ["이력서", "자기소개서", "자격증사본"],
  // screening_list: [
  //   {
  //     step: 1,
  //     screening: "서류전형",
  //   },
  //   {
  //     step: 2,
  //     screening: "실무면접",
  //   },
  //   {
  //     step: 3,
  //     screening: "임원면접",
  //   },
  // ],
  // closing_date: "2022-07-20",
  // contact_email: "support@test.com",
  // contact_phone_number: "02-1234-5678",
  // public_flag: true,
  // notification_list: ["문자", "메일", "카카오톡"],
  // submission_method: "",
  // details: "",
};

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case ACTIONS.UPDATE_INITIAL:
      return { ...state, ...action.payload };
    case ACTIONS.SET_VALUE:
      const { key, value } = action.payload;
      return { ...state, [key]: value };
    default:
      throw new Error(`Unknown action type: ${action}`);
  }
};

const useRegisterJobPosting = () => {
  const [searchParams] = useSearchParams();
  const profile = useProfile();
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    closing_date: moment()
      .add(searchParams.get("isIntern") === "on" ? 90 : 14, "days")
      .format("YYYY-MM-DD"),
  });
  const isEditMode = searchParams.get("mode") === "edit";
  const jobId = searchParams.get("job_id");
  const isMaster = !!searchParams.get("master")
  const {
    title,
    occupation_list,
    work_list,
    work_type_list,
    work_day_type,
    work_time,
    submission_list,
    submission_method_list,
    contact_email,
    contact_phone_number,
  } = state;

  const _setValue = (key: any, newValue: any) =>
    dispatch({
      type: ACTIONS.SET_VALUE,
      payload: { key: key, value: newValue },
    });

  const validate = () => {
    if (title.trim() === "") {
      alert("공고 제목을 입력해주세요.");
      return false;
    }
    if (title.length >= 100) {
      alert("공고 제목은 100자 이내로 입력해주세요.");
      return false;
    }
    if (occupation_list.length === 0) {
      alert("모집직종을 선택해주세요.");
      return false;
    }
    if (work_list.length === 0) {
      alert("담당업무를 선택해주세요.");
      return false;
    }
    if (work_type_list.length === 0) {
      alert("고용형태를 선택해주세요.");
      return false;
    }
    if (work_day_type === "") {
      alert("근무요일을 선택해주세요.");
      return false;
    }
    if (work_time.length >= 2000) {
      alert("근무시간은 최대 2000자를 지원합니다.");
      return false;
    }
    if (work_time === "") {
      alert("근무시간을 입력해주세요.");
      return false;
    }
    if (submission_list.length === 0) {
      alert("제출서류를 선택해주세요.");
      return false;
    }
    if (submission_method_list.length === 0) {
      alert("제출방법을 선택해주세요.");
      return false;
    }
    if (contact_email === "" || contact_phone_number === "") {
      alert("담당자 정보를 입력해주세요.");
      return false;
    }
    return true;
  };
  const fieldToLabel = (field: string) => {
    switch (field) {
      case "last_education_type":
        return "최종학력";
      case "title":
        return "제목";
      case "closing_date":
        return "마감일";
      case "work_day_type":
        return "근무요일";
      case "submission_list":
        return "제출서류";

      case "hiring_count":
        return "모집인원";
      case "title":
        return "제목";
      case "address":
        return "주소";
      case "extra_address":
        return "상세주소";
      case "occupation_list":
        return "직종선택";
      case "work_list":
        return "희망 업무";
      case "work_type_list":
        return "희망 근무 형태";
      case "screening_list":
        return "진행방법";
      default:
        return field;
    }
  };

  const register = async () => {
    if (!validate()) return;
    const params = {
      ...state,
    };

    if (isEditMode) {
      try {
        await updateJobPosting(Number(jobId), params);
        alert("수정이 완료되었습니다.");
        if(isMaster) {
          logout();
          window.location.reload();
        } else {
          navigate("/mypage/jobposting");
        }
      } catch (e: any) {
        const resp = e.response.data;
        if (Array.isArray(resp.data)) {
          const fields = resp.data.map((d: { field: string }) =>
            fieldToLabel(d.field)
          );
          alert(`다음 필드는 필수 입력입니다. \n - ${fields.join("\n - ")}`);
        } else {
          // if (/count is exceed/.test(e.response.data.data)) {
          //   alert("노출 가능한 공고는 최대 2개입니다.");
          // }
          if (/SalaryTypeRequest/.test(e.response.data.data)) {
            alert("희망 연봉을 입력해주세요.");
          } else {
            alert("오류가 발생하였습니다.");
          }
        }
      }
    } else {
      try {
        const {
          data: { current_count, max_count },
        } = await fetchJobPostingCount(searchParams.get("isIntern") === "on");
        if (current_count >= max_count) {
          alert(`노출 가능한 공고는 최대 ${max_count}개입니다.`);
          return;
        }

        await createJobPosting({
          ...params,
          intern_flag: searchParams.get("isIntern") === "on",
        });
        navigate(`${MYPAGE_PATH}/${MYPATH_SUB_PATH.JOBPOSTING}`);
      } catch (e: any) {
        const resp = e.response.data;
        if (Array.isArray(resp.data)) {
          const fields = resp.data.map((d: { field: string }) =>
            fieldToLabel(d.field)
          );
          alert(`다음 필드는 필수 입력입니다. \n - ${fields.join("\n - ")}`);
        } else {
          // if (/count is exceed/.test(e.response.data.data)) {
          //   alert("노출 가능한 공고는 최대 2개입니다.");
          // }
          if (/SalaryTypeRequest/.test(e.response.data.data)) {
            alert("희망 연봉을 입력해주세요.");
          } else {
            alert("오류가 발생하였습니다.");
          }
        }
      }
    }
  };

  useEffect(() => {
    if (profile as IClinicMemberView) {
      dispatch({
        type: ACTIONS.UPDATE_INITIAL,
        payload: {
          clinic_name: (profile as IClinicMemberView).clinic_name,
          address: (profile as IClinicMemberView).address,
          extra_address: (profile as IClinicMemberView).extra_address,
          homepage_url: (profile as IClinicMemberView).homepage_url,
          phone_number: (profile as IClinicMemberView).phone_number,
        } as IClinicMemberView,
      });
    }
  }, [profile]);

  useEffect(() => {
    if (jobId) {
      (async () => {
        const { data } = await fetchJobPosting(Number(jobId));
        dispatch({
          type: ACTIONS.UPDATE_INITIAL,
          payload: data,
        });
      })();
    }
  }, [jobId]);

  return {
    state,
    handler: {
      setAddress: (address: string) => _setValue("address", address),
      setExtraAddress: (address: string) => _setValue("extra_address", address),
      setClinicName: (name: string) => _setValue("clinic_name", name),
      sethomepageUrl: (url: string) => _setValue("homepage_url", url),
      setEmployeeCnt: (count: number) =>
        _setValue("employee_cnt", Number(count)),
      setLogoImageFilename: (filename: string) =>
        _setValue("logo_image_filename", filename),
      setImageFilenameList: (filenames: string[]) =>
        _setValue("image_filename_list", filenames),
      setIntroduction: (introduction: string) =>
        _setValue("introduction", introduction),
      setLastEducationType: (type: string) =>
        _setValue("last_education_type", type),
      setPhoneNumber: (phone: string) => _setValue("phone_number", phone),
      setOccupations: (occupation: string[]) =>
        _setValue("occupation_list", occupation),
      setScreenings: (screens: object[]) =>
        _setValue("screening_list", screens),
      setTitle: (title: string) => _setValue("title", title),
      setWorks: (works: string[]) => _setValue("work_list", works),
      setWorkType: (type: string[]) => _setValue("work_type_list", type),
      setMinWorkExperience: (year: string | null) =>
        _setValue("min_work_experience", year ? parseInt(year) : null),
      setMaxWorkExperience: (year: string | null) =>
        _setValue("max_work_experience", year ? parseInt(year) : null),
      setWorkDayType: (day: string[]) => _setValue("work_day_type", day),
      setWorkTime: (time: string) => _setValue("work_time", time),
      setBenefits: (benefits: string[]) => _setValue("benefit_list", benefits),
      setSubmissions: (submissions: string[]) =>
        _setValue("submission_list", submissions),
      setClosingDate: (date: string) => _setValue("closing_date", date),
      setContactEmail: (email: string) => _setValue("contact_email", email),
      setContactPhoneNumber: (phoneNumber: string) =>
        _setValue("contact_phone_number", phoneNumber),
      setNotifications: (noti: string[]) =>
        _setValue("notification_list", noti),
      setSalaryInfo: (info: any) => _setValue("salary_info", info),
      setSubmissionMethod: (method: string[]) =>
        _setValue("submission_method", method[0]),
      setSubmissionMethodList: (methods: string[]) =>
        _setValue("submission_method_list", methods),
      setDetails: (detail: string) => _setValue("details", detail),
      setHiringCount: (count: number) => _setValue("hiring_count", count),
      setPostAgreementFlag: (post_agreement_flag: boolean) => _setValue("post_agreement_flag", post_agreement_flag),
      register,
    },
  };
};
export default useRegisterJobPosting;
