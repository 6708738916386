import { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

import useRegisterResume from "hooks/resume/useRegisterResume";
import { MYPAGE_PATH, MYPATH_SUB_PATH } from "routes";

import Layout from "layouts";
import {
  CareerSection,
  EducationSection,
  EtcSection,
  JobSection,
  LicenseSection,
  ProfileSection,
} from "components/ResumeForm";
import { ResumeGroupPopup } from "components/ResumeForm/Comps";
import Alert from "components/Popups/Alert";
import { useUserContext } from "contexts/UserContext";

const ResumeFormPage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const { isEditMode, state, handler } = useRegisterResume(
    Number(searchParams.get("resume_id")),
    searchParams.get("mode"),
    !!searchParams.get("master")
  );
  const {
    name,
    birth_date,
    image_filename,
    email,
    public_email,
    phone_number,
    public_phone_number,
    address,
    extra_address,
    title,
    salary_info,
    occupation_list,
    work_list,
    work_type_list,
    last_education_type,
    last_education_name,
    last_education_start_date,
    last_education_end_date,
    graduation_type,
    cover_letter,
    public_flag,
    readyToRegister,
    work_experience_list,
    work_experience_month,
    license_list,
  } = state;
  const { changeValue, register } = handler;
  const { isSignIn } = useUserContext();

  const onReadyRegister = async () => {
    try {
      const data = await register();
      if (data !== false) {
        // api success
        navigate(`${MYPAGE_PATH}/${MYPATH_SUB_PATH.RESUME_GROUP}`);
      } else {
        // validate false
        setShowAlert(false);
        setShowPopup(false);
        changeValue("readyToRegister", false);
      }
    } catch (e) {
      // api error
      setShowAlert(false);
      setShowPopup(false);
      changeValue("readyToRegister", false);
    }
  };
  useEffect(() => {
    if (readyToRegister) onReadyRegister();
  }, [readyToRegister]);

  const [disabled, setDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (image_filename?.includes(".blob") || image_filename === "") {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [image_filename]);

  useEffect(() => {
    if (!isSignIn) {
      window.location.href = "/login";
    }
  }, [isSignIn]);

  return (
    <>
      <Layout>
        <section className="job-register">
          <div className="inner wrap">
            <div className="job-register-head">
              <div className="head-box">
                <i className="ic ic-register"></i>
                <span className="txt">이력서 작성</span>
              </div>
            </div>

            <ProfileSection
              name={name}
              birth_date={birth_date}
              image_filename={image_filename}
              email={email}
              public_email={public_email}
              phone_number={phone_number}
              public_phone_number={public_phone_number}
              address={address}
              extra_address={extra_address}
              onChangeValue={changeValue}
            />

            <article className="job-register-form type-resume">
              <JobSection
                title={title}
                occupation_list={occupation_list}
                work_list={work_list}
                work_type_list={work_type_list}
                salary_info={salary_info}
                onChangeValue={changeValue}
              />
              <EducationSection
                last_education_type={last_education_type}
                last_education_name={last_education_name}
                last_education_start_date={last_education_start_date}
                last_education_end_date={last_education_end_date}
                graduation_type={graduation_type}
                onChangeValue={changeValue}
              />
              <CareerSection
                work_experience_list={work_experience_list}
                work_experience_month={work_experience_month}
                onChangeValue={changeValue}
              />
              <LicenseSection
                license_list={license_list}
                onChangeValue={changeValue}
              />
              <EtcSection
                cover_letter={cover_letter}
                public_flag={public_flag}
                onChangeValue={changeValue}
              />

              <div className="form-menu-box">
                <button
                  onClick={() => setShowPopup(true)}
                  className="menu-btn"
                  disabled={disabled}
                >
                  이력서 {isEditMode ? "수정" : "등록"}
                </button>
              </div>
            </article>
          </div>
        </section>
      </Layout>

      {showPopup && (
        <ResumeGroupPopup
          onClose={() => setShowPopup(false)}
          onSelectGroup={(id: number) => changeValue("group_id", id)}
          onRegister={() => setShowAlert(true)}
        />
      )}
      {showAlert && (
        <Alert
          text="작성하신 이력서를 인재정보에 노출하고 면접제안을 받아보시겠습니까?"
          yesEvent={async () => {
            changeValue("public_flag", true);
            changeValue("readyToRegister", true);
          }}
          noEvent={() => {
            changeValue("public_flag", false);
            changeValue("readyToRegister", true);
          }}
        />
      )}
    </>
  );
};

export default ResumeFormPage;
