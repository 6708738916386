import { useEffect, useState } from "react";

import { fetchEducationFaq } from "api/education/rests";
import { IEducationFaqView } from "api/education/types";

const useEducationFaqs = () => {
  const [faqs, setFaqs] = useState<IEducationFaqView[]>([]);

  useEffect(() => {
    (async () => {
      const { data } = await fetchEducationFaq();
      setFaqs(data);
    })();
  }, []);

  return faqs;
};
export default useEducationFaqs;
