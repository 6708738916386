import { useEffect, useState } from "react";

import { fetchResume } from "api/resume/rests";
import { IResumeView } from "api/resume/types";

const useResume = (resumeId: number) => {
  const [resume, setResume] = useState<IResumeView | null>(null);

  useEffect(() => {
    if (resumeId < 0) return;
    (async () => {
      try {
        const { data } = await fetchResume(resumeId);
        setResume(data);
      } catch (e) {
        setResume(null);
      }
    })();
  }, [resumeId]);

  return resume;
};
export default useResume;
