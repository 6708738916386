import { useState } from "react";
import useEditMode from "hooks/useEditMode";

const InputFieldRow = ({
  label,
  value,
  disableEdit = false,
  onChange = () => {},
  onConfirm = () => {},
}: {
  label: string;
  value: string;
  disableEdit?: boolean;
  onChange?: (value: string) => void;
  onConfirm?: () => void;
}) => {
  const [prevValue, setPrevValue] = useState<string>("");
  const {
    isEditOn,
    handler: { onEditMode, offEditMode },
  } = useEditMode();

  const disabledValue = disableEdit;

  return (
    <div className="row">
      <div className="subject-box">{label}</div>

      <div className="con-box">
        {!isEditOn ? (
          <div className="view-box">
            <div className="view-txt">{value}</div>

            {!disabledValue && (
              <div className="menu-box">
                <button
                  className="menu-btn type-2"
                  style={{ backgroundColor: "#fff" }}
                  onClick={() => {
                    setPrevValue(value);
                    onEditMode();
                  }}
                >
                  수정
                </button>
              </div>
            )}
          </div>
        ) : (
          <div className="edit-box" style={{ display: "block", marginTop: 20 }}>
            <div className="edit-input-box">
              <div className="tbox">
                <input
                  type="text"
                  value={value}
                  onChange={(e) => onChange(e.target.value)}
                />
              </div>
            </div>

            <div className="menu-box" style={{marginTop: 10}}>
              <button
                className="menu-btn type-1"
                onClick={async () => {
                  await onConfirm();
                  offEditMode();
                }}
                style={{ backgroundColor: "#fff" }}
              >
                확인
              </button>
              <button
                className="menu-btn type-2"
                onClick={() => {
                  onChange(prevValue);
                  offEditMode();
                  setPrevValue("");
                }}
                style={{ backgroundColor: "#fff" }}
              >
                취소
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default InputFieldRow;
