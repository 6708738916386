import { useEffect, useState } from "react";
import { fetchMember } from "api/member/rests";
import { IMemberView } from "api/member/types";
import { useUserContext } from "contexts/UserContext";

// TODO: useProfile로 통합??
const useMember = (): IMemberView | undefined => {
  const { memberId } = useUserContext();
  const [member, setMember] = useState<IMemberView | undefined>();

  useEffect(() => {
    if (!memberId) return;

    (async () => {
      const { data } = await fetchMember();
      setMember(data);
    })();
  }, [memberId]);

  return member;
};
export default useMember;
