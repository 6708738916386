import { GENDER } from "utils/enums";
import useEditMode from "hooks/useEditMode";

const GenderFieldRow = ({ value }: { value: GENDER }) => {
  const {
    isEditOn,
    handler: { onEditMode, offEditMode },
  } = useEditMode();

  return (
    <div className="row">
      <div className="subject-box">성별</div>

      <div className="con-box">
        {!isEditOn ? (
          <div className="view-box">
            <div className="view-txt">
              {(value as any) === "female" ? "여성" : "남성"}
              <span className="view-value">
                {(value as any) === "female" ? "F" : "M"}
              </span>
            </div>

            {/* <div className="menu-box">
              <button
                className="menu-btn type-2"
                style={{ backgroundColor: "#fff" }}
                onClick={onEditMode}
              >
                수정
              </button>
            </div> */}
          </div>
        ) : (
          <div className="edit-box" style={{ display: "block" }}>
            <div className="edit-input-box">
              <div className="rbox">
                <label>
                  <input type="radio" name="gender" value={GENDER.남} />
                  <i></i>
                  <span className="txt">남성</span>
                </label>
              </div>

              <div className="rbox">
                <label>
                  <input type="radio" name="gender" value={GENDER.여} />
                  <i></i>
                  <span className="txt">여성</span>
                </label>
              </div>
            </div>

            <div className="menu-box">
              <button
                className="menu-btn type-1"
                style={{ backgroundColor: "#fff" }}
              >
                확인
              </button>
              <button
                className="menu-btn type-2"
                onClick={offEditMode}
                style={{ backgroundColor: "#fff" }}
              >
                취소
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default GenderFieldRow;
