const Paginator = ({
  currentPage,
  totalPage,
  changePage,
}: {
  currentPage: number;
  totalPage: number;
  changePage: (page: number) => void;
}) => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <article className="paging">
      <div className="paging-list">
        <button
          className="num-box prev-btn"
          onClick={() => {
            currentPage > 1 && changePage(currentPage - 1);
            scrollToTop();
          }}
        >
          &nbsp;
        </button>
        {[...Array(totalPage).keys()]
          .filter((page) => {
            return Math.abs(currentPage - (page + 1)) <= 4;
          })
          .map((page) => (
            <button
              key={page}
              className={`num-box ${page + 1 === currentPage ? "current" : ""}`}
              onClick={() => {
                changePage(page + 1);
                scrollToTop();
              }}
            >
              {(page + 1).toString()}
            </button>
          ))}
        <button
          className="num-box next-btn"
          onClick={() => {
            currentPage < totalPage && changePage(currentPage + 1);
            scrollToTop();
          }}
        >
          &nbsp;
        </button>
      </div>
    </article>
  );
};

export default Paginator;
