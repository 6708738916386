import React, { SetStateAction, useContext, useEffect } from "react";
import { ClinicSignupContext } from "contexts/SignupContex";

import ClinicCategoryFieldRow from "./Comps/ClinicCategoryFieldRow";
import InputFieldRow from "./Comps/InputFieldRow";
import { fetchLicense } from "api/clinic/rests";
import GenderBox from "./GenderBox";
import DatePicker from "components/DatePicker";

const ClinicBox = () => {
  const context = useContext(ClinicSignupContext);
  const {
    clinic_name,
    clinic_type,
    rep_name,
    rep_birth_date,
    rep_licence_number,
    rep_gender,
    errors,
    setClinicName,
    setClinicType,
    setRepBirthDate,
    setRepName,
    setRepGender,
    setRepLicenceNumber,
  } = context;

  const checkLicenseNumber = async () =>
    await fetchLicense("00002", "19820221", "홍길동", "1");

  useEffect(() => {
    (async () => {
      await checkLicenseNumber();
    })();
  }, []);

  return (
    <div className="form-box" data-type="corp" style={{ display: "block" }}>
      <div className="form-tit-box">병원정보</div>
      <ClinicCategoryFieldRow
        errorMessage={errors?.clinic_type}
        value={clinic_type}
        onChange={(value) => setClinicType(value)}
      />
      <div className="form-con-box">
        <InputFieldRow
          errorMessage={errors?.clinic_name}
          placeholder="치과명*"
          value={clinic_name}
          onChange={(value) => setClinicName(value)}
        />
        <InputFieldRow
          errorMessage={errors?.rep_name}
          placeholder="대표자명*"
          value={rep_name}
          onChange={(value) => setRepName(value)}
        />

        <DatePicker
          style={{
            width: "100%",
            backgroundColor: "#fff",
            margin: "8px 0",
            height: 48,
            boxShadow: "none",
          }}
          inputClassName="large no-shadow"
          value={rep_birth_date}
          maxDate={new Date()}
          dateFormat="yyyyMMdd"
          placeholderText="대표자 생년월일 / 19750125"
          onChange={(date: string) => {
            setRepBirthDate(date);
          }}
        />
        {context.clinic_type !='보건소'?
          <InputFieldRow
            errorMessage={errors?.rep_licence_number}
            placeholder="대표자 면허번호* (-없이 숫자만 입력)"
            value={rep_licence_number}
            numberOnly
            onChange={(value) => setRepLicenceNumber(value)}
          /> : null
        }

        <GenderBox
          value={rep_gender}
          onChange={(value) => setRepGender(value)}
        />

      </div>
    </div>
  );
};
export default ClinicBox;
