import { ISalaryRequest } from "api/resume/types";
import moment from "moment";
import { LECTURE_PATH, LECTURE_SUBPATH } from "routes";
import { SALARY_TYPE, TAX_TYPE } from "utils/enums";

export const isOverdue = (date?: string) =>
  date === undefined ? false : moment(date).diff(moment(), "days") < 0;

export const displayDate = (date?: string | Date, seperator = ".") =>
  date ? moment(date).format(`YYYY${seperator}MM${seperator}DD`) : "";

export const displayTime = (date?: string | Date) =>
  date
    ? moment(date).format(`A h:mm`).replace("AM", "오전").replace("PM", "오후")
    : "";

export const displayPlayTime = (second: number) => {
  const date = new Date(second * 1000).toISOString();

  const hour = date.substring(11, 13);
  const minute = date.substring(14, 16);
  if (hour === "00" && minute === "00") return "1분";
  return `${hour !== "00" ? `${hour}시간` : ""} ${
    minute !== "00" ? `${minute}분` : ""
  }`;
};

export const displayDateTime = (date?: string | Date, seperator = "-") =>
  date ? moment(date).format(`YYYY${seperator}MM${seperator}DD HH:mm`) : "";

export const displayYearMonth = (year: number = 0, month: number = 0) => {
  if (year === 0 && month === 0) {
    return "신입";
  }
  return (
    "경력 " +
    `${year > 0 ? `${year}년` : ""} ${month > 0 ? `${month}개월` : ""}`
  );
};

export const monthToYearMonth = (month: number = 0) => {
  const yy = month / 12;
  const mm = month % 12;

  return { year: Math.floor(yy), month: Math.floor(mm) };
};

export const displayExperienceMonth = (month: number = 0) => {
  const { year, month: _month } = monthToYearMonth(month);
  return displayYearMonth(year, _month);
};

export const displayMinMaxMonth = (min: number = 0, max: number = 0) => {
  const minYearMonth = monthToYearMonth(min);
  const maxYearMonth = monthToYearMonth(max);
  return `${displayYearMonth(minYearMonth.year, minYearMonth.month)} ${
    max > 0
      ? `~ ${displayYearMonth(maxYearMonth.year, maxYearMonth.month)}`
      : ""
  }`;
};

/**
 * 이미지나, 첨부파일 호출 서버 용 주소로 변환
 * @param url 리소스 경로
 * @returns 서버로 호출할 리소스 풀 path
 */
export const resourcePath = (url: string) =>
  `${process.env.REACT_APP_ENDPOINT || "http://localhost:3000/"}${url}`;

export const salaryText = (salary: ISalaryRequest) => {
  var strResult: string = "";
  if (salary.salary_type === "NEGOTIATION") {
    strResult = "면접시 협의";
  } else if (salary.salary_type === "MONTHLY") {
    strResult =
      "월급 " +
      salary.salary.toLocaleString() +
      "원 " +
      taxTypeText(salary.tax_type);
  } else if (salary.salary_type === "HOURLY") {
    strResult =
      "시급 " +
      salary.salary.toLocaleString() +
      "원 " +
      taxTypeText(salary.tax_type);
  } else if (salary.salary_type === "ANNUAL") {
    strResult =
      "연봉 " +
      salary.salary.toLocaleString() +
      "원 " +
      taxTypeText(salary.tax_type);
  }
  return strResult;
};

export const salaryTypeText = (salaryType: string) => {
  switch (salaryType) {
    case SALARY_TYPE.시급:
      return "시급";
    case SALARY_TYPE.월급:
      return "월급";
    case SALARY_TYPE.연봉:
      return "연봉";
    case SALARY_TYPE.협의:
      return "면접시 협의";
    default:
      return "면접시 협의";
  }
};

export const taxTypeText = (taxType: string) => {
  switch (taxType) {
    case TAX_TYPE.세전:
      return "세전";
    case TAX_TYPE.세후:
      return "세후";
    default:
      return "";
  }
};

export const monthToLabel = (month: number) => {
  if (month === 0) {
    return "무관";
  }
  var result: String = "";
  let nYear: number = Math.floor(month / 12);
  let nMonth: number = month % 12;
  if (nYear > 0) {
    result = String(nYear) + "년";
  }
  if (nMonth > 0) {
    result = result + String(nMonth) + "개월";
  }

  return result;
};
export const displaySalaryInfo = (salaryInfo: ISalaryRequest) =>
  `${salaryTypeText(salaryInfo.salary_type || "")} ${
    salaryInfo.salary?.toLocaleString() || ""
  } ${taxTypeText(salaryInfo.tax_type || "")}`;

export const displayWorkExperienceByYear = (
  min: number | null,
  max: number | null
) => {
  if (!min && !max) return "무관";
  if (!min) {
    return `경력 ${max}년 이하`;
  }
  if (!max) {
    return `경력 ${min}년 이상`;
  }
  return `경력 ${min}~${max}년`;
};

export const displayWorkExperience = (
  min: number | null,
  max: number | null
) => {
  let strResult: string = "empty";
  if (min === null) {
    strResult = "무관 ";
  } else if (min === 0) {
    if (max === 0) {
      strResult = "무관";
    } else if (max === null) {
      strResult = "무관";
    } else {
      strResult = "경력 " + monthToLabel(max) + "이하";
    }
  } else if (max === 0) {
    if (min === 0) {
      strResult = "무관";
    } else if (min === null) {
      strResult = "무관";
    } else {
      strResult = "경력 " + monthToLabel(min) + "이상";
    }
  } else {
    strResult = "경력 " + monthToLabel(min) + " 이상 ~ ";
    if (max != null) {
      strResult = strResult + monthToLabel(max!) + " 이하";
    }
  }
  return strResult;
};

export const dualValue = (
  val1: string | undefined,
  val2: string | undefined
) => {
  let strResult: string | undefined = "";
  if (val1 === null || val1 === "") {
    if (val2 === null) {
      strResult = "";
    } else {
      strResult = val2;
    }
  } else {
    if (val2 === null || val2 === "") {
      strResult = val1;
    } else {
      strResult = val1 + ", " + val2;
    }
  }
  if (strResult?.length === 2) {
    strResult = "";
  }

  return strResult;
};

export const checkBizID = (
  bizID: string //사업자등록번호 체크
) => {
  // bizID는 숫자만 10자리로 해서 문자열로 넘긴다.
  const checkID = [1, 3, 7, 1, 3, 7, 1, 3, 5, 1];
  let i,
    chkSum = 0,
    c2: string,
    remander;
  bizID = bizID.replace(/-/gi, "");

  for (i = 0; i <= 7; i++) chkSum += checkID[i] * Number(bizID.charAt(i));
  c2 = "0" + checkID[8] * Number(bizID.charAt(8));
  c2 = c2.substring(c2.length - 2, c2.length);
  chkSum += Math.floor(Number(c2.charAt(0))) + Math.floor(Number(c2.charAt(1)));
  remander = (10 - (chkSum % 10)) % 10;

  if (Math.floor(Number(bizID.charAt(9))) === remander) return true; // OK!
  return false;
};

export const screeningId = (id: string) =>
  `${id.substring(0, 3)}${[...Array(id.length - 3).keys()]
    .map((i) => "*")
    .join("")}`;

/**
 * 온라인강좌 lecture_type 표시라벨
 * @param take_type string
 * @returns 표시 라벨
 */
export const lectureTypeToLabel = (lectureType: string): string => {
  switch (lectureType) {
    case "LEGAL":
      return "법정의무교육";
    case "DENTIN":
      return "치과인강좌";
    case "HEALTH":
      return "구강보건교육";
    default:
      return "기타교육";
  }
};

export const lectureBaseurl = (lectureType: string): string => {
  switch (lectureType) {
    case "LEGAL":
      return `${LECTURE_PATH}/${LECTURE_SUBPATH.DUTY}`;
    case "DENTIN":
      return `${LECTURE_PATH}/${LECTURE_SUBPATH.DENTAL_IN}`;
    case "HEALTH":
      return `${LECTURE_PATH}/${LECTURE_SUBPATH.ORAL_HEALTH}`;
    default:
      return `${LECTURE_PATH}/${LECTURE_SUBPATH.ETC}`;
  }
};

const p = new RegExp(
  "^(?=.*[A-Za-z])(?=.*\\d)(?=.*[~!@#$%^&*()+|=])[A-Za-z\\d~!@#$%^&*()+|=]{8,16}",
  "i"
);

export const checkPassword = (password: string) => {
  return !password ? false : password.match(p) !== null;
};

export const checkBizNumber = (bizNumber: string) => {
  if (!bizNumber) return;
  var checkID = [1, 3, 7, 1, 3, 7, 1, 3, 5, 1];
  var i,
    chkSum = 0,
    c2,
    remander;
  bizNumber = bizNumber.replace(/-/gi, "");

  for (i = 0; i <= 7; i++) chkSum += checkID[i] * Number(bizNumber.charAt(i));
  c2 = "0" + checkID[8] * Number(bizNumber.charAt(8));
  c2 = c2.substring(c2.length - 2, c2.length);
  chkSum += Math.floor(Number(c2.charAt(0))) + Math.floor(Number(c2.charAt(1)));
  remander = (10 - (chkSum % 10)) % 10;

  console.log(Math.floor(Number(bizNumber.charAt(9))) === remander);

  return Math.floor(Number(bizNumber.charAt(9))) === remander;
};