import TrashImg from "assets/img/ic_trash.svg";

import { removeLectureEmployee } from "api/clinic/rests";
import { IClinicEmployeeView } from "api/clinic/types";

const StaffRemovePopup = ({
  employee,
  handleClose,
  onSuccess,
}: {
  employee: IClinicEmployeeView;
  handleClose: () => void;
  onSuccess: () => void;
}) => {
  const removeEmployee = async () => {
    try {
      await removeLectureEmployee(employee.employee_id);
      onSuccess();
    } catch (e) {
      alert("오류가 발생하였습니다.");
    }
  };

  return (
    <article
      className="popup popup-staff-remove mobile-layer"
      style={{ display: "block" }}
    >
      <div className="popup-wrap">
        <div className="popup-head">
          <button className="close-btn" onClick={handleClose}></button>
        </div>
        <div className="popup-body">
          <article className="popup-msg-form">
            <div className="ic-box">
              <img src={TrashImg} alt="이미지" />
            </div>

            <div className="txt-box">
              <div className="main-box">삭제하시겠습니까?</div>
              <div className="sub-box">삭제한 자료는 복구할 수 없습니다.</div>
            </div>
          </article>
        </div>

        <div className="popup-foot">
          <div className="foot-menu-box">
            <div
              className="foot-menu-btn type-1"
              onClick={removeEmployee}
            >
              삭제
            </div>
            <div
              className="foot-menu-btn type-2"
              onClick={handleClose}
            >
              취소
            </div>
          </div>
        </div>
      </div>
    </article>
  );
};
export default StaffRemovePopup;
