import { useEffect, useState } from "react";
import { useUserContext } from "contexts/UserContext";
import { USER_TYPE } from "utils/enums";
import { MESSAGE_PATH } from "routes";
import {
  fetchChatList as fetchMemberChatList,
  fetchQnaClinicMessageTop,
  fetchQnaMemberMessageTop,
} from "api/member/rests";
import { fetchChatList as fetchClinicChatList } from "api/clinic/rests";
import { IChatMemberView, IQnaChatView } from "api/types";

const MessageBox = () => {
  const { userType, name } = useUserContext();
  const [chats, setChats] = useState<IChatMemberView[]>([]);
  const [adminChat, setAdminChat] = useState<IQnaChatView | null>(null);
  const isMember = userType === USER_TYPE.개인회원;

  const fetch = async () => {
    try {
      const { data } = isMember
        ? await fetchMemberChatList("")
        : await fetchClinicChatList("");
      setChats(data);
    } catch (e) {}
    try {
      const { data: admin_data } = isMember
        ? await fetchQnaMemberMessageTop()
        : await fetchQnaClinicMessageTop();
      setAdminChat(admin_data);
    } catch (e) {}
  };

  useEffect(() => {
    (async () => await fetch())();
  }, []);

  const isOn =
    chats.filter((chat) => !chat.read_flag && chat.sender !== name).length >
      0 ||
    (adminChat?.from_admin === true && adminChat.read_flag === false);

  return (
    <div className="menu-box">
      <a href={`${MESSAGE_PATH}`} className={`msg-btn ${isOn ? "on" : ""}`}>
        &nbsp;
      </a>
    </div>
  );
};

export default MessageBox;
