import { ReactNode, useState, useEffect } from "react";

import { GROUP_CODE_ID, ICommonCodeView } from "api/code/types";
import { fetchCodes } from "api/code/rests";

const Option = ({
  checked,
  option,
  onClick,
}: {
  checked: boolean;
  option: ICommonCodeView;
  onClick: (item: string) => void;
}) => {
  return (
    <article className="label-item-box">
      <label>
        <input
          type="checkbox"
          checked={checked}
          onChange={() => onClick(option.code_name)}
        />
        <span className="txt">{option.description}</span>
      </label>
    </article>
  );
};

/** TODO: options 없에고 label-description으로 설정 */
const CheckboxGroupFieldRow = ({
  label,
  extraClass,
  selected,
  groupCodeId,
  // options,
  pickLastOne = false,
  dentistDiff = false,
  excludedLabel = [],
  onChange,
}: {
  label: string | ReactNode;
  extraClass?: string;
  /**
   * 선택한 값 리스트
   */
  selected: Array<string>;
  /**
   * 옵션으로 사용할 CODE(서버) 호출 값 (GROUP_CODE_ID)
   */
  groupCodeId?: GROUP_CODE_ID;
  /**
   * 선택 가능한 값 리스트 (TODO: 삭제할 것. groupCodeId로 대체)
   */
  // options?: Array<string>;
  // 치과의사만 따로 선택되게 하고 싶을 때
  dentistDiff?: boolean;
  pickLastOne?: boolean;
  excludedLabel?: string[];
  onChange: (selected: Array<string>) => void;
}) => {
  const [ops, setOps] = useState<ICommonCodeView[]>([]);

  useEffect(() => {
    if (groupCodeId === undefined) return;

    (async () => {
      const { data } = await fetchCodes(groupCodeId);
      setOps(data);
    })();
  }, [groupCodeId]);

  const handleClick = (clickedItem: string) => {
    let newValue: Array<string> = [];

    if (pickLastOne) {
      newValue = [clickedItem];
    } else {
      if (clickedItem === "치과의사" && !!dentistDiff) newValue = [clickedItem];
      else if (selected.includes(clickedItem)) {
        newValue = selected.filter((item) => item !== clickedItem);
      } else {
        newValue = [
          ...selected.filter((item) => item !== "치과의사" || !dentistDiff),
          clickedItem,
        ];
      }
    }

    onChange(newValue);
  };

  return (
    <div className={`row ${extraClass}`}>
      <div className="item-box">
        <div className="subject">{label}</div>
        <div className="con">
          <div className="check-list">
            <div className="list-wrap">
              {ops
                .filter((a) => !excludedLabel.includes(a.description))
                .sort((a, b) => a.display_order - b.display_order)
                .map((option, i) => (
                  <Option
                    key={i}
                    option={option}
                    checked={selected.includes(option.code_name)}
                    onClick={handleClick}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckboxGroupFieldRow;
