import { useNavigate } from "react-router-dom";
import CheckSvg from "assets/img/ic_lecture_chk.svg";

import { completeLecture as completeMemberLecture } from "api/member/rests";
import { completeLecture as completeClinicLecture } from "api/clinic/rests";
import { useUserContext } from "contexts/UserContext";
import { USER_TYPE } from "utils/enums";

const LectureCompletePopup = ({
  lectureId,
  courseUrl,
  nextLectureUrl,
  retentionTime,
  completeStrTime,
  onClose,
}: {
  lectureId: number;
  courseUrl: string;
  nextLectureUrl?: string;
  retentionTime: number;
  completeStrTime: string;
  onClose: () => void;
}) => {
  const { userType } = useUserContext();
  const navigate = useNavigate();

  const complete = async () => {
    // console.log(Number(lectureId), completeStrTime, retentionTime);
    try {
      userType === USER_TYPE.개인회원
        ? await completeMemberLecture(
            Number(lectureId),
            completeStrTime,
            retentionTime
          )
        : await completeClinicLecture(
            Number(lectureId),
            completeStrTime,
            retentionTime
          );
    } catch (e) {}
  };

  const moveNextLecture = async () => {
    await complete();

    if (nextLectureUrl === undefined) {
      alert("마지막 강의입니다.");
      window.location.href = courseUrl;
    } else {
      window.location.href = nextLectureUrl;
    }
  };

  const moveCoursePage = async () => {
    await complete();
    window.location.href = courseUrl;
  };

  return (
    <article
      className="popup popup-lecture-continue mobile-layer"
      style={{ display: "block" }}
    >
      <div className="popup-wrap">
        <div className="popup-head">
          <button className="close-btn" onClick={onClose}></button>
        </div>
        <div className="popup-body">
          <article className="popup-msg-form">
            <div className="ic-box">
              <img src={CheckSvg} alt="이미지" />
            </div>

            <div className="txt-box">
              <div className="main-box">수강완료</div>
              <div className="sub-box">
                이수시간을 모두 채웠습니다. 다음 강의를 계속 들으시겠습니까?
              </div>
            </div>
          </article>
        </div>

        <div className="popup-foot">
          <div className="foot-menu-box">
            <div
              className="foot-menu-btn type-1 normal"
              onClick={onClose}
            >
              계속 수강
            </div>
            <div
              className="foot-menu-btn type-3 normal"
              onClick={moveNextLecture}
            >
              다음 강의
            </div>
            <div
              className="foot-menu-btn type-4 normal"
              onClick={moveCoursePage}
            >
              나가기
            </div>
          </div>
        </div>
      </div>
    </article>
  );
};
export default LectureCompletePopup;
