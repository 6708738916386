import logo from "assets/img/img_intro_logo.svg";
import { Button } from "react-bootstrap";
import { loginAdmin } from "api/admin/rests";
import { useEffect, useState } from "react";
import { ADMIN_PATH } from "routes";

const AdminLoginPage = () => {
  const [id, setId] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  useEffect(() => {
    window.location.href = "https://admin.dent-in.co.kr";
  }, []);

  const handleLogin = async () => {
    try {
      const { data } = await loginAdmin(id, password);
      console.log(data);
      window.location.href = `${ADMIN_PATH}`;
    } catch (e: any) {
      const resp = e.response.data;
      alert("로그인에 실패했습니다.");
    }
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        height: "100%",
        backgroundColor: "#333",
        gap: "10px",
        justifyContent: "center",
      }}
    >
      <main
        // className="m-auto"
        style={{
          display: "flex",
          gap: "10px",
          flexDirection: "column",
          width: "500px",
        }}
      >
        <img
          src={logo}
          alt="이미지"
          width={185}
          height={58}
          style={{ marginBottom: "20px" }}
        />

        <div className="form-floating">
          <input
            type="email"
            className="form-control"
            id="floatingInput"
            value={id}
            onChange={(e) => setId(e.target.value)}
          />
          <label htmlFor="floatingInput">ID</label>
        </div>
        <div className="form-floating">
          <input
            type="password"
            className="form-control"
            id="floatingPassword"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <label htmlFor="floatingPassword">Password</label>
        </div>

        <Button
          variant="primary"
          onClick={handleLogin}
          style={{ marginTop: "25px", height: "58px" }}
        >
          로그인
        </Button>
      </main>
    </div>
  );
};
export default AdminLoginPage;
