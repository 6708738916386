import { useReducer } from "react";

import { IPasswordUpdateRequest } from "api/member/types";
import { checkMemberPassword, updateMember } from "api/member/rests";
import { checkClinicPassword, updateClinic } from "api/clinic/rests";
import { useUserContext } from "contexts/UserContext";
import { USER_TYPE } from "utils/enums";

enum ACTIONS {
  PASSWORD = "PASSWORD",
  PASSWORD_CONFIRM = "PASSWORD_CONFIRM",
  PASSWORD_UPDATED = "PASSWORD_UPDATED",
}

type State = Pick<IPasswordUpdateRequest, "password"> & {
  isConfirmed?: boolean;
  isUpdated?: boolean;
};
type Action =
  | {
      type: ACTIONS.PASSWORD;
      payload: string;
    }
  | {
      type: ACTIONS.PASSWORD_CONFIRM | ACTIONS.PASSWORD_UPDATED;
      payload: boolean;
    };

const initialState: State = {
  password: "",
};

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case ACTIONS.PASSWORD:
      return { ...state, password: action.payload };
    case ACTIONS.PASSWORD_CONFIRM:
      return { ...state, isConfirmed: action.payload };
    case ACTIONS.PASSWORD_UPDATED:
      return { ...state, isUpdated: action.payload };
    default:
      throw new Error(`Unknown action type: ${action}`);
  }
};

const usePassword = () => {
  const { memberId, userType } = useUserContext();
  const [state, dispatch] = useReducer(reducer, initialState);

  const checkPassword = async () => {
    try {
      userType === USER_TYPE.개인회원
        ? await checkMemberPassword(memberId, state.password)
        : await checkClinicPassword(memberId, state.password);

      dispatch({
        type: ACTIONS.PASSWORD_CONFIRM,
        payload: true,
      });
    } catch (e) {
      dispatch({
        type: ACTIONS.PASSWORD_CONFIRM,
        payload: false,
      });
    }
  };

  const updatePassword = async (password: string) => {
    try {
      userType === USER_TYPE.개인회원
        ? await updateMember({
            password_info: { password: state.password, new_password: password },
          })
        : await updateClinic({
            password_info: { password: state.password, new_password: password },
          });

      dispatch({
        type: ACTIONS.PASSWORD_UPDATED,
        payload: true,
      });
    } catch (e) {
      dispatch({
        type: ACTIONS.PASSWORD_UPDATED,
        payload: false,
      });
    }
  };

  return {
    /**
     * 예전 비밀번호 확인 여부
     */
    isConfirmed: state.isConfirmed,
    /**
     * 비밀번호 변경 성공 여부
     */
    isUpdated: state.isUpdated,
    /** 예전 비밀번호 */
    password: state.password,
    handler: {
      setPassword: (password: string) =>
        dispatch({
          type: ACTIONS.PASSWORD,
          payload: password,
        }),
      checkPassword,
      updatePassword,
    },
  };
};

export default usePassword;
