import { useEffect, useState } from "react";
import StaffImg from "assets/img/ic_staff.svg";
import { LECTURE_PATH, LECTURE_SUBPATH } from "routes";

import {
  checkLectureEmployees,
  addLectureEmployees,
  removeLectureEmployee,
  sendInviteTextMessage,
} from "api/clinic/rests";
import useLectureEmployees from "hooks/clinic/useLectureEmployees";
import { ILegalLectureTakeView } from "api/education/types";
import { useUserContext } from "contexts/UserContext";
import { useNavigate } from "react-router-dom";

interface IEmployee {
  name: string;
  phoneNumber: string;
  memberId: string;
}

const LectureAddEmployeePopup = ({
  selected,
  onClose,
}: {
  selected: ILegalLectureTakeView;
  onClose: () => void;
}) => {
  const userContext = useUserContext();
  const navigate = useNavigate();
  const { employees: initialEmployees } = useLectureEmployees();
  const [employees, setEmployees] = useState<IEmployee[]>([]);
  const [name, setName] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");

  const [isValid, setIsValid] = useState<boolean | undefined>(undefined);

  const addEmployee = async () => {
    if (name === "" || phoneNumber === "") {
      alert("회원 정보를 입력해주세요.");
      return;
    }

    try {
      const {
        data: { member_id },
      } = await checkLectureEmployees(name.trim(), phoneNumber.trim());

      await registEmployee(member_id);

      setEmployees([
        ...employees,
        {
          name,
          phoneNumber,
          memberId: member_id,
        },
      ]);
      setName("");
      setPhoneNumber("");
    } catch (e: any) {
      if (/already exist/.test(e.response.data.data)) {
        alert("이미 추가되어 있는 직원입니다.");
      } else {
        if (
          window.confirm(
            "가입되지 않은 회원입니다. 회원가입 요청 문자를 발송하겠습니까?"
          )
        ) {
          await sendInviteTextMessage(
            phoneNumber,
            `${name}님, 법정의무교육을 위한 치과인 가입링크 입니다. https://dent-in.co.kr/signup`
          );
          alert("초대 메세지를 전송하였습니다.");
        }
        setIsValid(false);
      }
    }
  };

  const deleteEmployee = async (memberId: string) => {
    try {
      let flag = window.confirm("삭제 하시겠습니까?");
      if (!flag) {
        return;
      }
      await removeLectureEmployee(memberId);
      setEmployees(
        employees.filter((employee) => employee.memberId !== memberId)
      );
    } catch (e) {
      alert("오류가 발생하였습니다.");
    }
  };

  const registEmployee = async (memberId: string) => {
    if (!memberId) return;
    try {
      await addLectureEmployees(memberId);
    } catch (e: any) {
      throw e;
    }
  };

  useEffect(() => {
    setEmployees(
      initialEmployees.map((e) => {
        return {
          name: e.name,
          phoneNumber: e.phone_number,
          memberId: e.employee_id,
        };
      })
    );
  }, [initialEmployees]);

  return (
    <article className="popup popup-staff-add" style={{ display: "block" }}>
      <div className="popup-wrap">
        <div className="popup-head">
          <div className="mobile-head-box">
            <div className="mobile-head-txt">직원 추가하기</div>
          </div>

          <button className="close-btn" onClick={() => onClose()}></button>
        </div>
        <div className="popup-body">
          <article className="popup-staff-form">
            <div className="ic-box">
              <img src={StaffImg} alt="이미지" />
            </div>

            <div className="txt-box">
              <div className="main-box">교육수강 리스트</div>
              <div className="sub-box">
                법정의무교육을 함께 수강할 직원을 등록해 주세요 <br />※ 개인회원
                가입된 회원만 추가 가능합니다.
              </div>
            </div>

            <div className="staff-set-box">
              <div className="set-list">
                {employees.map((employee) => (
                  <div key={employee.memberId} className="set-box">
                    <div className="group-box">
                      <div className="input-box">
                        <div className="tbox full">
                          <input
                            type="text"
                            placeholder="이름"
                            value={employee.name}
                            disabled={true}
                          />
                        </div>
                      </div>

                      <div className="input-box">
                        <div className="tbox full">
                          <input
                            type="text"
                            placeholder="연락처"
                            value={employee.phoneNumber}
                            disabled={true}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="search-btn-box">
                      <div
                        className="remove-btn on"
                        onClick={() => deleteEmployee(employee.memberId)}
                      >
                        삭제
                      </div>
                    </div>
                  </div>
                ))}

                <div className="set-box">
                  <div className="group-box">
                    <div className="input-box">
                      <div className="tbox full">
                        <input
                          type="text"
                          placeholder="이름"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="input-box">
                      <div className="tbox full">
                        <input
                          type="text"
                          placeholder="연락처"
                          value={phoneNumber}
                          onChange={(e) =>
                            setPhoneNumber(e.target.value.replace(/\D/g, ""))
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div className="search-btn-box">
                    <div className="add-btn" onClick={addEmployee}>
                      추가
                    </div>
                  </div>
                </div>
              </div>

              {isValid === false && (
                <div className="result-box">
                  <div className="result-txt type-fail">
                    유효하지 않은 회원입니다. 회원 가입 후 등록해주세요.
                  </div>
                </div>
              )}
            </div>
          </article>
        </div>

        <div className="popup-foot">
          <div className="foot-menu-box">
            <button
              onClick={() => {
                if(selected.take_type === 'NONE') {
                  window.localStorage.removeItem(selected.lecture_id.toString())
                  navigate(
                    `${LECTURE_PATH}/${LECTURE_SUBPATH.DUTY}/${selected.course_id}?collectFlag=1`
                  );
                } else {
                  if (
                    window.confirm(
                      "새로 수강하시면 기존 진행은 삭제되고 이수증은 다시 출력할 수 없습니다. 진행하시겠습니까?"
                    )
                  ) {
                    window.localStorage.removeItem(selected.lecture_id.toString())
                    navigate(
                      `${LECTURE_PATH}/${LECTURE_SUBPATH.DUTY}/${selected.course_id}?collectFlag=1&retry=1`
                    );
                  }
                }

              }}
              className="foot-menu-btn type-1"
            >
              {selected.take_type !== "NONE"?'다시 수강하기':'수강하기'}
            </button>
          </div>
        </div>
      </div>
    </article>
  );
};
export default LectureAddEmployeePopup;
