const PageTitle = ({
  iconClass,
  title,
}: {
  iconClass: string;
  title: string;
}) => (
  <div className="tit-box">
    <i className={`ic ${iconClass}`}></i>
    <span className="txt">{title}</span>
  </div>
);
export default PageTitle;
// TODO: 삭제??
