export const PAGE_SIZE = 10;
export const SEARCH_PAGE_SIZE = 24;
export const ONE_PATH_SIZE = 100;

export const BANNER_TYPES = {
  MAIN: 1,
  LOGIN: 2,
  SIGNUP: 3,
  NOTICE: 4,
  NOTICE_DETAIL: 5,
  FAQ: 6,
  LECTURE_MAIN: 7,
  LECTURE_LIST: 8,
  LECTURE_EMPLOYEE: 9,
  MY_LECTURE: 10,
  LECTURE_DETAIL: 11,
  LECTURE_NOTICE: 12,
  RESUME: 13,
  RECRUIT: 14,
};
