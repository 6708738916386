import { ChangeEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  InputFieldRow,
  GenderFieldRow,
  BoxTitle,
  PasswordFieldRow,
} from "components/ProfilePage/Comps";
import UploaderPopup from "components/Popups/UploaderPopup";
import WorkArea from "components/ProfilePage/WorkArea";
import useMemberProfile from "hooks/member/useMemberProfile";
import { resourcePath } from "utils/formatter";
import { GENDER } from "utils/enums";
import {
  updateMember,
  deleteMember as deleteMemberAPI,
} from "api/member/rests";
import { logout } from "utils/storage";
import { fetchCodes } from "api/code/rests";
import { GROUP_CODE_ID, ICommonCodeView } from "api/code/types";
import PolicyAgreeFieldRow from "components/ProfilePage/Comps/PolicyAgreeFieldRow";

const MemberProfile = () => {
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [editOccu, setEditOccu] = useState(false);
  const [showWithDrawPopup, setShowWithDrawPopup] = useState(false);
  const [occupations, setOccupations] = useState<ICommonCodeView[]>([]);
  const {
    state: {
      name,
      birth_date,
      phone_number,
      email,
      gender,
      image_filename,
      occupation,
      auth_code,
    },
    handler: {
      setEmail,
      updateEmail,
      setImage,
      setOccupation,
      updateOccupation,
      updateAuthCode,
    },
  } = useMemberProfile();

  useEffect(() => {
    (async () => {
      const { data } = await fetchCodes(GROUP_CODE_ID.회원직종);
      setOccupations(
        data.filter(
          (item) =>
            (item.code_name !== "치과의사") !== (occupation === "치과의사")
        )
      );
    })();
  }, []);

  const updateProfile = async (filename: string) => {
    await updateMember({
      image_filename: filename,
    });
    setImage(filename);
  };
  const handleUpdateOccupation = () => {
    updateOccupation();
    setEditOccu(false);
  };
  const deleteMember = async () => {
    try {
      await deleteMemberAPI();
      logout();
      alert("회원 탈퇴가 완료되었습니다.");
      window.location.href = "/"; // 로그아웃 후 main 으로 이동
    } catch (_) {
      alert("오류로 인해 탈퇴처리가 실패되었습니다.");
    }
  };

  const onPhoneNumberChange = () => {
    const userCode = process.env.REACT_APP_IMP_CODE;

    // @ts-ignore
    const { IMP } = window;

    IMP.init("imp88488634");
    IMP.certification({}, (response: any) => {
      if (response.success) {
        updateAuthCode(response.imp_uid).then(() => {
          window.location.reload();
        });
      }
    });
  };

  return (
    <>
      <div className="profile-box">
        <a
          href="#none"
          className="profile-btn-box"
          onClick={() => {
            setShowPopup(true);
          }}
        >
          <div className="img-box type-circle">
            <img
              src={resourcePath(image_filename)}
              alt="이미지"
              style={{ borderRadius: "50%" }}
            />
          </div>
        </a>
      </div>

      <div className="my-form-list">
        <div className="my-form-box">
          <BoxTitle title="기본정보" />
          <div className="my-form-con-box">
            <InputFieldRow label="이름" value={name || ""} disableEdit={true} />
            <InputFieldRow
              label="생년월일"
              value={birth_date}
              disableEdit={true}
            />
            <GenderFieldRow value={gender as GENDER} />
          </div>
        </div>

        <div className="my-form-box">
          <BoxTitle title="연락처 정보" />
          <div className="my-form-con-box">
            <div className="row">
              <div className="subject-box">휴대폰</div>

              <div className="con-box">
                <div className="view-box">
                  <div className="view-txt">
                    {!auth_code ? phone_number : "인증되었습니다."}
                  </div>
                  <div className="menu-box">
                    <button
                      className="menu-btn type-2"
                      style={{ backgroundColor: "#fff" }}
                      onClick={() => {
                        onPhoneNumberChange();
                      }}
                    >
                      수정
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <InputFieldRow
              label="이메일"
              value={email}
              onChange={setEmail}
              onConfirm={updateEmail}
            />
          </div>
        </div>
        <div className="my-form-box">
          <BoxTitle title="직종" />
          <div className="my-form-con-box">
            {!editOccu ? (
              <div className="row">
                <div className="subject-box">직종</div>
                <div className="con-box">
                  <div className="view-box">
                    <div className="view-txt">
                      {
                        occupations.find((e) => e.code_name === occupation)
                          ?.description
                      }
                    </div>
                    <div className="menu-box">
                      {occupation !== "치과의사" && (
                        <button
                          className="menu-btn type-2"
                          onClick={() => setEditOccu(true)}
                        >
                          수정
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="member-profile-occupation-wrap">
                <div>
                  <div className="title">직종</div>
                </div>
                <div className="select-wrap">
                  <select
                    className="select-box"
                    defaultValue={occupation}
                    onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                      setOccupation(e.target.value);
                    }}
                  >
                    {occupations.map(({ code_name, description }, idx) => (
                      <option key={`occ-${code_name}-${idx}`} value={code_name}>
                        {description}
                      </option>
                    ))}
                  </select>
                  <div>
                    <button
                      className="member-profile-occupation-btn-confirm"
                      onClick={handleUpdateOccupation}
                    >
                      확인
                    </button>
                    <button
                      className="member-profile-occupation-btn"
                      onClick={() => setEditOccu(false)}
                    >
                      취소
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <WorkArea />

        <div className="my-form-box">
          <BoxTitle title="계정 정보" />
          <div className="my-form-con-box">
            <PasswordFieldRow />
          </div>
        </div>
        <div className="my-form-box">
          <BoxTitle title="약관 동의" />
          <div className="my-form-con-box">
            <PolicyAgreeFieldRow />
          </div>
        </div>

        <div className="my-form-box">
          <BoxTitle title="회원 탈퇴" />
          <div className="my-form-con-box">
            <div className="withdraw-wrap">
              <button
                className="withdraw-btn"
                onClick={() => setShowWithDrawPopup(true)}
              >
                탈퇴
              </button>
            </div>
          </div>
        </div>
      </div>

      {showPopup && (
        <UploaderPopup
          photoUrl={image_filename}
          onClose={() => setShowPopup(false)}
          onUpload={(filename) => updateProfile(filename)}
        />
      )}
      {showWithDrawPopup && (
        <article className="popup" style={{ display: "block" }}>
          <div className="popup-wrap">
            <div className="popup-head">
              <button
                className="close-btn"
                onClick={() => setShowWithDrawPopup(false)}
              ></button>
            </div>

            <div className="popup-body">
              <div className="withdraw-popup-body">
                <p>회원 탈퇴를 진행하시겠습니까?</p>
                <div>
                  <button onClick={deleteMember}>네</button>
                </div>
                {/* <div>
                <button onClick={() => setShowWithDrawPopup(false)}>아니오</button>
              </div> */}
              </div>
            </div>
          </div>
        </article>
      )}
    </>
  );
};

export default MemberProfile;
