import { useCallback, useEffect, useState } from "react";

import { SUCCESS_RESULT } from "api/types";
import { fetchResumeGroups } from "api/member/rests";
import { IResumeGroupView } from "api/member/types";

const useResumeGroups = () => {
  const [resumeGroups, setResumeGroups] = useState<Array<IResumeGroupView>>([]);

  const fetch = useCallback(async () => {
    const { data, result } = await fetchResumeGroups();

    if (result === SUCCESS_RESULT) {
      setResumeGroups(data as IResumeGroupView[]);
    }
  }, []);

  useEffect(() => {
    (async () => fetch())();
  }, [fetch]);

  return { resumeGroups, handler: fetch };
};

export default useResumeGroups;
