import { useEffect, useState } from "react";

import { fetchDrives, fetchNotices } from "api/notice/rests";
import { IDriveView, INoticeView, TNoticeType } from "api/notice/types";

export const useDriveNotices = () => {
  const [page, setPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [drives, setDrives] = useState<IDriveView[]>([]);

  useEffect(() => {
    (async () => {
      const {
        data: { total_page, element_list },
      } = await fetchDrives(page);
      setDrives(element_list);
      setTotalPage(total_page);
    })();
  }, [page]);

  return { page, totalPage, drives, handler: { setPage } };
};

export const useNoticesByType = (type: TNoticeType) => {
  const [page, setPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [notices, setNotices] = useState<INoticeView[]>([]);

  useEffect(() => {
    (async () => {
      const {
        data: { total_page, element_list },
      } = await fetchNotices(page, type);
      setNotices(element_list);
      setTotalPage(total_page);
    })();
  }, [page, type]);

  return { page, totalPage, notices, handler: { setPage } };
};

const useNotices = () => {
  const [page, setPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [notices, setNotices] = useState<INoticeView[]>([]);

  useEffect(() => {
    (async () => {
      const {
        data: { total_page, element_list },
      } = await fetchNotices(page);
      setNotices(element_list);
      setTotalPage(total_page);
    })();
  }, [page]);

  return { page, totalPage, notices, handler: { setPage } };
};
export default useNotices;
