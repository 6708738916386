import logo from "assets/img/img_footer_logo.svg";
import { POLICY_PATH } from "routes";
import { bridgeAlert, loadFCM } from "utils/appBridge";

const Footer = () => (
  <footer>
    <div className="inner wrap">
      <div className="top-box">
        <div className="footer-logo">
          <a href={`/main`} className="menu-btn">
            <img src={logo} alt="이미지" />
          </a>
        </div>

        <div className="footer-txt">구인구직부터 온라인 교육까지, 치과인!</div>
      </div>

      <div className="bottom-box">
        <div className="copyright">
          ⓒ 2022. 치과인 all rights reserved.
          <br />
          04802 서울특별시 성동구 광나루로 257 대한치과의사협회(송정동 81-7)
          <br />
          TEL : 02-2024-9100 FAX : 02-468-4655/58
          <br />
          대표자 : 박태근 사업자명 : (사)대한치과의사협회 사업자번호 :
          218-82-01076
          <br />
          온라인 교육 이용 문의 : 02-2024-9185
          <br />
          온라인 교육 외 문의 : 02-2024-9116
        </div>
        <div className="footer-menu">
          <a href={`${POLICY_PATH}?type=BUSINESS`} className="menu-btn">
            사업자정보
          </a>
          <a
            href={`${POLICY_PATH}?type=TERM&title=이용약관`}
            className="menu-btn"
          >
            이용약관
          </a>
          <a
            href={`${POLICY_PATH}?type=PRIVACY_USE_POLICY`}
            className="menu-btn"
          >
            개인정보처리방침
          </a>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
