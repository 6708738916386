import { BrowserRouter, Routes, Route } from "react-router-dom";

import LectureLayout from "layouts/LectureLayout";
import MypageLayout from "layouts/MypageLayout";
import AdminLayout from "layouts/AdminLayout";

import FindIdPasswordPage from "pages/FindIdPasswordPage";
import MemberFindIdPage from "pages/FindIdPasswordPage/MemberFindIdPage";
import ClinicFindIdPage from "pages/FindIdPasswordPage/ClinicFindIdPage";
import ClinicFindPasswordPage from "pages/FindIdPasswordPage/ClinicFindPasswordPage";
import MemberFindPasswordPage from "pages/FindIdPasswordPage/MemberFindPasswordPage";
import IntroPage from "pages/IntroPage";
import RecruitPage from "pages/Recruit";
import MessagePage from "pages/MessagePage";
import NoticePage from "pages/Notice/NoticePage";
import NoticeDetailPage from "pages/Notice/NoticeDetailPage";
import LoginPage from "pages/LoginPage";
import SignupPage from "pages/SignupPage";
import ProfilePage from "pages/MyPage/ProfilePage";
import MyJobPostingPage from "pages/MyPage/MyJobPostingPage";
import PreviewIncomePage from "pages/MyPage/PreviewIncomePage";
import VolunteerPage from "pages/MyPage/VolunteerPage";
import MyWishJobPostingPage from "pages/MyPage/MyWishJobPostingPage";
import MyWishResumePage from "pages/MyPage/MyWishResumePage";
import ApplyHistoryPage from "pages/MyPage/ApplyHistoryPage";
import MyLecturePage from "pages/MyPage/MyLecturePage";
import ResumePage from "pages/ResumePage";
import ResumeFormPage from "pages/ResumeFormPage";
import ResumeGroupPage from "pages/MyPage/ResumeGroupPage";
import ResumeGroupDetailPage from "pages/MyPage/ResumeGroupDetailPage";
import ResumeSearchPage from "pages/ResumeSearchPage";
import JobPostingPage from "pages/JobPostingPage";
import JobPostingFormPage from "pages/JobPostingFormPage";
import JobPostingSearchPage from "pages/JobPostingSearchPage";
import LectureListPage from "pages/LectureListPage";
import LecturePage from "pages/LecturePage";
import LectureMainPage from "pages/LectureMainPage";
import LectureMyPage from "pages/LectureMyPage";
import LecturePlayPage from "pages/LecturePlayPage";
import MemberOfferHistoryPage from "pages/MyPage/MemberOfferHistoryPage";
import ClinicOfferHistoryPage from "pages/MyPage/ClinicOfferHistoryPage";
import LectureFaqPage from "pages/LectureFaqPage";
import LectureNoticePage from "pages/LectureNoticePage";
import LectureNoticeDetailPage from "pages/LectureNoticeDetailPage";
import LecturePersonnelPage from "pages/LecturePersonnelPage";
import DutyLecturePage from "pages/DutyLecturePage";
import DutyLecturePlayPage from "pages/DutyLecturePlayPage";
import SearchPage from "pages/SearchPage";
import PolicyPage from "pages/PolicyPage";
import AdminUserPage from "pages/Admin/AdminUserPage";
import AdminJobPostingPage from "pages/Admin/AdminJobPostingPage";
import AdminNoticePage from "pages/Admin/AdminNoticePage";
import AdminLoginPage from "pages/Admin/AdminLoginPage";
import AdminEducationPage from "pages/Admin/AdminEducationPage";
import AdminBannerPage from "pages/Admin/AdminBannerPage";
import AdminResumePage from "pages/Admin/AdminResumePage";
import AdminEducationDentInPage from "pages/Admin/AdminEducationDentInPage";
import DentInLectureTable from "components/Admin/DentInLectureTable";
import AdminEducationOralHealthPage from "pages/Admin/AdminEducationOralHealthPage";
import OralHealthLectureTable from "components/Admin/OralHealthLectureTable";
import AdminEducationEtcPage from "pages/Admin/AdminEducationEtcPage";
import AdminUserStatPage from "pages/Admin/AdminUserStatPage";
import EtcLectureTable from "components/Admin/EtcLectureTable";
import NoticeEventPage from "pages/Notice/NoticeEventPage";
import Intern from "pages/Intern";
import NoticeDrivePage from "pages/Notice/NoticeDrivePage";
import NoticeDriveDetailPage from "pages/Notice/NoticeDriveDetailPage";
import NoticeInternPage from "pages/Notice/NoticeInternPage";
import LandingMaster from "./pages/LandingMaster";
import DutyLectureCourseListPage from "./pages/DutyLectureCourseListPage";

export const HOME_PATH = "/";
export const MAIN_PATH = "/main"; // 구인구직 메인
export const LECTURE_PATH = "/lecture"; //  온라인 교육 메인
export const ADMIN_PATH = "/admin"; // 어드민 메인

export const FIND_ID_PASSWORD = "/find-id-pw";
export const RESUME_PATH = "/resume";
export const RESUME_FORM_PATH = "/resume-form";
export const JOBPOSTING_PATH = "/jobposting";
export const JOBPOSTING_FORM_PATH = "/jobposting-form";
export const NOTICE_PATH = "/notice";
export const LOGIN_PATH = "/login";
export const SIGNUP_PATH = "/signup";
export const MYPAGE_PATH = "/mypage";
export const MESSAGE_PATH = "/message";
export const SEARCH_PATH = "/search";
export const POLICY_PATH = "/policy";
export const LANDGING_MASTER_PATH = "/landing";

export enum LECTURE_SUBPATH {
  DUTY = "duty", // 법정의무교육
  DUTY_COMPLETE = "complete", // 법정의무교육
  DENTAL_IN = "dental-in", // 치과인 강좌
  ORAL_HEALTH = "oral-health", // 구강보건 교육
  ETC = "etc", // 기타 교육
  MY_ING = "my-ing", // My - 수강중인강좌
  MY_DONE = "my-done", // My - 이수내역
  NOTICE = "notice", // 공지사항
  FAQ = "faq", // FAQ
}
export enum MYPATH_SUB_PATH {
  RESUME_GROUP = "resume-group", // 이력서 관리
  APPLY = "apply", // 지원내역
  REQUESTED = "requested", // 면접제안(개인회원)
  REQUEST = "request", // 면접제안(병원회원)
  FAVORITE_JOBPOSTING = "favorite-jobposting", // 찜한공고
  FAVORITE_RESUME = "favorite-resume", // 찜한인재
  LECTURE = "lecture", // 온라인교육 관리
  PREVIEW_INCOME = "preview-income", // 실수령액 계산기
  PROFILE = "profile", // 회원정보 관리
  JOBPOSTING = "jobposting", // (병원회워) 공고 현황
  VOLUNTEER = "volunteer", // (병원회워) 지원자 현황
}
export enum NOTICE_SUBPATH {
  EVENT = "event", // 이벤트 게시판
  DRIVE = "drive",
  INTERN = "intern",
}

const AppRoutes = () => (
  <BrowserRouter>
    <Routes>
      <Route index element={<IntroPage />} />
      <Route path="/intern" element={<Intern />} />
      {/* ID/PW 찾기 */}
      <Route path={FIND_ID_PASSWORD}>
        <Route index element={<FindIdPasswordPage />} />
        <Route path={"member/id"} element={<MemberFindIdPage />} />
        <Route path={"member/password"} element={<MemberFindPasswordPage />} />
        <Route path={"clinic/id"} element={<ClinicFindIdPage />} />
        <Route path={"clinic/password"} element={<ClinicFindPasswordPage />} />
      </Route>
      {/* 로그인 */}
      <Route path={LOGIN_PATH} element={<LoginPage />} />
      {/* 회원가입 */}
      <Route path={SIGNUP_PATH} element={<SignupPage />} />
      {/* 약관 */}
      <Route path={`${POLICY_PATH}`} element={<PolicyPage />} />

      {/* 이력서 작성 */}
      <Route path={`${RESUME_FORM_PATH}`} element={<ResumeFormPage />} />
      {/* 공고 작성 */}
      <Route
        path={`${JOBPOSTING_FORM_PATH}`}
        element={<JobPostingFormPage />}
      />

      {/* 메인 */}
      <Route path={MAIN_PATH}>
        <Route index element={<RecruitPage />} />
        <Route path={`:recruitId`} element={<div>채용공고 #상세 </div>} />
      </Route>
      {/* 채용정보 */}
      <Route path={JOBPOSTING_PATH}>
        <Route index element={<JobPostingSearchPage />} />
        <Route path={`:jobPostingId`} element={<JobPostingPage />} />
      </Route>
      {/* 인재정보 */}
      <Route path={RESUME_PATH}>
        <Route index element={<ResumeSearchPage />} />
        <Route path={`:resumeId`} element={<ResumePage />} />
      </Route>
      {/* 공지사항 */}
      <Route path={NOTICE_PATH}>
        <Route index element={<NoticePage />} />
        <Route path={`${NOTICE_SUBPATH.EVENT}`} element={<NoticeEventPage />} />
        <Route path={`${NOTICE_SUBPATH.DRIVE}`} element={<NoticeDrivePage />} />
        <Route
          path={`${NOTICE_SUBPATH.INTERN}`}
          element={<NoticeInternPage />}
        />
        <Route
          path={`${NOTICE_SUBPATH.DRIVE}/:driveId`}
          element={<NoticeDriveDetailPage />}
        />
        <Route path={`:noticeId`} element={<NoticeDetailPage />} />
      </Route>
      {/* 메세지 */}
      <Route path={`${MESSAGE_PATH}`} element={<MessagePage />} />
      {/* 검색 */}
      <Route path={`${SEARCH_PATH}`} element={<SearchPage />} />

      {/* 마이페이지 */}
      {MyPageRoutes}
      {/* 교육 */}
      {EducationRoutes}
      {/* 어드민 */}
      <Route path={`${ADMIN_PATH}`} element={<AdminLayout />}>
        <Route index element={<AdminUserStatPage />} />
        <Route path="dashboard" element={<AdminUserStatPage />} />
        <Route path="user" element={<AdminUserPage />} />
        <Route path="userStat" element={<AdminUserStatPage />} />
        <Route path="jobposting" element={<AdminJobPostingPage />} />
        <Route path="resume" element={<AdminResumePage />} />
        <Route path="education" element={<AdminEducationPage />} />
        <Route
          path="education/dent-in"
          element={<AdminEducationDentInPage />}
        />
        <Route
          path="education/dent-in/:course_id"
          element={<DentInLectureTable />}
        />
        <Route
          path="education/oral-health"
          element={<AdminEducationOralHealthPage />}
        />
        <Route
          path="education/oral-health/:course_id"
          element={<OralHealthLectureTable />}
        />
        <Route path="education/etc" element={<AdminEducationEtcPage />} />
        <Route path="education/etc/:course_id" element={<EtcLectureTable />} />
        <Route path="notice" element={<AdminNoticePage />} />
        <Route path="banner" element={<AdminBannerPage />} />
      </Route>
      <Route path={`${ADMIN_PATH}/login`} element={<AdminLoginPage />} />
      <Route path={`${LANDGING_MASTER_PATH}`} element={<LandingMaster />} />
      {/* 어드민 - 끝 */}

      <Route
        path="*"
        element={
          <main style={{ padding: "1rem" }}>
            <p>There's nothing here!</p>
          </main>
        }
      />
    </Routes>
  </BrowserRouter>
);
export default AppRoutes;

/**
 * 마이페이지
 */
const MyPageRoutes = (
  <Route path={`${MYPAGE_PATH}`} element={<MypageLayout />}>
    <Route path={`${MYPATH_SUB_PATH.RESUME_GROUP}`}>
      <Route index element={<ResumeGroupPage />} />
      <Route path={`:resume_group_id`} element={<ResumeGroupDetailPage />} />
    </Route>

    <Route path={MYPATH_SUB_PATH.APPLY}>
      <Route index element={<ApplyHistoryPage />} />
    </Route>

    <Route path={MYPATH_SUB_PATH.REQUESTED}>
      <Route index element={<MemberOfferHistoryPage />} />
    </Route>

    <Route path={MYPATH_SUB_PATH.REQUEST}>
      <Route index element={<ClinicOfferHistoryPage />} />
    </Route>

    <Route path={MYPATH_SUB_PATH.FAVORITE_JOBPOSTING}>
      <Route index element={<MyWishJobPostingPage />} />
    </Route>

    <Route path={MYPATH_SUB_PATH.FAVORITE_RESUME}>
      <Route index element={<MyWishResumePage />} />
    </Route>

    <Route path={MYPATH_SUB_PATH.LECTURE}>
      <Route index element={<MyLecturePage />} />
    </Route>

    <Route path={MYPATH_SUB_PATH.PREVIEW_INCOME}>
      <Route index element={<PreviewIncomePage />} />
    </Route>

    <Route path={MYPATH_SUB_PATH.JOBPOSTING}>
      <Route index element={<MyJobPostingPage />} />
    </Route>
    <Route path={MYPATH_SUB_PATH.VOLUNTEER}>
      <Route index element={<VolunteerPage />} />
    </Route>

    <Route path={`${MYPATH_SUB_PATH.PROFILE}`} element={<ProfilePage />} />

  </Route>
);

/**
 * 온라인 교육 페이지
 */
const EducationRoutes = (
  <Route path={`${LECTURE_PATH}`} element={<LectureLayout />}>
    <Route index element={<LectureMainPage />} />
    <Route path={LECTURE_SUBPATH.DUTY}>
      <Route index element={<DutyLecturePage />} />

      <Route path={`:courseId`} element={<DutyLecturePlayPage />} />
      <Route path={`:courseId/personnel`} element={<LecturePersonnelPage />} />
    </Route>
    <Route path={LECTURE_SUBPATH.DUTY_COMPLETE} element={<DutyLectureCourseListPage />} />
    <Route path={LECTURE_SUBPATH.DENTAL_IN}>
      <Route index element={<LectureListPage />} />
      <Route path={`:courseId`}>
        <Route index element={<LecturePage />} />
        <Route path={`:lectureId`} element={<LecturePlayPage />} />
      </Route>
    </Route>
    <Route path={LECTURE_SUBPATH.ORAL_HEALTH}>
      <Route index element={<LectureListPage />} />
      <Route path={`:courseId`}>
        <Route index element={<LecturePage />} />
        <Route path={`:lectureId`} element={<LecturePlayPage />} />
      </Route>
    </Route>
    <Route path={LECTURE_SUBPATH.ETC}>
      <Route index element={<LectureListPage />} />
      <Route path={`:courseId`}>
        <Route index element={<LecturePage />} />
        <Route path={`:lectureId`} element={<LecturePlayPage />} />
      </Route>
    </Route>
    <Route
      path={LECTURE_SUBPATH.MY_ING}
      element={<LectureMyPage tab="ing" />}
    />
    <Route
      path={LECTURE_SUBPATH.MY_DONE}
      element={<LectureMyPage tab="done" />}
    />
    <Route path={LECTURE_SUBPATH.NOTICE}>
      <Route index element={<LectureNoticePage />} />
      <Route path={`:noticeId`} element={<LectureNoticeDetailPage />} />
    </Route>
    <Route path={LECTURE_SUBPATH.FAQ}>
      <Route index element={<LectureFaqPage />} />
    </Route>
  </Route>
);
