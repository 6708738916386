import { useEffect, useState } from "react";
import moment from "moment";
import Swiper from "swiper";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { deleteJobPosting } from "api/clinic/rests";
import { useUserContext } from "contexts/UserContext";
import ApplyPopup from "components/Popups/ApplyPopup";
import RecommendJobPosting from "components/JobPosting/RecommendJobPosting";
import useJobPosting from "hooks/jobPosting/useJobPosting";
import useMyJobPostings from "hooks/jobPosting/useMyJobPostings";
import Layout from "layouts";
import { wishJobPosting, cancelWishJobPosting } from "api/member/rests";
import { USER_TYPE } from "utils/enums";
import {
  resourcePath,
  displayWorkExperience,
  displaySalaryInfo,
  dualValue,
  displayWorkExperienceByYear,
} from "utils/formatter";
import { JOBPOSTING_FORM_PATH, JOBPOSTING_PATH, MESSAGE_PATH } from "routes";
import Map from "components/JobPosting/Map";
import Alert from "components/Popups/Alert";

// import NoPicturePng from "assets/img/no-picture.png";

const JobPostingPage = () => {
  const navigate = useNavigate();
  const { isSignIn, userType } = useUserContext();
  const { jobPostings } = useMyJobPostings();
  const { jobPostingId } = useParams();
  const { jobPosting, handler: fetch } = useJobPosting(
    Number(jobPostingId) || -1
  );

  const [deleteAlert, setDeleteAlert] = useState<boolean>(false);
  const [showApplyPopup, setShowApplyPopup] = useState<boolean>(false);

  const handleWish = async () => {
    if (jobPosting) {
      try {
        jobPosting.wish_flag
          ? await cancelWishJobPosting(jobPosting.job_id)
          : await wishJobPosting(jobPosting.job_id);
        await fetch();
      } catch (e: any) {
        if (/already exist/.test(e.response.data.data)) {
          alert("이미 찜한 공고입니다.");
        } else {
          alert("오류가 발생하였습니다.");
        }
      }
    }
  };

  const handleDelete = () => {
    if (jobPosting) {
      setDeleteAlert(true);
    }
  };

  useEffect(() => {
    new Swiper(".resume-img-list-box .swiper-container", {
      slidesPerView: 3,
      spaceBetween: 6,

      loop: true,

      breakpoints: {
        1024: {
          slidesPerView: 4.7,
          spaceBetween: 20,
        },
      },
    });
  }, []);

  if (!jobPosting) return null;

  return (
    <Layout>
      <section className="resume-view">
        <div className="inner wrap">
          <div className="resume-view-layout">
            <div className="main-layout-box">
              <div className="resume-tit-box">
                <div
                  className="tit-txt"
                  style={{ cursor: "pointer" }}
                  onClick={() => {}}
                >
                  {`${jobPosting?.intern_flag ? "[실습치과] " : ""}${
                    jobPosting?.title
                  }`}
                </div>
                {isSignIn && userType === USER_TYPE.개인회원 && (
                  <article className="mobile-floating-menu">
                    <div className="menu-wrap">
                      <div className="menu-box">
                        <a
                          className={
                            jobPosting?.wish_flag ? "like-btn on" : "like-btn"
                          }
                          onClick={handleWish}
                        >
                          <span className="txt">{jobPosting?.wish_count}</span>
                          <i className="ic ic-star"></i>
                        </a>
                      </div>
                      <div className="menu-box">
                        <a
                          href={`${MESSAGE_PATH}?target_id=${jobPosting?.job_id}&memberId=${jobPosting?.member_id}&clinicName=${jobPosting?.clinic_name}`}
                          className="message-btn"
                        >
                          <span className="txt">메세지</span>
                          <i className="ic ic-message"></i>
                        </a>
                      </div>
                      <div className="menu-box">
                        <div className="day-box">
                          D-
                          {moment(jobPosting?.closing_date).diff(
                            moment(),
                            "days"
                          )}
                        </div>

                        <a
                          className="apply-btn"
                          onClick={() => setShowApplyPopup(true)}
                          role="button"
                        >
                          지원하기
                        </a>
                      </div>
                    </div>
                  </article>
                )}
                {isSignIn && (
                  <div className="option-box">
                    <div
                      className={`like-btn ${
                        jobPosting?.wish_flag ? "on" : ""
                      }`}
                      onClick={handleWish}
                    >
                      <span className="txt">{jobPosting?.wish_count}</span>
                      <i className="ic ic-like"></i>
                    </div>

                    {userType === USER_TYPE.개인회원 && (
                      <a
                        href={`${MESSAGE_PATH}?target_id=${jobPosting?.job_id}&memberId=${jobPosting?.member_id}&clinicName=${jobPosting?.clinic_name}`}
                        className="option-btn type-3"
                      >
                        <span className="txt">메세지</span>
                        <i className="ic ic-message"></i>
                      </a>
                    )}

                    {userType === USER_TYPE.개인회원 ? (
                      <div
                        className="option-btn type-2"
                        onClick={() => setShowApplyPopup(true)}
                      >
                        <span className="txt">지원하기</span>
                        <span className="dday-txt">
                          D-
                          {moment(jobPosting?.closing_date).diff(
                            moment(),
                            "days"
                          )}
                        </span>
                      </div>
                    ) : (
                      jobPostings
                        .map((jp) => jp.job_id)
                        .includes(jobPosting?.job_id || 0) && (
                        <div className="option-box">
                          <div
                            className="option-btn type-4"
                            onClick={handleDelete}
                          >
                            <span className="txt">삭제하기</span>
                          </div>
                          <a
                            href={`${JOBPOSTING_FORM_PATH}?job_id=${
                              jobPosting?.job_id
                            }&mode=edit${
                              jobPosting?.intern_flag ? "&isIntern=on" : ""
                            }`}
                            className="option-btn type-4"
                          >
                            <span className="txt">수정하기</span>
                          </a>
                        </div>
                      )
                    )}
                  </div>
                )}
              </div>

              <div className="resume-profile-box">
                <div className="profile-img-box type-full">
                  {jobPosting?.logo_image_filename === "" ? (
                    <div className="no-picture">
                      <div className="name">{jobPosting.clinic_name}</div>
                    </div>
                  ) : (
                    <div className="img-box type-square">
                      <img
                        src={resourcePath(
                          jobPosting?.logo_image_filename || ""
                        )}
                        alt="이미지"
                      />
                    </div>
                  )}
                </div>

                <div className="resume-img-list-box mobile">
                  <div className="list-wrap">
                    {jobPosting?.image_filename_list.map((image, i) => (
                      <div className="img-box" key={i}>
                        <img src={resourcePath(image || "")} alt="이미지" />
                      </div>
                    ))}
                  </div>
                </div>

                <div className="profile-info-box">
                  <div className="row">
                    <div className="subject">병원명</div>
                    <div className="con">{jobPosting?.clinic_name}</div>
                  </div>

                  <div className="row">
                    <div className="subject">주소</div>
                    <div className="con">{jobPosting?.address}</div>
                  </div>

                  <div className="row">
                    <div className="subject">상세주소</div>
                    <div className="con">{jobPosting?.extra_address}</div>
                  </div>

                  <div className="row">
                    <div className="subject">홈페이지</div>
                    <div className="con">{jobPosting?.homepage_url}</div>
                  </div>

                  <div className="row">
                    <div className="subject">대표번호</div>
                    <div className="con">{jobPosting?.phone_number}</div>
                  </div>
                </div>
              </div>

              <div className="resume-img-list-box web">
                <div className="list-wrap">
                  {jobPosting?.image_filename_list.map((image, i) => (
                    <div className="img-box" key={i}>
                      <img src={resourcePath(image || "")} alt="이미지" />
                    </div>
                  ))}
                </div>
              </div>

              <div className="resume-data-box">
                <div className="resume-data-group">
                  <div className="data-list-box">
                    <div className="row">
                      <div className="subject">구성원 현황</div>
                      <div className="con">{jobPosting?.employee_cnt} 명</div>
                    </div>
                    <div className="row">
                      <div className="subject">모집 인원</div>
                      <div className="con">{jobPosting?.hiring_count} 명</div>
                    </div>
                    <div className="row">
                      <div className="subject">
                        <span className="web">병/의원</span> 요약소개
                      </div>
                      <div
                        className="con"
                        dangerouslySetInnerHTML={{
                          __html: jobPosting?.introduction.replaceAll(
                            "\n",
                            "<br />"
                          ),
                        }}
                      ></div>
                    </div>
                    <div className="row">
                      <div className="subject">모집직종</div>
                      <div className="con">
                        {(jobPosting?.occupationList || []).join(", ")}
                      </div>
                    </div>
                    <div className="row">
                      <div className="subject">담당업무</div>
                      <div className="con">
                        {(jobPosting?.work_list || []).join(", ")}
                      </div>
                    </div>
                    <div className="row">
                      <div className="subject">고용형태</div>
                      <div className="con">
                        {(jobPosting?.work_type_list || []).join(", ")}
                      </div>
                    </div>
                    <div className="row">
                      <div className="subject">경력조건</div>
                      <div className="con">
                        {!!jobPosting &&
                          displayWorkExperienceByYear(
                            jobPosting.min_work_experience,
                            jobPosting.max_work_experience
                          )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="subject">학력조건</div>
                      <div className="con">
                        {jobPosting?.last_education_type}
                      </div>
                    </div>
                    <div className="row">
                      <div className="subject">급여조건</div>
                      <div className="con">
                        {jobPosting?.salary_info &&
                        jobPosting.salary_info.salary
                          ? displaySalaryInfo(jobPosting.salary_info)
                          : "면접시 협의"}
                      </div>
                    </div>

                    <div className="row">
                      <div className="subject">근무요일</div>
                      <div className="con">
                        {jobPosting?.work_day_type.includes("일")
                          ? `주 ${jobPosting?.work_day_type}`
                          : jobPosting?.work_day_type}
                      </div>
                    </div>
                    <div className="row">
                      <div className="subject">근무시간</div>
                      <div className="con">
                        {jobPosting?.work_time.split("\n").map((str) => (
                          <p>{str}</p>
                        ))}
                      </div>
                    </div>
                    <div className="row">
                      <div className="subject">복리후생</div>
                      <div className="con">
                        {(jobPosting?.benefit_list || []).join(", ")}
                      </div>
                    </div>
                    <div className="row">
                      <div className="subject">제출서류</div>
                      <div className="con">
                        {(jobPosting?.submission_list || []).join(", ")}
                      </div>
                    </div>
                    <div className="row">
                      <div className="subject">제출방법</div>
                      <div className="con">
                        {jobPosting?.submission_method_list.join(", ")}
                      </div>
                    </div>
                    <div className="row">
                      <div className="subject">전형방법</div>
                      <div className="con">
                        {jobPosting?.screening_list
                          .map((sl) => sl.screening)
                          .join(" - ")}
                      </div>
                    </div>

                    <div className="row">
                      <div className="subject">마감일</div>
                      <div className="con">{jobPosting?.closing_date}</div>
                    </div>

                    <div className="row">
                      <div className="subject">담당자 정보</div>
                      <div className="con">
                        {isSignIn
                          ? dualValue(
                              jobPosting?.contact_email,
                              jobPosting?.contact_phone_number
                            )
                          : "***"}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="resume-data-group">
                  <div className="data-tit-box">상세 채용정보</div>

                  {/* <div className="data-txt-box">{jobPosting?.details}</div> */}
                  {jobPosting?.details && (
                    <div
                      className="data-txt-box"
                      dangerouslySetInnerHTML={{
                        __html: jobPosting?.details.replaceAll("\n", "<br/>"),
                      }}
                    ></div>
                  )}
                </div>

                <div className="resume-data-group">
                  <div className="data-tit-box">지도로 보기</div>
                  <Map
                    latitude={jobPosting?.latitude}
                    longitude={jobPosting?.longitude}
                  />
                </div>
              </div>
            </div>
            <div className="sub-layout-box">
              <RecommendJobPosting userType={userType}/>
            </div>
          </div>
        </div>

        {jobPosting && showApplyPopup && (
          <ApplyPopup
            jobId={jobPosting?.job_id}
            handleClose={() => setShowApplyPopup(false)}
          />
        )}

        {deleteAlert && (
          <Alert
            text="삭제하시겠습니까?"
            yesEvent={async () => {
              try {
                await deleteJobPosting(jobPosting?.job_id);
              } finally {
                setDeleteAlert(false);
                navigate(`${JOBPOSTING_PATH}`);
              }
            }}
            noEvent={() => {
              setDeleteAlert(false);
            }}
          />
        )}
      </section>
    </Layout>
  );
};

export default JobPostingPage;
