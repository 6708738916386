import { GROUP_CODE_ID } from "api/code/types";
import { IResumeEditRequest } from "api/member/types";
import { CheckboxGroupFieldRow } from "components/FormFields";
import { SalaryFieldRow, Title } from "components/ResumeForm/Comps";
import useMember from "hooks/member/useMember";

type TProps = Pick<
  IResumeEditRequest,
  "title" | "occupation_list" | "work_list" | "work_type_list" | "salary_info"
> & {
  onChangeValue: (
    key:
      | "title"
      | "occupation_list"
      | "work_list"
      | "work_type_list"
      | "salary_info",
    newValue: number | string | Array<string> | object
  ) => void;
};

const JobSection = ({
  title,
  occupation_list,
  work_list,
  work_type_list,
  salary_info,
  onChangeValue,
}: TProps) => {
  const member = useMember();
  return (
    <div className="form-group">
      <Title
        value={title}
        onChange={(title) => onChangeValue("title", title)}
      />
      {member && (
        <CheckboxGroupFieldRow
          label="직종선택*"
          selected={occupation_list}
          groupCodeId={
            member?.occupation === "치과의사"
              ? GROUP_CODE_ID.회원직종_치과의사
              : GROUP_CODE_ID.이력서직종
          }
          excludedLabel={member?.occupation !== "치과의사" ? ["치과의사"] : []}
          onChange={(value) => onChangeValue("occupation_list", value)}
        />
      )}
      <CheckboxGroupFieldRow
        label="희망업무*"
        selected={work_list}
        groupCodeId={GROUP_CODE_ID.이력서업무}
        onChange={(value) => onChangeValue("work_list", value)}
      />
      <CheckboxGroupFieldRow
        label={
          <>
            <span className="web">희망 </span>근무 형태*
          </>
        }
        selected={work_type_list}
        groupCodeId={GROUP_CODE_ID.근무형태}
        onChange={(value) => onChangeValue("work_type_list", value)}
      />

      <SalaryFieldRow
        salaryInfo={salary_info}
        onChange={(value) => onChangeValue("salary_info", value)}
      />
      <div className="notice">
        ※ 희망근무지역은 마이페이지에서 편집하실 수 있습니다.
      </div>
    </div>
  );
};

export default JobSection;
