import { useUserContext } from "contexts/UserContext";
import { MYPAGE_PATH, MYPATH_SUB_PATH, RESUME_FORM_PATH, JOBPOSTING_FORM_PATH } from "routes";
import Actions from "./Actions";
import Logins from "./Logins";
import Notice from "./Notice";

import { USER_TYPE } from "utils/enums";
import { fetchJobPostingCount } from 'api/clinic/rests';
import { useNavigate } from "react-router-dom";

const InfoBox = ({ name, userType }: { name: string; userType: USER_TYPE }) => {
  const navigate = useNavigate();
  const linkJobPostingForm = async () => {
    try {
      const {
        data: { current_count, max_count },
      } = await fetchJobPostingCount(false);
      if (current_count >= max_count) {
        alert(`노출 가능한 공고는 최대 ${max_count}개입니다.`);
        return;
      } else {
        navigate(JOBPOSTING_FORM_PATH);
      }
    } catch (e) {}
  };
  return (
    <div className="login-info-box">
      <div className="info-user-box">
        <i
          className={`ic ${
            userType === USER_TYPE.개인회원 ? "ic-type1" : "ic-type2"
          }`}
        ></i>
        <div className="user-type-box">
          {userType === USER_TYPE.개인회원 ? "개인" : "병원"}회원
        </div>
        <div className="user-name-box">{name}</div>
      </div>
      <div className="info-my-box">
        <a
          href={`${MYPAGE_PATH}/${
            userType === USER_TYPE.개인회원
              ? MYPATH_SUB_PATH.RESUME_GROUP
              : MYPATH_SUB_PATH.JOBPOSTING
          }`}
          className="my-btn"
        >
          <i className="ic ic-my"></i>
          <span className="txt">마이 페이지</span>
        </a>
        {userType === USER_TYPE.개인회원 &&
        <a
          href={`${RESUME_FORM_PATH}`}
          className="my-btn-2"
        >
          <i className="ic ic-my-w"></i>
          <span className="txt">
        이력서 작성</span>
        </a>
        }
        {userType === USER_TYPE.병원회원 &&
        <a
          className="my-btn-2"
          onClick={linkJobPostingForm}
          role="button"
        >
          <i className="ic ic-my-w"></i>
          <span className="txt">공고 작성</span>
        </a>
        }
      </div>
    </div>
  );
}

const MyBoard = () => {
  const { isSignIn, userType, name } = useUserContext();

  return (
    <div className="my-form" style={{ width: "326px" }}>
      {isSignIn ? <InfoBox name={name} userType={userType} /> : <Logins />}
      <Actions isSignIn={isSignIn} />
      <Notice />
    </div>
  );
};

export default MyBoard;
