import { useCallback, useEffect, useState } from "react";

import { fetchJobPosting } from "api/jobPosting/rests";
import { IJobOpeningView } from "api/jobPosting/types";
import { useNavigate } from "react-router-dom";

const useJobPosting = (jobId: number) => {
  const [jobPosting, setJobPosting] = useState<IJobOpeningView | null>(null);
  const navigate = useNavigate();
  const fetch = useCallback(async () => {
    if (jobId <= 0) return;

    try {
      const { data } = await fetchJobPosting(jobId);
      setJobPosting(data);
    } catch (e: any) {
      setJobPosting(null);
      window.alert(e.response.data.data);
      navigate(-1);
    }
  }, [jobId]);

  useEffect(() => {
    (async () => fetch())();
  }, [fetch]);

  return { jobPosting, handler: fetch };
};

export default useJobPosting;
