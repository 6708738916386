import { useState } from "react";
import { useLocation } from "react-router-dom";
import { LECTURE_PATH, LECTURE_SUBPATH } from "routes";

// import { fetchBookmarks as fetchClinicBookmarks } from "api/clinic/rests";
// import { fetchBookmarks as fetchMemberBookmarks } from "api/member/rests";
// import { useUserContext } from "contexts/UserContext";
import { useBookmarkContext } from "contexts/BookmarkContext";
import { useUserContext } from "contexts/UserContext";
// import { ILectureCourseBookmarkView } from "api/education/types";
// import { USER_TYPE } from "utils/enums";
import { lectureBaseurl } from "utils/formatter";
import { USER_TYPE } from "utils/enums";

const Menu = ({
  active = false,
  label,
  children,
}: {
  active?: boolean;
  label: string;
  children: any;
}) => {
  const [isOpened, setIsOpened] = useState<boolean>(active || false);

  return (
    <li className="depth1-box">
      <div
        className={`depth1-btn ${isOpened ? "on" : ""} ${
          active ? "active" : ""
        }`}
        onClick={() => setIsOpened(!isOpened)}
      >
        {label}
      </div>
      <ul className="depth2">{children}</ul>
    </li>
  );
};
const Submenu = ({ label, subpath }: { label: string; subpath: string }) => {
  const { pathname } = useLocation();
  return (
    <li>
      <a
        href={`${LECTURE_PATH}/${subpath}`}
        className={`depth2-btn ${pathname.includes(subpath) ? "on" : ""}`}
      >
        {label}
      </a>
    </li>
  );
};

const SideMenu = () => {
  const { isSignIn, userType } = useUserContext();
  const { bookmarks } = useBookmarkContext();
  // const { userType } = useUserContext();
  const { pathname } = useLocation();
  // const [bookmarks, setBookmarks] = useState<ILectureCourseBookmarkView[]>([]);

  // useEffect(() => {
  //   (async () => {
  //     const { data } =
  //       userType === USER_TYPE.개인회원
  //         ? await fetchMemberBookmarks()
  //         : await fetchClinicBookmarks();
  //     setBookmarks(data);
  //   })();
  // }, []);

  return (
    <article className="lnb-edu-nav">
      <div className="lnb-wrap">
        <div className="edu-menu-list">
          <ul className="depth1">
            <Menu
              active={/etc|dental-in|oral-health/.test(pathname)}
              label="홈"
            >
              <Submenu
                label="치과인 강좌"
                subpath={LECTURE_SUBPATH.DENTAL_IN}
              />
              <Submenu
                label="구강보건교육"
                subpath={LECTURE_SUBPATH.ORAL_HEALTH}
              />
              <Submenu label="기타교육" subpath={LECTURE_SUBPATH.ETC} />
            </Menu>
            {userType === USER_TYPE.병원회원 &&
              <Menu
                  active={/duty|complete/.test(pathname)}
                  label="법정의무교육">
                  <Submenu
                      label="교육목록"
                      subpath={LECTURE_SUBPATH.DUTY}
                  />
                  <Submenu
                      label="수강내역"
                      subpath={LECTURE_SUBPATH.DUTY_COMPLETE}
                  />
              </Menu>
            }
            {isSignIn && (
              <>
                <Menu active={/my-ing|my-done/.test(pathname)} label="MY">
                  <Submenu
                    label="수강중인강좌"
                    subpath={LECTURE_SUBPATH.MY_ING}
                  />
                  <Submenu label="이수내역" subpath={LECTURE_SUBPATH.MY_DONE} />
                </Menu>

                <Menu label="즐겨찾기">
                  {bookmarks.map((bookmark) => (
                    <li key={bookmark.course_id}>
                      <a
                        href={`${lectureBaseurl(bookmark.lecture_type)}/${
                          bookmark.course_id
                        }`}
                        className="depth2-btn"
                      >
                        {bookmark.title}
                      </a>
                    </li>
                  ))}
                </Menu>
              </>
            )}

            <Menu active={/notice|faq/.test(pathname)} label="더보기">
              <Submenu label="공지사항" subpath={LECTURE_SUBPATH.NOTICE} />
              <Submenu label="FAQ" subpath={LECTURE_SUBPATH.FAQ} />
            </Menu>
          </ul>
        </div>
      </div>
    </article>
  );
};

export default SideMenu;
