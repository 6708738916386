import { useState, ChangeEvent, useMemo } from "react";
import { IWorkArea, TArea } from "hooks/useWorkArea";
import { Area } from "./JobPosting/Comps";

const WorkAreaSelector = ({
  value,
  workArea,
  selectedId,
  onSelect,
}: {
  value?: TArea | null;
  workArea: IWorkArea[];
  selectedId?: string | null;
  onSelect: (id: string) => void;
}) => {
  const [selectedCity, setSelectedCity] = useState<string>("null");

  // const selectedWorkArea: IWorkArea = useMemo(
  //   () =>
  //     workArea.find((area) => area.city === selectedCity) || {
  //       city: "",
  //       areas: [],
  //     },
  //   [workArea, selectedCity]
  // );

  const selectedWorkAreas = workArea.find(
    (item) => item.city === (selectedCity || value?.city)
  )?.areas;

  return (
    <>
      <div className="sbox">
        <select
          value={selectedCity}
          onChange={(e: ChangeEvent<HTMLSelectElement>) => {
            setSelectedCity(e.target.value);
            const selectedArea = workArea.find(
              (area) => area.city === e.target.value
            );
            selectedArea?.areas[0]?.id && onSelect(selectedArea.areas[0].id);
          }}
        >
          <option key="area_select" value="null" disabled>
            지역 선택
          </option>
          {workArea.map((area) => (
            <option key={area.city} value={area.city}>
              {area.city}
            </option>
          ))}
        </select>
      </div>

      <div className="sbox">
        <select
          value={
            selectedId ||
            selectedWorkAreas?.find((item) => item.district === value?.district)
              ?.id
          }
          onChange={(e: ChangeEvent<HTMLSelectElement>) =>
            onSelect(e.target.value)
          }
        >
          {selectedWorkAreas &&
            selectedWorkAreas.map((area) => (
              <option key={area.id} value={area.id}>
                {area.district}
              </option>
            ))}
        </select>
      </div>
    </>
  );
};

export default WorkAreaSelector;
