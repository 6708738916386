import axios, { AxiosError } from "axios";
import qs from "qs";

import { refreshToken as memberRefershToken } from "./member/rests";
import { refreshToken as clinicRefreshToken } from "./clinic/rests";
import { getAuthToken, getAuth } from "utils/storage";
import { USER_TYPE } from "utils/enums";
import { loadFCM } from "utils/appBridge";

export const doctorLicenseClient = axios.create({
  baseURL: "https://www.kda.or.kr/",
});

// const endpoint = "http://localhost:3000/";

const handleSessionExpired = (error: AxiosError) => {
  /** TODO : 403 에러 시 refresh-token 처리 */
  if (error.response?.status === 403) {
    console.debug("session expired. refresh token!");
    const { user_type } = getAuth();

    if (user_type) {
      (user_type === USER_TYPE.병원회원
        ? clinicRefreshToken()
        : memberRefershToken()
      ).then(() => loadFCM());
    }

    return Promise.reject(new Error("SessionExpiredError", error));
  }
  return Promise.reject(error);
};

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_ENDPOINT,
  headers: {
    Authorization: `Bearer ${getAuthToken() || ""}`,
  },
  paramsSerializer: (params) => qs.stringify(params),
  withCredentials: true,
});
(window as any).axiosClient = axiosClient;

axiosClient.interceptors.response.use(undefined, handleSessionExpired);

export default axiosClient;
