import useEditMode from "hooks/useEditMode";
import { IWorkArea, TArea } from "hooks/useWorkArea";

import WorkAreaSelector from "components/WorkAreaSelector";
import { useEffect, useState } from "react";

const WorkAreaFieldRow = ({
  label,
  workAreas,
  value,
  onSelect,
  onConfirm,
}: {
  label: string;
  workAreas: IWorkArea[];
  value: TArea | null;
  onSelect: (id: string) => void;
  onConfirm: () => void;
}) => {
  const {
    isEditOn,
    handler: { onEditMode, offEditMode },
  } = useEditMode();

  const [selectedId, setSelectedId] = useState<string>("");
  const [clickSubmit, setClickSubmit] = useState<boolean>(false);

  useEffect(() => {
    if (!clickSubmit) return;
    onConfirm();
    setClickSubmit(false);
  }, [selectedId, clickSubmit]);

  return (
    <div className="row">
      <div className="subject-box" style={{width: "50px"}}>{label}</div>

      <div className="con-box work-area">
        {!isEditOn ? (
          <div className="view-box">
            {value && (
              <div className="view-txt">
                <span className="depth1-txt">{value?.city}</span>
                <span className="unit">&gt;</span>
                <span className="depth2-txt">{value?.district}</span>
              </div>
            )}

            <div className="menu-box">
              <button
                className="menu-btn type-2"
                style={{ backgroundColor: "#fff" }}
                onClick={onEditMode}
              >
                수정
              </button>
            </div>
          </div>
        ) : (
          <div className="edit-box" style={{ display: "block" }}>
            <div className="edit-input-box">
              <WorkAreaSelector
                value={value}
                workArea={workAreas}
                selectedId={selectedId}
                onSelect={setSelectedId}
              />
            </div>

            <div className="menu-box work-area">
              <button
                className="menu-btn type-1"
                style={{ backgroundColor: "#fff" }}
                onClick={() => {
                  setClickSubmit(true);
                  selectedId
                    ? onSelect(selectedId)
                    : onSelect(workAreas[0].areas[0].id || "");
                  offEditMode();
                }}
              >
                확인
              </button>
              <button
                className="menu-btn type-2"
                style={{ backgroundColor: "#fff" }}
                onClick={() => {
                  setSelectedId("");
                  offEditMode();
                }}
              >
                취소
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default WorkAreaFieldRow;
