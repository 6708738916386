import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AlarmSVG from "assets/img/img_alarm_item.svg";

import { IJobActionResumeView } from "api/clinic/types";
import { fetchJobPostingHistory, readApplyResume } from "api/clinic/rests";
import {
  fetchJobPostingHistory as fetchMemberJobPostingHistory,
  readRequestedJobPosting,
} from "api/member/rests";
import { useUserContext } from "contexts/UserContext";
import { USER_TYPE } from "utils/enums";
import { MYPAGE_PATH, MYPATH_SUB_PATH } from "routes";

const AlarmBox = () => {
  const navigate = useNavigate();
  const { userType } = useUserContext();
  const [showAlarm, setShowAlarm] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [alarms, setAlarms] = useState<IJobActionResumeView[]>([]);

  const isMember = userType === USER_TYPE.개인회원;

  const handleClick = async (job_id: number, resume_id: number) => {
    if (isMember) {
      await readRequestedJobPosting(job_id, resume_id);
      navigate(`${MYPAGE_PATH}/${MYPATH_SUB_PATH.REQUESTED}`);
    } else {
      await readApplyResume(job_id, resume_id);
      navigate(`${MYPAGE_PATH}/${MYPATH_SUB_PATH.VOLUNTEER}`);
    }
  };

  const fetch = useCallback(async () => {
    const {
      data: { total_page, element_list },
    } = isMember
      ? await fetchMemberJobPostingHistory("OFFER", page, 5)
      : await fetchJobPostingHistory("APPLY", page, 5);
    setAlarms([...alarms, ...(element_list as IJobActionResumeView[])]);
    setTotalPage(total_page);
    // setTotalElements(total_elements);
  }, [page]);

  useEffect(() => {
    (async () => await fetch())();
  }, [fetch]);

  return (
    <div className="menu-box">
      <button
        className={`alarm-btn ${
          alarms.filter((a) => !a.read_flag).length > 0 ? "on" : ""
        }`}
        onClick={async () => {
          await fetch();
          setShowAlarm(!showAlarm);
        }}
      />

      <div
        className="alarm-form-box"
        style={{ display: showAlarm ? "block" : "" }}
      >
        <div className="list-box">
          <ul>
          {alarms.map((alarm) => (
              <li key={alarm.job_id}>
                <div
                  className={alarm.read_flag ? "off box" : "box"}
                  onClick={() => handleClick(alarm.job_id, alarm.resume_id)}
                >
                  <div className="img-box">
                    <img src={AlarmSVG} alt="이미지" />
                  </div>
                  <div className="info-box">
                    <div className="txt-box">
                      ‘{alarm.title}’{" "}
                      {isMember
                        ? "공고 면접제안이 도착했어요!"
                        : "이력서로 지원제안이 도착했어요!"}
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>

          {(alarms.length === 0 || page < totalPage) && (
            <div className="list-more-box">
              <div
                className="list-more-btn"
                onClick={() => setPage(page + 1)}
              >
                도착한 알림이 없습니다.
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AlarmBox;
