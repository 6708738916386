import { ReactNode } from "react";

const HeadBox = ({
  count,
  actionComp,
  filterComp,
  countUnit = "건",
}: {
  count: number;
  countUnit?: string;
  actionComp?: ReactNode;
  filterComp?: ReactNode;
}) => (
  <article className="my-group-head-box">
    <div className="group-head-box">
      <div className="left-box">
        <div className="cnt-box">
          총 {count}
          {countUnit}
        </div>
        <div className="filter-box">{filterComp}</div>
      </div>
      <div className="right-box">{actionComp}</div>
    </div>
  </article>
);

export default HeadBox;
