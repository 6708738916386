import { IJobOpeningOverview } from "api/jobPosting/types";
import { useUserContext } from "contexts/UserContext";
import { USER_TYPE } from "utils/enums";
import { resourcePath } from "utils/formatter";

const RecruitItem = ({
  hospital,
  subject,
  logo,
  remainedDays,
  url,
  favorite,
  onClickFavorite,
  item,
}: {
  hospital: string;
  subject: string;
  logo: string;
  remainedDays: number;
  url: string;
  favorite: boolean;
  onClickFavorite: () => void;
  item: IJobOpeningOverview;
}) => {
  const { isSignIn, userType, occupation } = useUserContext();

  const isOpeningAvailable =
    !item.occupation_list.includes("치과의사") ||
    (isSignIn &&
      (userType === USER_TYPE.병원회원 ||
        (userType === USER_TYPE.개인회원 && occupation === "치과의사")));

  return (
    <li>
      <div className="item-wrap">
        <a
          href={isOpeningAvailable ? url : ""}
          className="item-box"
          onClick={(e) => {
            if (!isOpeningAvailable) {
              e.preventDefault();
              window.alert("치과의사 공고는 치과의사만 접근할 수 있습니다");
            }
          }}
        >
          {logo === "" ? (
            <div className="no-picture">
              <div className="name">{hospital}</div>
            </div>
          ) : (
            <div className="img-box">
              <img src={resourcePath(logo)} alt="이미지" />
            </div>
          )}
          <div className="info-box">
            <div className="top-box">
              <div className="subject-box">{hospital}</div>
              <div className="con-box">{subject}</div>
            </div>
            <div className="bottom-box">
              {remainedDays < 100 && (
                <div className="day-box">
                  <i className="ic ic-day"></i>
                  <span className="txt">D-{remainedDays}</span>
                </div>
              )}
              {isSignIn && userType === USER_TYPE.개인회원 && (
                <div className="like-box">
                  <article className="like-toggle-btn">
                    <button
                      type="button"
                      className={favorite ? "on" : ""}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onClickFavorite();
                      }}
                    ></button>
                  </article>
                </div>
              )}
            </div>
          </div>
        </a>
      </div>
    </li>
  );
};

export default RecruitItem;
