import React, { useContext, useState } from "react";
import { SignupContext } from "contexts/SignupContex";

import InputFieldRow from "./Comps/InputFieldRow";
import { checkMemberExistById } from "api/member/rests";
import { checkClinicMemberById } from "api/clinic/rests";
const EventFollowIdBox = () => {
  const context = useContext(SignupContext);
  const {
    event_follow_id,
    event_follow_id_clinic,
    setEventFollowId,
    setEventFollowIdClinic,
  } = context;

  const changeEventFollowId = (id: string) => {
    checkMemberExistById(id)
      .then(() => setFollowIdCheck(true))
      .catch(() => setFollowIdCheck(false));
    setEventFollowId(id);
  };

  const changeEventFollowIdClinic = (id: string) => {
    checkClinicMemberById(id)
      .then(() => setFollowIdClinicCheck(true))
      .catch(() => setFollowIdClinicCheck(false));
    setEventFollowIdClinic(id);
  };

  const [followIdCheck, setFollowIdCheck] = useState<boolean>(true);
  const [followIdClinicCheck, setFollowIdClinicCheck] = useState<boolean>(true);

  return (
    <div className="form-box" style={{ display: "block" }}>
      <div className="form-tit-box">추천인 ID</div>
      <div className="form-con-box">
        <InputFieldRow
          errorMessage={
            event_follow_id && !followIdCheck
              ? "확인되지 않은 개인회원 ID 입니다"
              : ""
          }
          placeholder="추천인 ID (치과인 ID 입력)"
          value={event_follow_id}
          onChange={changeEventFollowId}
        />

        <InputFieldRow
          errorMessage={
            event_follow_id_clinic && !followIdClinicCheck
              ? "확인되지 않은 병원회원 ID 입니다"
              : ""
          }
          placeholder="추천인 병원 ID (치과인 병원 ID 입력)"
          value={event_follow_id_clinic}
          onChange={changeEventFollowIdClinic}
        />
      </div>
    </div>
  );
};

export default EventFollowIdBox;
