import AdminResumeTable from "components/Admin/AdminResumeTable";
import Breadcrumb from "react-bootstrap/Breadcrumb";

const AdminResumePage = () => (
  <>
    <Breadcrumb>
      <Breadcrumb.Item>이력서관리</Breadcrumb.Item>
      <Breadcrumb.Item>리스트</Breadcrumb.Item>
    </Breadcrumb>

    <AdminResumeTable />

  </>
);
export default AdminResumePage;
