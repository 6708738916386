import { useCallback, useEffect, useState } from "react";
import {
  Button,
  Dropdown,
  DropdownButton,
  Form,
  InputGroup,
  Table,
  Pagination,
} from "react-bootstrap";

import {
  fetchClinicMembers,
  activeClinicMember,
  searchClinicMember,
} from "api/admin/rests";
import { IAdminClinicMemberView } from "api/admin/types";

import { displayDateTime } from "utils/formatter";

import ClinicMember from "./ClinicMember";

const QUERY_TYPE = [
  { label: "회원ID", value: "member_id" },
  { label: "병원명", value: "clinic_name" },
  { label: "사업자등록번호", value: "biz_reg_number" },
];
const ClinicMemberTable = () => {
  const [page, setPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [queryType, setQueryType] = useState<{ label: string; value: string }>(
    QUERY_TYPE[0]
  );
  const [query, setQuery] = useState<string>("");

  const [members, setMembers] = useState<IAdminClinicMemberView[]>([]);
  const [selectedMemberId, setSelectedMemberId] = useState<string>("");

  const toggleMemberActive = async (memberId: string, activeFlag: boolean) => {
    if (
      window.confirm(
        `해당 멤버를 ${activeFlag ? "재개" : "중지"} 하시겠습니까?`
      )
    ) {
      await activeClinicMember(memberId, activeFlag);
      getClinicMembers();
    }
  };

  const getClinicMembers = useCallback(async () => {
    if (query) {
      const {
        data: { element_list, total_page },
      } = await searchClinicMember(page, {
        [queryType.value]: query,
        sort_property: "MEMBER_ID",
        sort_direction: "ASC",
      });
      setMembers(element_list);
      setTotalPage(total_page);
    } else {
      const {
        data: { element_list, total_page },
      } = await fetchClinicMembers(page);

      setMembers(element_list);
      setTotalPage(total_page);
    }
  }, [page, query]);

  // const search = useCallback(async () => {
  //   if (!query) return;

  //   const {
  //     data: { element_list, total_page },
  //   } = await searchClinicMember(page, {
  //     [queryType.value]: query,
  //     sort_property: "MEMBER_ID",
  //     sort_direction: "ASC",
  //   });
  //   setMembers(element_list);
  //   setTotalPage(total_page);
  // }, [query, page]);

  useEffect(() => {
    (async () => {
      getClinicMembers();
    })();
  }, [getClinicMembers]);

  return (
    <>
      <div>
        <InputGroup className="mb-3">
          <DropdownButton variant="outline-secondary" title={queryType.label}>
            {QUERY_TYPE.map((qType, i) => (
              <Dropdown.Item key={i} onClick={() => setQueryType(qType)}>
                {qType.label}
              </Dropdown.Item>
            ))}
          </DropdownButton>
          <Form.Control
            placeholder={`${queryType.label}를 입력하세요.`}
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
          {/* <Button variant="outline-secondary">검색</Button> */}
        </InputGroup>
      </div>
      <div style={{ minHeight: "750px" }}>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>MemberId</th>
              <th>병원명</th>
              <th>대표명</th>
              <th>대표전화번호</th>
              <th>가입일</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {members.map((member) => (
              <tr key={member.member_id}>
                <td
                  style={{ cursor: "pointer" }}
                  onClick={() => setSelectedMemberId(member.member_id)}
                >
                  {member.member_id}
                  {!member.active_flag && <> (중지된 회원)</>}
                </td>
                <td>{member.clinic_name}</td>
                <td>{member.rep_name}</td>
                <td>{member.rep_phone_number}</td>
                <td>{displayDateTime(member.reg_date)}</td>
                <td style={{ display: "flex", gap: "4px" }}>
                  <Button
                    variant={member.active_flag ? "light" : "primary"}
                    size="sm"
                    onClick={() =>
                      toggleMemberActive(member.member_id, !member.active_flag)
                    }
                  >
                    회원 {member.active_flag ? "중지" : "재개"}
                  </Button>

                  <Button
                    size="sm"
                    variant="outline-secondary"
                    onClick={() => setSelectedMemberId(member.member_id)}
                  >
                    편집
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <Pagination>
          {[...Array(totalPage).keys()].map((pageIdx) => (
            <Pagination.Item
              key={pageIdx + 1}
              active={pageIdx + 1 === page}
              onClick={() => setPage(pageIdx + 1)}
            >
              {pageIdx + 1}
            </Pagination.Item>
          ))}
        </Pagination>
      </div>

      <ClinicMember
        memberId={selectedMemberId}
        onClose={() => {
          setSelectedMemberId("");
          getClinicMembers();
        }}
      />
    </>
  );
};
export default ClinicMemberTable;
