import { ChangeEvent } from "react";
import PasswordCheckImg from "assets/img/ic_password_check.svg";

const CheckPassword = ({
  isConfirmed,
  password,
  onChange,
  onConfirm,
}: {
  isConfirmed?: boolean;
  password: string;
  onChange: (password: string) => void;
  onConfirm: () => void;
}) => {
  return (
    <article className="popup-check">
      <div className="ic-box">
        <img src={PasswordCheckImg} alt="이미지" />
      </div>

      <div className="txt-box">
        <div className="main-box">현재 비밀번호를 입력해주세요</div>
      </div>

      <div className="check-input-box">
        <div className="input-group">
          <div className="input-box">
            <div className="tbox full">
              <input
                type="password"
                value={password}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  onChange(e.target.value)
                }
              />
            </div>
            <button className="check-btn web" onClick={onConfirm}>
              입력
            </button>
          </div>

          {isConfirmed === false && (
            <div className="input-msg-box">비밀번호가 일치하지 않습니다.</div>
          )}
        </div>
      </div>
    </article>
  );
};

export default CheckPassword;
