import { useEffect, useState, useCallback } from "react";

import { fetchJobPostings, fetchJobPostingVolunteers } from "api/clinic/rests";
import {
  IJobActionResumeView,
  IJobOpeningClinicOverview,
} from "api/clinic/types";
import { DueDate } from "components/JobPosting/Comps";
import Paginator from "components/Paginator";
import { JOBPOSTING_FORM_PATH, RESUME_PATH } from "routes";
import {
  isOverdue,
  displayMinMaxMonth,
  resourcePath,
  displaySalaryInfo,
  displayExperienceMonth,
} from "utils/formatter";
import DefaultProfile from "assets/img/no_profile.png";
const ClinicOfferHistoryPage = () => {
  const [page, setPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [histories, setHistories] = useState<IJobOpeningClinicOverview[]>([]);

  const fetch = useCallback(async () => {
    // const {
    //   data: { total_page, total_elements, element_list },
    // } = await fetchJobPostingHistory("OFFER", page);
    // setHistories(element_list);
    // setTotalPage(total_page);
    // setTotalElements(total_elements);
    const { data } = await fetchJobPostings("OFFER");
    setHistories(data);
    // setTotalPage(total_page);
  }, [page]);

  const offer = async (jobId: number, resumeId: number) => {
    //   try {
    //     await cancelApply(jobId, resumeId);
    //     await fetch();
    //   } catch (e) {
    //     alert("오류가 발생하였습니다.");
    //   }
  };

  useEffect(() => {
    (async () => await fetch())();
  }, [page]);

  return (
    <section className="my-corp-job">
      <article className="lnb-contents-tit">
        <div className="tit-box">
          <i className="ic ic-request"></i>
          <span className="txt">면접제안 현황</span>
        </div>
      </article>

      <article className="my-group-head-box">
        <div className="group-head-box">
          <div className="left-box">
            <div className="cnt-box">총 {histories?.length || 0}건</div>
          </div>
          <div className="right-box"></div>
        </div>
      </article>

      <div className="group-data-body-box">
        <div className="list-wrap">
          <ul>
            {histories.map((history) => (
              // <JobPosting key={history.job_id} {...history} />
              <History
                key={history.job_id}
                history={history}
                onCancel={offer}
              />
            ))}
          </ul>
        </div>
      </div>

      {totalPage > 1 && (
        <Paginator
          currentPage={page}
          totalPage={totalPage}
          changePage={(page) => setPage(page)}
        />
      )}
    </section>
  );
};
export default ClinicOfferHistoryPage;

const History = ({
  history,
  onCancel,
}: {
  history: IJobOpeningClinicOverview;
  onCancel: (jobId: number, resumeId: number) => void;
}) => {
  const [showOffers, setShowOffers] = useState<boolean>(false);
  const [offers, setOffers] = useState<IJobActionResumeView[]>([]);

  useEffect(() => {
    if (!showOffers) return;
    (async () => {
      const {
        data: { element_list },
      } = await fetchJobPostingVolunteers(history.job_id, "OFFER");
      setOffers(element_list);
    })();
  }, [showOffers]);

  return (
    <li>
      <div className="item-box">
        <div className="label-box">
          <div className="label-txt type-1">
            <div
              className={`label-txt ${
                isOverdue(history.closing_date) ? "type-2" : "type-1"
              }`}
            >
              {isOverdue(history.closing_date) ? "마감" : "공고중"}
            </div>
          </div>
        </div>

        <DueDate
          startDate={history.registration_date}
          endDate={history.closing_date}
        />
        <div className="name-box">{`${
          history.intern_flag ? "[실습치과] " : ""
        }${history.title}`}</div>

        <article className="my-summary-box">
          <div className="summary-box">
            <div className="summary-item-box">
              <div className="row">
                <div className="summary-txt-box type-5">
                  {history.clinic_name}
                </div>
              </div>
            </div>

            <div className="summary-item-box">
              <div className="row">
                <div className="summary-txt-box type-2">
                  지역 {history.city}
                </div>
              </div>
            </div>
          </div>
        </article>

        <article className="my-information-box">
          <div className="information-box">
            <div className="information-txt">
              {history.work_type_list.join(", ")}
            </div>
            <div className="information-txt">
              {history.occupation_list.join(", ")}
            </div>
            <div className="information-txt">
              {displayMinMaxMonth(
                history.min_work_experience,
                history.max_work_experience
              )}
            </div>
            <div className="information-txt">
              {history.work_list.join(", ")}
            </div>
          </div>
        </article>

        <article className="my-menu-box m-other-type1">
          <div className="menu-box">
            <div
              className="menu-btn type-small type-4"
              onClick={() => setShowOffers(!showOffers)}
            >
              <span className="txt">
                <span className="accent">{history.action_count}</span>건의
                면접제안
              </span>
              <i className="ic ic-arrow"></i>
            </div>
          </div>
        </article>
      </div>
      {showOffers && (
        <div className="volunteer-box on">
          {offers.map((offer) => (
            <div className="volunteer-list">
              <div className="list-box">
                <div className="profile-box">
                  <div className="img-box">
                    <img
                      src={offer.image_filename?resourcePath(offer.image_filename):DefaultProfile}
                      alt="이미지"
                    />
                  </div>
                </div>

                <div className="detail-box">
                  <div className="detail-top-box">
                    <div className="detail-tit-box">{offer.title}</div>
                    <div className="detail-name-box">{offer.name}</div>
                  </div>

                  <div className="detail-bottom-box">
                    <article className="my-summary-box type-padding-reset">
                      <div className="summary-box">
                        <div className="summary-item-box">
                          <div className="row">
                            <div className="summary-txt-box type-1">
                              {" "}
                              {displayExperienceMonth(
                                offer.work_experience_month
                              )}
                            </div>
                          </div>
                          <div className="row">
                            <div className="summary-txt-box type-3">
                              직종 {offer.occupation_list.join(", ")}
                            </div>
                          </div>
                        </div>

                        <div className="summary-item-box">
                          <div className="row">
                            <div className="summary-txt-box type-6">
                              학력 {offer.last_education_name} 졸
                            </div>
                          </div>

                          <div className="row">
                            <div className="summary-txt-box type-7">
                              희망연봉 {displaySalaryInfo(offer.salary_info)}
                            </div>
                          </div>
                        </div>
                      </div>
                    </article>
                  </div>
                </div>

                <div className="detail-menu-box">
                  <a
                    href={`${RESUME_PATH}/${offer.resume_id}`}
                    className="detail-menu-btn"
                  >
                    상세
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </li>
  );
};
