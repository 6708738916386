import { useLocation, useNavigate } from "react-router-dom";

import { useUserContext } from "contexts/UserContext";
import {
  MYPAGE_PATH,
  MYPATH_SUB_PATH,
  SEARCH_PATH,
  LECTURE_PATH,
  LECTURE_SUBPATH,
} from "routes";
import { USER_TYPE } from "utils/enums";
import AlarmBox from "components/commons/Header/AlarmBox";
import MessageBox from "../commons/Header/MessageBox";
import { useMediaQuery } from "react-responsive";
import { logout } from "utils/storage";

const Title = () => {
  const navigate = useNavigate();
  const { userType } = useUserContext();

  return (
    <div className="lnb-tit-box">
      <div className="tit-txt">
        <i className="ic ic-my"></i>
        <span className="txt">마이 페이지</span>
      </div>

      <div className="mobile-head-box">
        <div className="mobile-head-txt">마이 페이지</div>

        <div className="header-menu">
          <div className="menu-box mobile">
            <a href={`${SEARCH_PATH}`} className="search-btn"></a>
          </div>
          <MessageBox />
          <AlarmBox />
          <div className="menu-box">
            <a
              href={`${MYPAGE_PATH}/${
                userType === USER_TYPE.개인회원
                  ? MYPATH_SUB_PATH.RESUME_GROUP
                  : MYPATH_SUB_PATH.JOBPOSTING
              }`}
              className="my-btn"
            ></a>
          </div>
        </div>
      </div>

      <button className="mobile-back-btn" onClick={() => navigate(-1)}></button>
    </div>
  );
};

const LinkMenu = ({
  isActive,
  label,
  url,
}: {
  isActive: boolean;
  label: string;
  url: string;
}) => (
  <li>
    <a href={url} className={`lnb-btn ${isActive ? "on" : ""}`}>
      {label}
    </a>
  </li>
);

const SideMenu = () => {
  const { userType } = useUserContext();
  const { pathname } = useLocation();

  const memberLinkProps = [
    {
      isActive: pathname.includes(MYPATH_SUB_PATH.RESUME_GROUP),
      label: "이력서 관리",
      url: `${MYPAGE_PATH}/${MYPATH_SUB_PATH.RESUME_GROUP}`,
    },
    {
      isActive: false,
      label: "치과인교육 관리",
      url: `${LECTURE_PATH}/${LECTURE_SUBPATH.MY_ING}`,
    },
    {
      isActive: pathname.includes(MYPATH_SUB_PATH.APPLY),
      label: "지원내역",
      url: `${MYPAGE_PATH}/${MYPATH_SUB_PATH.APPLY}`,
    },
    {
      isActive: pathname.includes(MYPATH_SUB_PATH.REQUESTED),
      label: "면접제안",
      url: `${MYPAGE_PATH}/${MYPATH_SUB_PATH.REQUESTED}`,
    },
    {
      isActive: pathname.includes(MYPATH_SUB_PATH.FAVORITE_JOBPOSTING),
      label: "찜한공고",
      url: `${MYPAGE_PATH}/${MYPATH_SUB_PATH.FAVORITE_JOBPOSTING}`,
    },
    {
      isActive: pathname.includes(MYPATH_SUB_PATH.PREVIEW_INCOME),
      label: "실수령액 계산기",
      url: `${MYPAGE_PATH}/${MYPATH_SUB_PATH.PREVIEW_INCOME}`,
    },
    {
      isActive: pathname.includes(MYPATH_SUB_PATH.PROFILE),
      label: "회원정보 관리",
      url: `${MYPAGE_PATH}/${MYPATH_SUB_PATH.PROFILE}`,
    },
  ];

  const clinicLinkProps = [
    {
      isActive: pathname.includes(MYPATH_SUB_PATH.JOBPOSTING),
      label: "공고 현황",
      url: `${MYPAGE_PATH}/${MYPATH_SUB_PATH.JOBPOSTING}`,
    },
    {
      isActive: false,
      label: "치과인교육 관리",
      url: `${LECTURE_PATH}/${LECTURE_SUBPATH.MY_ING}`,
    },
    {
      isActive: pathname.includes(MYPATH_SUB_PATH.VOLUNTEER),
      label: "지원자 현황",
      url: `${MYPAGE_PATH}/${MYPATH_SUB_PATH.VOLUNTEER}`,
    },
    {
      isActive: pathname.includes(MYPATH_SUB_PATH.REQUEST),
      label: "면접제안 현황",
      url: `${MYPAGE_PATH}/${MYPATH_SUB_PATH.REQUEST}`,
    },
    {
      isActive: pathname.includes(MYPATH_SUB_PATH.FAVORITE_RESUME),
      label: "찜한 인재",
      url: `${MYPAGE_PATH}/${MYPATH_SUB_PATH.FAVORITE_RESUME}`,
    },
    {
      isActive: pathname.includes(MYPATH_SUB_PATH.LECTURE),
      label: "법정의무교육 관리",
      url: `${MYPAGE_PATH}/${MYPATH_SUB_PATH.LECTURE}`,
    },
    {
      isActive: pathname.includes(MYPATH_SUB_PATH.PROFILE),
      label: "회원정보 관리",
      url: `${MYPAGE_PATH}/${MYPATH_SUB_PATH.PROFILE}`,
    },
  ];

  const linkProps =
    userType === USER_TYPE.개인회원 ? memberLinkProps : clinicLinkProps;
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1200px)" });

  return (
    <article className="lnb-nav">
      <div className="lnb-wrap">
        <Title />

        <div className="lnb-con-box">
          <ul>
            {linkProps.map((link, i) => (
              <LinkMenu key={i} {...link} />
            ))}
            {!isTabletOrMobile && (
              <li>
                <button
                  className={`lnb-btn`}
                  onClick={() => {
                    logout();
                    window.location.href = "/";
                  }}
                >
                  로그아웃
                </button>
              </li>
            )}
          </ul>
        </div>
      </div>
    </article>
  );
};

export default SideMenu;
