const SearchType = ({
  label,
  type,
  selected,
  onClick,
}: {
  label: string;
  type: string;
  selected: boolean;
  onClick: () => void;
}) => (
  <div className="type-box">
    <a
      // href="https://ssooya.com/209_dental/html/human_info_step.html#none"
      className={`type-btn ${selected ? "on" : ""}`}
      data-type={type}
      onClick={onClick}
    >
      <i className={`ic ic-${type}`}></i>
      <span className="txt">{label}</span>
    </a>
  </div>
);
export default SearchType;
