import MemberImg from "assets/img/img_login_type1.svg";
import ClinicImg from "assets/img/img_login_type2.svg";

import { LOGIN_PATH } from "routes";

import { USER_TYPE } from "utils/enums";

const LoginLink = ({
  label,
  userType,
  url,
}: {
  label: string;
  /**
   * cssType 1 : 개인회원 / 2 : 병원회원
   */
  userType: USER_TYPE;
  url: string;
}) => (
  <a href={url} className="login-type-btn">
    <div className="img-box">
      <img
        // src={`./img/img_login_type${cssType.toString()}.svg`}
        src={userType === USER_TYPE.개인회원 ? MemberImg : ClinicImg}
        alt="이미지"
        className={`login-type-${userType === USER_TYPE.개인회원 ? "1" : "2"}`}
      />
    </div>
    <div className="txt-box">
      <b>{label}</b>
      <br />
      로그인
    </div>
  </a>
);

const LoginBox = () => (
  <div className="login-type-box">
    <LoginLink
      label="개인회원"
      userType={USER_TYPE.개인회원}
      url={`${LOGIN_PATH}?login_type=${USER_TYPE.개인회원}`}
    />
    <LoginLink
      label="병원회원"
      userType={USER_TYPE.병원회원}
      url={`${LOGIN_PATH}?login_type=${USER_TYPE.병원회원}`}
    />
  </div>
);

export default LoginBox;
