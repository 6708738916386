import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";

import { fetchDrive, fetchNotice } from "api/notice/rests";
import { IDriveView, INoticeView } from "api/notice/types";

import Layout from "layouts";

import { Banner } from "components/commons";
import { axiosFile } from "utils/axiosFile";

const NoticeDriveDetailPage = () => {
  const { driveId } = useParams();
  const [drive, setDrive] = useState<IDriveView | null>(null);

  useEffect(() => {
    if (driveId === undefined) return;

    (async () => {
      const { data } = await fetchDrive(Number(driveId));
      setDrive(data);
    })();
  }, [driveId]);
  return (
    <Layout>
      <section className="notice-list">
        <div className="inner wrap">
          <article className="board-view-head">
            <div className="view-tit-box">
              <div className="tit-box">{drive?.title}</div>
            </div>

            <div className="view-sub-box">
              <div className="sub-box">운영자</div>
              <div className="sub-box">
                {dayjs(
                  drive?.upd_date ? drive?.upd_date : drive?.reg_date
                ).format(`YYYY.MM.DD HH:mm:ss`)}
              </div>
            </div>
          </article>

          <article className="board-view-body">
            <div className="contents-box">
              {drive?.contents && (
                <div
                  className="data-txt-box"
                  dangerouslySetInnerHTML={{
                    __html: drive?.contents.replaceAll("\n", "<br/>"),
                  }}
                ></div>
              )}
              {/* {notice?.contents.split("\n").map((row) => (
                <>
                  {row}
                  <br />
                </>
              ))} */}
            </div>
            {drive?.file_download_link && (
              <button
                className="material-link"
                onClick={() =>
                  axiosFile(
                    drive.file_download_link as string,
                    drive.file_download_filename as string
                  )
                }
              >
                파일 자료 🗂
              </button>
            )}
            <div className="img-box">
              <Banner type="NOTICE_DETAIL" />
            </div>
          </article>
        </div>
      </section>
    </Layout>
  );
};
export default NoticeDriveDetailPage;
