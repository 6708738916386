import { useEffect } from "react";

interface MapProps {
  latitude: number | undefined;
  longitude: number | undefined;
}

declare global {
  interface Window {
    kakao: any;
  }
}

const Map = ({ latitude, longitude }: MapProps) => {
  useEffect(() => {
    const mapScript = document.createElement("script");
    mapScript.async = true;
    mapScript.src =
      "//dapi.kakao.com/v2/maps/sdk.js?appkey=b4a4cb35fe6531167415b2eaf5aae024&autoload=false&libraries=clusterer,services";
    document.head.appendChild(mapScript);

    const onLoadKakaoMap = () => {
      window.kakao.maps.load(() => {
        let container = document.getElementById("map");
        let options = {
          center: new window.kakao.maps.LatLng(latitude, longitude),
          level: 7,
        };
        let map = new window.kakao.maps.Map(container, options);
        let marker = new window.kakao.maps.Marker({
          position: new window.kakao.maps.LatLng(latitude, longitude),
        });
        marker.setMap(map);
      });
    };

    mapScript.addEventListener("load", onLoadKakaoMap);

    return () => mapScript.removeEventListener("load", onLoadKakaoMap);
  }, [latitude, longitude]);

  return (
    <div className="map-box">
      <div className="map-area" id="map" style={{ aspectRatio: "2.5" }}>
        <div className="map-search-box" style={{ zIndex: 10 }}>
          <form id="search-form">
            <input type="text" placeholder="지역, 지하철역 검색" id="keyword" />
            <button type="submit" className="search-btn" />
          </form>
        </div>
      </div>
    </div>
  );
};

export default Map;
