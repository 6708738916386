import { IResumeEditRequest } from "api/member/types";
import { AddressFieldRow, InputFieldRow } from "components/FormFields";
import { InputWithToggleFieldRow } from "components/ResumeForm/Comps";
import PhotoIdBox from "components/PhotoIdBox";

const ProfileSection = ({
  address,
  extra_address,
  name,
  birth_date,
  email,
  image_filename,
  public_email,
  phone_number,
  public_phone_number,
  onChangeValue,
}: Pick<
  IResumeEditRequest,
  | "address"
  | "name"
  | "birth_date"
  | "email"
  | "image_filename"
  | "public_email"
  | "phone_number"
  | "public_phone_number"
> & {
  extra_address: string;
  onChangeValue: (
    key:
      | "address"
      | "extra_address"
      | "name"
      | "birth_date"
      | "email"
      | "image_filename"
      | "public_email"
      | "phone_number"
      | "public_phone_number",
    newValue: boolean | string
  ) => void;
}) => {
  return (
    <article className="job-register-corp-info">
      <div className="corp-info-group">
        <div>
          <div className="profile-box type-circle">
            <PhotoIdBox
              photoUrl={image_filename || undefined}
              onChange={(filename) => onChangeValue("image_filename", filename)}
            />
          </div>
          {(!image_filename || image_filename.includes(".blob")) && (
            <div
              style={{
                color: "#ff5252",
                textAlign: "center",
                fontSize: "14px",
                paddingTop:"10px",
              }}
            >
              사진을 업로드해주세요*
            </div>
          )}
        </div>

        <div className="corp-info-box">
          <InputFieldRow
            label="이름*"
            value={name}
            placeholder="이름을 입력해주세요"
            onChange={(name) => onChangeValue("name", name)}
          />
          <InputFieldRow
            label="생년월일*"
            value={birth_date}
            placeholder="생년월일을 입력해주세요"
            onChange={(birth_date) => onChangeValue("birth_date", birth_date)}
          />
          <InputWithToggleFieldRow
            label="이메일*"
            value={email}
            toggleValue={public_email}
            placeholder="이메일을 입력해주세요"
            onChange={(email) => onChangeValue("email", email)}
            onChangeToggle={(on) => onChangeValue("public_email", on)}
          />
          <InputWithToggleFieldRow
            label="휴대폰*"
            type="tel"
            value={phone_number}
            toggleValue={public_phone_number}
            placeholder="휴대폰 번호를 입력해주세요 (-없이 숫자만 입력)"
            numberOnly
            onChange={(phone_number) =>
              onChangeValue("phone_number", phone_number)
            }
            onChangeToggle={(on) => onChangeValue("public_phone_number", on)}
          />
          {/* <InputFieldRow
            label="주소*"
            value={address}
            placeholder="주소를 입력해주세요"
            onChange={(address) => onChangeValue("address", address)}
          /> */}

          <div className="row">
            <div className="subject">주소*</div>
            <div className="con">
              <AddressFieldRow
                value={address}
                onChange={(value) => onChangeValue("address", value)}
              />
            </div>
          </div>
          <InputFieldRow
            label="상세주소"
            value={extra_address}
            onChange={(value) => onChangeValue("extra_address", value)}
            placeholder="상세주소를 입력해주세요"
          />
        </div>
      </div>
    </article>
  );
};

export default ProfileSection;
