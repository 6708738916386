import { useCallback, useEffect, useState } from "react";
import { sortBy } from "lodash";

import { IClinicMemberWishView } from "api/clinic/types";
import {
  fetchWishResume,
  cancelWishResume,
  updateWishMemo,
} from "api/clinic/rests";
import Paginator from "components/Paginator";
import {
  resourcePath,
  displayYearMonth,
  displaySalaryInfo,
} from "utils/formatter";
import { RESUME_PATH } from "routes";
import DefaultProfile from "assets/img/no_profile.png";
const useMyWishJobPosting = () => {
  const [page, setPage] = useState<number>(1);
  const [myWish, setMyWish] = useState<IClinicMemberWishView[]>([]);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [totalPage, setTotalPage] = useState<number>(0);

  const fetchMyWish = useCallback(async () => {
    const {
      data: { total_page, total_elements, element_list },
    } = await fetchWishResume(page);
    setMyWish(element_list);
    setTotalPage(total_page);
    setTotalElements(total_elements);
  }, [page]);

  const cancelWish = async (resumeId: number) => {
    try {
      await cancelWishResume(resumeId);
      await fetchMyWish();
    } catch (e) {
      alert("오류가 발생하였습니다.");
    }
  };

  useEffect(() => {
    (async () => await fetchMyWish())();
  }, [fetchMyWish]);

  return {
    myWish,
    page,
    totalPage,
    totalElements,
    handler: { cancelWish, setPage },
  };
};

const MyWishResumePage = () => {
  const {
    myWish,
    page,
    totalPage,
    totalElements,
    handler: { cancelWish, setPage },
  } = useMyWishJobPosting();

  return (
    <section className="my-corp-job">
      <article className="lnb-contents-tit">
        <div className="tit-box">
          <i className="ic ic-volunteer"></i>
          <span className="txt">찜한 인재</span>
        </div>
      </article>

      <article className="my-group-head-box">
        <div className="group-head-box">
          <div className="left-box">
            <div className="cnt-box">총 {totalElements}건</div>
          </div>
          <div className="right-box"></div>
        </div>
      </article>

      <article className="my-volunteer-list">
        <div className="volunteer-box">
          <div className="volunteer-list">
            {sortBy(myWish, ["action_date", "resume_id"]).map((wish) => (
              <MyWish
                key={wish.resume_id}
                wish={wish}
                onClickCancel={() => cancelWish(wish.resume_id)}
              />
            ))}
          </div>
        </div>
      </article>

      {totalPage > 1 && (
        <Paginator
          currentPage={page}
          totalPage={totalPage}
          changePage={(page) => setPage(page)}
        />
      )}
    </section>
  );
};
export default MyWishResumePage;

const MyWish = ({
  wish,
  onClickCancel,
}: {
  wish: IClinicMemberWishView;
  onClickCancel: () => void;
}) => {
  const [showMemo, setShowMemo] = useState<boolean>(false);
  const [memo, setMemo] = useState<string>(wish.memo);

  const saveMemo = async () => {
    try {
      await updateWishMemo(wish.resume_id, memo);
      alert("메모가 작성되었습니다.");
      window.location.reload()
    } catch (e) {
      alert("에러가 발생하였습니다.");
    }
  }

  return (
    <div className="list-box">
      <div className="list-top-box">
        <div className="profile-box">
          <div className="img-box">
            <img src={wish.image_filename?resourcePath(wish.image_filename):DefaultProfile} alt="이미지" />
          </div>
        </div>

        {/* <div className="option-box">
          <article className="favorite-toggle-box">
            <button type="button" className="favorite-btn"></button>
          </article>

          <div className="date-box web">
            <div className="subject">지원일</div>
            <div className="con">2022.03.11</div>
          </div>
        </div> */}

        <div className="detail-box">
          <div className="detail-top-box">
            <div className="detail-tit-box">{wish.title}</div>
            <div className="detail-name-box">{wish.name}</div>
          </div>

          <div className="detail-bottom-box">
            <article className="my-summary-box type-padding-reset">
              <div className="summary-box">
                <div className="summary-item-box">
                  <div className="row">
                    <div className="summary-txt-box type-1">
                      {displayYearMonth(
                        Math.floor(wish.work_experience_month / 12),
                        wish.work_experience_month % 12
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="summary-txt-box type-3">
                      직종 {wish.occupation_list.join(", ")}
                    </div>
                  </div>
                </div>

                <div className="summary-item-box">
                  <div className="row">
                    <div className="summary-txt-box type-6">
                      학력 {wish.last_education_name} 졸
                    </div>
                  </div>

                  <div className="row">
                    <div className="summary-txt-box type-7">
                      희망연봉 {displaySalaryInfo(wish.salary_info)}
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>

        <div className="detail-menu-box">
          <a href="#none" className="detail-menu-btn" onClick={onClickCancel}>
            삭제
          </a>
          <a
            href={`${RESUME_PATH}/${wish.resume_id}`}
            className="detail-menu-btn"
          >
            상세
          </a>
          <a
            href="#none"
            className="detail-menu-btn type-memo"
            onClick={() => setShowMemo(!showMemo)}
          >
            <span className="txt">메모</span>
            <i className="ic ic-arrow"></i>
          </a>
        </div>

        <div className="mobile-date-box">
          <div className="subject">지원일</div>
          <div className="con">2022.03.11</div>
        </div>
      </div>

      {showMemo && (
        <div className="list-memo-box" style={{ display: "block" }}>
          <div className="memo-box">
            <div className="memo-input-box">
              <textarea
                placeholder="지원자에 대한 메모를 입력하는 곳입니다."
                value={memo || ""}
                onChange={(e) => setMemo(e.target.value)}
              ></textarea>
            </div>

            <div className="memo-btn-box">
              <a href="#none" className="memo-btn" onClick={saveMemo}>
                {wish.memo?'수정':'등록'}
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
