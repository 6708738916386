import { useEffect, useState } from "react";
import { IResumeEditRequest } from "api/member/types";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { uploadMemberImage } from 'api/member/rests';
import { resourcePath } from 'utils/formatter';

const EtcSection = ({
  cover_letter,
  public_flag,
  onChangeValue,
}: Pick<IResumeEditRequest, "cover_letter" | "public_flag"> & {
  onChangeValue: (
    key: "cover_letter" | "public_flag",
    newValue: boolean | string
  ) => void;
}) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const onEditorStateChange = (editorState: EditorState) => {
    // editorState에 값 설정
    setEditorState(editorState);
  };

  useEffect(() => {
    const blocksFromHtml = htmlToDraft(cover_letter || "");
    if (blocksFromHtml) {
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );
      const editorState = EditorState.createWithContent(contentState);
      setEditorState(editorState);
    }
  }, [cover_letter]);

  return (
    <div className="form-group">
      <div className="row">
        <div className="full-box">
          <div className="subject accent">자기소개서</div>
          <div className="con">
            <div className="textbox">
              {/* <textarea
                placeholder="자기소개서를 입력해주세요"
                value={cover_letter || ""}
                onChange={(e) => onChangeValue("cover_letter", e.target.value)}
              ></textarea> */}

              <Editor
                localization={{
                  locale: "ko",
                }}
                toolbar={{
                  options: [
                    "inline",
                    "blockType",
                    "colorPicker",
                    "fontSize",
                    "fontFamily",
                    "list",
                    "textAlign",
                    "emoji",
                    "image",
                  ],
                  image: {
                    uploadCallback: (file: File) => {
                      return new Promise((resolve, reject) => {
                        const data = new FormData();
                        data.append("file", file, file.name);
                        uploadMemberImage(data).then((res) => {
                          resolve({
                            data: { link: resourcePath(res.data.file_name) },
                          });
                        });
                      });
                    },
                    alt: { present: true, mandatory: false },
                  },
                }}
                editorState={editorState}
                placeholder="자기소개서를 입력해주세요"
                onEditorStateChange={onEditorStateChange}
                onBlur={() => {
                  const editorToHtml = draftToHtml(
                    convertToRaw(editorState.getCurrentContent())
                  );
                  onChangeValue("cover_letter", editorToHtml);
                }}
              />
            </div>
          </div>

          {/* <div className="public-floating-box">
            <div className="toggle-btn-box big">
              <label className="toggle">
                <input
                  type="checkbox"
                  checked={public_flag}
                  onChange={() => onChangeValue("public_flag", !public_flag)}
                />
                <span className="toggle-txt toggle-on-txt">이력서 공개</span>
                <span className="toggle-txt toggle-off-txt">이력서 비공개</span>
                <span className="toggle-bg"></span>
              </label>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default EtcSection;
