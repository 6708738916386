import { useNavigate } from "react-router-dom";

import { IJobActionResumeView } from "api/clinic/types";
import { readApplyResume, updateVolunteerMemo } from "api/clinic/rests";
import Salary from "components/Salary";
import { MESSAGE_PATH, RESUME_PATH } from "routes";
import {
  displayDate,
  resourcePath,
  displayYearMonth,
  monthToYearMonth,
} from "utils/formatter";
import {useEffect, useState} from "react";
import { wishResume, cancelWishResume } from "api/clinic/rests";
import DefaultProfile from "assets/img/no_profile.png";
const VolunteerBox = ({
  volunteer,
  handleRefresh,
}: {
  volunteer: IJobActionResumeView;
  handleRefresh: () => void;
}) => {
  const navigate = useNavigate();
  const [showMemo, setShowMemo] = useState<boolean>(false);
  const [memo, setMemo] = useState<string>(volunteer.memo);
  const [isMemoEdit, setIsMemoEdit] = useState<boolean>(false);


  const toggleWish = async () => {
    volunteer.wish_flag
      ? await cancelWishResume(volunteer.resume_id)
      : await wishResume(volunteer.resume_id);
    await handleRefresh();
  };
  const sendReadSignal = async (jobId: number, resumeId: number) => {
    await readApplyResume(jobId, resumeId);
  };
  const saveMemo = async () => {
    try {
      await updateVolunteerMemo(volunteer.job_id, volunteer.resume_id, memo);
      setIsMemoEdit(false);
      alert("메모가 작성되었습니다.");
    } catch (e) {
      alert("에러가 발생하였습니다.");
    }
  };

  const { year, month } = monthToYearMonth(volunteer.work_experience_month);
  return (
    <div className="list-box">
      <div className="list-top-box">
        <div className="profile-box">
          <div className="img-box">
            <img src={volunteer.image_filename?resourcePath(volunteer.image_filename):DefaultProfile} alt="이미지" />
          </div>
        </div>

        <div className="option-box">
          <article className="favorite-toggle-box">
            <button
              type="button"
              className={`favorite-btn ${volunteer.wish_flag ? "on" : ""}`}
              onClick={toggleWish}
            ></button>
          </article>

          <div className="date-box web">
            <div className="subject">지원일</div>
            <div className="con">{displayDate(volunteer.action_date)}</div>
          </div>
        </div>

        <div className="detail-box">
          <div className="detail-top-box">
            <div className="detail-tit-box">{volunteer.title}</div>
            <div className="detail-name-box">{volunteer.name}</div>
          </div>

          <div className="detail-bottom-box">
            <article className="my-summary-box type-padding-reset">
              <div className="summary-box">
                <div className="summary-item-box">
                  <div className="row">
                    <div className="summary-txt-box type-1">
                      {displayYearMonth(year, month)}
                    </div>
                  </div>
                  <div className="row">
                    <div className="summary-txt-box type-3">
                      직종 {volunteer.occupation_list.join(", ")}
                    </div>
                  </div>
                </div>

                <div className="summary-item-box">
                  <div className="row">
                    <div className="summary-txt-box type-6">
                      학력 {volunteer.last_education_name}{" "}
                      {volunteer.graduation_flag ? "졸" : ""}
                    </div>
                  </div>

                  <div className="row">
                    <div className="summary-txt-box type-7">
                      희망연봉 <Salary {...volunteer.salary_info} />
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>

        <div className="detail-menu-box">
          <a
            href={`${MESSAGE_PATH}?target_id=${volunteer.resume_id}&memberId=${volunteer.member_id}&clinicName=${volunteer.name}`}
            className="detail-menu-btn type-msg"
          >
            <span className="txt">메세지</span>
            <i className="ic ic-message"></i>
          </a>
          <div
            className="detail-menu-btn"
            onClick={() => {
              sendReadSignal(volunteer.job_id, volunteer.resume_id);
              navigate(`${RESUME_PATH}/${volunteer.resume_id}`);
            }}
          >
            상세
          </div>
          <div
            className="detail-menu-btn type-memo"
            onClick={() => setShowMemo(!showMemo)}
          >
            <span className="txt">메모</span>
            <i className="ic ic-arrow"></i>
          </div>
        </div>
        <div className="mobile-date-box">
          <div className="subject">지원일</div>
          <div className="con">{displayDate(volunteer.action_date)}</div>
        </div>
      </div>

      <div
        className="list-memo-box"
        style={{ display: showMemo ? "block" : "" }}
      >
        <div className="memo-box">
          <div className="memo-input-box">
            {volunteer.memo && !isMemoEdit ? (
              <div className="memo-txt-box">{memo}</div>
            ) : (
              <textarea
                placeholder="지원자에 대한 메모를 입력하는 곳입니다."
                value={memo || ""}
                onChange={(e) => setMemo(e.target.value)}
              ></textarea>
            )}
          </div>

          <div className="memo-btn-box">
            {volunteer.memo && !isMemoEdit ? (
              <a
                className="memo-btn"
                onClick={() => setIsMemoEdit(true)}
                role="button"
              >
                수정
              </a>
            ) : (
              <a className="memo-btn" onClick={saveMemo} role="button">
                등록
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VolunteerBox;
