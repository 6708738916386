import {
  IResumeLicenseView,
  IResumeLicenseEdit,
  IResumeWorkExperienceView,
  ISalaryRequest,
} from "api/resume/types";

export enum FIELDS {
  아이디 = "member_id",
  비밀번호 = "password",
  이메일 = "email",
  성별 = "gender",
  직종 = "occupation",
  인증코드 = "auth_code",
  인증코드상태 = "auth_state",
  개인정보수집정책동의목록 = "policy_agree_list",
  면허번호 = "licence_number",
  추천인 = "event_follow_id",
  병원추천인 = "event_follow_id_clinic",
}

/** TODO: 공통으로 별도 파일 분리? */
export interface IMemberPolicyAgreeRequest {
  /**
   * 개인 정보 수집 정책 고유 번호
   */
  policy_id: number;
  /**
   * 개인 정보 수집 정책 동의 여부 (true: 동의, false: 비동의
   */
  agree: boolean;
}

export interface IMemberPolicyAgreeInfo {
  policy_id: number;
  type: string;
  category: string;
  require_flag: boolean;
  title: string;
  contents: string;
  agree: boolean;
}

export interface IMemberView {
  member_id: string;
  name: string;
  gender: string;
  birth_date: string;
  phone_number: string;
  email: string;
  occupation: string;
}

export interface IResumeLicenseRequest {
  license: string;
  certificate_date: string;
}
// interface IPhoneUpdateRequest {}
export interface IPasswordUpdateRequest {
  /**
   * 현재 password
   */
  password: string;
  /**
   * 새로운 password
   */
  new_password: string;
}

/**
 * 회원가입
 */
export interface IMemberCreateRequest {
  member_id: string;
  password: string;
  auth_code: string;
  email: string;
  image_filename?: string;
  occupation: string;
  name?: string;
  licence_number?: string;
  referrer?: string;
  policy_agree_list: Array<{
    policy_id: number;
    agree: boolean;
  }>;
  event_follow_id: string;
  event_follow_id_clinic: string;
}

/**
 * 회원 정보 변경
 */
export type IMemberUpdateRequest = Pick<
  IMemberCreateRequest,
  "name" | "email"
> & {
  gender: string;
  password_info?: IPasswordUpdateRequest;
  image_filename: string;
  auth_code: string;
};

export interface IResultResponseMemberView {
  /**
   * 회원 고유 id
   */
  member_id: string;
  /**
   * 회원 이름
   */
  name: string;
  /**
   * 성별
   */
  gender: string;
  /**
   * 생년월일
   */
  birth_date: string;
  /**
   * 회원 이메일
   */
  email: string;
  /**
   * 회원 직종
   */
  occupation: string;

  /**
   * 핸드폰 번호 변경을 위한 PASS 인증
   */
  auth_code: string;
}

/**
 * 회원 로그인
 */
export interface IMemberLoginRequest {
  last_login_device?: string;
  member_id: string;
  password: string;
  fcm_token?: string;
}
/**
 * 마스터 로그인
 */
export interface IMasterLoginRequest {
  member_id: string;
}
/**
 * 이력서
 */
export type TResumeEditRequestFieldKey = keyof IResumeEditRequest;
export interface IResumeEditRequest {
  group_id: number;
  image_filename: string;
  name: string;
  birth_date: string;
  email: string;
  public_email: true;
  phone_number: string;
  public_phone_number: true;
  address: string;
  title: string;
  occupation_list: Array<string>;
  work_list: Array<string>;
  work_type_list: Array<string>;
  salary_info: ISalaryRequest;
  last_education_type: string;
  last_education_name: string;
  last_education_start_date: string;
  last_education_end_date: string;
  graduation_type: string;
  work_experience_list: Array<IResumeWorkExperienceView> | null;
  work_experience_month: number;
  license_list: Array<IResumeLicenseEdit>;
  cover_letter?: string;
  cover_letter_filename?: string;
  public_flag: true;
  public_class_complete: boolean;
}

/**
 * 이력서 그룹 view
 */
export interface IResumeGroupView {
  group_id: number;
  group_name: string;
  default_flag: boolean;
  resume_count: number;
  reg_date: string;
  upd_date: string;
}
/**
 * 이력서 그룹 생성/편집
 *
 * @property {string} group_name 이력서 그룹명
 */
export interface IResumeGroupEditRequest {
  /**
   * 이력서 그룹명
   */
  group_name: string;
}

export interface IResumeMemberView {
  resume_id: number;
  member_id: string;
  group_name: string;
  image_filename: string;
  name: string;
  birth_date: string;
  email: string;
  public_email: true;
  phone_number: string;
  public_phone_number: true;
  address: string;
  title: string;
  occupation_list: Array<string>;
  work_type_list: Array<string>;
  work_list: Array<string>;
  annual_salary: number;
  last_education_type: string;
  last_education_name: string;
  last_education_start_date: string;
  last_education_end_date: string;
  work_experience_list: [
    {
      company_name: string;
      work: string;
      start_date: string;
      end_date: string;
    }
  ];
  work_experience_month: number;
  license_list: Array<IResumeLicenseView>;
  cover_letter: string;
  public_flag: true;
  public_class_complete: true;
}

export interface IResumeMemberOverview {
  resume_id: number;
  title: string;
  city: string;
  work_experience_month: number;
  occupation_list: Array<string>;
  work_type_list: Array<string>;
  work_list: Array<string>;
  public_flag: boolean;
  upd_date: string;
}

export interface IMemberWishView {
  job_id: number;
  clinic_name: string;
  title: string;
  city: string;
  closing_date: string;
  work_type_list: Array<string>;
  occupation_list: Array<string>;
  min_work_experience: number;
  max_work_experience: number;
  work_list: Array<string>;
  intern_flag: boolean;
}

export interface IJobActionView {
  job_id: number;
  resume_id: number;
  action_type: string;
  action: string;
  read_flag: boolean;
  upd_Date: string;
}

export interface IJobActionOpeningView {
  job_id: number;
  resume_id: number;
  action_type: string;
  action: string;
  read_flag: true;
  action_date: string;
  clinic_name: string;
  title: string;
  closing_date: string;
  city: string;
  occupation_list: Array<string>;
  work_list: Array<string>;
  work_type_list: Array<string>;
  min_work_experience: number;
  max_work_experience: number;
  intern_flag: boolean;
  member_id: string;
}

export interface IMemberFindWithNameRequest {
  member_id:string;
  name:string;
}