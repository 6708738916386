import { GROUP_CODE_ID } from "api/code/types";
import { IResumeEditRequest } from "api/member/types";
import DatePicker from "components/DatePicker";
import { CheckboxGroupFieldRow } from "components/FormFields";
import { InputFieldRow } from "components/FormFields";
import { EDUCATION_TYPE } from "utils/enums";
import moment from "moment";

type TProps = Pick<
  IResumeEditRequest,
  | "last_education_type"
  | "last_education_name"
  | "last_education_start_date"
  | "last_education_end_date"
  | "graduation_type"
> & {
  onChangeValue: (
    key:
      | "last_education_type"
      | "last_education_name"
      | "last_education_start_date"
      | "last_education_end_date"
      | "graduation_type",
    newValue: string | Array<string>
  ) => void;
};

const EducationSection = ({
  last_education_type,
  last_education_name,
  last_education_start_date,
  last_education_end_date,
  graduation_type,
  onChangeValue,
}: TProps) => (
  <div className="form-group">
    <div className="form-tit-box">학력사항</div>

    <CheckboxGroupFieldRow
      label="최종학력"
      selected={[last_education_type]}
      groupCodeId={GROUP_CODE_ID.최종학력}
      pickLastOne={true}
      onChange={(occupations) =>
        onChangeValue("last_education_type", occupations[0])
      }
      excludedLabel={["무관"]}
    />

    <InputFieldRow
      label="학교명"
      value={last_education_name}
      placeholder="학교명을 입력해주세요"
      withItemBox={true}
      onChange={(name) => onChangeValue("last_education_name", name)}
    />

    <div className="row last-border">
      <div className="item-box">
        <div className="subject">재학기간</div>
        <div className="con">
          <div className="career-box">
            <div className="mobile-row-box">
              <DatePicker
                value={last_education_start_date}
                maxDate={
                  last_education_end_date
                    ? moment(last_education_end_date).toDate()
                    : null
                }
                onChange={(date) =>
                  onChangeValue("last_education_start_date", date)
                }
              />
              <div className="year-range-box">-</div>
              <DatePicker
                value={last_education_end_date}
                minDate={
                  last_education_start_date
                    ? moment(last_education_start_date).toDate()
                    : new Date()
                }
                onChange={(date) =>
                  onChangeValue("last_education_end_date", date)
                }
              />
            </div>

            <div className="mobile-row-box">
              <div className="sbox smaller off type-support m-other-type">
                <select
                  value={graduation_type}
                  onChange={(e) =>
                    onChangeValue("graduation_type", e.target.value)
                  }
                >
                  <option value="졸업">졸업</option>
                  <option value="휴학">휴학</option>
                  <option value="재학">재학</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default EducationSection;
