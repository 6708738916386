import { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Layout from "layouts";

import { IChatMemberView, IQnaChatView } from "api/types";
import { fetchChatList as fetchClinicChatList } from "api/clinic/rests";
import {
  fetchChatList as fetchMemberChatList,
  fetchClinicQna,
  fetchMemberQna,
} from "api/member/rests";
import { useUserContext } from "contexts/UserContext";
import { ChatContent } from "components/Message";
import { USER_TYPE } from "utils/enums";
import { displayTime } from "utils/formatter";

import ChatListHead from "components/Message/ChatListHead";
import { MESSAGE_PATH } from "routes";
import QnaContent from "components/Message/QnaContent";
import LoginPage from "./LoginPage";

const MessagePage = () => {
  const [params, setParams] = useSearchParams();
  const chat_name = params.get("name");
  const is_qna = params.get("qna");
  const navigate = useNavigate();

  const { isSignIn, userType } = useUserContext();
  const [keyword, setKeyword] = useState<string>("");
  const [chats, setChats] = useState<IChatMemberView[]>([]);
  const [qnas, setQnas] = useState<IQnaChatView[]>([]);
  const [selectedChat, setSelectedChat] = useState<IChatMemberView | null>(
    null
  );
  const [onlyUnread, setOnlyUnread] = useState<boolean>(false);
  const [updateTrigger, setUpdateTrigger] = useState<number>(0);
  const [isTempChatAdded, setIsTempChatAdded] = useState(false);
  const [isQnaSelected, setIsQnaSelected] = useState<boolean>(false);

  const fetchChats = useCallback(async () => {
    const urlMemberId = params.get("memberId") ?? "";
    const urlName = params.get("clinicName") ?? "";
    const urlTargetId = params.get("target_id");
    const targetId = urlTargetId ? parseInt(urlTargetId, 10) : 0;
    const { data } =
        userType === USER_TYPE.개인회원
            ? await fetchMemberChatList(keyword)
            : await fetchClinicChatList(keyword);

    const hasMatchingMember = data.some((chat) => chat.member_id === urlMemberId);

    if (urlMemberId && urlName && !hasMatchingMember) {
      data.unshift({
        chat_id: targetId,
        name: urlName,
        last_reg_date: new Date().toISOString(),
        last_message: "",
        member_id: urlMemberId,
        read_flag: true,
        member_type: "",
        sender: "",
      });
      setIsTempChatAdded(true);
    }

    setChats([...data]);

    const { data: qna_data } =
      userType === USER_TYPE.개인회원
        ? await fetchMemberQna()
        : await fetchClinicQna();
    setQnas(qna_data);
    if (!selectedChat) {
      setSelectedChat(data.find((d) => d.name === chat_name) || null);
    }
  }, [chat_name, userType, keyword, updateTrigger]);

  useEffect(() => {
    (async () => await fetchChats())();
  }, [fetchChats]);

  if (!isSignIn) {
    return <LoginPage />;
  }

  return (
    <Layout>
      <section className="chat">
        <div className="inner wrap">
          <div className="chat-list">
            <ChatListHead
              onlyUnread={onlyUnread}
              keyword={keyword}
              onChangeOnlyUnread={(onlyUnread) => setOnlyUnread(onlyUnread)}
              onChangeKeyword={(keyword) => setKeyword(keyword)}
              // onSearch={searchChats}
            />
            <div className="chat-list-body scroll-effect">
              <ul>
                <li>
                  <div
                      className="chat-item-box"
                      style={{
                        cursor: "pointer",
                        backgroundColor: isQnaSelected ? "lightblue" : "transparent", // 선택 시 배경색 변경
                      }}
                      onClick={() => {
                        setSelectedChat(null); // 기존 선택된 채팅 초기화
                        setIsQnaSelected(true); // QnA 선택 상태 활성화
                        navigate(`${MESSAGE_PATH}?qna=true`);

                        // unshift된 임시 데이터 제거
                        if (isTempChatAdded) {
                          setChats((prevChats) => prevChats.slice(1));
                          setIsTempChatAdded(false);
                        }
                      }}
                  >
                    <div className="subject-box">
                      <div className="subject-txt">1:1 문의</div>
                      <div className="date-txt">{displayTime(qnas.at(-1)?.reg_date)}</div>
                    </div>
                    <div className="con-box">{qnas.at(-1)?.message}</div>
                  </div>
                </li>
                {chats
                    .filter((chat) => (onlyUnread ? !chat.read_flag : true))
                    .map((chat) => {
                      const urlMemberId = params.get("memberId") ?? "";
                      const urlName = params.get("clinicName") ?? "";
                      const isHighlighted =
                          !isQnaSelected &&
                          (urlMemberId && urlName &&!is_qna && (chat.member_id === urlMemberId || !chats.some((c) => c.member_id === urlMemberId)));

                      return (
                          <li key={chat.chat_id}>
                            <div
                                className="chat-item-box"
                                style={{
                                  cursor: chat.last_message ? "pointer" : "default",
                                  backgroundColor: isHighlighted ? "lightblue" : "transparent",
                                }}
                                onClick={
                                  chat.last_message
                                      ? () => {
                                        setIsQnaSelected(false); // QnA 선택 해제
                                        if (isTempChatAdded) {
                                          setChats(chats.slice(1));
                                          setIsTempChatAdded(false);
                                        }
                                        setParams({
                                          target_id: chat.chat_id.toString(),
                                          clinicName: chat.name.toString(),
                                          memberId: chat.member_id.toString(),
                                        });
                                        setSelectedChat(chat);
                                      }
                                      : undefined
                                } // 메시지가 없으면 클릭 방지
                            >
                              <div className="subject-box">
                                <div className="subject-txt">{chat.name}</div>
                                <div className="date-txt">
                                  {displayTime(chat.last_reg_date)}
                                </div>
                              </div>

                              <div className="con-box">{chat.last_message}</div>
                            </div>
                          </li>
                      );
                    })}
              </ul>
            </div>
          </div>
          {!is_qna ? (
              <ChatContent
                  chat={selectedChat}
                  onUpdateChat={() => setUpdateTrigger(updateTrigger + 1)}
              />
          ) : (
              <QnaContent qnas={qnas} setQnas={setQnas}/>
          )}
        </div>
      </section>
    </Layout>
  );
};

export default MessagePage;
