import { useCallback, useEffect, useState } from "react";

import { IMemberWishView } from "api/member/types";
import { fetchWishJobPosting, cancelWishJobPosting } from "api/member/rests";
import { PageTitle } from "components/MyPage";
import Paginator from "components/Paginator";
import { displayDate, displayMinMaxMonth } from "utils/formatter";
import ApplyPopup from "components/Popups/ApplyPopup";
import { JOBPOSTING_PATH } from "routes";

const useMyWishJobPosting = () => {
  const [page, setPage] = useState<number>(1);
  const [myWish, setMyWish] = useState<IMemberWishView[]>([]);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [totalPage, setTotalPage] = useState<number>(0);

  const fetchMyWish = useCallback(async () => {
    const {
      data: { total_page, total_elements, element_list },
    } = await fetchWishJobPosting(page);
    setMyWish(element_list);
    setTotalPage(total_page);
    setTotalElements(total_elements);
  }, [page]);

  const cancelWish = async (jobId: number) => {
    try {
      await cancelWishJobPosting(jobId);
      await fetchMyWish();
    } catch (e) {
      alert("오류가 발생하였습니다.");
    }
  };

  useEffect(() => {
    (async () => await fetchMyWish())();
  }, [fetchMyWish]);

  return {
    myWish,
    page,
    totalPage,
    totalElements,
    handler: { cancelWish, setPage },
  };
};

const MyWishJobPostingPage = () => {
  const {
    myWish,
    page,
    totalPage,
    totalElements,
    handler: { cancelWish, setPage },
  } = useMyWishJobPosting();

  return (
    <section className="my-resume-group">
      <article className="lnb-contents-tit">
        <PageTitle iconClass="ic-favorite" title="찜한공고" />
      </article>

      <article className="my-group-head-box">
        <div className="group-head-box">
          <div className="left-box">
            <div className="cnt-box">총 {totalElements}건</div>
          </div>
        </div>
      </article>

      <div className="group-data-body-box">
        <div className="list-wrap">
          <ul>
            {myWish.map((wish) => (
              <MyWish
                key={wish.job_id}
                wish={wish}
                onClickCancel={() => cancelWish(wish.job_id)}
              />
            ))}
          </ul>
        </div>
      </div>

      {totalPage > 1 && (
        <Paginator
          currentPage={page}
          totalPage={totalPage}
          changePage={(page) => setPage(page)}
        />
      )}
    </section>
  );
};
export default MyWishJobPostingPage;

const MyWish = ({
  wish,
  onClickCancel,
}: {
  wish: IMemberWishView;
  onClickCancel: () => void;
}) => {
  const [showApplyPopup, setShowApplyPopup] = useState<boolean>(false);

  return (
    <>
      <li>
        <div className="item-box">
          <div className="date-box">
            <span className="subject">마감일</span>{" "}
            <span className="con">{displayDate(wish.closing_date)}</span>
          </div>
          <a href={`${JOBPOSTING_PATH}/${wish.job_id}`} className="name-box">
            {`${wish.intern_flag ? "[실습치과] " : ""}${wish.title}`}
          </a>

          <article className="my-summary-box m-type3">
            <div className="summary-box">
              <div className="summary-item-box">
                <div className="row">
                  <div className="summary-txt-box type-5">
                    {wish.clinic_name}
                  </div>
                </div>
              </div>

              <div className="summary-item-box">
                <div className="row">
                  <div className="summary-txt-box type-2">지역 {wish.city}</div>
                </div>
              </div>
            </div>
          </article>

          <article className="my-information-box">
            <div className="information-box">
              <div className="information-txt">
                {wish.work_type_list.join(", ")}
              </div>
              <div className="information-txt">
                {wish.occupation_list.join(", ")}
              </div>
              <div className="information-txt">
                {displayMinMaxMonth(
                  wish.min_work_experience,
                  wish.max_work_experience
                )}
              </div>
              <div className="information-txt">{wish.work_list.join(", ")}</div>
            </div>
          </article>

          <article className="my-menu-box">
            <div className="menu-box">
              <a
                href="#none"
                className="menu-btn type-1"
                onClick={() => setShowApplyPopup(true)}
              >
                <span className="txt">지원</span>
              </a>
              <a
                href="#none"
                className="menu-btn type-2"
                onClick={onClickCancel}
              >
                <span className="txt">취소</span>
              </a>
            </div>
          </article>
        </div>
      </li>

      {showApplyPopup && (
        <ApplyPopup
          jobId={wish.job_id}
          handleClose={() => setShowApplyPopup(false)}
        />
      )}
    </>
  );
};
