import AlarmBox from "components/commons/Header/AlarmBox";
import { useUserContext } from "contexts/UserContext";
import {
  MESSAGE_PATH,
  MYPAGE_PATH,
  MYPATH_SUB_PATH,
  SEARCH_PATH,
} from "routes";
import { USER_TYPE } from "utils/enums";

const MobileHeader = () => {
  const { userType } = useUserContext();

  return (
    <div className="mobile-head-box">
      <div className="mobile-head-txt"></div>

      <div className="header-menu">
        <div className="menu-box mobile">
          <a href={`${SEARCH_PATH}`} className="search-btn"></a>
        </div>

        <div className="menu-box">
          <a href={`${MESSAGE_PATH}`} className="msg-btn on"></a>
        </div>

        <AlarmBox />

        <div className="menu-box">
          <a
            href={`${MYPAGE_PATH}/${
              userType === USER_TYPE.개인회원
                ? MYPATH_SUB_PATH.RESUME_GROUP
                : MYPATH_SUB_PATH.JOBPOSTING
            }`}
            className="my-btn"
          ></a>
        </div>
      </div>
    </div>
  );
};
export default MobileHeader;
